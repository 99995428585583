import Vue from 'vue'
import Router from 'vue-router'

// views
import AdministrationProductCheckerActivityLogs from '@/views/administration/productChecker/ActivityLogs'
import AdministrationProductCheckerProducts from '@/views/administration/productChecker/Products'
import ChangePassword from '@/views/profile/ChangePassword'
import DashboardStatusBranchDistribution from '@/views/dashboard/StatusBranchDistribution'
import DashboardHighSeasonOrdersDistribution from '@/views/dashboard/HighSeasonOrdersDistribution'
import DriverManagementDrivers from '@/views/driverManagement/Drivers'
import DriverManagementDriver from '@/views/driverManagement/Driver'
import DriverManagementDeliveryCompanies from '@/views/driverManagement/DeliveryCompanies'
import FridgeManagementFridge from '@/views/fridgeManagement/Fridge'
import FridgeManagementProductionFridge from '@/views/fridgeManagement/ProductionFridge'
import WrappingAreaManagementWrappingArea from '@/views/wrappingAreaManagement/WrappingArea'
import HighSeasonDashboard from '@/views/highSeason/Dashboard'
import HighSeasonDispatchingFridges from '@/views/highSeason/DispatchingFridges'
import HighSeasonProductionFridges from '@/views/highSeason/ProductionFridges'
import HighSeasonOrdersDelivery from '@/views/highSeason/OrdersDelivery'
import HighSeasonOrdersDeliveryStatus from '@/views/highSeason/OrdersDeliveryStatus'
import HighSeasonTransitFridges from '@/views/highSeason/TransitFridges'
import HighSeasonSeason from '@/views/highSeason/Season'
import HighSeasonSeasons from '@/views/highSeason/Seasons'
import HighSeasonWrappingArea from '@/views/highSeason/WrappingArea'
import Login from '@/views/Login'
import Order from '@/views/orderManagement/Order'
import PurchaseOrder from '@/views/orderManagement/PurchaseOrder'
import ReportSalesByProduct from '@/views/report/sales/Product'
import ReportOrderOrder from '@/views/report/order/Order'
import ReportOrderDelivery from '@/views/report/order/Delivery'
import ReportOrderDeliveryCost from '@/views/report/order/DeliveryCost'
import ReportOrderDriver from '@/views/report/order/Driver'
import ReportCustomerCustomer from '@/views/report/customer/Customer'
import ReportCustomerOrder from '@/views/report/customer/Order'
import SearchOrders from '@/views/orderManagement/Orders'
import UserManagementActivityLogs from '@/views/userManagement/ActivityLogs'
import UserManagementUser from '@/views/userManagement/User'
import UserManagementUsers from '@/views/userManagement/Users'
import CustomerManagementCustomer from '@/views/customerManagement/Customer'
import CustomerManagementCustomers from '@/views/customerManagement/Customers'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/dashboard/statusBranchDistribution',
        name: 'dashboardStatusBranchDistribution',
        component: DashboardStatusBranchDistribution
    },
    {
        path: '/dashboard/highSeasonOrdersDistribution',
        name: 'dashboardHighSeasonOrdersDistribution',
        component: DashboardHighSeasonOrdersDistribution
    },
    {
        path: '/orderManagement/orders',
        name: 'orderManagementOrders',
        component: SearchOrders
    },
    {
        path: '/orderManagement/order',
        name: 'order',
        component: Order
    },
    {
        path: '/orderManagement/purchaseOrder',
        name: 'purchaseOrder',
        component: PurchaseOrder
    },
    {
        path: '/profile/changepassword',
        name: 'changePassword',
        component: ChangePassword
    },
    {
        path: '/userManagement/users',
        name: 'userManagementUsers',
        component: UserManagementUsers
    },
    {
        path: '/userManagement/User',
        name: 'userManagementUser',
        component: UserManagementUser
    },
    {
        path: '/userManagement/activityLogs',
        name: 'userManagementActivityLogs',
        component: UserManagementActivityLogs
    },
    {
        path: '/highSeason/seasons',
        name: 'highSeasonSeasons',
        component: HighSeasonSeasons
    },
    {
        path: '/highSeason/season',
        name: 'highSeasonSeason',
        component: HighSeasonSeason
    },
    {
        path: '/highSeason/wrappingArea',
        name: 'highSeasonWrappingArea',
        component: HighSeasonWrappingArea
    },
    {
        path: '/highSeason/dashboard',
        name: 'highSeasonDashboard',
        component: HighSeasonDashboard
    },
    {
        path: '/fridgeManagement/fridge',
        name: 'fridgeManagementFridge',
        component: FridgeManagementFridge
    },
    {
        path: '/fridgeManagement/productionFridge',
        name: 'fridgeManagementProductionFridge',
        component: FridgeManagementProductionFridge
    },
    {
        path: '/wrappingAreaManagement/WrappingArea',
        name: 'wrappingAreaManagementWrappingArea',
        component: WrappingAreaManagementWrappingArea
    },
    {
        path: '/highSeason/productionFridges',
        name: 'highSeasonProductionFridges',
        component: HighSeasonProductionFridges
    },
    {
        path: '/highSeason/dispatchingFridges',
        name: 'highSeasonDispatchingFridges',
        component: HighSeasonDispatchingFridges
    },
    {
        path: '/highSeason/transitFridges',
        name: 'highSeasonTransitFridges',
        component: HighSeasonTransitFridges
    },
    {
        path: '/highSeason/ordersDelivery',
        name: 'highSeasonOrdersDelivery',
        component: HighSeasonOrdersDelivery
    },
    {
        path: '/highSeason/ordersDeliveryStatus',
        name: 'highSeasonOrdersDeliveryStatus',
        component: HighSeasonOrdersDeliveryStatus
    },
    {
        path: '/driverManagement/drivers',
        name: 'driverManagementDrivers',
        component: DriverManagementDrivers
    },
    {
        path: '/driverManagement/driver',
        name: 'driverManagementDriver',
        component: DriverManagementDriver
    },
    {
        path: '/driverManagement/deliveryCompanies',
        name: 'driverManagementDeliveryCompanies',
        component: DriverManagementDeliveryCompanies
    },
    {
        path: '/report/sales/product',
        name: 'reportSalesByProduct',
        component: ReportSalesByProduct
    },
    {
        path: '/report/order/order',
        name: 'reportOrderOrder',
        component: ReportOrderOrder
    },
    {
        path: '/report/order/delivery',
        name: 'reportOrderDelivery',
        component: ReportOrderDelivery
    },
    {
        path: '/report/order/deliveryCost',
        name: 'reportOrderDeliveryCost',
        component: ReportOrderDeliveryCost
    },
    {
        path: '/report/order/driver',
        name: 'reportOrderDriver',
        component: ReportOrderDriver
    },
    {
        path: '/report/customer/customer',
        name: 'reportCustomerCustomer',
        component: ReportCustomerCustomer
    },
    {
        path: '/report/customer/order',
        name: 'reportCustomerOrder',
        component: ReportCustomerOrder
    },
    {
        path: '/customerManagement/customers',
        name: 'customerManagementCustomers',
        component: CustomerManagementCustomers
    },
    {
        path: '/customerManagement/customer',
        name: 'customerManagementCustomer',
        component: CustomerManagementCustomer
    },
    {
        path: '/administration/productChecker/activityLogs',
        name: 'administrationProductCheckerActivityLogs',
        component: AdministrationProductCheckerActivityLogs
    },
    {
        path: '/administration/productChecker/products',
        name: 'administrationProductCheckerProducts',
        component: AdministrationProductCheckerProducts
    },
];

export default routes;