<script>
import Cryptr from "cryptr";

// node version 10.16.0

const secret =
  "VQ8CIYC9IE3J0VH7HXP8JRZS9VCTQHFYQZT9M9Q95MN5FESZLI2SX77QKJO0GZMF";
const cryptr = new Cryptr(secret);

const options = {
  version: "web-1.11.1",
  crypter: cryptr,
  baseUrl: "https://api.exotica.com",
  // baseUrl: "https://dev1.exotica.com:444",
  user:
    localStorage.getItem("user") === null
      ? {}
      : JSON.parse(cryptr.decrypt(localStorage.getItem("user")))
};

export default options;
</script>
