<template>
  <!-- begin login -->
  <div class="login bg-black animated fadeInDown">
    <loading
      loader="spinner"
      background-color="#000"
      :opacity="spinnerOpacity"
      :active.sync="spinnerIsLoading"
      :can-cancel="spinnerUserCanCancel"
      :is-full-page="spinnerFullPage"
    ></loading>
    <!-- begin brand -->
    <div class="login-header">
      <div class="brand">
        <img src="@/assets/images/logo.png" alt height="70" class="inline-desktop-view" />
        <img src="@/assets/images/logo-white.png" alt height="70" class="inline-mobile-view" />
      </div>
      <div class="icon">
        <i class="fa fa-lock"></i>
      </div>
    </div>
    <!-- end brand -->
    <!-- begin login-content -->
    <div class="login-content">
      <form @submit="login" class="margin-bottom-0">
        <div class="form-group m-b-20">
          <input
            type="text"
            ref="username"
            class="form-control form-control-lg inverse-mode"
            style="text-transform: lowercase"
            placeholder="Username"
            required
            autocomplete="off"
            v-model="user.username"
            @keydown="closeMessage"
          />
        </div>
        <div class="form-group m-b-20">
          <input
            type="password"
            class="form-control form-control-lg inverse-mode"
            placeholder="Password"
            required
            autocomplete="off"
            v-model="user.password"
            @keydown="closeMessage"
          />
        </div>
        <div class="login-buttons">
          <button type="submit" class="btn btn-success btn-block btn-lg">Sign me in</button>
        </div>
        <div class="forgot-password-link">
          <a
            v-if="user && user.username.trim() != ''"
            href="javascript:void(0)"
            @click="forgotPassword"
          >Forgot Password?</a>
        </div>
      </form>
    </div>
    <!-- end login-content -->
    <!-- Error Message -->
    <div id="autentication-msg">
      <div :class="{show: showErrMsg}" class="alert alert-danger fade">
        <strong>Authentication Failed!</strong>
        &nbsp;&nbsp;&nbsp;
        {{ authenticationMessage }}
      </div>
    </div>
    <!-- end Error Message -->
  </div>
  <!-- end login -->
</template>

<script>
import PageOptions from "@/conf/PageOptions";
import Options from "@/conf/Options";

export default {
  created() {
    PageOptions.pageEmpty = true;
  },
  mounted() {
    localStorage.clear();
    this.$refs.username.focus();
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    next();
  },
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      user: {
        username: "",
        password: ""
      },
      showErrMsg: false,
      authenticationMessage: "Incorrect username or password."
    };
  },
  methods: {
    login: function(e) {
      if (typeof e !== "undefined") e.preventDefault();
      this.spinnerIsLoading = true;
      let user = this.user;
      user.username = user.username.trim().toLowerCase();
      this.$store
        .dispatch("doLogin", user)
        .then(response => {
          // Authentcation was successful
          if (response === 1) {
            PageOptions.pageEmpty = false;
            if (Options.user.roleId == 5 || Options.user.roleId == 8)
              this.$router.push(
                "/highSeason/productionFridges?login=" + new Date().getTime()
              );
            else if (Options.user.roleId == 6)
              this.$router.push(
                "/highSeason/wrappingArea?login=" + new Date().getTime()
              );
            else if (Options.user.roleId == 13)
              this.$router.push(
                "/administration/productChecker/products?login=" + new Date().getTime()
              );
            else
              this.$router.push(
                "/orderManagement/orders?login=" + new Date().getTime()
              );
            setTimeout(() => location.reload(true), 250);
          }

          // New password required
          if (response === 2) {
            this.$swal.fire({
              type: null,
              title: "New Password Required",
              html:
                '<input type="password" id="new-password" placeholder="New Password" class="swal2-input">' +
                '<input type="password" id="confirm-password" placeholder="Confirm Password" class="swal2-input">',
              showConfirmButton: true,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Update Password",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancel",
              focusConfirm: false,
              preConfirm: () => {
                let password = document.getElementById("new-password").value;
                let confirmPassword = document.getElementById(
                  "confirm-password"
                ).value;

                if (password === "" && confirmPassword === "") {
                  this.$swal.showValidationMessage("New password is required");
                  return;
                }

                if (password !== confirmPassword) {
                  this.$swal.showValidationMessage("passwords do not match");
                  return;
                }

                let user = this.user;
                user.newPassword = password;

                this.$swal.showValidationMessage("updating, please wait");

                this.$store
                  .dispatch("doProvideNewPassword", user)
                  .then(() => {
                    this.user.password = password;
                    this.login();
                    this.$swal.close();
                  })
                  .catch(error => {
                    this.$swal.showValidationMessage(error);
                    return;
                  })
                  .finally(() => {
                    return;
                  });
              }
            });
            return;
          }

          this.showErrMsg = true;
        })
        .catch(error => {
          // Authentcation failed
          if (error === "User does not exist.")
            error = "Incorrect username or password.";
          this.authenticationMessage = error;
          this.showErrMsg = true;
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    forgotPassword: function() {
      this.user.username = this.user.username.trim().toLowerCase();
      this.$store
        .dispatch("forgotPassword", this.user)
        .then(response => {
          if (response == 1) {
            this.$swal.fire({
              type: null,
              title: "Update your password",
              html:
                "<div>A verification code was sent to the e-mail address associated to your account</div>" +
                '<input type="text" id="verification-code" placeholder="Verification Code" class="swal2-input">' +
                '<input type="password" id="password" placeholder="New Password" class="swal2-input">' +
                '<input type="password" id="confirm-password" placeholder="Confirm Password" class="swal2-input">',
              showConfirmButton: true,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Update Password",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancel",
              focusConfirm: false,
              preConfirm: () => {
                let password = document.getElementById("password").value;
                let confirmPassword = document.getElementById(
                  "confirm-password"
                ).value;
                let verificationCode = document
                  .getElementById("verification-code")
                  .value.trim();

                if (verificationCode === "") {
                  this.$swal.showValidationMessage(
                    "Verification Code is required"
                  );
                  return;
                }

                if (password === "" && confirmPassword === "") {
                  this.$swal.showValidationMessage("New password is required");
                  return;
                }

                if (password !== confirmPassword) {
                  this.$swal.showValidationMessage("passwords do not match");
                  return;
                }

                this.$swal.showValidationMessage("processing, please wait");

                let data = {
                  username: this.user.username,
                  verificationCode: verificationCode,
                  password: password
                };

                this.$store
                  .dispatch("updatePassword", data)
                  .then(() => {
                    this.user.password = password;
                    this.login();
                    this.$swal.close();
                  })
                  .catch(errorMsg => {
                     this.$swal.showValidationMessage(errorMsg);
                    return;
                  })
                  .finally(() => {
                    return;
                  });
              }
            });
            return;
          }
        })
        .catch(errorMsg => {
          if (errorMsg === "Username/client id combination not found.")
            errorMsg = "user not found.";

          this.$swal.fire({
            type: "error",
            title: null,
            text: errorMsg,
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK"
          });
          return;
        })
        .finally(() => {
          return;
        });
    },
    closeMessage: function() {
      this.showErrMsg = false;
      this.authenticationMessage = "Incorrect username or password.";
    }
  }
};
</script>