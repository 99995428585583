<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Transit Fridges</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">High Season</h1>
    <!-- end page-header -->
    <panel title="Transit Fridges">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div v-if="fridges.length == 0">
        <b-alert variant="danger" :show="!spinnerIsLoading">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">No transit fridges found</p>
        </b-alert>
      </div>
      <div v-else>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Order added successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>

        <div style="max-width: 700px">
          <div>
            <label class="f-w-700">Transit Fridge</label>
            <i v-if="fridgeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <select class="form-control" v-model="fridgeId" @change="checkForm">
              <option value="0" hidden selected v-if="fridgeId === 0">Select a transit fridge</option>
              <option
                v-for="fridge in fridges"
                :value="fridge.fridgeId"
                :key="fridge.fridgeId"
              >{{ fridge.label }}</option>
            </select>
          </div>

          <div v-if="fridgeId != 0">
            <div style="margin-top: 25px">
              <label class="f-w-700">Order ID</label>
              <i v-if="orderId.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <input
                type="text"
                class="form-control"
                ref="orderId"
                placeholder="# Enter the Order ID"
                style="margin-bottom: 5px"
                v-model="orderId"
                @keyup="checkForm"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="submit"
                :disabled="fridgeId == 0 || orderId.trim() === ''"
              >Add to Fridge</button>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      btnAddDisabled: true,
      btnAddText: "Add",
      fridges: [],
      fridgeId: 0,
      orderId: ""
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      let retrieveFridgeListFilter = {
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ],
          [
            {
              field: "typeId",
              value: 3,
              condition: "eq"
            }
          ]
        ],
        sort: [{ field: "label", direction: "asc" }]
      };
      this.$store
        .dispatch("retrieveFridgeList", retrieveFridgeListFilter)
        .then(retrieveFridgeListResponse => {
          this.fridges = retrieveFridgeListResponse.data;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    submit: function() {
      // Check if the order ID is the correct format
      if (Number.isNaN(Number(this.orderId.trim()))) {
        this.errorMessage = "Invalid order ID: " + this.orderId.trim();
        return;
      }

      this.spinnerIsLoading = true;
      this.orderId = this.orderId.trim();
      let data = {
        fridgeId: this.fridgeId,
        orderId: this.orderId
      };

      this.$store
        .dispatch("addOrderToFridge", data)
        .then(response => {
          if (response.data.messageType === "Error") {
            this.errorMessage = response.data.message;
          } else {
            this.showSuccessMessage = true;
            this.errorMessage = "";
            this.orderId = "";
            this.checkForm();
            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.submit();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    checkForm: function() {
      this.errorMessage = "";
      if (
        this.fridgeId != 0 &&
        this.orderId.trim() !== "" &&
        event.keyCode === 13
      )
        this.submit();
      else if (this.fridges.length > 0 && this.fridgeId != 0)
        this.$nextTick(() => {
          sleep(1).then(() => {
            this.$refs.orderId.focus();
          });
        });
    }
  },
  created() {
    this.load();
  }
};
</script>