import Vue from "vue";
import Vuex from "vuex";
import Options from "@/conf/Options";
import auth from "@/services/auth.service";
import api from "@/services/api.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken:
      localStorage.getItem("accessToken") === null
        ? null
        : Options.crypter.decrypt(localStorage.getItem("accessToken")),
    uploadProgress: null
  },
  mutations: {
    loadAccessToken: (state) => {
      state.accessToken =
        localStorage.getItem("accessToken") === null
          ? null
          : Options.crypter.decrypt(localStorage.getItem("accessToken"));
    },
    updateUploadProgress: (state, value) => {
      state.uploadProgress = value;
    }
  },
  actions: {
    doLogin(fct, user) {
      return new Promise((resolve, reject) => {
        auth
          .doLogin(user)
          .then((response) => {
            if (response.code === 1) {
              // Authentcation was successful
              localStorage.setItem(
                "accessToken",
                Options.crypter.encrypt(response.accessToken)
              );
              localStorage.setItem(
                "refreshToken",
                Options.crypter.encrypt(JSON.stringify(response.refreshToken))
              );
              this.commit("loadAccessToken");
              return api
                .retrieveCurrentUserInfo()
                .then((loggedinUser) => {
                  Options.user = loggedinUser;
                  localStorage.setItem(
                    "user",
                    Options.crypter.encrypt(JSON.stringify(loggedinUser))
                  );
                  resolve(1);
                })
                .catch((error) => {
                  let errorMsg = "Error occurred. Please try again later.";
                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  )
                    errorMsg = "Incorrect username or password.";
                  this.dispatch("doLogout");
                  reject(errorMsg);
                });
            }

            if (response.code === 2) {
              // New password required
              localStorage.removeItem("accessToken");
              resolve(2);
            }
          })
          .catch((error) => {
            localStorage.removeItem("accessToken");
            reject(error);
          });
      });
    },
    doProvideNewPassword(fct, user) {
      return auth.doProvideNewPassword(user);
    },
    forgotPassword(fct, user) {
      return auth.forgotPassword(user);
    },
    updatePassword(fct, data) {
      return auth.updatePassword(data);
    },
    doLogout() {
      auth.doLogout();
      Options.user = null;
      this.commit("loadAccessToken");
    },
    checkAuth() {
      this.dispatch("loadAccessToken");
    },
    loadAccessToken() {
      return new Promise((resolve) => {
        // 5 minutes to expires => get a new access token
        if (
          localStorage.getItem("accessToken") &&
          JSON.parse(
            atob(
              Options.crypter
                .decrypt(localStorage.getItem("accessToken"))
                .split(".")[1]
            )
          ).exp *
          1000 -
          Date.now() <
          300000
        ) {
          auth
            .doRefreshToken()
            .then((accessToken) => {
              localStorage.setItem(
                "accessToken",
                Options.crypter.encrypt(accessToken)
              );
            })
            .finally(() => {
              this.commit("loadAccessToken");
              resolve(1);
            });
        } else resolve(1);
      });
    },
    retrieveOrders(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrders(filter);
      });
    },
    retrieveOrder(fct, orderId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrder(orderId);
      });
    },
    checkOrder(fct, orderId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.checkOrder(orderId);
      });
    },
    checkOrderDriver(fct, orderId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.checkOrderDriver(orderId);
      });
    },
    retrieveOrderProducts(fct, orderId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderProducts(orderId);
      });
    },
    retrieveActivityLogs(fct, orderId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveActivityLogs(orderId);
      });
    },
    retrieveRoleList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveRoleList();
      });
    },
    retrieveDriverCompanyList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDriverCompanyList(filter);
      });
    },
    retrieveCompanyInfo(fct, id) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCompanyInfo(id);
      });
    },
    retrieveFridgeInfo(fct, id) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveFridgeInfo(id);
      });
    },
    retrieveWrappingAreaInfo(fct, id) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveWrappingAreaInfo(id);
      });
    },
    retrieveStoreList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveStoreList();
      });
    },
    retrieveLineOfProductList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveLineOfProductList();
      });
    },
    retrieveBranchList(fct, storeid) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveBranchList(storeid);
      });
    },
    retrieveHighSeasonsList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveHighSeasonsList(filter);
      });
    },
    retrieveHighSeasonDashboard(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveHighSeasonDashboard(data);
      });
    },
    retrieveDashboardHighSeasonStatuses(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDashboardHighSeasonStatuses(data);
      });
    },
    retrieveProductLineOfProduct(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveProductLineOfProduct(data);
      });
    },
    retrieveSeason(fct, seasonId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveSeason(seasonId);
      });
    },
    retrieveProductsList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveProductsList(filter);
      });
    },
    retrieveFridgeList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveFridgeList(filter);
      });
    },
    retrieveFridgeProducts(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveFridgeProducts(data);
      });
    },
    retrieveWrappingAreaList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveWrappingAreaList(filter);
      });
    },
    retrieveFridgeTypeList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveFridgeTypeList();
      });
    },
    retrieveGenderList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveGenderList();
      });
    },
    retrievePhoneTypeList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrievePhoneTypeList();
      });
    },
    createUser(fct, user) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createUser(user);
      });
    },
    createOrder(fct, order) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createOrder(order);
      });
    },
    createCustomer(fct, customer) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createCustomer(customer);
      });
    },
    createHighSeason(fct, highSeason) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createHighSeason(highSeason);
      });
    },
    updateHighSeason(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateHighSeason(data);
      });
    },
    createFridge(fct, fridge) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createFridge(fridge);
      });
    },
    createWrappingArea(fct, wrappingArea) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.createWrappingArea(wrappingArea);
      });
    },
    updateWrappingAreaInfo(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateWrappingAreaInfo(data);
      });
    },
    addProductsToWrappingArea(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.addProductsToWrappingArea(data);
      });
    },
    addOrderToFridge(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.addOrderToFridge(data);
      });
    },
    addProductsToFridge(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.addProductsToFridge(data);
      });
    },
    retrieveSpecialInstructionOptionList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveSpecialInstructionOptionList();
      });
    },
    retrieveCountryList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCountryList();
      });
    },
    retrieveCurrencyList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCurrencyList();
      });
    },
    retrieveVehicleTypeList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveVehicleTypeList();
      });
    },
    retrievePaymentMethodList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrievePaymentMethodList();
      });
    },
    retrieveRegionList(fct, countryCode) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveRegionList(countryCode);
      });
    },
    retrieveSalesByProductReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveSalesByProductReport(data);
      });
    },
    retrieveSalesByProductReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveSalesByProductReportExcelFile(data);
      });
    },
    retrieveOrderDeliveryReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderDeliveryReport(data);
      });
    },
    retrieveOrderDeliveryReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderDeliveryReportExcelFile(data);
      });
    },
    retrieveOrdersReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrdersReport(data);
      });
    },
    retrieveOrdersReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrdersReportExcelFile(data);
      });
    },
    retrieveDeliveryCostReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDeliveryCostReport(data);
      });
    },
    retrieveDeliveryCostReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDeliveryCostReportExcelFile(data);
      });
    },
    retrieveOrderDriverReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderDriverReport(data);
      });
    },
    retrieveOrderDriverReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderDriverReportExcelFile(data);
      });
    },
    retrieveCustomerListReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomerListReport(data);
      });
    },
    retrieveCustomerListReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomerListReportExcelFile(data);
      });
    },
    retrieveCustomerOrderReport(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomerOrderReport(data);
      });
    },
    retrieveCustomerOrderReportExcelFile(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomerOrderReportExcelFile(data);
      });
    },
    retrieveOrderStatusList(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderStatusList(data);
      });
    },
    retrieveOrderRestrictedStatusList(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderRestrictedStatusList(data);
      });
    },
    retrieveStatusBranchDistribution(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveStatusBranchDistribution(data);
      });
    },
    retrieveCityList(fct, regionId) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCityList(regionId);
      });
    },
    retrieveUserList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveUserList(filter);
      });
    },
    retrieveCustomerList(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomerList(data);
      });
    },
    retrieveDriverList(fct, filter) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDriverList(filter);
      });
    },
    retrieveDriver(fct, id) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveDriver(id);
      });
    },
    retrieveUser(fct, id) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveUser(id);
      });
    },
    retrieveCustomer(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveCustomer(data);
      });
    },
    retrievePriceCheckerActivityLogs(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrievePriceCheckerActivityLogs(data);
      });
    },
    retrievePriceCheckerProducts(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrievePriceCheckerProducts(data);
      });
    },
    retrievePriceCheckerProductsExcelFile() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrievePriceCheckerProductsExcelFile();
      });
    },
    importPriceCheckerProducts(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.importPriceCheckerProducts(data);
      });
    },
    removePriceCheckerProduct(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.removePriceCheckerProduct(data);
      });
    },
    removeAllProducts() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.removeAllProducts();
      });
    },
    updateDriverInfo(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateDriverInfo(data);
      });
    },
    updateUserInfo(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateUserInfo(data);
      });
    },
    updateCompanyInfo(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateCompanyInfo(data);
      });
    },
    updateFridgeInfo(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateFridgeInfo(data);
      });
    },
    updateFridgeProductQuantity(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateFridgeProductQuantity(data);
      });
    },
    updateOrderAddress(fct, updateOrderAddressData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateOrderAddress(
          updateOrderAddressData.orderId,
          updateOrderAddressData.address
        );
      });
    },
    assignOrderToBranch(fct, assignOrderToBranchData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.assignOrderToBranch(
          assignOrderToBranchData.orderData,
          assignOrderToBranchData.branchId
        );
      });
    },
    assignOrdersToDriver(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.assignOrdersToDriver(data);
      });
    },
    updateOrdersDeliveryStatus(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateOrdersDeliveryStatus(data);
      });
    },
    updateSpecialInstruction(fct, updateSpecialInstructionData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateSpecialInstruction(
          updateSpecialInstructionData.orderId,
          updateSpecialInstructionData.specialInstruction
        );
      });
    },
    updateDeliveryDate(fct, deliveryDateData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateDeliveryDate(
          deliveryDateData.orderId,
          deliveryDateData.deliveryDate
        );
      });
    },
    updateOrderStatus(fct, orderStatus) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateOrderStatus(orderStatus.orderId, orderStatus.status);
      });
    },
    updateGiftMessage(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateGiftMessage(data);
      });
    },
    updateCustomer(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateCustomer(data);
      });
    },
    updateUser(fct, userData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.updateUser(userData);
      });
    },
    printJob(fct, printData) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.printJob(printData);
      });
    },
    addCompany(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.addCompany(data);
      });
    },
    registerDriver(fct, data) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.registerDriver(data);
      });
    },
    retrieveAddressesByPhoneNumber(fct, phoneNumber) {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveAddressesByPhoneNumber(phoneNumber);
      });
    },
    retrieveOrderTypeList() {
      return this.dispatch("loadAccessToken").then(() => {
        return api.retrieveOrderTypeList();
      });
    },
  },
});
