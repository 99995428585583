<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Production Fridges</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">High Season</h1>
    <!-- end page-header -->
    <panel title="Production Fridges">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div v-if="seasons.length == 0">
        <b-alert variant="danger" :show="!spinnerIsLoading">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">No active seasons found</p>
        </b-alert>
      </div>
      <div v-else>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Products added successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>

        <div style="max-width: 700px">
          <div v-if="seasons.length > 1">
            <label class="f-w-700">High Season</label>
            <i v-if="seasonId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <select
              class="form-control"
              v-model="seasonId"
              @change="loadSeason"
              :disabled="seasonId != 0"
            >
              <option value="0" hidden selected v-if="seasonId === 0">Select a Season</option>
              <option
                v-for="season in seasons"
                :value="season.id"
                :key="season.id"
              >{{ season.label }}</option>
            </select>
          </div>
          <div v-else-if="seasons.length == 1">
            <label class="f-w-700">High Season:</label>
            <label class="m-l-10">{{ seasons[0].label }}</label>
          </div>
          <div v-else>No Active Seasons</div>
          <div v-if="seasonId != 0">
            <div style="margin-top: 25px">
              <label class="f-w-700">Production Fridge</label>
              <i v-if="fridgeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <select
                class="form-control"
                v-model="fridgeId"
                @change="checkForm"
                :disabled="fridgeId != 0"
              >
                <option value="0" hidden selected v-if="fridgeId === 0">Select a production fridge</option>
                <option
                  v-for="fridge in fridges"
                  :value="fridge.fridgeId"
                  :key="fridge.fridgeId"
                >{{ fridge.label }}</option>
              </select>
            </div>
          </div>

          <div v-if="seasonId != 0 && fridgeId != 0">
            <div style="margin-top: 25px">
              <label class="f-w-700">SKU</label>
              <i v-if="sku.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <span
                v-if="lineOfProduct != null"
                class="badge badge-green badge-square m-l-10"
              >{{ lineOfProduct }}</span>
              <input
                type="text"
                class="form-control"
                ref="sku"
                placeholder="# Enter the SKU"
                style="margin-bottom: 5px"
                v-model="sku"
                @keyup="checkForm"
              />
            </div>
            <div style="margin-top: 25px">
              <label class="f-w-700">Quantity</label>
              <input
                type="number"
                class="form-control"
                placeholder="# Enter the product quantity"
                style="margin-bottom: 5px"
                v-model="quantity"
                @keyup="checkForm"
                @change="checkForm"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="add"
                :disabled="btnAddDisabled"
              >{{ btnAddText }}</button>
            </div>
          </div>
        </div>
        <div v-if="seasonId != 0 && fridgeId != 0" style="margin-top: 75px">
          <div v-if="productsList.length > 0">
            <div style="display: flex; flex-wrap: wrap">
              <span
                v-for="product in productsList"
                :value="product"
                :key="product"
                class="label label-green m-2 f-s-11"
              >{{ product }}</span>
            </div>
          </div>
          <vue-good-table
            ref="productsTable"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No products entered</div>
          </vue-good-table>
          <div>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="submit"
              :disabled="rows.length == 0"
            >Submit</button>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="doReset"
            >Reset</button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      columns: [
        {
          label: "SKU",
          field: "sku",
          tdClass: "text-left"
        },
        {
          label: "Product",
          field: "name",
          tdClass: "text-left"
        },
        {
          label: "Quantity Ordered",
          field: "quantity",
          type: "number",
          tdClass: "text-left"
        }
      ],
      rows: [],
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter Products"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "name", type: "asc" }]
      },
      btnAddDisabled: true,
      btnAddText: "Add",
      seasons: [],
      seasonId: 0,
      seasonIdOriginalValue: 0,
      productsList: [],
      fridges: [],
      fridgeId: 0,
      filter: {
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ]
      },
      sku: "",
      quantity: 1,
      lineOfProduct: null
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveHighSeasonsList", this.filter)
        .then(response => {
          this.seasons = response;

          if (this.seasons.length == 1) {
            this.seasonId = this.seasons[0].id;
            this.seasonIdOriginalValue = this.seasons[0].id;
          }

          let retrieveFridgeListFilter = {
            filter: [
              [
                {
                  field: "enabled",
                  value: true,
                  condition: "eq"
                }
              ],
              [
                {
                  field: "typeId",
                  value: 1,
                  condition: "eq"
                }
              ]
            ],
            sort: [{ field: "label", direction: "asc" }]
          };
          this.$store
            .dispatch("retrieveFridgeList", retrieveFridgeListFilter)
            .then(retrieveFridgeListResponse => {
              this.fridges = retrieveFridgeListResponse.data;
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            })
            .finally(() => {
              this.spinnerIsLoading = false;
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    submit: function() {
      this.spinnerIsLoading = false;
      let products = [];
      this.rows.forEach(function(row) {
        let product = { sku: "", quantity: 0 };
        product.sku = row.sku;
        product.quantity = row.quantity;
        products.push(product);
      });

      let data = {
        fridgeId: this.fridgeId,
        products: {
          highSeasonId: this.seasonId,
          products: products
        }
      };

      this.$store
        .dispatch("addProductsToFridge", data)
        .then(response => {
          if (response.data.messageType === "Error") {
            this.errorMessage = response.data.message;
          } else {
            this.showSuccessMessage = true;
            this.reset();
            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.submit();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    doReset: function() {
      this.$swal
        .fire({
          type: null,
          title: "Proceed with the reset?",
          text: null,
          showCancelButton: true,
          allowOutsideClick: false,
          focusCancel: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reset"
        })
        .then(result => {
          if (result.value) {
            this.reset();
          }
        });
    },
    reset: function() {
      this.productsList = [];
      this.rows = [];
      this.sku = "";
      this.quantity = 0;
      this.fridgeId = 0;
      this.seasonId = this.seasonIdOriginalValue;
      this.lineOfProduct = null;
      this.checkForm();
    },
    add: function() {
      this.spinnerIsLoading = true;
      this.sku = this.sku.trim().toUpperCase();
      let data = {
        highSeasonId: this.seasonId,
        sku: this.sku
      };

      this.$store
        .dispatch("retrieveProductLineOfProduct", data)
        .then(response => {
          if (response.hasOwnProperty("productName")) {
            if (response.productExists) this.addProduct(response);
            else if (this.quantity < 0) this.addProduct(response);
            else
              this.$swal
                .fire({
                  type: "warning",
                  title: "Not a high season product",
                  text: 'Do you want to add "' + this.sku + '" to the fridge?',
                  showCancelButton: true,
                  allowOutsideClick: false,
                  focusCancel: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Add to fridge"
                })
                .then(result => {
                  if (result.value) {
                    this.addProduct(response);
                  }
                });
          } else {
            this.errorMessage =
              'Product with SKU "' + data.sku + '" was not found';
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.add();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    addProduct: function(response) {
      if (this.lineOfProduct === null) {
        if (response.lineOfProduct) this.lineOfProduct = response.lineOfProduct;
        else this.lineOfProduct = "unavailable";
      } else if (
        (!response.lineOfProduct && this.lineOfProduct !== "unavailable") ||
        (response.lineOfProduct &&
          response.lineOfProduct !== this.lineOfProduct)
      ) {
        this.errorMessage =
          'Product with line of product "' +
          (typeof response.lineOfProduct === "undefined"
            ? "unavailable"
            : response.lineOfProduct) +
          '" cannot be added to this fridge';
      }

      // valid product
      if (this.errorMessage === "") {
        let row = {
          sku: this.sku,
          name: response.productName,
          quantity: parseInt(this.quantity)
        };
        let productFound = false;

        let _this = this;
        this.rows.forEach(function(product) {
          if (product.sku === row.sku) {
            productFound = true;
            product.quantity = product.quantity + row.quantity;
            if (product.quantity == 0)
              _this.rows.splice(_this.rows.indexOf(product), 1);
          }
        });

        if (!productFound) this.rows.push(row);

        // reset values
        this.sku = "";
        this.quantity = 1;
        this.$nextTick(() => {
          sleep(1).then(() => {
            this.$refs.sku.focus();
            this.checkForm();
          });
        });
      }
    },
    checkForm: function() {
      this.errorMessage = "";

      // control of quantity field
      let _this = this;
      let productFound = false;
      this.rows.forEach(function(product) {
        if (product.sku === _this.sku) {
          productFound = true;
          if (parseInt(_this.quantity) + product.quantity < 0)
            _this.quantity = Math.abs(parseInt(product.quantity)) * -1;
        }
      });

      if (!productFound && parseInt(this.quantity) < 1) this.quantity = 1;

      // Add button control
      this.btnAddDisabled = this.sku.trim() === "" || this.quantity === "0";
      this.btnAddText = parseInt(this.quantity) < 0 ? "Subtract" : "Add";

      this.$nextTick(() => {
        sleep(1).then(() => {
          if (this.$refs.sku && this.sku === "") this.$refs.sku.focus();
        });
      });
    },
    loadSeason: function() {
      if (this.seasonId != 0)
        this.$store
          .dispatch("retrieveSeason", this.seasonId)
          .then(retrieveSeasonResponse => {
            this.productsList = retrieveSeasonResponse.data.products;
            this.checkForm();
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }
            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.loadSeason();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
    }
  },
  created() {
    this.load();
  }
};
</script>