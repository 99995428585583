<template>
  <div v-if="data" style="font-family: 'Arial', sans-serif; font-size: 12px;">
    <div
      v-for="(printJob, printJobIndex) in data.printJobs"
      v-bind:key="printJobIndex"
    >
      <div v-if="printProductionOrder">
        <div
          v-for="(product, productIndex) in printJob.products"
          v-bind:key="productIndex"
          style="page-break-after: always;"
        >
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div
            id="production-order-header-div"
            style="width: 100%; overflow: hidden;"
          >
            <div
              id="production-order-order-reference-div"
              style="float: left; width: 60%;"
            >
              <div style="float: left; width: 70%;">
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt
                    style="height: 40px; margin-top: -10px;"
                  />
                </div>
                <div style="font-size: 24px;">Production Order</div>
                <div
                  id="production-order-reprinted-div"
                  style="font-size: 14px; font-weight: bold;"
                >
                  <span v-if="printJob.printJobs[0].printed"
                    >REPRINTED - copies:
                    {{ printJob.printJobs[0].printCount }}</span
                  >
                  <span
                    v-if="
                      printJob.printJobs[0].printed &&
                      printJob.products.length > 1
                    "
                    >|</span
                  >
                  <span v-if="printJob.products.length > 1"
                    >{{ printJob.products.length }} items combined</span
                  >
                </div>
              </div>
              <div
                style="
                  float: left;
                  width: 30%;
                  text-align: center;
                  margin-top: 25px;
                "
              >
                <span
                  v-if="
                    !printJob.products[productIndex].hasOwnProperty(
                      'giftMessage'
                    )
                  "
                  style="
                    border: solid 1px;
                    font-weight: bolder;
                    padding: 20px;
                    font-size: 1.5em;
                    background-color: #ff0;
                  "
                  >No Card</span
                >
              </div>
            </div>
            <!-- ----- -->
            <div style="float: right; width: 40%; text-align: right;">
              <barcode
                :value="printJob.orderId"
                format="CODE39"
                width="1"
                height="50"
                textMargin="5"
                fontSize="16"
                fontOptions="bold"
                text-align="center"
                :text="'Order: '.concat(printJob.orderId)"
              >
                <span style="font-size: 24px; color: #cecece;"
                  >Barcode Not Available</span
                >
              </barcode>
            </div>
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div style="overflow: hidden;">
            <div
              v-if="printJob.deliveryDate"
              id="production-order-delivery-div"
              style="font-size: 18px;"
            >
              Deliver on
              {{ printJob.deliveryDate | moment("dddd, MMMM Do YYYY") }}
              <span v-if="printJob.deliverySlot">
                {{ printJob.deliverySlot }}</span
              >
            </div>
            <div
              v-else
              style="
                font-size: 18px;
                font-weight: bolder;
                border: solid 1px;
                padding: 0 5px;
                float: left;
                border-radius: 5px;
              "
            >
              Delivery Date Not Available
            </div>
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div>
              <span style="font-size: 1.1em; font-weight: 700;">To:</span>
              {{
                printJob.shippingAddress.clientName +
                " (" +
                printJob.shippingAddress.city +
                ")"
              }}
            </div>
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div id="production-order-product-information-div">
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
                padding: 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Product Information
              </legend>
              <div id="production-order-product-information-content-div">
                <!-- ----- -->
                <div style="float: left; width: 60%;">
                  <div style="width: 100%;">
                    <div style="font-size: 18px; padding: 0;">
                      <span
                        v-if="printJob.products[productIndex].lineOfProduct"
                        style="
                          font-weight: bolder;
                          background-color: #ff0;
                          padding: 2px 5px;
                        "
                        >{{
                          printJob.products[productIndex].lineOfProduct
                        }}:</span
                      >
                      {{ printJob.products[productIndex].productName }}
                    </div>
                    <div
                      v-if="printJob.products[productIndex].color"
                      style="margin: 14px 25px 0 0; text-align: right;"
                    >
                      <span
                        style="
                          font-size: 14px;
                          padding: 5px;
                          background-color: #ff0;
                          border: 1px solid #000;
                        "
                      >
                        Color:
                        <span
                          style="
                            font-size: 18px;
                            font-weight: bolder;
                            vertical-align: middle;
                          "
                          >{{ printJob.products[productIndex].color }}</span
                        >
                      </span>
                    </div>
                    <br />
                    <fieldset
                      v-if="printJob.products[productIndex].options.length > 0"
                      style="
                        border: 1px solid #000;
                        margin-right: 25px;
                        border-radius: 10px;
                      "
                    >
                      <legend style="font-size: 16px;">Addons</legend>
                      <table style="padding: 10px; border: none; width: 100%;">
                        <thead>
                          <tr>
                            <th style="text-align: left;">Qty</th>
                            <th style="text-align: left;">Name</th>
                            <th style="text-align: left;">
                              Unit Price ({{
                                printJob.invoicingDetails.currencyCode
                              }})
                            </th>
                            <th style="text-align: left;">
                              Total ({{
                                printJob.invoicingDetails.currencyCode
                              }})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(option, optionIndex) in printJob.products[
                              productIndex
                            ].options"
                            v-bind:key="optionIndex"
                          >
                            <td>{{ option.quantityOrdered }}</td>
                            <td>
                              <span
                                style="padding: 0 5px; background-color: #ff0;"
                                >{{ option.name }}</span
                              >
                            </td>
                            <td>
                              {{
                                formatNumber(
                                  option.price / option.quantityOrdered
                                )
                              }}
                            </td>
                            <td>{{ formatNumber(option.price) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <div v-else style="font-weight: bolder;">No Addons</div>
                  </div>
                  <div style="width: 100%; margin: 15px 0 0 0;">
                    <table
                      style="
                        text-align: left;
                        border-collapse: collapse;
                        width: calc(100% - 25px);
                      "
                    >
                      <tr>
                        <th style="border-bottom: 1px solid black;">
                          Currency
                        </th>
                        <td style="border-bottom: 1px solid black;">
                          {{ printJob.currencyCode }}
                        </td>
                      </tr>
                      <tr>
                        <th style="border-bottom: 1px solid black;">
                          Product Price
                        </th>
                        <td style="border-bottom: 1px solid black;">
                          {{
                            formatNumber(
                              printJob.products[productIndex].unitPrice
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th style="border-bottom: 1px solid black;">
                          Addons Price
                        </th>
                        <td style="border-bottom: 1px solid black;">
                          {{
                            formatNumber(
                              printJob.products[productIndex].totalPrice -
                                printJob.products[productIndex].unitPrice
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th style="border-bottom: 1px solid black;">VAT</th>
                        <td style="border-bottom: 1px solid black;">
                          {{
                            formatNumber(printJob.products[productIndex].vat)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th style="border-bottom: 1px solid black;">Total</th>
                        <td style="border-bottom: 1px solid black;">
                          {{
                            formatNumber(
                              printJob.products[productIndex].totalPrice +
                                printJob.products[productIndex].vat
                            )
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- ----- -->
                <div style="float: right; width: 40%;">
                  <img
                    :src="printJob.products[productIndex].imageUrl"
                    style="width: 100%;"
                  />
                </div>
                <!-- ----- -->
              </div>
            </fieldset>
            <!-- <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
              "
              v-if="
                printJob.internalSpecialInstruction &&
                printJob.internalSpecialInstruction !== ''
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Internal Instruction
              </legend>
              <div>{{ printJob.internalSpecialInstruction }}</div>
            </fieldset> -->
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
              "
              v-if="printJob.specialInstruction"
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Special Instruction
              </legend>
              <div>{{ printJob.specialInstruction }}</div>
            </fieldset>
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
              "
              v-if="printJob.products[productIndex].productDescription"
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Product Description
              </legend>
              <div>
                {{
                  stripHtml(printJob.products[productIndex].productDescription)
                }}
              </div>
            </fieldset>
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
              "
              v-if="
                printJob.products[productIndex].hasOwnProperty('giftMessage')
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Gift Message
              </legend>
              <div v-if="printJob.products[productIndex].giftMessage.recipient">
                <span
                  v-if="
                    (
                      'To: ' +
                      printJob.products[productIndex].giftMessage.recipient +
                      ', message: ' +
                      printJob.products[productIndex].giftMessage.message.trim()
                    ).length > 120
                  "
                  >{{
                    (
                      "To: " +
                      printJob.products[productIndex].giftMessage.recipient +
                      ", message: " +
                      printJob.products[productIndex].giftMessage.message.trim()
                    ).substring(0, 117)
                  }}...</span
                >
                <span v-else>{{
                  "To: " +
                  printJob.products[productIndex].giftMessage.recipient +
                  ", message: " +
                  printJob.products[productIndex].giftMessage.message.trim()
                }}</span>
              </div>
              <div v-else>
                <span
                  v-if="
                    (
                      'message: ' +
                      printJob.products[productIndex].giftMessage.message.trim()
                    ).length > 120
                  "
                  >{{
                    (
                      "message: " +
                      printJob.products[productIndex].giftMessage.message.trim()
                    ).substring(0, 117)
                  }}...</span
                >
                <span v-else>{{
                  "message: " +
                  printJob.products[productIndex].giftMessage.message.trim()
                }}</span>
              </div>
            </fieldset>
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Invoicing Details
              </legend>
              <div>
                <div style="float: left; width: 50%;">
                  <div style="font-size: 16px; margin-bottom: 10px;">
                    Billing Information
                  </div>
                  <table
                    style="
                      text-align: left;
                      border-collapse: collapse;
                      width: calc(100% - 25px);
                    "
                  >
                    <tr>
                      <th style="border-bottom: 1px solid black;">
                        Sender Name
                      </th>
                      <td style="border-bottom: 1px solid black;">
                        {{ printJob.billingAddress.clientName }}
                      </td>
                    </tr>
                    <tr v-if="printJob.orderTypeId == 1">
                      <th style="border-bottom: 1px solid black;">Telephone</th>
                      <td style="border-bottom: 1px solid black;">
                        {{ printJob.billingAddress.telephone }}
                      </td>
                    </tr>
                    <tr>
                      <th style="border-bottom: 1px solid black;">Country</th>
                      <td style="border-bottom: 1px solid black;">
                        {{ printJob.billingAddress.country }}
                      </td>
                    </tr>
                    <tr>
                      <th style="border-bottom: 1px solid black;">Region</th>
                      <td style="border-bottom: 1px solid black;">
                        {{ printJob.billingAddress.region }}
                      </td>
                    </tr>
                    <tr>
                      <th style="border-bottom: 1px solid black;">City</th>
                      <td style="border-bottom: 1px solid black;">
                        {{ printJob.billingAddress.city }}
                      </td>
                    </tr>
                    <tr>
                      <th valign="top" style="border-bottom: 1px solid black;">
                        Street
                      </th>
                      <td style="border-bottom: 1px solid black;">
                        <span
                          v-for="(street, index) in printJob.billingAddress
                            .street"
                          v-bind:key="index"
                        >
                          {{ street }}
                          <br />
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div style="float: right; width: 50%; text-align: left;">
                  <div style="font-size: 16px; margin-bottom: 10px;">
                    Payment Information
                  </div>
                  <div>
                    Total billed amount is
                    <strong>{{
                      printJob.invoicingDetails.currencyCode +
                      " " +
                      formatNumber(printJob.invoicingDetails.totalBilledAmount)
                    }}</strong>
                    <br />including shipping fees of
                    <strong>{{
                      printJob.invoicingDetails.currencyCode +
                      " " +
                      formatNumber(printJob.invoicingDetails.shippingFees)
                    }}</strong>
                    <br />
                    <br />
                    <div style="font-weight: 700; margin-bottom: 5px;">
                      Payment details:
                    </div>
                    <div style="white-space: pre-line;">
                      {{ printJob.invoicingDetails.comment }}
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
        </div>
      </div>
      <!-- ================================================================================================================================================================================= -->
      <div v-if="printDeliveryNote">
        <div
          v-for="(product, productIndex) in printJob.products"
          v-bind:key="productIndex"
          style="page-break-after: always;"
        >
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div
            id="delivery-note-header-div"
            style="width: 100%; height: 100px;"
          >
            <div
              id="delivery-note-order-reference-div"
              style="float: left; width: 60%;"
            >
              <div style="float: left; width: 70%;">
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt
                    style="height: 40px; margin-top: -10px;"
                  />
                </div>
                <div style="font-size: 24px;">Delivery Note</div>
                <div
                  id="delivery-note-reprinted-div"
                  style="font-size: 16px; font-weight: bold;"
                >
                  <span v-if="printJob.printJobs[0].printed"
                    >REPRINTED - copies:
                    {{ printJob.printJobs[0].printCount }}</span
                  >
                  <span
                    v-if="
                      printJob.printJobs[0].printed &&
                      printJob.products.length > 1
                    "
                    >|</span
                  >
                  <span v-if="printJob.products.length > 1"
                    >{{ printJob.products.length }} items combined</span
                  >
                </div>
              </div>
              <div
                style="
                  float: left;
                  width: 30%;
                  text-align: center;
                  margin-top: 25px;
                "
              >
                <span
                  v-if="
                    !printJob.products[productIndex].hasOwnProperty(
                      'giftMessage'
                    )
                  "
                  style="
                    border: solid 1px;
                    font-weight: bolder;
                    padding: 20px;
                    font-size: 1.5em;
                    background-color: #ff0;
                  "
                  >No Card</span
                >
              </div>
            </div>
            <!-- ----- -->
            <div style="float: right; width: 40%; text-align: right;">
              <barcode
                :value="printJob.orderId"
                format="CODE39"
                width="1"
                height="50"
                textMargin="5"
                fontSize="16"
                fontOptions="bold"
                text-align="center"
                :text="'Order: '.concat(printJob.orderId)"
              >
                <span style="font-size: 24px; color: #cecece;"
                  >Barcode Not Available</span
                >
              </barcode>
            </div>
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div
            v-if="printJob.branchName"
            style="font-size: 18px; margin-bottom: 10px;"
          >
            Branch:
            <span
              v-if="printJob.products[productIndex].lineOfProduct"
              style="
                font-weight: bolder;
                background-color: #ff0;
                padding: 2px 5px;
              "
              >{{ printJob.branchName }}</span
            >
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div
            v-if="printJob.deliveryDate"
            id="delivery-note-delivery-div"
            style="font-size: 18px;"
          >
            Deliver on
            {{ printJob.deliveryDate | moment("dddd, MMMM Do YYYY") }}
            <span v-if="printJob.deliverySlot">
              {{ printJob.deliverySlot }}</span
            >
          </div>
          <div v-else style="font-size: 18px; font-weight: bolder;">
            Delivery Date Not Available
          </div>
          <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
          <div id="delivery-note-recipient-information-div">
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
                padding: 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Delivery Information
              </legend>
              <div>
                <div style="float: left; width: 50%;">
                  <table
                    style="
                      text-align: left;
                      border-collapse: collapse;
                      width: calc(100% - 25px);
                    "
                  >
                    <tr>
                      <th>Recipient Name</th>
                      <td>{{ printJob.shippingAddress.clientName }}</td>
                    </tr>
                    <tr>
                      <th>Telephone</th>
                      <td
                        v-if="printJob.surpriseTheRecipient"
                        style="text-decoration: line-through;"
                      >
                        {{ printJob.shippingAddress.telephone }}
                      </td>
                      <td v-else>{{ printJob.shippingAddress.telephone }}</td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td v-if="printJob.shippingAddress.company">
                        {{ printJob.shippingAddress.company }}
                      </td>
                      <td v-else>N/A</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{{ printJob.shippingAddress.country }}</td>
                    </tr>
                  </table>
                </div>
                <div style="float: right; width: 50%; text-align: left;">
                  <table
                    style="
                      text-align: left;
                      border-collapse: collapse;
                      width: calc(100% - 25px);
                    "
                  >
                    <tr>
                      <th>Region</th>
                      <td>{{ printJob.shippingAddress.region }}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{{ printJob.shippingAddress.city }}</td>
                    </tr>
                    <tr>
                      <th valign="top">Landmark</th>
                      <td v-if="printJob.shippingAddress.township">
                        {{ printJob.shippingAddress.township }}
                      </td>
                      <td v-else>N/A</td>
                    </tr>
                    <tr>
                      <th valign="top">Street</th>
                      <td>
                        <span
                          v-for="(street, index) in printJob.shippingAddress
                            .street"
                          v-bind:key="index"
                        >
                          {{ street }}
                          <br />
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
          <div id="delivery-note-product-information-div">
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
                padding: 0 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Product Information &amp; Special Instruction
              </legend>
              <div id="delivery-note-product-information-content-div">
                <!-- ----- -->
                <div style="margin: 20px 0; overflow: hidden;">
                  <div style="float: left; width: 50%; margin: 0;">
                    <div style="font-size: 20px; padding: 0;">
                      <span
                        v-if="printJob.products[productIndex].lineOfProduct"
                        style="
                          font-weight: bolder;
                          background-color: #ff0;
                          padding: 2px 5px;
                        "
                        >{{
                          printJob.products[productIndex].lineOfProduct
                        }}:</span
                      >
                      {{ printJob.products[productIndex].productName }}
                    </div>
                    <div
                      v-if="printJob.products[productIndex].color"
                      style="margin: 14px 25px 0 0; text-align: right;"
                    >
                      <span
                        style="
                          font-size: 14px;
                          padding: 5px;
                          background-color: #ff0;
                          border: 1px solid #000;
                        "
                      >
                        Color:
                        <span
                          style="
                            font-size: 18px;
                            font-weight: bolder;
                            vertical-align: middle;
                          "
                          >{{ printJob.products[productIndex].color }}</span
                        >
                      </span>
                    </div>
                    <br />
                    <fieldset
                      v-if="printJob.products[productIndex].options.length > 0"
                      style="
                        border: 1px solid #000;
                        margin-right: 20px;
                        border-radius: 10px;
                      "
                    >
                      <legend style="font-size: 16px;">Addons</legend>
                      <table style="padding: 10px; border: none; width: 100%;">
                        <thead>
                          <tr>
                            <th style="text-align: left;">Qty</th>
                            <th style="text-align: left;">Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(option, optionIndex) in printJob.products[
                              productIndex
                            ].options"
                            v-bind:key="optionIndex"
                          >
                            <td>{{ option.quantityOrdered }}</td>
                            <span
                              style="padding: 0 5px; background-color: #ff0;"
                              >{{ option.name }}</span
                            >
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </div>
                  <!-- -------------------------------------------------------------------------------------------------- -->
                  <div style="float: left; width: 50%; margin: 0;">
                    <fieldset
                      style="border: none; margin-top: 10px; padding: 10px;"
                      v-if="printJob.specialInstruction"
                    >
                      <legend
                        style="
                          padding: 0 15px;
                          margin-left: 0;
                          font-size: 20px;
                        "
                      >
                        Special Instruction
                      </legend>
                      <div style="white-space: pre-line; font-size: 1.5em; background-color: #ff0; margin-top: 16px; padding: 10px 15px; border-radius: 5px;" dir="auto">{{ printJob.specialInstruction }}</div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- ----- -->
          <div v-if="printJob.surpriseTheRecipient">
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
                padding: 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Surprise The Recipient
              </legend>
              <div>
                <div style="padding-bottom: 15px;">
                  Contact Name: {{ printJob.surpriseTheRecipientName }}
                </div>
                <div style="padding-bottom: 15px;">
                  Relation: {{ printJob.surpriseTheRecipientRelation }}
                </div>
                <div style="padding-bottom: 15px;">
                  Phone Number: {{ printJob.surpriseTheRecipientPhone }}
                </div>
              </div>
            </fieldset>
          </div>
          <!-- ----- -->
          <div>
            <fieldset
              style="
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #000;
                margin-top: 10px;
                padding: 10px;
              "
            >
              <legend
                style="padding: 0 15px; margin-left: 50px; font-size: 20px;"
              >
                Delivery Details
              </legend>
              <div>
                <div style="float: left; width: 50%;">
                  <div style="font-size: 20px; margin-bottom: 10px;">
                    Driver Details
                  </div>
                  <div style="padding-bottom: 15px;">Name:</div>
                  <div style="padding-bottom: 15px;">Time:</div>
                  <div style="padding-bottom: 15px;">
                    Reason for delivery failure:
                  </div>
                </div>
                <div style="float: right; width: 50%; text-align: left;">
                  <div style="font-size: 20px; margin-bottom: 10px;">
                    Recipient Details
                  </div>
                  <div style="padding-bottom: 15px;">Full Name:</div>
                  <div style="padding-bottom: 15px;">Delivery Time:</div>
                  <div style="padding-bottom: 15px;">Signature:</div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <!-- ================================================================================================================================================================================= -->
      <div v-if="printGiftMessage">
        <div
          v-for="(product, productIndex) in printJob.products"
          v-bind:key="productIndex"
          style="page-break-after: always; font-family: serif;"
        >
          <div
            id="gift-message-envelope"
            style="
              width: 120mm;
              height: 90mm;
              page-break-after: always;
              position: relative;
            "
          >
            <div
              style="
                margin-top: 13mm;
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 1.3em;
              "
            >
              To: {{ product.giftMessage.recipient }}
              <br v-if="product.shippingCity" />
              {{ product.shippingCity }}
              <br v-if="product.shippingCity" />
            </div>
            <span
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 0.9em;
                margin: 0 8mm 28mm 0;
              "
              >{{ product.orderId }}</span
            >
          </div>
          <div
            v-if="printJob.products[productIndex].hasOwnProperty('giftMessage')"
            id="gift-message-content"
            style="
              width: 120mm;
              height: 90mm;
              page-break-after: always;
              position: relative;
            "
          >
            <div
              style="
                margin: auto 0;
                padding: 0mm 10mm 0 10mm;
                width: 85%;
                height: 50%;
                font-size: 1.1em;
                text-align: justify;
              "
            >
              <div style="margin: 3mm;"></div>
              To: {{ product.giftMessage.recipient }}
              <div style="margin: 1mm;"></div>
              From: {{ product.giftMessage.sender }}
              <br />
              <br />
              <br />
              <div style="white-space: pre-line;" dir="auto">
                {{ product.giftMessage.message }}
              </div>
            </div>
            <span
              style="
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 0.9em;
                margin: 0 8mm 20mm 0;
              "
              >{{ product.orderId }}</span
            >
          </div>
        </div>
      </div>
      <!-- ================================================================================================================================================================================= -->
      <div
        v-if="printSticker"
        style="font-family: serif; padding: 0; margin: -1mm -1mm 0 -1mm;"
      >
        <span
          v-for="(product, productIndex) in printJob.products"
          v-bind:key="productIndex"
        >
          <span
            v-if="productIndex != 0 && productIndex % 24 == 0"
            style="page-break-before: always; display: block;"
          ></span>
          <span
            style="
              width: 33%;
              max-width: 33%;
              height: 36.4mm;
              max-height: 36.4mm;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
            "
          >
            <div style="margin-top: 10mm;">
              To: {{ product.giftMessage.recipient }}
            </div>
            <div>{{ product.shippingCity }}</div>
            <div style="float: right; margin: 10mm 4mm 0 0; font-size: 0.8em;">
              {{ product.orderId }}
            </div>
          </span>
        </span>
      </div>
      <!-- ================================================================================================================================================================================= -->
      <div
        v-if="printReceipt"
        style="
          font-family: 'Arial', sans-serif;
          padding: 0;
          margin: -1mm -1mm 0 -1mm;
        "
      >
        <div style="width: 50%; text-align: left; margin-bottom: 25px;">
          <img
            v-if="printJob.storeId == 2"
            src="@/assets/images/logo-uae-bw.png"
            alt
            style="height: 30px;"
          />
          <img
            v-else
            src="@/assets/images/logo.png"
            alt
            style="height: 40px; margin-top: -10px;"
          />
        </div>
        <div style="font-size: 24px; text-align: center;">Receipt</div>
        <div style="margin-top: 50px;">
          <div
            style="
              display: inline-block;
              width: 60%;
              max-width: 60%;
              vertical-align: top;
            "
          >
            <table
              cellspacing="0"
              cellpadding="0"
              style="border: none; text-align: left;"
            >
              <tr>
                <th>Received From</th>
                <td>: {{ printJob.billingAddress.clientName }}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>: {{ printJob.billingAddress.telephone }}</td>
              </tr>
              <tr>
                <th style="vertical-align: text-top; padding-right: 5px;">
                  Billing Address
                </th>
                <td>
                  :
                  {{ printJob.billingAddress.city }}
                  <br />
                  &nbsp;
                  {{ printJob.billingAddress.region }}
                  <br />
                  &nbsp;
                  {{ printJob.billingAddress.country }}
                </td>
              </tr>
            </table>
          </div>
          <div style="display: inline-block; width: 40%; max-width: 40%;">
            <table
              cellspacing="0"
              cellpadding="0"
              style="border: none; text-align: left;"
            >
              <tr>
                <th>Receipt Number</th>
                <td>
                  :
                  {{ printJob.receiptNumber }}
                </td>
              </tr>
              <tr>
                <th>Receipt Date</th>
                <td>
                  : {{ printJob.receiptTs | moment("dddd, MMMM Do YYYY") }}
                </td>
              </tr>
              <tr>
                <th style="padding-right: 5px;">Print Date &amp; Time</th>
                <td>: {{ printJob.printTs | moment("YYYY-MM-DD HH:mm") }}</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td></td>
              </tr>
              <tr>
                <th>Currency</th>
                <td>: {{ printJob.currencyCode }}</td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  :
                  {{
                    printJob.totalPrice
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="margin-top: 25px;">
          <div>
            <span style="font-weight: 700;">The sum of</span>
            {{ printJob.amountInLetters }}
          </div>
          <div>
            <span style="font-weight: 700;">In settlement of</span>
            order № {{ printJob.orderId }} ({{ printJob.productsStr }})
          </div>
        </div>
        <div
          style="
            display: inline-block;
            float: right;
            margin: 40px;
            border: 1px solid #888;
            min-width: 105px;
            min-height: 70px;
            text-align: center;
            vertical-align: middle;
            line-height: 70px;
          "
        >
          <div style="color: #aeaeae; font-size: 1.2em;">Signature</div>
        </div>
        <div
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            min-width: 100%;
            font-size: 0.8em;
            border-top: 1px solid #ccc;
            padding-top: 10px;
            text-align: justify;
            text-align-last: justify;
          "
        >
          <div style="display: inline-block; width: 33%; max-width: 33%;">
            <div style="display: inline-block;">
              <div>P.O. Box 11-9666 Beirut LB</div>
              <div>Capital LBP 6,300,000,000</div>
              <div>
                Fully Paid.
                <span style="font-weight: 700;">RCB 38142.78</span>
              </div>
            </div>
          </div>
          <div
            style="
              display: inline-block;
              width: 34%;
              max-width: 34%;
              text-align: center;
            "
          >
            <div
              style="display: inline-block; text-align: left; margin-left: 30%;"
            >
              <div>
                Exotica s.a.l.
                <span style="font-weight: 700;">MOF 2296-601</span>
              </div>
              <div>Zouk Mosbeh, Main Road</div>
              <div>
                <span style="font-weight: 700;">1533</span> www.exotica.com
              </div>
            </div>
          </div>
          <div style="display: inline-block; width: 33%; max-width: 33%;">
            <div style="float: right;">
              <div>T: +961 9 210 023</div>
              <div>F: +961 9 211 365</div>
              <div>E: info@exotica.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "order-print-job",
  data: function () {
    return {
      data: "",
      printProductionOrder: false,
      printDeliveryNote: false,
      printGiftMessage: false,
      printSticker: false,
      printReceipt: false,
    };
  },
  methods: {
    formatNumber: function (value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    getStickerCellStyle: function (index) {
      let style =
        index != 0 && index % 24 == 0
          ? "page-break-before: always; display: block"
          : "";
      return style;
    },
    updateData(printType, data) {
      return new Promise((resolve) => {
        // reset
        this.printProductionOrder = false;
        this.printDeliveryNote = false;
        this.printGiftMessage = false;
        this.printSticker = false;
        this.printReceipt = false;

        switch (printType) {
          case 1:
            this.printProductionOrder = true;
            break;
          case 2:
            this.printDeliveryNote = true;
            break;
          case 3:
            this.printGiftMessage = true;
            break;
          case 4:
            this.printSticker = true;
            break;
          case 5:
            this.printReceipt = true;
            break;
        }

        this.data = data;
        resolve(data);
      });
    },
    stripHtml(html) {
      var temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      );
    },
  },
};
</script>
