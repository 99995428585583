<template>
  <div>
    <!-- begin #header -->
    <div id="header" class="header navbar-default">
      <!-- begin navbar-header -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle pull-right"
          v-on:click="toggleMobileRightSidebar"
          v-if="pageOptions.pageWithTwoSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-toggle pull-left"
          v-on:click="toggleMobileSidebar"
          v-if="pageOptions.pageWithTwoSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/images/logo.png" alt />
        </router-link>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileSidebar"
          v-if="!pageOptions.pageWithTwoSidebar && (!pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar)"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-toggle"
          v-on:click="toggleMobileTopMenu"
          v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <button
          type="button"
          class="navbar-toggle p-0 m-r-0"
          v-on:click="toggleMobileMegaMenu"
          v-if="pageOptions.pageWithMegaMenu"
        >
          <span class="fa-stack fa-lg text-inverse m-t-2">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
          </span>
        </button>
      </div>
      <!-- end navbar-header -->
      <!-- begin header-nav -->
      <ul class="navbar-nav navbar-right">
        <li class="dropdown navbar-language" v-if="pageOptions.pageWithLanguageBar">
          <b-dropdown variant="link" menu-class="p-b-0">
            <template slot="button-content">
              <span class="flag-icon flag-icon-us" title="us"></span>
              <span class="name">EN</span>
              <b class="caret"></b>
            </template>
            <b-dropdown-item href="javascript:;">
              <span class="flag-icon flag-icon-us" title="us"></span> English
            </b-dropdown-item>
            <b-dropdown-item href="javascript:;">
              <span class="flag-icon flag-icon-cn" title="cn"></span> Chinese
            </b-dropdown-item>
            <b-dropdown-item href="javascript:;">
              <span class="flag-icon flag-icon-jp" title="jp"></span> Japanese
            </b-dropdown-item>
            <b-dropdown-item href="javascript:;">
              <span class="flag-icon flag-icon-be" title="be"></span> Belgium
            </b-dropdown-item>
            <b-dropdown-divider class="m-b-0"></b-dropdown-divider>
            <b-dropdown-item href="javascript:;" class="text-center">more options</b-dropdown-item>
          </b-dropdown>
        </li>
        <li class="dropdown navbar-user">
          <b-dropdown variant="link" menu-class="dropdown-menu-right">
            <template slot="button-content">
              <div class="image image-icon bg-black text-grey-darker">
                <i class="fa fa-user"></i>
              </div>
              <span class="d-none d-md-inline">{{ firstName + ' ' + lastName }}</span>
              <b class="caret"></b>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <clock :blink="true" :displaySeconds="false" :twelveHour="false" />
            </template>
            <b-dropdown-item href="javascript:;" @click="changePassword">
              <i class="fa fa-key" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Change Password
            </b-dropdown-item>
            <!--b-dropdown-item href="javascript:;"><span class="badge badge-danger pull-right">2</span> Inbox</b-dropdown-item-->
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="javascript:;" @click="logout">
              <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;Log Out
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <li class="divider d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar"></li>
        <li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
          <a
            href="javascript:;"
            v-on:click="toggleRightSidebar"
            data-click="right-sidebar-toggled"
            class="f-s-14"
          >
            <i class="fa fa-th"></i>
          </a>
        </li>
      </ul>
      <!-- end header navigation right -->
    </div>
    <!-- end #header -->
  </div>
</template>

<script>
import PageOptions from "@/conf/PageOptions";
import Options from "@/conf/Options";
import Clock from "@/components/clock";

export default {
  name: "Header",
  components: {
    Clock
  },
  data() {
    return {
      pageOptions: PageOptions,
      firstName: Options.user.firstName,
      lastName: Options.user.lastName
    };
  },
  methods: {
    toggleMobileSidebar() {
      this.pageOptions.pageMobileSidebarToggled = !this.pageOptions
        .pageMobileSidebarToggled;
    },
    toggleMobileRightSidebar() {
      this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions
        .pageMobileRightSidebarToggled;
    },
    toggleMobileTopMenu() {
      this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
    },
    toggleMobileMegaMenu() {
      this.pageOptions.pageMobileMegaMenu = !this.pageOptions
        .pageMobileMegaMenu;
    },
    toggleRightSidebar() {
      this.pageOptions.pageRightSidebarToggled = !this.pageOptions
        .pageRightSidebarToggled;
    },
    checkForm: function(e) {
      e.preventDefault();
      this.$router.push({ path: "/extra/search" });
    },
    logout: function() {
      this.$store.dispatch("doLogout").then(() => {
        this.$router.push("/?logout=" + new Date().getTime());
        setTimeout(() => location.reload(true), 250);
      });
    },
    changePassword: function() {
      this.$router.push("/profile/changePassword");
    }
  }
};
</script>
