<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Customer Management</li>
      <li class="breadcrumb-item active">Customers</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Customer Management</h1>
    <!-- end page-header -->
    <panel title="Customers">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <div v-if="keywords !== ''" class="alert alert-yellow fade show">
          Showing results for
          <span
            style="font-weight: bold; font-size: 1.3em; padding: 0 15px"
          >{{ keywords }}</span>
        </div>
        <div>
          <vue-good-table
            ref="customersTable"
            mode="remote"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :totalRows="totalRecords"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
          >
            <div slot="emptystate">No customers found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'email'">{{ props.row.email ? props.row.email:"N/A"}}</span>
              <span v-else-if="props.column.field === 'enabled'">
                <span v-if="props.row.enabled" class="label label-green">Active</span>
                <span v-else class="label label-danger">Inactive</span>
              </span>
              <span
                v-else-if="props.column.field === 'storeName' && hideStoreColumn"
              >{{ props.column.hidden = "true" }}</span>
              <span v-else-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="navigateToEditCustomerPage(props.row.id)"
                >Edit</button>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="navigateToCreateCustomerPage"
          >Create Customer</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";

export default {
  data() {
    return {
      // spinner
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      hideStoreColumn: Options.user.roleId != 1,
      keywords: "",
      columns: [
        {
          label: "First Name",
          field: "firstName",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          label: "Family Name",
          field: "lastName",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          label: "E-mail",
          field: "email",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          label: "Creation Date",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd",
          thClass: "text-center",
          tdClass: "text-center"
        },
        {
          label: "Store",
          field: "storeName",
          thClass: "text-center",
          tdClass: "text-left"
        },
        {
          label: "Active",
          field: "enabled",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Action",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Customers per page",
        perPage: 10,
        perPageDropdown: [10, 25, 50],
        ofLabel: "of"
      },
      filter: {
        offset: 0,
        limit: 10,
        filter: [],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "lastName", direction: "asc" }
        ]
      },
      vueGoodTableServerParams: {
        columnFilters: {},
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "lastName", direction: "asc" }
        ],
        page: 1,
        perPage: 10
      },
      searchOptions: {
        enabled: true,
        trigger: "enter",
        placeholder: "Refine your search result by entering multiple keywords"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          { field: "firstName", type: "asc" },
          { field: "lastName", type: "asc" }
        ]
      }
    };
  },
  methods: {
    load: function() {
      let data = {
        typeUrl: "/type/1",
        filter: this.filter
      };
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveCustomerList", data)
        .then(response => {
          this.rows = response.data.customers;
          this.totalRecords = response.data.totalCount;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    navigateToCreateCustomerPage: function() {
      let routeData = this.$router.resolve({
        name: "customerManagementCustomer"
      });
      window.open(routeData.href, "_self");
    },
    navigateToEditCustomerPage: function(userId) {
      let routeData = this.$router.resolve({
        name: "customerManagementCustomer",
        query: { id: userId }
      });
      window.open(routeData.href, "_self");
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.load();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage }, false);
      this.load();
    },
    onSortChange(params) {
      this.updateParams(
        {
          sort: params
        },
        true
      );
      this.load();
    },
    onSearch(search) {
      if (this.spinnerIsLoading) return; //Abort in case another search was initiated

      this.filter.offset = 0;
      this.filter.filter = [];
      this.keywords = search.searchTerm.trim();
      var keywords = search.searchTerm.trim().split(" ");

      for (var i = 0; i < keywords.length; i++) {
        let searchGroup = [];

        let firstName = {
          field: "firstName",
          value: "",
          condition: "contains"
        };

        let familyName = {
          field: "lastName",
          value: "",
          condition: "contains"
        };

        let email = {
          field: "email",
          value: "",
          condition: "contains"
        };

        let storeName = {
          field: "storeName",
          value: "",
          condition: "contains"
        };

        let keyword = keywords[i].trim();
        if (keyword !== "") {
          firstName.value = keyword;
          searchGroup.push(firstName);

          familyName.value = keyword;
          searchGroup.push(familyName);

          email.value = keyword;
          searchGroup.push(email);

          storeName.value = keyword;
          searchGroup.push(storeName);

          this.filter.filter.push(searchGroup);
        }
      }
      this.load();
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.filter.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.filter.limit = this.vueGoodTableServerParams.perPage;
      this.filter.sort = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.filter.sort.push(sortByColumn);
      }
    }
  },
  created() {
    this.load();
  }
};
</script>