<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Orders Delivery</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">High Season</h1>
    <!-- end page-header -->
    <panel title="Orders Delivery">
      <loading loader="spinner" background-color="#000" :opacity="spinnerOpacity" :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel" :is-full-page="spinnerFullPage"></loading>
      <div>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Order(s) assigned successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>

        <div style="max-width: 700px;">
          <div>
            <label class="f-w-700">Driver</label>
            <i v-if="driverId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <v-select ref="drivers" :options="drivers" placeholder="Select a driver" :reduce="(label) => label.id"
              @input="checkForm" v-model="driverId">
              <template #option="{ label, mobileNumber }">
                <span style="font-weight: 700;">{{ label }}</span>
                <span style="float: right;">{{ mobileNumber }}</span>
              </template>
            </v-select>
            <div v-if="driverId != 0" style="padding: 5px 0 0 8px;">
              <span style="color: #000;">Mobile: {{ driverMobileNumber }}</span>
              |
              <span>
                {{ driverCompanyName }}
                ({{ driverCompanyPhoneNumber }})
              </span>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <label class="f-w-700">Vehicle Type</label>
            <i v-if="vehicleTypeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <v-select ref="vehicleTypes" :options="vehicleTypes" placeholder="Select a vehicle type"
              :reduce="(label) => label.id" @input="checkForm" v-model="vehicleTypeId">
            </v-select>
          </div>

          <div>
            <div style="margin-top: 25px;">
              <label class="f-w-700">Order ID</label>
              <i v-if="orderId.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <input type="text" class="form-control" ref="orderId" placeholder="# Enter the Order ID"
                style="margin-bottom: 5px;" v-model="orderId" :disabled="driverId == 0 || vehicleTypeId == 0"
                @keyup="checkForm" />
            </div>
            <div>
              <button type="button" class="btn btn-success m-5" style="float: right;" @click="add"
                :disabled="driverId == 0 || vehicleTypeId == 0 || orderId.trim() === ''">
                {{ btnAddText }}
              </button>
              <span v-if="rows.length > 0" style="margin-top: 10px; float: left;">
                <span class="label label-green m-2 f-s-14">{{
        rows.length
      }}</span>
                <span class="f-w-700">order{{ rows.length > 1 ? "s" : "" }} captured</span>
              </span>
            </div>
          </div>
        </div>
        <div style="margin-top: 75px;">
          <vue-good-table ref="ordersTable" styleClass="vgt-table striped bordered" :columns="columns" :rows="rows"
            :line-numbers="false" :pagination-options="paginationOptions" :selectOptions="selectOptions"
            :search-options="searchOptions" :sort-options="sortOptions">
            <div slot="emptystate">No orders entered</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <button type="button" class="btn btn-link" style="text-decoration: none; color: inherit;"
                  @click="remove(props.row.orderId)">
                  Remove
                </button>
              </span>
            </template>
          </vue-good-table>
          <div>
            <button type="button" class="btn btn-success m-5" style="float: right;" @click="submit"
              :disabled="rows.length == 0">
              Submit
            </button>
            <button type="button" class="btn btn-success m-5" style="float: right;"
              :disabled="driverId == 0 && vehicleTypeId == 0" @click="doReset">
              Reset
            </button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      roleId: Options.user.roleId,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      columns: [
        {
          label: "Order ID",
          field: "orderId",
          tdClass: "text-left"
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-left",
          sortable: false
        }
      ],
      rows: [],
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter Orders"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "orderId", type: "asc" }]
      },
      btnAddDisabled: true,
      btnAddText: "Add",
      orders: [],
      orderId: "",
      drivers: [],
      vehicleTypes: [],
      driverId: 0,
      vehicleTypeId: 0,
      driverMobileNumber: "",
      driverCompanyName: "",
      driverCompanyPhoneNumber: "",
      filter: {
        offset: -1,
        limit: -1,
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ]
      }
    };
  },
  methods: {
    load: function () {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveDriverList", this.filter)
        .then((response) => {
          let _this = this;
          response.data.drivers.forEach(function (driver) {
            _this.drivers.push({
              id: driver.driverId,
              label: driver.firstName.trim() + " " + driver.familyName.trim(),
              mobileNumber: driver.mobileNumber.trim(),
              companyName: driver.companyName.trim(),
              companyPhoneNumber: driver.companyPhoneNumber.trim()
            });
          });

          this.$store
            .dispatch("retrieveVehicleTypeList")
            .then((responseVehicleType) => {
              responseVehicleType.forEach(function (vehicleType) {
                _this.vehicleTypes.push({
                  id: vehicleType.id,
                  label: vehicleType.name
                });
              });
            })
            .catch((error) => {
              let titleTxt = "Error";
              let msgTxt = error.message;
              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then((result) => {
                  if (result.value) {
                    this.load();
                  }
                });
            })
            .finally(() => {
              this.spinnerIsLoading = false;
            });
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;
          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          // this.spinnerIsLoading = false;
        });
    },
    checkForm: function (event) {
      if (!this.driverId) this.driverId = 0;

      let _this = this;

      if (this.driverId == 0 || this.vehicleTypeId == 0)
        this.orderId = "";

      if (this.driverId == 0) {
        _this.driverMobileNumber = "";
        _this.driverCompanyName = "";
        _this.driverCompanyPhoneNumber = "";
      } else
        this.drivers.forEach(function (driver) {
          if (driver.id == _this.driverId) {
            _this.driverMobileNumber = driver.mobileNumber;
            _this.driverCompanyName = driver.companyName;
            _this.driverCompanyPhoneNumber = driver.companyPhoneNumber;
          }
        });

      this.errorMessage = "";
      this.orderId = this.orderId.trim();
      if (event && this.orderId !== "" && event.keyCode === 13) this.add();
      else if (this.drivers.length > 0 && this.driverId != 0 && this.vehicleTypes.length > 0 && this.vehicleTypeId != 0)
        this.$nextTick(() => {
          sleep(1).then(() => {
            this.$refs.orderId.focus();
          });
        });
    },
    remove: function (orderId) {
      this.$swal
        .fire({
          type: null,
          title: "Remove order " + orderId + "?",
          text: null,
          showCancelButton: true,
          allowOutsideClick: false,
          focusCancel: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Remove"
        })
        .then((result) => {
          if (result.value) {
            let _this = this;
            this.rows.forEach(function (order) {
              if (order.orderId === orderId) {
                _this.rows.splice(_this.rows.indexOf(order), 1);
              }
            });
          }
        });
    },
    submit: function () {
      this.spinnerIsLoading = true;

      let orders = [];

      this.rows.forEach(function (row) {
        orders.push(Number(row.orderId));
      });

      let data = {
        driverId: this.driverId,
        vehicleTypeId: this.vehicleTypeId,
        orderIds: { orderIds: orders }
      };

      this.$store
        .dispatch("assignOrdersToDriver", data)
        .then((response) => {
          if (response.data.messageType === "Error") {
            this.errorMessage = response.data.message;
          } else {
            this.showSuccessMessage = true;
            this.reset();
            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.submit();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    doReset: function () {
      this.$swal
        .fire({
          type: null,
          title: "Proceed with the reset?",
          text: null,
          showCancelButton: true,
          allowOutsideClick: false,
          focusCancel: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reset"
        })
        .then((result) => {
          if (result.value) {
            this.reset();
          }
        });
    },
    reset: function () {
      this.errorMessage = "";
      this.rows = [];
      this.driverId = 0;
      this.vehicleTypeId = 0;
      this.orderId = "";
      this.checkForm();
    },
    add: function () {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("checkOrder", this.orderId)
        .then((response) => {
          if (response.data.orderExists) {
            this.$store
              .dispatch("checkOrderDriver", this.orderId)
              .then((responseCheckOrderDriver) => {
                if (responseCheckOrderDriver.data.alreadyAssigned) {
                  this.$swal
                    .fire({
                      type: null,
                      title: null,
                      text:
                        "Order already assigned to a driver, reassign to a new driver?",
                      showCancelButton: true,
                      allowOutsideClick: false,
                      focusCancel: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Reassign"
                    })
                    .then((result) => {
                      if (result.value) {
                        this.doAdd();
                      }
                    });
                } else {
                  if (response.data.orderStatus === "delivered") {
                    if (
                      this.roleId == 1 ||
                      this.roleId == 4 ||
                      this.roleId == 12
                    ) {
                      // role "Super Admin" or "Administrator" or "Advanced Admin"
                      this.$swal
                        .fire({
                          type: null,
                          title: null,
                          text:
                            "Order already delivered, reassign to a new driver?",
                          showCancelButton: true,
                          allowOutsideClick: false,
                          focusCancel: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Reassign"
                        })
                        .then((result) => {
                          if (result.value) {
                            this.doAdd();
                          }
                        });
                    } else {
                      this.$swal.fire({
                        type: "error",
                        title: "Error",
                        text:
                          "Order already delivered, reassignment to a new driver is not allowed",
                        showCancelButton: false,
                        allowOutsideClick: false,
                        focusCancel: true,
                        confirmButtonColor: "#d33",
                        confirmButtonText: "OK"
                      });
                    }
                  } else this.doAdd();
                }
              })
              .catch((error) => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }

                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then((result) => {
                    if (result.value) {
                      this.add();
                    }
                  });
              })
              .finally(() => {
                this.spinnerIsLoading = false;
              });
          } else {
            this.errorMessage =
              'Order with ID "' + this.orderId + '" was not found';
            this.spinnerIsLoading = false;
          }
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.add();
              }
            });
        });
    },
    doAdd: function () {
      let order = { orderId: this.orderId };
      let alreadyCaptured = false;
      let _this = this;

      this.rows.forEach(function (order) {
        if (order.orderId === _this.orderId) alreadyCaptured = true;
      });
      if (!alreadyCaptured) this.rows.push(order);
      this.orderId = "";
      this.$nextTick(() => {
        sleep(1).then(() => {
          this.$refs.orderId.focus();
        });
      });
    }
  },
  created() {
    this.load();
  }
};
</script>
