import Options from "@/conf/Options";

var cognito = require("amazon-cognito-identity-js");

function doLogin(user) {
  return new Promise((resolve, reject) => {
    var authenticationDetails = new cognito.AuthenticationDetails({
      Username: user.username,
      Password: user.password,
    });

    var poolData = {
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    };

    var cognitoUserPool = new cognito.CognitoUserPool(poolData);
    var userData = {
      Username: user.username,
      Pool: cognitoUserPool,
    };

    var cognitoUser = new cognito.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        let response = {
          code: 1,
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken(),
        };
        resolve(response);
      },
      onFailure: function (err) {
        reject(err.message);
      },
      newPasswordRequired: function () {
        let response = {
          code: 2,
        };
        resolve(response);
      },
    });
  });
}

// ----------------------------------------------------------------------------------------------------
function doLogout() {
  var poolData = {
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
  };

  if (localStorage.getItem("user") !== null) {
    var user = JSON.parse(
      Options.crypter.decrypt(localStorage.getItem("user"))
    );

    var cognitoUserPool = new cognito.CognitoUserPool(poolData);
    var userData = {
      Username: user.username,
      Pool: cognitoUserPool,
    };

    var cognitoUser = new cognito.CognitoUser(userData);
    cognitoUser.signOut();
  }
  localStorage.clear();
}

function doRefreshToken() {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("user") !== null) {
      var poolData = {
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
        UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
      };
      var user = JSON.parse(
        Options.crypter.decrypt(localStorage.getItem("user"))
      );

      var cognitoUserPool = new cognito.CognitoUserPool(poolData);
      var userData = {
        Username: user.username,
        Pool: cognitoUserPool,
      };

      var cognitoUser = new cognito.CognitoUser(userData);
      var refreshToken = JSON.parse(
        Options.crypter.decrypt(localStorage.getItem("refreshToken"))
      );
      refreshToken.getToken = function () {
        return this.token;
      };

      cognitoUser.refreshSession(refreshToken, (err, result) => {
        if (err) reject(err);
        resolve(result.getAccessToken().getJwtToken());
      });
    }
  });
}

function forgotPassword(user) {
  return new Promise((resolve, reject) => {
    var poolData = {
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    };

    var cognitoUserPool = new cognito.CognitoUserPool(poolData);
    var userData = {
      Username: user.username,
      Pool: cognitoUserPool,
    };

    var cognitoUser = new cognito.CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err.message);
      },
      inputVerificationCode() {
        resolve(1);
      },
    });
  });
}

function updatePassword(data) {
  var poolData = {
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
  };

  var cognitoUserPool = new cognito.CognitoUserPool(poolData);
  var userData = {
    Username: data.username,
    Pool: cognitoUserPool,
  };

  var cognitoUser = new cognito.CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(data.verificationCode, data.password, {
      onFailure: (error) => {
        reject(error.message);
      },
      onSuccess: (response) => {
        resolve(response);
      },
      undefined,
    });
  });
}

function doProvideNewPassword(user) {
  return new Promise((resolve, reject) => {
    var authenticationDetails = new cognito.AuthenticationDetails({
      Username: user.username,
      Password: user.password,
    });

    var poolData = {
      ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    };

    var cognitoUserPool = new cognito.CognitoUserPool(poolData);
    var userData = {
      Username: user.username,
      Pool: cognitoUserPool,
    };

    var cognitoUser = new cognito.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        let response = {
          code: 1,
          accessToken: result.getAccessToken().getJwtToken(),
          refreshToken: result.getRefreshToken(),
        };
        resolve(response);
      },
      onFailure: function (err) {
        reject(err.message);
      },
      newPasswordRequired: function () {
        cognitoUser.completeNewPasswordChallenge(user.newPassword, null, this, {
          onSuccess: function (response) {
            resolve(response);
          },
          onFailure: function (error) {
            reject(error);
          },
        });
      },
    });
  });
}
// ----------------------------------------------------------------------------------------------------
export default {
  doLogin,
  doProvideNewPassword,
  doLogout,
  doRefreshToken,
  forgotPassword,
  updatePassword,
};
