<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Season</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">{{ seasonId ? "Edit Season" : "Season Definition"}}</h1>
    <!-- end page-header -->
    <panel :title="seasonTitle">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div class="image-overlay" v-if="showImageOverlay" @click="showImageOverlay = false">
        <img :src="imageOverlayUrl" />
      </div>
      <div>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Season {{seasonId ? "updated":"created"}} successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>
        <div v-if="showMainForm">
          <div style="max-width: 700px">
            <div>
              <label class="f-w-700">Season Label</label>
              <i v-if="showLabelRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <input
                type="text"
                ref="seasonLabel"
                class="form-control"
                placeholder="# Enter the season label"
                style="margin-bottom: 5px"
                v-model="label"
                @keyup="checkForm"
              />
            </div>
            <div style="margin-top: 25px; position: relative">
              <label class="f-w-700">Start Date</label>
              <i v-if="showStartDateRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <date-picker
                v-model="seasonDateRange.startDate"
                :config="seasonDateRangeConfigs.start"
                @dp-change="onSeasonDateStartChange"
                placeholder="# Select season start date"
                class="form-control"
              ></date-picker>
            </div>
            <div style="margin-top: 25px; position: relative">
              <label class="f-w-700">End Date</label>
              <i v-if="showEndDateRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <date-picker
                v-model="seasonDateRange.endDate"
                :config="seasonDateRangeConfigs.end"
                @dp-change="onSeasonDateEndChange"
                placeholder="# Select season end date"
                class="form-control"
              ></date-picker>
            </div>
            <div style="margin-top: 25px">
              <label class="f-w-700">Store</label>
              <select class="form-control" @change="storeChanged" v-model="storeId">
                <option value="0" hidden selected v-if="storeId === 0">Select a Store</option>
                <option v-for="store in stores" :value="store.id" :key="store.id">{{ store.name }}</option>
              </select>
            </div>
            <div style="margin-top: 25px">
              <label class="f-w-700">Branch auto-assignement</label>
              <i v-if="showBranchRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <select class="form-control" @change="checkForm" v-model="branchId">
                <option value="0" hidden selected v-if="branchId === 0">Select a Branch</option>
                <option
                  v-for="branch in branches"
                  :value="branch.id"
                  :key="branch.id"
                >{{ branch.name }}</option>
              </select>
            </div>
            <div style="margin-top: 25px">
              <label class="f-w-700">Status</label>
              <div class="checkbox checkbox-css m-b-10">
                <input type="checkbox" id="status" v-model="seasonEnabled" />
                <label for="status">Active</label>
              </div>
            </div>
          </div>
          <div>
            <label class="f-w-700">Products</label>
            <span
              v-if="productsList.length > 0"
              class="label label-green m-2 m-l-10 f-s-14"
            >{{ productsList.length }}</span>
            <div class="checkbox checkbox-css m-b-10">
              <input
                type="checkbox"
                id="products-enabled"
                v-model="productsEnabled"
                @change="onProductsEnabledChanged"
              />
              <label for="products-enabled">Enabled Products</label>
            </div>
            <div>
              <button type="button" class="btn btn-success m-5" @click="checkAll">Check All</button>
              <button type="button" class="btn btn-success m-5" @click="uncheckAll">Uncheck All</button>
            </div>
            <div v-if="productsList.length > 0">
              <div style="display: flex; flex-wrap: wrap">
                <span
                  v-for="product in productsList"
                  :value="product"
                  :key="product"
                  class="label label-green m-2 f-s-11"
                >{{ product }}</span>
              </div>
            </div>
            <b-alert variant="yellow" :show="!productsEnabled" class="m-t-10">
              <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
              <p class="m-b-0">
                You are selecting from the
                <strong>disabled products</strong> list
              </p>
            </b-alert>
            <vue-good-table
              ref="productsTable"
              mode="remote"
              styleClass="vgt-table striped bordered"
              :columns="columns"
              :rows="rows"
              :line-numbers="false"
              :totalRows="totalRecords"
              :pagination-options="paginationOptions"
              :selectOptions="selectOptions"
              :search-options="searchOptions"
              :sort-options="sortOptions"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-cell-click="onCellClick"
              @on-search="onSearch"
            >
              <div slot="emptystate">No products found</div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'sku'" style="display: block; cursor: pointer">
                  <span
                    v-if="productsList.indexOf(props.row.sku) >= 0"
                    class="badge badge-green"
                  >&nbsp;</span>
                  <span v-else class="badge badge-secondary badge-square">&nbsp;</span>
                  {{ props.row.sku }}
                </span>
                <span
                  v-else-if="props.column.field === 'name'"
                  style="display: block; cursor: pointer"
                >{{ props.row.name }}</span>
              </template>
            </vue-good-table>
          </div>
          <div style="margin-top: 15px">
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="createSeason"
              :disabled="!enableSubmitBtn"
            >{{seasonId ? "Update":"Create"}} Season</button>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="cancel"
            >Cancel</button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

Array.prototype.removeByValue = function(val) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == val) {
      this.splice(i, 1);
      break;
    }
  }
};

export default {
  data() {
    return {
      seasonId: this.$route.query.id,
      seasonTitle: "-",
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showMainForm: true,
      showSuccessMessage: false,
      errorMessage: "",
      showLabelRequired: true,
      showStartDateRequired: true,
      showEndDateRequired: true,
      showBranchRequired: true,
      seasonDateRange: {
        startDate: null,
        endDate: null
      },
      seasonDateRangeConfigs: {
        start: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        },
        end: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        }
      },
      columns: [
        {
          label: "SKU",
          field: "sku",
          tdClass: "text-left"
        },
        {
          label: "Name",
          field: "name",
          tdClass: "text-left"
        },
        {
          label: "Line Of Product",
          field: "lineOfProduct",
          tdClass: "text-left",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Products per page",
        perPage: 10,
        perPageDropdown: [10, 15, 30, 50, 100, 200],
        ofLabel: "of"
      },
      searchOptions: {
        enabled: true,
        placeholder: "Refine your search result by entering multiple keywords",
        trigger: "enter",
        skipDiacritics: true
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "name", type: "asc" }]
      },
      label: "",
      storeId: Options.user.storeId,
      branchId: 0,
      seasonEnabled: true,
      productsEnabled: true,
      enableSubmitBtn: false,
      stores: [],
      branches: [],
      vueGoodTableServerParams: {
        columnFilters: {},
        sort: [
          {
            field: "name",
            direction: "asc"
          }
        ],
        page: 1,
        perPage: 10
      },
      productsFilter: {
        offset: 0,
        limit: 10,
        filter: [
          [
            {
              field: "storeId",
              value: Options.user.storeId,
              condition: "eq"
            }
          ],
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ],
        sort: [
          {
            field: "name",
            direction: "asc"
          }
        ]
      },
      showImageOverlay: false,
      imageOverlayUrl: "",
      productsList: [],
      searchTermBuffer: ""
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveStoreList")
        .then(retrieveStoreListResponse => {
          this.stores = retrieveStoreListResponse.data;
          if (this.seasonId) {
            this.$store
              .dispatch("retrieveSeason", this.seasonId)
              .then(retrieveSeasonResponse => {
                this.label = retrieveSeasonResponse.data.label;
                this.seasonTitle =
                  "Season: " + retrieveSeasonResponse.data.label;
                this.seasonDateRange.startDate =
                  retrieveSeasonResponse.data.dateFrom;
                this.seasonDateRange.endDate =
                  retrieveSeasonResponse.data.dateTo;
                this.storeId = retrieveSeasonResponse.data.storeId;
                this.branchId = retrieveSeasonResponse.data.branchId;
                this.seasonEnabled = retrieveSeasonResponse.data.enabled;
                this.productsList = retrieveSeasonResponse.data.products;
                this.checkForm();
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.load();
                    }
                  });
              });
          } else {
            this.seasonTitle = "New Season";
            this.loadProducts();
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
      this.storeChanged();
    },
    loadProducts() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveProductsList", this.productsFilter)
        .then(retrieveProductsListResponse => {
          this.rows = retrieveProductsListResponse.products;
          this.totalRecords = retrieveProductsListResponse.totalCount;
          this.$refs.seasonLabel.focus();
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.loadProducts();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    doSearch(searchTerm) {
      this.searchTermBuffer = searchTerm;
      if (this.spinnerIsLoading) return; //Abort in case another search was initiated

      this.productsFilter.offset = 0;
      this.productsFilter.filter = [];
      var keywords = searchTerm.trim().split(" ");

      let sku = {
        field: "sku",
        value: "",
        condition: "contains"
      };

      let name = {
        field: "name",
        value: "",
        condition: "contains"
      };

      let lineOfProduct = {
        field: "lineOfProduct",
        value: "",
        condition: "eq"
      };

      let store = {
        field: "storeId",
        value: "",
        condition: "eq"
      };

      let productEnabled = {
        field: "enabled",
        value: false,
        condition: "eq"
      };

      let searchGroup = [];

      for (var i = 0; i < keywords.length; i++) {
        let keyword = keywords[i].trim();
        if (keyword !== "") {
          sku.value = keyword;
          searchGroup.push(sku);

          name.value = keyword;
          searchGroup.push(name);

          lineOfProduct.value = keyword;
          searchGroup.push(lineOfProduct);

          this.productsFilter.filter.push(searchGroup);
        }
      }

      store.value = this.storeId;
      let storeFilter = [];
      storeFilter.push(store);
      this.productsFilter.filter.push(storeFilter);

      productEnabled.value = this.productsEnabled;
      let productEnabledFilter = [];
      productEnabledFilter.push(productEnabled);
      this.productsFilter.filter.push(productEnabledFilter);

      this.loadProducts();
    },
    onSearch(search) {
      this.doSearch(search.searchTerm);
    },
    onProductsEnabledChanged() {
      this.doSearch(this.searchTermBuffer);
    },
    checkAll: function() {
      let _this = this;
      this.rows.forEach(function(row) {
        if (_this.productsList.indexOf(row.sku) < 0)
          _this.productsList.push(row.sku);
      });

      this.productsList.sort();
    },
    uncheckAll: function() {
      let _this = this;
      this.rows.forEach(function(row) {
        if (_this.productsList.indexOf(row.sku) >= 0)
          _this.productsList.removeByValue(row.sku);
      });

      this.productsList.sort();
    },
    storeChanged() {
      this.branchId = 0;
      this.productsList = [];
      if (this.storeId !== 0)
        this.$store
          .dispatch("retrieveBranchList", this.storeId)
          .then(retrieveBranchListResponse => {
            this.branches = retrieveBranchListResponse.data;
            this.doSearch(this.searchTermBuffer);
            this.checkForm();
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.load();
                }
              });
          });
    },
    checkForm: function() {
      this.errorMessage = "";

      this.showLabelRequired = this.label.trim() === "";
      this.showStartDateRequired = !this.seasonDateRange.startDate;
      this.showEndDateRequired = !this.seasonDateRange.endDate;
      this.showBranchRequired = this.branchId == 0;

      this.enableSubmitBtn = !(
        this.showLabelRequired ||
        this.showStartDateRequired ||
        this.showEndDateRequired ||
        this.showBranchRequired
      );
    },
    createSeason: function() {
      let highSeason = {
        label: this.label,
        storeId: this.storeId,
        dateFrom: this.seasonDateRange.startDate,
        dateTo: this.seasonDateRange.endDate,
        branchId: this.branchId,
        enabled: this.seasonEnabled,
        products: this.productsList
      };

      this.spinnerIsLoading = true;

      if (this.seasonId) {
        let data = {
          seasonId: this.seasonId,
          highSeason: highSeason
        };
        this.$store
          .dispatch("updateHighSeason", data)
          .then(updateHighSeasonResponse => {
            if (updateHighSeasonResponse.data.messageType === "Error") {
              this.errorMessage = updateHighSeasonResponse.data.message;
            } else {
              this.errorMessage = "";
              this.showMainForm = false;
              this.showSuccessMessage = true;
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "highSeasonSeasons"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createSeason();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      } else
        this.$store
          .dispatch("createHighSeason", highSeason)
          .then(createHighSeasonResponse => {
            if (createHighSeasonResponse.data.messageType === "Error") {
              this.errorMessage = createHighSeasonResponse.data.message;
            } else {
              this.errorMessage = "";
              this.showMainForm = false;
              this.showSuccessMessage = true;
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "highSeasonSeasons"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createSeason();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
    },
    cancel: function() {
      let routeData = this.$router.resolve({
        name: "highSeasonSeasons"
      });
      window.open(routeData.href, "_self");
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );

      this.productsFilter.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.productsFilter.limit = this.vueGoodTableServerParams.perPage;
      this.productsFilter.sort = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.productsFilter.sort.push(sortByColumn);
      }
    },
    onSeasonDateStartChange(e) {
      this.$set(this.seasonDateRangeConfigs.end, "minDate", e.date);
      this.checkForm();
    },
    onSeasonDateEndChange(e) {
      this.$set(this.seasonDateRangeConfigs.start, "maxDate", e.date);
      this.checkForm();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage }, false);
      this.loadProducts();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.loadProducts();
    },
    onSortChange(params) {
      this.updateParams(
        {
          sort: params
        },
        true
      );
      this.loadProducts();
    },
    onColumnFilter(params) {
      this.updateParams(params, false);
      this.loadProducts();
    },
    onCellClick: function(record) {
      // SKU cell clicked
      if (record.column.field === "sku") {
        if (this.productsList.indexOf(record.row.sku) < 0)
          this.productsList.push(record.row.sku);
        else this.productsList.removeByValue(record.row.sku);

        this.productsList.sort();
      }

      // Name cell clicked
      if (record.column.field === "name") {
        this.imageOverlayUrl = record.row.urlImage;
        this.showImageOverlay = true;
      }

      this.checkForm();
    }
  },
  created() {
    this.load();
  }
};
</script>