<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Order Management</li>
      <li class="breadcrumb-item active">Order</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Order Management</h1>
    <!-- end page-header -->
    <panel>
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div class="image-overlay" v-if="showImageOverlay" @click="showImageOverlay = false">
        <img :src="imageOverlayUrl" />
      </div>
      <template slot="header">
        <h4 class="panel-title">{{ panelTitle }}</h4>
        <b-form-group
          v-if="showViewSwitch"
          class="m-0"
          style="float: right; margin: -20px 30px 0 0 !important"
        >
          <b-form-radio-group
            id="orderViewRadio"
            ref="orderViewRadio"
            buttons
            button-variant="success"
            size="xs"
            v-model="showMainView"
            :options="[
        { text: 'Main View', value: true },
        { text: 'Store View', value: false }
      ]"
            name="orderView"
          />
        </b-form-group>
      </template>
      <!-- ----------------------------------------------------------------------------------------------------------------- -->
      <div v-if="order">
        <b-tabs pills v-if="showMainView">
          <b-tab title="Order & Account Information" active>
            <b-card header="Order Information" bg-variant="light" header-border-variant="dark">
              <b-alert v-if="showDeliveryDateSuccessMessage" variant="success" show>
                <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                <p class="m-b-0">Updated</p>
              </b-alert>
              <b-card-group columns>
                <b-card header="Order Date" bg-variant="light" :class="orderInformationCardType">
                  <b-card-text class="text-black-darker f-s-14">{{ order.creationTs }}</b-card-text>
                </b-card>
                <b-card header="Order Status" bg-variant="light" :class="orderInformationCardType">
                  <b-card-text class="text-black-darker f-s-14">{{ order.status }}</b-card-text>
                </b-card>
                <b-card header="Delivery Date" bg-variant="light" :class="orderInformationCardType">
                  <div style="float: right; margin-top: -47px">
                    <b-link
                      v-if="orderDeliveryDateEditMode && (roleId != 3 || (roleId == 3 && orderId.startsWith('2')))"
                      href
                      @click="cancelEditingDeliveryDate"
                      style="padding-right: 20px;"
                    >Cancel</b-link>
                    <b-link
                      href
                      v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))"
                      @click="editAndUpdateOrderDeliveryDate"
                      style="padding-right: 20px;"
                    >{{ orderDeliveryDateEditMode ? "Update" : "Edit" }}</b-link>
                  </div>
                  <div v-if="orderDeliveryDateEditMode">
                    <date-picker
                      v-model="orderDeliveryDate"
                      :config="orderDeliveryDatePickerConfig"
                      placeholder="Select a delivery date"
                    ></date-picker>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="timeSlot-1"
                        name="deliverySlot"
                        v-model="orderDeliverySlot"
                        :value="timeSlot1"
                      />
                      <label for="timeSlot-1">{{ timeSlot1 }}</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="timeSlot-2"
                        name="deliverySlot"
                        v-model="orderDeliverySlot"
                        :value="timeSlot2"
                      />
                      <label for="timeSlot-2">{{ timeSlot2 }}</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="timeSlot-3"
                        name="deliverySlot"
                        v-model="orderDeliverySlot"
                        :value="timeSlot3"
                      />
                      <label for="timeSlot-3">{{ timeSlot3 }}</label>
                    </div>
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.deliveryDate"
                      class="text-black-darker f-s-14"
                    >{{ order.deliveryDate | moment("dddd, MMMM Do YYYY") }} {{ order.deliverySlot }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card
                  header="Purchased From"
                  bg-variant="light"
                  :class="orderInformationCardType"
                >
                  <b-card-text class="text-black-darker f-s-14">{{ order.storeName }}</b-card-text>
                </b-card>
                <!--
                <b-card
                  v-if="order.rate !== 0"
                  :header="currencyRateTitle"
                  bg-variant="light"
                  class="card-type-2"
                >
                  <b-card-text class="text-black-darker f-s-14">{{ order.rate.toFixed(2) }}</b-card-text>
                </b-card>
                -->
              </b-card-group>
            </b-card>
            <b-card header="Account Information" bg-variant="light" header-border-variant="dark">
              <b-card-group columns>
                <b-card header="Customer Name" bg-variant="light" class="card-type-2">
                  <b-card-text
                    class="text-black-transparent-3 f-s-14"
                    v-if="order.clientName === 'null null'"
                  >N/A</b-card-text>
                  <b-card-text class="text-black-darker f-s-14" v-else>{{ order.clientName }}</b-card-text>
                </b-card>
                <b-card header="Email Address" bg-variant="light" class="card-type-2">
                  <b-card-text
                    class="text-black-transparent-3 f-s-14"
                    v-if="order.email.trim() === ''"
                  >N/A</b-card-text>
                  <b-card-text class="text-black-darker f-s-14" v-else>{{ order.email }}</b-card-text>
                </b-card>
                <b-card header="Customer Group" bg-variant="light" class="card-type-2">
                  <b-card-text
                    class="text-black-darker f-s-14"
                    v-if="order.customerGroup === 'NOT LOGGED IN'"
                  >
                    <span class="label label-yellow">{{ order.customerGroup }}</span>
                  </b-card-text>
                  <b-card-text class="text-black-darker f-s-14" v-else>{{ order.customerGroup }}</b-card-text>
                </b-card>
                <b-card header="Date of Birth" bg-variant="light" class="card-type-2">
                  <b-card-text
                    class="text-black-darker f-s-14"
                    v-if="order.dateOfBirth"
                  >{{ new Date(order.dateOfBirth).getFullYear() + "-" + ("0" + new Date(order.dateOfBirth).getMonth()).slice(-2) + "-" + ("0" + new Date(order.dateOfBirth).getDate()).slice(-2) }}</b-card-text>
                  <b-card-text class="text-black-transparent-3 f-s-14" v-else>N/A</b-card-text>
                </b-card>
              </b-card-group>
            </b-card>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab title="Billing & Delivery Addresses">
            <b-card
              :header="billingAddressHeader"
              bg-variant="light"
              header-border-variant="dark"
              :class="billingAddressCardClass"
            >
              <b-alert v-if="showBillingAddressSuccessMessage" variant="success" show>
                <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                <p class="m-b-0">Updated</p>
              </b-alert>
              <b-alert v-if="showBillingAddressNoChangesMessage" variant="warning" show>
                <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                <p
                  class="m-b-0"
                >No changes were made to the billing address, update request cancelled</p>
              </b-alert>
              <b-card-group columns>
                <b-card header="Billing Name" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# First Name"
                      style="margin-bottom: 5px"
                      v-model="billingAddressFirstName"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Family Name"
                      v-model="billingAddressFamilyName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.billingAddress.clientName && order.billingAddress.clientName.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.billingAddress.clientName }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Company Name" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Company Name"
                      style="margin-bottom: 5px"
                      v-model="billingAddressCompanyName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.billingAddress.company && order.billingAddress.company !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.billingAddress.company }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Country" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <select
                      class="form-control"
                      v-model="billingAddressCountryId"
                      @change="billingAddressCountryChanged"
                    >
                      <option
                        v-for="country in billingAddressCountryOptions"
                        :value="country.id"
                        :key="country.id"
                      >{{ country.name }}</option>
                    </select>
                  </div>
                  <b-card-text
                    v-else
                    class="text-black-darker f-s-14"
                  >{{ order.billingAddress.country }}</b-card-text>
                </b-card>
                <b-card header="Region" bg-variant="light" class="card-type-2">
                  <span v-if="billingAddressEditMode">
                    <select
                      v-if="billingAddressRegionOptions.length > 0"
                      class="form-control"
                      style="margin-bottom: 5px"
                      v-model="billingAddressRegionId"
                      @change="billingAddressRegionChanged"
                    >
                      <option
                        value="0"
                        hidden
                        selected
                        v-if="billingAddressRegionId == 0"
                      >Select a Region</option>
                      <option
                        v-for="region in billingAddressRegionOptions"
                        :value="region.id"
                        :key="region.id"
                      >{{ region.name }}</option>
                    </select>
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      placeholder="# Region"
                      style="margin-bottom: 5px"
                      v-model="billingAddressRegionName"
                    />
                  </span>
                  <span v-else>
                    <b-card-text
                      v-if="order.billingAddress.region"
                      class="text-black-darker f-s-14"
                    >{{ order.billingAddress.region }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </span>
                </b-card>
                <b-card header="City" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <select
                      v-if="billingAddressCityOptions.length > 0"
                      class="form-control"
                      v-model="billingAddressCityId"
                    >
                      <option
                        value="0"
                        hidden
                        selected
                        v-if="billingAddressCityId === 0"
                      >Select a City</option>
                      <option
                        v-for="city in billingAddressCityOptions"
                        :value="city.id"
                        :key="city.id"
                      >{{ city.name }}</option>
                    </select>
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      placeholder="# City"
                      style="margin-bottom: 5px"
                      v-model="billingAddressCityName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.billingAddress.city && order.billingAddress.city.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.billingAddress.city }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Street" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Street Line 1"
                      v-model="billingAddressStreet1"
                      style="margin-bottom: 5px"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Street Line 2"
                      v-model="billingAddressStreet2"
                    />
                  </div>
                  <b-card-text v-else class="text-black-darker f-s-14">
                    <div
                      v-if="order.billingAddress.street.length > 0 && (order.billingAddress.street.length != 1 || order.billingAddress.street[0].trim() !== '')"
                    >
                      <span
                        v-for="street in order.billingAddress.street"
                        v-bind:key="street.street"
                      >
                        {{ street }}
                        <br />
                      </span>
                    </div>
                    <div v-else>
                      <b-card-text class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                    </div>
                  </b-card-text>
                </b-card>
                <b-card header="Landmark" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Landmark"
                      style="margin-bottom: 5px"
                      v-model="billingAddressLandmark"
                    />
                  </div>
                  <span v-else>
                    <b-card-text
                      v-if="order.billingAddress.township && order.billingAddress.township.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.billingAddress.township.trim() }}</b-card-text>
                    <b-card-text class="text-black-transparent-3 f-s-14" v-else>N/A</b-card-text>
                  </span>
                </b-card>
                <b-card header="Telephone" bg-variant="light" class="card-type-2">
                  <div v-if="billingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Telephone"
                      v-model="billingAddressTelephone"
                    />
                  </div>
                  <span v-else>
                    <b-card-text
                      class="text-black-darker f-s-14"
                      v-if="order.billingAddress.telephone && order.billingAddress.telephone.trim() !== '-'"
                    >{{ order.billingAddress.telephone }}</b-card-text>
                    <b-card-text class="text-black-transparent-3 f-s-14" v-else>N/A</b-card-text>
                  </span>
                </b-card>
                <b-link
                  v-if="billingAddressEditMode && (roleId != 3 || (roleId == 3 && orderId.startsWith('2')))"
                  href
                  @click="cancelEditingBillingAddress"
                  style="float: right; padding-right: 20px"
                >Cancel</b-link>
                <b-link
                  href
                  v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))"
                  @click="editAndUpdateBillingAddress"
                  style="float: right; padding-right: 20px"
                >{{ billingAddressEditMode ? "Update" : "Edit" }}</b-link>
              </b-card-group>
            </b-card>
            <!-- ----------------------------------------------------------------------------------------------------------------- -->
            <b-card
              :header="shippingAddressHeader"
              bg-variant="light"
              header-border-variant="dark"
              :class="shippingAddressCardClass"
            >
              <b-alert v-if="showShippingAddressSuccessMessage" variant="success" show>
                <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                <p class="m-b-0">Updated</p>
              </b-alert>
              <b-alert v-if="showShippingAddressNoChangesMessage" variant="warning" show>
                <i class="fa fa-exclamation-triangle fa-2x pull-left m-r-10"></i>
                <p
                  class="m-b-0"
                >No changes were made to the delivery address, update request cancelled</p>
              </b-alert>
              <b-card-group columns>
                <b-card header="Recipient Name" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# First Name"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressFirstName"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Family Name"
                      v-model="shippingAddressFamilyName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.shippingAddress.clientName && order.shippingAddress.clientName.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.shippingAddress.clientName }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Company Name" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Company Name"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressCompanyName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.shippingAddress.company && order.shippingAddress.company !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.shippingAddress.company }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Country" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <select
                      class="form-control"
                      v-model="shippingAddressCountryId"
                      @change="shippingAddressCountryChanged"
                    >
                      <option
                        v-for="country in shippingAddressCountryOptions"
                        :value="country.id"
                        :key="country.id"
                      >{{ country.name }}</option>
                    </select>
                  </div>
                  <b-card-text
                    v-else
                    class="text-black-darker f-s-14"
                  >{{ order.shippingAddress.country }}</b-card-text>
                </b-card>
                <b-card header="Region" bg-variant="light" class="card-type-2">
                  <span v-if="shippingAddressEditMode">
                    <select
                      v-if="shippingAddressRegionOptions.length > 0"
                      class="form-control"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressRegionId"
                      @change="shippingAddressRegionChanged"
                    >
                      <option
                        value="0"
                        hidden
                        selected
                        v-if="shippingAddressRegionId == 0"
                      >Select a Region</option>
                      <option
                        v-for="region in shippingAddressRegionOptions"
                        :value="region.id"
                        :key="region.id"
                      >{{ region.name }}</option>
                    </select>
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      placeholder="# Region"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressRegionName"
                    />
                  </span>
                  <span v-else>
                    <b-card-text
                      v-if="order.shippingAddress.region"
                      class="text-black-darker f-s-14"
                    >{{ order.shippingAddress.region }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </span>
                </b-card>
                <b-card header="City" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <select
                      v-if="shippingAddressCityOptions.length > 0"
                      class="form-control"
                      v-model="shippingAddressCityId"
                    >
                      <option
                        value="0"
                        hidden
                        selected
                        v-if="shippingAddressCityId === 0"
                      >Select a City</option>
                      <option
                        v-for="city in shippingAddressCityOptions"
                        :value="city.id"
                        :key="city.id"
                      >{{ city.name }}</option>
                    </select>
                    <input
                      v-else
                      type="text"
                      class="form-control"
                      placeholder="# City"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressCityName"
                    />
                  </div>
                  <div v-else>
                    <b-card-text
                      v-if="order.shippingAddress.city && order.shippingAddress.city.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.shippingAddress.city }}</b-card-text>
                    <b-card-text v-else class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                  </div>
                </b-card>
                <b-card header="Street" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Street Line 1"
                      v-model="shippingAddressStreet1"
                      style="margin-bottom: 5px"
                    />
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Street Line 2"
                      v-model="shippingAddressStreet2"
                    />
                  </div>
                  <b-card-text v-else class="text-black-darker f-s-14">
                    <div
                      v-if="order.shippingAddress.street.length > 0 && (order.shippingAddress.street.length != 1 || order.shippingAddress.street[0].trim() !== '')"
                    >
                      <span
                        v-for="street in order.shippingAddress.street"
                        v-bind:key="street.street"
                      >
                        {{ street }}
                        <br />
                      </span>
                    </div>
                    <div v-else>
                      <b-card-text class="text-black-transparent-3 f-s-14">N/A</b-card-text>
                    </div>
                  </b-card-text>
                </b-card>
                <b-card header="Landmark" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Landmark"
                      style="margin-bottom: 5px"
                      v-model="shippingAddressLandmark"
                    />
                  </div>
                  <span v-else>
                    <b-card-text
                      v-if="order.shippingAddress.township && order.shippingAddress.township.trim() !== ''"
                      class="text-black-darker f-s-14"
                    >{{ order.shippingAddress.township.trim() }}</b-card-text>
                    <b-card-text class="text-black-transparent-3 f-s-14" v-else>N/A</b-card-text>
                  </span>
                </b-card>
                <b-card header="Telephone" bg-variant="light" class="card-type-2">
                  <div v-if="shippingAddressEditMode">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="# Telephone"
                      v-model="shippingAddressTelephone"
                    />
                  </div>
                  <span v-else>
                    <b-card-text
                      class="text-black-darker f-s-14"
                      v-if="order.shippingAddress.telephone && order.shippingAddress.telephone.trim() !== '-'"
                    >{{ order.shippingAddress.telephone }}</b-card-text>
                    <b-card-text class="text-black-transparent-3 f-s-14" v-else>N/A</b-card-text>
                  </span>
                </b-card>
                <b-link
                  v-if="shippingAddressEditMode && (roleId != 3 || (roleId == 3 && orderId.startsWith('2')))"
                  href
                  @click="cancelEditingShippingAddress"
                  style="float: right; padding-right: 20px"
                >Cancel</b-link>
                <b-link
                  v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))"
                  href
                  @click="editAndUpdateShippingAddress"
                  style="float: right; padding-right: 20px"
                >{{ shippingAddressEditMode ? "Update" : "Edit" }}</b-link>
              </b-card-group>
            </b-card>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab title="Special Instruction">
            <b-alert v-if="showSpecialInstructionSuccessMessage" variant="success" show>
              <i class="fa fa-check fa-2x pull-left m-r-10"></i>
              <p class="m-b-0">Updated</p>
            </b-alert>
            <div class="form-group">
              <label class="f-w-700">Delivery Special Instruction</label>
              <select
                class="form-control m-b-10"
                :disabled="roleId == 3 && !orderId.startsWith('2')"
                v-model="deliverySpecialInstructionCode"
                @change="checkSpecialInstructionChanges"
              >
                <option
                  value="0"
                  hidden
                  selected
                  v-if="deliverySpecialInstructionCode == 0"
                >Select a Special Instruction Option</option>
                <option
                  v-for="option in deliverySpecialInstructionOptions"
                  :value="option.option"
                  :key="option.option"
                >{{ option.value }}</option>
              </select>
              <div v-if="deliverySpecialInstructionCode == 102">
                <label class="f-w-700">Special Instruction Details</label>
                <textarea
                  type="text"
                  class="form-control"
                  rows="5"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  placeholder="# Special Instruction"
                  v-model="specialInstructionMessage"
                  @keyup="checkSpecialInstructionChanges"
                />
              </div>
              <div class="checkbox checkbox-css m-b-10">
                <input
                  type="checkbox"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  id="send-anonymously"
                  v-model="isSendAnonymously"
                  @change="checkSpecialInstructionChanges"
                />
                <label for="send-anonymously">Send anonymously</label>
              </div>
              <div class="checkbox checkbox-css m-b-25">
                <input
                  type="checkbox"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  id="surprise-the-recipient"
                  v-model="isSurpriseTheRecipient"
                  @change="checkSpecialInstructionChanges"
                />
                <label for="surprise-the-recipient">Surprise the recipient</label>
              </div>
              <div v-if="isSurpriseTheRecipient">
                <label class="f-w-700">Alternative Recipient (The person to contact)</label>
                <br />
                <label class="col-form-label f-w-700">Name</label>
                <input
                  type="text"
                  class="form-control"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  placeholder="# Name"
                  v-model="alternativeRecipientName"
                  @keyup="checkSpecialInstructionChanges"
                />
                <label class="col-form-label f-w-700">Relation</label>
                <input
                  type="text"
                  class="form-control"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  placeholder="# Relation"
                  v-model="alternativeRecipientRelation"
                  @keyup="checkSpecialInstructionChanges"
                />
                <label class="col-form-label f-w-700">Phone</label>
                <input
                  type="text"
                  class="form-control"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  placeholder="# Phone"
                  v-model="alternativeRecipientPhone"
                  @keyup="checkSpecialInstructionChanges"
                />
              </div>
              <!-- <div style="margin: 25px 0">
                <label class="f-w-700">Internal Instruction</label>
                <textarea
                  placeholder="# Internal Instruction"
                  class="form-control"
                  :disabled="roleId == 3 && !orderId.startsWith('2')"
                  rows="8"
                  v-model="internalInstructionMessage"
                  @keyup="checkSpecialInstructionChanges"
                />
              </div> -->
              <button
                type="button"
                class="btn btn-success m-5"
                v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))"
                @click="updateSpecialInstruction"
                :disabled="!showSpecialInstructionRevertBtn"
              >Update</button>
              <button
                v-if="showSpecialInstructionRevertBtn && (roleId != 3 || (roleId == 3 && orderId.startsWith('2')))"
                type="button"
                class="btn btn-success"
                @click="revertSpecialInstruction"
              >Revert</button>
            </div>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab title="Order Details">
            <div style="margin-bottom: 20px" class="f-w-700">
              <b-alert v-if="showUpdateGiftMessageSuccessMessage" variant="success" show>
                <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                <p class="m-b-0">Updated</p>
              </b-alert>
            </div>
            <b-card header="Ordered Items" bg-variant="light" header-border-variant="dark">
              <vue-good-table
                id="itemsOrderes"
                styleClass="vgt-table striped bordered"
                :columns="itemsColumns"
                :rows="itemsRows"
                :line-numbers="itemsLineNumbers"
                :sort-options="itemsSortOptions"
              >
                <div slot="emptystate">No items found in this order</div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'imageUrl'" class="text-black-darker">
                    <img
                      :src="props.row.imageUrl"
                      class="img-product"
                      style="cursor: pointer"
                      @click="viewImage(props.row.imageUrl)"
                    />
                  </span>
                  <span
                    v-else-if="props.column.field === 'description' && order.orderTypeId == 1"
                    class="text-black-darker"
                    style="white-space: pre; display: inline-block; min-width:200px; max-width: 500px"
                  >{{ props.row.description }}</span>
                  <span
                    v-else-if="props.column.field === 'description'"
                    class="text-black-darker"
                    style="display: inline-block; min-width:200px; max-width: 500px"
                    v-html="props.row.description"
                  ></span>
                  <span
                    v-else-if="props.column.field === 'name'"
                    class="text-black-darker"
                    style="display: inline-block; min-width: 200px"
                  >
                    <span class="f-w-700">{{ props.row.name }}</span>
                    <span style="display: block">{{ "SKU: " + props.row.sku }}</span>

                    <span v-if="props.row.options.length > 0 || props.row.color">
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >Options:</span>
                      <span
                        v-if="props.row.color"
                        style="display: block; margin-left: 10px"
                      >{{ "- Color: " + props.row.color }}</span>
                      <span
                        style="display: block; margin-left: 10px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ "- " + option.name }}</span>
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'quantityOrdered'">
                    {{ props.row.quantityOrdered }}
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <br />
                      <br v-if="props.row.color" />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block;"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ option.quantityOrdered }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'giftMessage'"
                    style="display: inline-block; min-width: 90px"
                  >
                    <button
                      v-if="props.row.giftMessage && Object.keys(props.row.giftMessage).length !== 0"
                      type="button"
                      class="btn btn-grey btn-xs"
                      @click="viewGiftMessage(props.row.giftMessage, props.row.productId, props.row.orderId, true)"
                    >Gift Message</button>
                    <button
                      v-else
                      type="button"
                      class="btn btn-grey btn-xs"
                      @click="viewGiftMessage(props.row.giftMessage, props.row.productId, props.row.orderId, true)"
                    >Add Gift Message</button>
                  </span>
                  <span
                    v-else-if="props.column.field === 'originalPrice'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.originalPrice) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseOriginalPrice) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.originalPrice) + ")" }}</span>
                    -->
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <br v-if="props.row.color" />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block; margin-left: 5px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ props.row.currencyCode + " " + formatNumber(option.price) }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'price'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.price) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.basePrice) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.price) + ")" }}</span>
                    -->
                  </span>
                  <span v-else-if="props.column.field === 'subtotal'">
                    {{ props.row.currencyCode + " " + formatNumber(props.row.subtotal) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseSubtotal) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.subtotal) + ")" }}</span>
                    -->
                  </span>
                  <span
                    v-else-if="props.column.field === 'taxAmount'"
                    style="display: inline-block; min-width: 110px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.taxAmount) + " (" + props.row.taxPercent + "%)"}}
                    <!-- 
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseTaxAmount) + " (" + props.row.taxPercent + "%)"}}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.taxAmount) + ")" }}</span>
                    -->
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <br v-if="props.row.color" />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block; margin-left: 5px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ props.row.currencyCode + " " + formatNumber(option.taxAmount) }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'discountAmount'"
                    style="display: inline-block; min-width: 115px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.baseDiscountAmount) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.discountAmount) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.discountAmount) + ")" }}</span>
                    -->
                  </span>
                  <span
                    v-else-if="props.column.field === 'rowTotal'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.rowTotal) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseRowTotal) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.rowTotal) + ")" }}</span>
                    -->
                  </span>
                </template>
              </vue-good-table>
            </b-card>
            <b-card header="Delivery Fee" bg-variant="light" header-border-variant="dark">
              <b-card-text class="text-black-darker f-w-700">{{ order.shippingDescription }}</b-card-text>
              <b-card-text class="text-black-darker f-s-12">
                {{ order.currencyCode + " " + this.formatNumber(order.shippingAmount) }}
                <!--
                {{ order.baseCurrencyCode + " " + this.formatNumber(order.baseShippingAmount) }}
                <br />
                <span
                  v-if="order.baseCurrencyCode !== order.currencyCode"
                >{{ "(" + order.currencyCode + " " + this.formatNumber(order.shippingAmount) + ")"}}</span>
                -->
              </b-card-text>
            </b-card>
            <b-card
              v-if="order.paymentMethod"
              header="Payment Method"
              bg-variant="light"
              header-border-variant="dark"
            >
              <b-card-text class="text-black-darker f-w-700">{{ order.paymentMethod }}</b-card-text>
              <b-card-text class="text-black-darker f-s-12">{{ order.paymentDetail }}</b-card-text>
            </b-card>
            <b-card header="Order Total" bg-variant="light" header-border-variant="dark">
              <b-card-text class="text-black-darker f-s-14">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Subtotal</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.subtotal) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseSubtotal) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.subtotal) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Delivery Fee</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.shippingAmount) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseShippingAmount) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.shippingAmount) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Tax</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.taxAmount) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTaxAmount) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.taxAmount) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Grand Total</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.grandTotal) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseGrandTotal) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.grandTotal) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total Paid</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.totalPaid) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalPaid) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalPaid) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Grand Refunded</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.totalRefunded) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalRefunded) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalRefunded) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Grand Due</strong>
                        </td>
                        <td class="text-right">
                          {{ order.currencyCode + " " + this.formatNumber(order.totalDue) }}
                          <!--
                          <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalDue) }}</strong>
                          <br />
                          <span
                            v-if="order.baseCurrencyCode !== order.currencyCode"
                          >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalDue) + ")"}}</span>
                          -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-text>
            </b-card>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab title="Status History">
            <b-alert v-if="showOrderStatusSuccessMessage" variant="success" show>
              <i class="fa fa-check fa-2x pull-left m-r-10"></i>
              <p class="m-b-0">Updated</p>
            </b-alert>
            <b-alert v-if="showOrderStatusErrorMessage" variant="danger" show>
              <i class="fa fa-exclamation-circle fa-2x pull-left m-r-10"></i>
              <p class="m-b-0">{{ orderStatusErrorMessage }}</p>
            </b-alert>
            <div class="form-group">
              <label class="Bold">Set Status as</label>
              <select
                class="form-control m-b-10"
                v-model="orderStatusId"
                @change="checkOrderStatusChanges"
              >
                <option
                  v-for="option in orderStatusOptions"
                  :value="option.id"
                  :key="option.id"
                >{{ option.name }}</option>
              </select>
              <label class="Bold" v-if="orderStatusId == 11">Driver</label>
              <select
                class="form-control m-b-10"
                v-model="driverId"
                v-if="orderStatusId == 11"
                @change="checkOrderStatusChanges"
              >
                <option value="0" hidden selected v-if="driverId == 0">Select Driver</option>
                <option
                  v-for="driver in drivers"
                  :value="driver.driverId"
                  :key="driver.driverId"
                >{{ driver.firstName + ' ' + driver.familyName }}</option>
              </select>
              <v-select ref="vehicleTypes" v-if="orderStatusId == 11" :options="vehicleTypes" style="background-color: #ffffff"
                    placeholder="Select Vehicle Type" @input="checkOrderStatusChanges" v-model="vehicleType"></v-select>
                    <br />
              <label class="Bold">Comment</label>
              <textarea
                type="text"
                class="form-control m-b-10"
                rows="5"
                placeholder="# Comment"
                v-model="orderStatusComment"
                @keyup="checkOrderStatusChanges"
              />
              <div v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))" class="checkbox checkbox-css m-b-10">
                <input type="checkbox" id="notify-customer" v-model="isCustomerNotified" />
                <label for="notify-customer">Notify customer by email</label>
              </div>
              <div
                v-if="(roleId != 3 || (roleId == 3 && orderId.startsWith('2')))&& order.customerGroup !== 'NOT LOGGED IN'"
                class="checkbox checkbox-css m-b-25"
              >
                <input type="checkbox" id="storefront-visible" v-model="isVisibleOnFront" />
                <label for="storefront-visible">Visible on storefront</label>
              </div>
              <vue-good-table
                id="statusHistory"
                styleClass="vgt-table striped bordered"
                :columns="columns"
                :rows="rows"
                :line-numbers="lineNumbers"
              >
                <div slot="emptystate">No History</div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'isCustomerNotified'">
                    <span v-if="props.row.isCustomerNotified">
                      <span v-if="props.row.isCustomerNotified === 1">
                        <span class="label label-info">Customer Notified</span>
                      </span>
                      <span v-else-if="props.row.isCustomerNotified === 2">
                        <span class="label label-dark">Not Applicable</span>
                      </span>
                    </span>
                    <span v-else>
                      <span class="label label-danger">Customer Not Notified</span>
                    </span>
                  </span>
                </template>
              </vue-good-table>
              <button
                type="button"
                class="btn btn-success m-t-15"
                :disabled="!showUpdateStatusBtn"
                @click="updateOrderStatus"
              >Update Status</button>
              <button
                type="button"
                class="btn btn-success m-t-15 m-l-5"
                v-if="showUpdateStatusBtn"
                @click="cancelUpdateOrderStatus"
              >Cancel</button>
            </div>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab v-if="showActivityLogs" title="Activity Logs">
            <vue-good-table
              id="activityLogs"
              styleClass="vgt-table striped bordered"
              :columns="activityLogsColumns"
              :rows="activityLogsRows"
              :line-numbers="activityLogsLineNumbers"
              row-style-class="activity-logs-row"
            >
              <div slot="emptystate">No Activity Logs</div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'description'">{{ props.row.description }}</span>
              </template>
            </vue-good-table>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
          <b-tab v-if="showAction" title="Action">
            <div>
              <b-alert v-if="showAssignToBranchSuccessMessage" variant="success" show>
                <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                <p class="m-b-0">Order assigned successfully</p>
              </b-alert>
              <label class="Bold">Assign to branch</label>
              <select
                class="form-control m-b-10"
                v-model="branchId"
                @change="chechAssignToBranchValue"
                :disabled="order.denyAssignToBranch"
              >
                <option value="0" hidden selected v-if="branchId == 0">Select a branch</option>
                <option
                  v-for="branch in branches"
                  :value="branch.id"
                  :key="branch.id"
                >{{ branch.name }}</option>
              </select>
              <button
                type="button"
                class="btn btn-success m-5"
                v-if="roleId != 3 || (roleId == 3 && orderId.startsWith('2'))"
                @click="assignToBranch"
                :disabled="!enableAssignToBranchBtn"
              >Assign</button>
            </div>
          </b-tab>
          <!-- ----------------------------------------------------------------------------------------------------------------- -->
        </b-tabs>
        <div v-else class="store-view">
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Order Information</div>
            <div class="card-body">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td>Order Date</td>
                    <td>{{ order.creationTs }}</td>
                  </tr>
                  <tr>
                    <td>Order Status</td>
                    <td>{{order.status}}</td>
                  </tr>
                  <tr>
                    <td>Delivery Date</td>
                    <td>{{ order.deliveryDate | moment("dddd, MMMM Do YYYY") }} {{ order.deliverySlot === 'All Day' ? "":"in the"}} {{ order.deliverySlot }}</td>
                  </tr>
                  <tr>
                    <td>Purchased From</td>
                    <td>{{ order.storeName }}</td>
                  </tr>
                  <!--
                  <tr v-if="order.rate !== 0">
                    <td>{{ this.order.currencyCode + " / " + this.order.baseCurrencyCode + " rate" }}</td>
                    <td>{{ order.rate.toFixed(2) }}</td>
                  </tr>
                  -->
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="showAccountInformation" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Account Information</div>
            <div class="card-body">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td>Customer Name</td>
                    <td v-if="order.clientName === 'null null'">N/A</td>
                    <td v-else>{{ order.clientName }}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>{{ order.email }}</td>
                  </tr>
                  <tr>
                    <td>Customer Group</td>
                    <td v-if="order.customerGroup === 'NOT LOGGED IN'">
                      <span class="label label-yellow">{{ order.customerGroup }}</span>
                    </td>
                    <td v-else>{{ order.customerGroup }}</td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td
                      v-if="order.dateOfBirth"
                    >{{ new Date(order.dateOfBirth).getFullYear() + "-" + ("0" + new Date(order.dateOfBirth).getMonth()).slice(-2) + "-" + ("0" + new Date(order.dateOfBirth).getDate()).slice(-2) }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="showBillingAddress" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Billing Address</div>
            <div class="card-body">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td>Billing Name</td>
                    <td
                      v-if="order.billingAddress.clientName && order.billingAddress.clientName.trim() !== ''"
                    >{{ order.billingAddress.clientName }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td
                      v-if="order.billingAddress.company && order.billingAddress.company !== ''"
                    >{{ order.billingAddress.company }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{{ order.billingAddress.country }}</td>
                  </tr>
                  <tr>
                    <td>Region</td>
                    <td v-if="order.billingAddress.region">{{ order.billingAddress.region }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td v-if="order.billingAddress.city">{{ order.billingAddress.city }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr v-for="street in order.billingAddress.street" v-bind:key="street.street">
                    <td>Street</td>
                    <td v-if="street.trim() !== ''">{{ street }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Landmark</td>
                    <td v-if="order.billingAddress.township">{{ order.billingAddress.township }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Telephone</td>
                    <td
                      v-if="order.billingAddress.telephone && order.billingAddress.telephone.trim() !== '-'"
                    >{{ order.billingAddress.telephone }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Delivery Address</div>
            <div class="card-body">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td>Recipient Name</td>
                    <td
                      v-if="order.shippingAddress.clientName && order.shippingAddress.clientName.trim() !== ''"
                    >{{ order.shippingAddress.clientName }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td
                      v-if="order.shippingAddress.company && order.shippingAddress.company !== ''"
                    >{{ order.shippingAddress.company }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{{ order.shippingAddress.country }}</td>
                  </tr>
                  <tr>
                    <td>Region</td>
                    <td v-if="order.shippingAddress.region">{{ order.shippingAddress.region }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td v-if="order.shippingAddress.city">{{ order.shippingAddress.city }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr v-for="street in order.shippingAddress.street" v-bind:key="street.street">
                    <td>Street</td>
                    <td v-if="street.trim() !== ''">{{ street }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Landmark</td>
                    <td v-if="order.shippingAddress.township">{{ order.shippingAddress.township }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                  <tr>
                    <td>Telephone</td>
                    <td
                      v-if="order.shippingAddress.telephone && order.shippingAddress.telephone.trim() !== '-'"
                    >{{ order.shippingAddress.telephone }}</td>
                    <td v-else class="text-black-transparent-3 f-s-14">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Special Instruction</div>
            <div class="card-body">
              <table class="table table-condensed table-striped">
                <tbody>
                  <tr>
                    <td>Delivery Special Instruction</td>
                    <td v-if="deliverySpecialInstructionCode == 0">
                      <span class="label label-danger">No Special Instruction</span>
                    </td>
                    <td v-else>
                      <span
                        style="white-space: pre-line; text-align: left; margin-top: -20px"
                      >{{ this.order.specialInstructionLabel }}</span>
                    </td>
                  </tr>
                  <tr v-if="deliverySpecialInstructionCode == 102">
                    <td>Special Instruction Message</td>
                    <td>{{ specialInstructionMessage }}</td>
                  </tr>
                  <tr v-if="isSendAnonymously">
                    <td>Send Anonymously</td>
                    <td>Order should be sent anonymously</td>
                  </tr>
                  <tr v-if="isSurpriseTheRecipient">
                    <td>Surprise The Recipient</td>
                    <td>Alternative recipient should be contacted for delivery</td>
                  </tr>
                  <tr v-if="isSurpriseTheRecipient">
                    <td>Alternative Recipient Name</td>
                    <td>{{ alternativeRecipientName }}</td>
                  </tr>
                  <tr v-if="isSurpriseTheRecipient">
                    <td>Alternative Recipient Relation</td>
                    <td>{{ alternativeRecipientRelation }}</td>
                  </tr>
                  <tr v-if="isSurpriseTheRecipient">
                    <td>Alternative Recipient Phone No.</td>
                    <td>{{ alternativeRecipientPhone }}</td>
                  </tr>
                  <!-- <tr v-if="internalInstructionMessage && internalInstructionMessage !== ''">
                    <td>Internal Instruction</td>
                    <td>{{ internalInstructionMessage }}</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Ordered Items</div>
            <div class="card-body">
              <vue-good-table
                id="itemsOrderes"
                styleClass="vgt-table striped bordered"
                :columns="itemsColumns"
                :rows="itemsRows"
                :line-numbers="itemsLineNumbers"
                :sort-options="itemsSortOptions"
              >
                <div slot="emptystate">No items found in this order</div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'imageUrl'" class="text-black-darker">
                    <img
                      :src="props.row.imageUrl"
                      class="img-product"
                      style="cursor: pointer"
                      @click="viewImage(props.row.imageUrl)"
                    />
                  </span>
                  <span
                    v-else-if="props.column.field === 'description' && order.orderTypeId == 1"
                    class="text-black-darker"
                    style="white-space: pre; display: inline-block; min-width:200px; max-width: 500px"
                  >{{ props.row.description }}</span>
                  <span
                    v-else-if="props.column.field === 'description'"
                    class="text-black-darker"
                    style="display: inline-block; min-width:200px; max-width: 500px"
                    v-html="props.row.description"
                  ></span>
                  <span
                    v-else-if="props.column.field === 'name'"
                    class="text-black-darker"
                    style="display: inline-block; min-width: 200px"
                  >
                    <span class="f-w-700">{{ props.row.name }}</span>
                    <span style="display: block">{{ "SKU: " + props.row.sku }}</span>
                    <span v-if="props.row.options.length > 0 || props.row.color">
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >Options:</span>
                      <span
                        v-if="props.row.color"
                        style="display: block; margin-left: 10px"
                      >{{ "- Color: " + props.row.color }}</span>
                      <span
                        style="display: block; margin-left: 10px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ "- " + option.name }}</span>
                    </span>
                  </span>
                  <span v-else-if="props.column.field === 'quantityOrdered'">
                    {{ props.row.quantityOrdered }}
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <br />
                      <br v-if="props.row.color" />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block;"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ option.quantityOrdered }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'giftMessage'"
                    style="display: inline-block; min-width: 90px"
                  >
                    <button
                      v-if="props.row.giftMessage"
                      type="button"
                      class="btn btn-grey btn-xs"
                      @click="viewGiftMessage(props.row.giftMessage, props.row.productId, props.row.orderId, false)"
                    >Gift Message</button>
                    <span v-else class="label label-danger">No Gift Message</span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'originalPrice'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.originalPrice) }}
                    <!--
                      {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseOriginalPrice) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.originalPrice) + ")" }}</span>
                    -->
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <br v-if="props.row.color" />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block; margin-left: 5px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ props.row.currencyCode + " " + formatNumber(option.price) }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'price'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.price) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.basePrice) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.price) + ")" }}</span>
                    -->
                  </span>
                  <span
                    v-else-if="props.column.field === 'subtotal'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.subtotal) }}
                    <!--
                      {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseSubtotal) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.subtotal) + ")" }}</span>
                    -->
                  </span>
                  <span
                    v-else-if="props.column.field === 'taxAmount'"
                    style="display: inline-block; min-width: 110px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.taxAmount) + " (" + props.row.taxPercent + "%)"}}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseTaxAmount) + " (" + props.row.taxPercent + "%)"}}
                    <br />
                    <br v-if="props.row.color" />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.taxAmount) + ")" }}</span>
                    -->
                    <span v-if="props.row.options.length > 0">
                      <br />
                      <span
                        class="text-black-darker f-w-700"
                        style="display: block; margin: 15px 0 5px 0"
                      >&nbsp;</span>
                      <span
                        style="display: block; margin-left: 5px"
                        v-for="option in props.row.options"
                        v-bind:key="option.name"
                      >{{ props.row.currencyCode + " " + formatNumber(option.taxAmount) }}</span>
                    </span>
                  </span>
                  <span
                    v-else-if="props.column.field === 'discountAmount'"
                    style="display: inline-block; min-width: 115px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.discountAmount) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseDiscountAmount) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.discountAmount) + ")" }}</span>
                    -->
                  </span>
                  <span
                    v-else-if="props.column.field === 'rowTotal'"
                    style="display: inline-block; min-width: 90px"
                  >
                    {{ props.row.currencyCode + " " + formatNumber(props.row.rowTotal) }}
                    <!--
                    {{ props.row.baseCurrencyCode + " " + formatNumber(props.row.baseRowTotal) }}
                    <br />
                    <span
                      v-if="order.baseCurrencyCode !== order.currencyCode"
                    >{{ "(" + props.row.currencyCode + " " + formatNumber(props.row.rowTotal) + ")" }}</span>
                    -->
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Delivery Fee</div>
            <div class="card-body text-black">
              <span class="text-black f-w-700">{{ order.shippingDescription }}</span>
              <br />
              {{ order.currencyCode + " " + this.formatNumber(order.shippingAmount) }}
              <!--
              {{ order.baseCurrencyCode + " " + this.formatNumber(order.baseShippingAmount) }}
              <br />
              <span
                v-if="order.baseCurrencyCode !== order.currencyCode"
              >{{ "(" + order.currencyCode + " " + this.formatNumber(order.shippingAmount) + ")"}}</span>
              -->
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Payment Method</div>
            <div class="card-body text-black">
              <span
                class="text-black f-w-700"
                style="display: block; margin-bottom: 10px"
              >{{ order.paymentMethod }}</span>
              {{ order.paymentDetail }}
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Order Total</div>
            <div class="card-body">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <strong>Subtotal</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.subtotal) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseSubtotal) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.subtotal) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Delivery Fee</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.shippingAmount) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseShippingAmount) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.shippingAmount) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tax</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.taxAmount) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTaxAmount) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.taxAmount) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Grand Total</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.grandTotal) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseGrandTotal) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.grandTotal) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Paid</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.totalPaid) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalPaid) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalPaid) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Grand Refunded</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.totalRefunded) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalRefunded) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalRefunded) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Grand Due</strong>
                    </td>
                    <td class="text-right">
                      {{ order.currencyCode + " " + this.formatNumber(order.totalDue) }}
                      <!--
                      <strong>{{ order.baseCurrencyCode + " " + this.formatNumber(order.baseTotalDue) }}</strong>
                      <br />
                      <span
                        v-if="order.baseCurrencyCode !== order.currencyCode"
                      >{{"(" +order.currencyCode + " " + this.formatNumber(order.totalDue) + ")"}}</span>
                      -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Status History</div>
            <div class="card-body">
              <vue-good-table
                id="statusHistory"
                styleClass="vgt-table striped bordered"
                :columns="columns"
                :rows="rows"
                :line-numbers="lineNumbers"
              >
                <div slot="emptystate">No History</div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'isCustomerNotified'">
                    <span v-if="props.row.isCustomerNotified">
                      <span v-if="props.row.isCustomerNotified === 1">
                        <span class="label label-info">Customer Notified</span>
                      </span>
                      <span v-else-if="props.row.isCustomerNotified === 2">
                        <span class="label label-dark">Not Applicable</span>
                      </span>
                    </span>
                    <span v-else>
                      <span class="label label-danger">Customer Not Notified</span>
                    </span>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
          <div class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">Order Status</div>
            <div class="card-body text-black">
              <div style="margin-bottom: 20px" class="f-w-700">
                <b-alert v-if="showOrderRestrictedStatusSuccessMessage" variant="success" show>
                  <i class="fa fa-check fa-2x pull-left m-r-10"></i>
                  <p class="m-b-0">Updated</p>
                </b-alert>
                <b-alert v-if="showOrderRestrictedStatusErrorMessage" variant="danger" show>
                  <i class="fa fa-exclamation-circle fa-2x pull-left m-r-10"></i>
                  <p class="m-b-0">{{ orderRestrictedStatusErrorMessage }}</p>
                </b-alert>
                Status: {{ this.order.status }}
              </div>
              <span v-if="roleId != 9 && roleId != 10 && roleId != 11">
                <label class="Bold">Set Status as</label>
                <select
                  class="form-control m-b-10"
                  v-model="orderRestrictedStatusId"
                  @change="checkOrderRestrictedStatusChanges"
                >
                  <option
                    value="0"
                    hidden
                    selected
                    v-if="orderRestrictedStatusId == 0"
                  >Select Status</option>
                  <option
                    v-for="option in orderRestrictedStatusOptions"
                    :value="option.id"
                    :key="option.id"
                  >{{ option.name }}</option>
                </select>
                <label class="Bold" v-if="orderRestrictedStatusId == 11">Driver</label>
                <select
                  class="form-control m-b-10"
                  v-model="driverId"
                  v-if="orderRestrictedStatusId == 11"
                  @change="checkOrderRestrictedStatusChanges"
                >
                  <option value="0" hidden selected v-if="driverId == 0">Select Driver</option>
                  <option
                    v-for="driver in drivers"
                    :value="driver.driverId"
                    :key="driver.driverId"
                  >{{ driver.firstName + ' ' + driver.familyName }}</option>
                </select>
                <v-select ref="vehicleTypes" v-if="orderRestrictedStatusId == 11" :options="vehicleTypes" style="background-color: #ffffff"
                    placeholder="Select Vehicle Type" @input="checkOrderRestrictedStatusChanges" v-model="vehicleType"></v-select>
                    <br />
                <label class="Bold">Comment</label>
                <textarea
                  type="text"
                  class="form-control m-b-10"
                  rows="5"
                  placeholder="# Comment"
                  v-model="orderRestrictedStatusComment"
                  @keyup="checkOrderRestrictedStatusChanges"
                />
              </span>
              <div v-if="roleId != 9 && roleId != 10 && roleId != 11">
                <button
                  type="button"
                  class="btn btn-success m-t-15"
                  :disabled="!showUpdateRestrictedStatusBtn"
                  @click="updateOrderRestrictedStatus"
                >Update Status</button>
                <button
                  type="button"
                  class="btn btn-success m-t-15 m-l-5"
                  v-if="showUpdateRestrictedStatusBtn"
                  @click="cancelUpdateOrderRestrictedStatus"
                >Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      orderId: this.$route.query.id,
      roleId: Options.user.roleId,
      showViewSwitch:
        Options.user.roleId == 1 ||
        Options.user.roleId == 2 ||
        (Options.user.roleId == 3 && this.$route.query.id.startsWith("2")) ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 12 ||
        Options.user.roleId == 999,
      showMainView:
        Options.user.roleId == 1 ||
        (Options.user.roleId == 3 && this.$route.query.id.startsWith("2")) ||
        Options.user.roleId == 2 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 12 ||
        Options.user.roleId == 999,
      showAccountInformation: Options.user.roleId != 9,
      showBillingAddress: Options.user.roleId != 9,
      showImageOverlay: false,
      imageOverlayUrl: "",
      branches: [],
      branchId: 0,
      branchIdOriginalValue: 0,
      showDeliveryDateSuccessMessage: false,
      showAssignToBranchSuccessMessage: false,
      showActivityLogs: 
        Options.user.roleId == 1 ||
        (Options.user.roleId == 3 && this.$route.query.id.startsWith("2")) ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 12 ||
        Options.user.roleId == 999,
      showAction:
        Options.user.roleId == 1 ||
        Options.user.roleId == 2 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 12 ||
        Options.user.roleId == 999,
      enableAssignToBranchBtn: false,
      orderStatusErrorMessage: "",
      orderRestrictedStatusErrorMessage: "",
      storeId: Options.user.storeId,
      panelTitle: "Order # " + this.$route.query.id,
      order: null,
      items: null,
      billingAddressCardClass: "",
      shippingAddressCardClass: "",
      orderStatusOptions: [],
      orderRestrictedStatusOptions: [],
      drivers: [],
      orderStatusId: 0,
      orderStatusIdOriginalValue: 0,
      orderRestrictedStatusId: 0,
      driverId: 0,
      driverIdOriginalValue: 0,
      orderDeliveryDateEditMode: false,
      orderInformationCardType: "card-type-2",
      orderDeliveryDate: "",
      orderDeliverySlot: "",
      orderDeliverySlotOriginalValue: "",
      orderDeliveryDatePickerConfig: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: false,
        maxDate: false,
        showTodayButton: true
      },
      orderStatusComment: "",
      orderRestrictedStatusComment: "",
      deliverySpecialInstructionCode: 0,
      deliverySpecialInstructionCodeOriginalValue: 0,
      deliverySpecialInstructionOptions: [],
      vehicleTypes: [],
      vehicleType: null,
      showBillingAddressSuccessMessage: false,
      showBillingAddressNoChangesMessage: false,
      showShippingAddressSuccessMessage: false,
      showShippingAddressNoChangesMessage: false,
      // billing address
      billingAddressHeader: "Billing Address",
      billingAddressEditMode: false,
      billingAddressFirstName: "",
      billingAddressFirstNameOriginalValue: "",
      billingAddressFamilyName: "",
      billingAddressFamilyNameOriginalValue: "",
      billingAddressCompanyName: "",
      billingAddressCompanyNameOriginalValue: "",
      billingAddressLandmark: "",
      billingAddressLandmarkOriginalValue: "",
      billingAddressCountryId: "",
      billingAddressCountryIdOriginalValue: "",
      billingAddressCountryOptions: [],
      billingAddressRegionId: 0,
      billingAddressRegionIdOriginalValue: 0,
      billingAddressRegionName: "",
      billingAddressRegionNameOriginalValue: "",
      billingAddressRegionOptions: [],
      billingAddressCityName: "",
      billingAddressCityNameOriginalValue: "",
      billingAddressCityId: 0,
      billingAddressCityIdOriginalValue: 0,
      billingAddressCityOptions: [],
      billingAddressTelephone: "",
      billingAddressTelephoneOriginalValue: "",
      billingAddressStreet1: "",
      billingAddressStreet1OriginalValue: "",
      billingAddressStreet2: "",
      billingAddressStreet2OriginalValue: "",
      // shipping address
      shippingAddressHeader: "Delivery Address",
      shippingAddressEditMode: false,
      shippingAddressFirstName: "",
      shippingAddressFirstNameOriginalValue: "",
      shippingAddressFamilyName: "",
      shippingAddressFamilyNameOriginalValue: "",
      shippingAddressCompanyName: "",
      shippingAddressCompanyNameOriginalValue: "",
      shippingAddressLandmark: "",
      shippingAddressLandmarkOriginalValue: "",
      shippingAddressCountryId: "",
      shippingAddressCountryIdOriginalValue: "",
      shippingAddressCountryOptions: [],
      shippingAddressRegionId: 0,
      shippingAddressRegionIdOriginalValue: 0,
      shippingAddressRegionName: "",
      shippingAddressRegionNameOriginalValue: "",
      shippingAddressRegionOptions: [],
      shippingAddressCityName: "",
      shippingAddressCityNameOriginalValue: "",
      shippingAddressCityId: 0,
      shippingAddressCityIdOriginalValue: 0,
      shippingAddressCityOptions: [],
      shippingAddressTelephone: "",
      shippingAddressTelephoneOriginalValue: "",
      shippingAddressStreet1: "",
      shippingAddressStreet1OriginalValue: "",
      shippingAddressStreet2: "",
      shippingAddressStreet2OriginalValue: "",
      //
      isSendAnonymously: false,
      isSendAnonymouslyOriginalValue: false,
      isSurpriseTheRecipient: false,
      isSurpriseTheRecipientOriginalValue: false,
      isCustomerNotified: false,
      isVisibleOnFront: false,
      specialInstructionMessage: "",
      specialInstructionMessageOriginalValue: "",
      // internalInstructionMessage: "",
      // internalInstructionMessageOriginalValue: "",
      alternativeRecipientName: "",
      alternativeRecipientNameOriginalValue: "",
      alternativeRecipientRelation: "",
      alternativeRecipientRelationOriginalValue: "",
      alternativeRecipientPhone: "",
      alternativeRecipientPhoneOriginalValue: "",
      showSpecialInstructionRevertBtn: false,
      showUpdateStatusBtn: false,
      showUpdateRestrictedStatusBtn: false,
      showSpecialInstructionSuccessMessage: false,
      showOrderStatusSuccessMessage: false,
      showOrderStatusErrorMessage: false,
      showOrderRestrictedStatusSuccessMessage: false,
      showOrderRestrictedStatusErrorMessage: false,
      showUpdateGiftMessageSuccessMessage: false,
      // currencyRateTitle: "",
      activeTab: 0,
      showBooks: false,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      lineNumbers: true,
      activityLogsLineNumbers: true,
      columns: [
        {
          label: "Date & Time",
          field: "createdAt",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd HH:mm"
        },
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Comment",
          field: "comment"
        },
        {
          label: "Notification",
          field: "isCustomerNotified"
        }
      ],
      rows: [],
      activityLogsColumns: [
        {
          label: "Date & Time",
          field: "ts",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd HH:mm"
        },
        {
          label: "User",
          field: "userName"
        },
        {
          label: "Action",
          field: "actionName"
        },
        {
          label: "Description",
          field: "description"
        }
      ],
      activityLogsRows: [],
      itemsLineNumbers: true,
      itemsSortOptions: { enabled: false },
      itemsColumns: [
        {
          label: "Product Name",
          field: "name"
        },
        {
          label: "Product Image",
          field: "imageUrl"
        },
        {
          label: "Product Description",
          field: "description"
        },
        {
          label: "Original Price",
          field: "originalPrice"
        },
        {
          label: "Price",
          field: "price"
        },
        {
          label: "Qty",
          field: "quantityOrdered"
        },
        {
          label: "Tax Amount (%)",
          field: "taxAmount"
        },
        {
          label: "Discount Amount",
          field: "discountAmount"
        },
        {
          label: "Row Total",
          field: "rowTotal"
        },
        {
          label: "Gift Message",
          field: "giftMessage"
        }
      ],
      itemsRows: [],
      driverFilter: {
        offset: -1,
        limit: -1,
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ]
      }
    };
  },
  computed: {
    timeSlot1: function() {
      let result = "Before 3PM";
      if (this.orderDeliverySlotOriginalValue && this.orderDeliverySlotOriginalValue.includes(":"))
            result = "Before 3:00PM";
      return result;
    },
    timeSlot2: function() {
      let result = "After 3PM";
      if (this.orderDeliverySlotOriginalValue && this.orderDeliverySlotOriginalValue.includes(":"))
            result = "After 3:00PM";
      return result;
    },
    timeSlot3: function() {
      return "All Day";
    },
  },
  methods: {
    load() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveOrder", this.orderId)
        .then(response => {
          this.$store
            .dispatch("retrieveSpecialInstructionOptionList")
            .then(responseRetrieveSpecialInstructionOptionList => {
              let orderStatusData = {
                storeId: this.storeId,
                typeId: response.orders[0].orderTypeId
              };
              this.$store
                .dispatch("retrieveOrderStatusList", orderStatusData)
                .then(responseRetrieveOrderStatusList => {
                  let orderStatusRestrictedData = {
                    storeId: this.storeId,
                    typeId: response.orders[0].orderTypeId
                  };
                  this.$store
                    .dispatch(
                      "retrieveOrderRestrictedStatusList",
                      orderStatusRestrictedData
                    )
                    .then(responseRetrieveOrderRestrictedStatusList => {
                      this.orderRestrictedStatusOptions =
                        responseRetrieveOrderRestrictedStatusList.data;
                    });
                  this.$store
                    .dispatch("retrieveDriverList", this.driverFilter)
                    .then(responseRetrieveDriverList => {
                      this.order = response.orders[0];
                      if (this.showActivityLogs) {
                        this.$store
                          .dispatch("retrieveActivityLogs", this.orderId)
                          .then(responseRetrieveActivityLogs => {
                            this.activityLogsRows = responseRetrieveActivityLogs;
                          });
                      }

                      let _this = this;
                      this.$store
                        .dispatch("retrieveVehicleTypeList")
                        .then(responseRetrieveVehicleTypeList => {
                          _this.vehicleTypes = [];
                          responseRetrieveVehicleTypeList.forEach(function (vehicleType) {
                            _this.vehicleTypes.push({
                              id: vehicleType.id,
                              label: vehicleType.name
                            });
                          });
                        });

                      if (this.showAction) {
                        this.$store
                          .dispatch("retrieveBranchList", this.order.storeId)
                          .then(responseRetrieveBranchList => {
                            this.branches = responseRetrieveBranchList.data;
                          });
                      }

                      this.branchId = this.order.branchId;
                      this.branchIdOriginalValue = this.order.branchId;

                      this.panelTitle = "Order # " + this.$route.query.id;

                      /*this.currencyRateTitle =
                        this.order.currencyCode +
                        " / " +
                        this.order.baseCurrencyCode +
                        " rate";*/
                      this.specialInstructionMessage = this.order.specialInstructionMessage;
                      this.specialInstructionMessageOriginalValue = this.order.specialInstructionMessage;

                      // this.internalInstructionMessage = this.order.specialInstructionInternalMessage;
                      // this.internalInstructionMessageOriginalValue = this.order.specialInstructionInternalMessage;

                      if (this.order.hasOwnProperty("specialInstructionCode")) {
                        this.deliverySpecialInstructionCode = this.order.specialInstructionCode;
                        this.deliverySpecialInstructionCodeOriginalValue = this.order.specialInstructionCode;
                      } else {
                        this.deliverySpecialInstructionCode = 0;
                        this.deliverySpecialInstructionCodeOriginalValue = 0;
                      }
                      this.itemsRows = response.items;
                      this.orderStatusId = this.order.statusId;
                      this.orderStatusIdOriginalValue = this.order.statusId;
                      if (this.order.driverId)
                        this.driverId = this.order.driverId;
                      else this.driverId = 0;
                      this.driverIdOriginalValue = this.order.driverId;
                      this.rows = this.order.statusHistories;
                      this.isSendAnonymously = this.order.sendAnonymously;
                      this.isSendAnonymouslyOriginalValue = this.order.sendAnonymously;
                      this.isSurpriseTheRecipient = this.order.surpriseTheRecipient;
                      this.isSurpriseTheRecipientOriginalValue = this.order.surpriseTheRecipient;
                      this.alternativeRecipientName = this.order.surpriseTheRecipientName;
                      this.alternativeRecipientNameOriginalValue = this.order.surpriseTheRecipientName;
                      this.alternativeRecipientRelation = this.order.surpriseTheRecipientRelation;
                      this.alternativeRecipientRelationOriginalValue = this.order.surpriseTheRecipientRelation;
                      this.alternativeRecipientPhone = this.order.surpriseTheRecipientPhone;
                      this.alternativeRecipientPhoneOriginalValue = this.order.surpriseTheRecipientPhone;

                      //Delivery Date
                      this.orderDeliveryDate = this.order.deliveryDate;
                      this.orderDeliverySlot = this.order.deliverySlot;
                      this.orderDeliverySlotOriginalValue = this.order.deliverySlot;

                      // Billing Address
                      this.billingAddressFirstName = this.order.billingAddress.firstName;
                      this.billingAddressFirstNameOriginalValue = this.order.billingAddress.firstName;
                      this.billingAddressFamilyName = this.order.billingAddress.lastName;
                      this.billingAddressFamilyNameOriginalValue = this.order.billingAddress.lastName;
                      this.billingAddressCompanyName = this.order.billingAddress.company;
                      this.billingAddressCompanyNameOriginalValue = this.order.billingAddress.company;
                      this.billingAddressLandmark = this.order.billingAddress.township;
                      this.billingAddressLandmarkOriginalValue = this.order.billingAddress.township;
                      this.billingAddressCountryId = this.order.billingAddress.countryId;
                      this.billingAddressCountryIdOriginalValue = this.order.billingAddress.countryId;
                      this.billingAddressRegionName = this.order.billingAddress.region;
                      this.billingAddressRegionNameOriginalValue = this.order.billingAddress.region;
                      if (
                        this.order.billingAddress.hasOwnProperty("regionId")
                      ) {
                        this.billingAddressRegionId = this.order.billingAddress.regionId;
                        this.billingAddressRegionIdOriginalValue = this.order.billingAddress.regionId;
                      } else {
                        this.billingAddressRegionId = 0;
                        this.billingAddressRegionIdOriginalValue = 0;
                      }
                      this.billingAddressRegionName = this.order.billingAddress.region;
                      this.billingAddressRegionNameOriginalValue = this.order.billingAddress.region;
                      this.billingAddressCityName = this.order.billingAddress.city;
                      this.billingAddressCityNameOriginalValue = this.order.billingAddress.city;
                      if (this.order.billingAddress.hasOwnProperty("cityId")) {
                        this.billingAddressCityId = this.order.billingAddress.cityId;
                        this.billingAddressCityIdOriginalValue = this.order.billingAddress.cityId;
                      } else {
                        this.billingAddressCityId = 0;
                        this.billingAddressCityIdOriginalValue = 0;
                      }
                      this.billingAddressTelephone = this.order.billingAddress.telephone;
                      this.billingAddressTelephoneOriginalValue = this.order.billingAddress.telephone;
                      if (this.order.billingAddress.street.length == 1) {
                        this.billingAddressStreet1 = this.order.billingAddress.street[0];
                        this.billingAddressStreet1OriginalValue = this.order.billingAddress.street[0];
                        this.billingAddressStreet2 = "";
                        this.billingAddressStreet2OriginalValue = "";
                      } else if (this.order.billingAddress.street.length > 1) {
                        this.billingAddressStreet1 = this.order.billingAddress.street[0];
                        this.billingAddressStreet1OriginalValue = this.order.billingAddress.street[0];
                        this.billingAddressStreet2 = this.order.billingAddress.street[1];
                        this.billingAddressStreet2OriginalValue = this.order.billingAddress.street[1];
                      } else {
                        this.billingAddressStreet1 = "";
                        this.billingAddressStreet1OriginalValue = "";
                        this.billingAddressStreet2 = "";
                        this.billingAddressStreet2OriginalValue = "";
                      }

                      // Shipping Address
                      this.shippingAddressFirstName = this.order.shippingAddress.firstName;
                      this.shippingAddressFirstNameOriginalValue = this.order.shippingAddress.firstName;
                      this.shippingAddressFamilyName = this.order.shippingAddress.lastName;
                      this.shippingAddressFamilyNameOriginalValue = this.order.shippingAddress.lastName;
                      this.shippingAddressCompanyName = this.order.shippingAddress.company;
                      this.shippingAddressCompanyNameOriginalValue = this.order.shippingAddress.company;
                      this.shippingAddressLandmark = this.order.shippingAddress.township;
                      this.shippingAddressLandmarkOriginalValue = this.order.shippingAddress.township;
                      this.shippingAddressCountryId = this.order.shippingAddress.countryId;
                      this.shippingAddressCountryIdOriginalValue = this.order.shippingAddress.countryId;
                      this.shippingAddressRegionName = this.order.shippingAddress.region;
                      this.shippingAddressRegionNameOriginalValue = this.order.shippingAddress.region;
                      if (
                        this.order.shippingAddress.hasOwnProperty("regionId")
                      ) {
                        this.shippingAddressRegionId = this.order.shippingAddress.regionId;
                        this.shippingAddressRegionIdOriginalValue = this.order.shippingAddress.regionId;
                      } else {
                        this.shippingAddressRegionId = 0;
                        this.shippingAddressRegionIdOriginalValue = 0;
                      }
                      this.billingAddressRegionName = this.order.billingAddress.region;
                      this.billingAddressRegionNameOriginalValue = this.order.billingAddress.region;
                      this.shippingAddressCityName = this.order.shippingAddress.city;
                      this.shippingAddressCityNameOriginalValue = this.order.shippingAddress.city;
                      if (this.order.shippingAddress.hasOwnProperty("cityId")) {
                        this.shippingAddressCityId = this.order.shippingAddress.cityId;
                        this.shippingAddressCityIdOriginalValue = this.order.shippingAddress.cityId;
                      } else {
                        this.shippingAddressCityId = 0;
                        this.shippingAddressCityIdOriginalValue = 0;
                      }
                      this.shippingAddressTelephone = this.order.shippingAddress.telephone;
                      this.shippingAddressTelephoneOriginalValue = this.order.shippingAddress.telephone;
                      if (this.order.shippingAddress.street.length == 1) {
                        this.shippingAddressStreet1 = this.order.shippingAddress.street[0];
                        this.shippingAddressStreet1OriginalValue = this.order.shippingAddress.street[0];
                        this.shippingAddressStreet2 = "";
                        this.shippingAddressStreet2OriginalValue = "";
                      } else if (this.order.shippingAddress.street.length > 1) {
                        this.shippingAddressStreet1 = this.order.shippingAddress.street[0];
                        this.shippingAddressStreet1OriginalValue = this.order.shippingAddress.street[0];
                        this.shippingAddressStreet2 = this.order.shippingAddress.street[1];
                        this.shippingAddressStreet2OriginalValue = this.order.shippingAddress.street[1];
                      } else {
                        this.shippingAddressStreet1 = "";
                        this.shippingAddressStreet1OriginalValue = "";
                        this.shippingAddressStreet2 = "";
                        this.shippingAddressStreet2OriginalValue = "";
                      }

                      this.deliverySpecialInstructionOptions = responseRetrieveSpecialInstructionOptionList;
                      this.orderStatusOptions =
                        responseRetrieveOrderStatusList.data;

                      this.drivers = responseRetrieveDriverList.data.drivers;
                    });
                })
                .catch(error => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }

                  this.$swal
                    .fire({
                      type: "error",
                      title: titleTxt,
                      text: msgTxt,
                      showCancelButton: true,
                      allowOutsideClick: false,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Retry"
                    })
                    .then(result => {
                      if (result.value) {
                        this.load();
                      } else if (
                        result.dismiss === this.$swal.DismissReason.cancel
                      ) {
                        this.$router.push("/orderManagement/orders");
                      }
                    });
                })
                .finally(() => {
                  this.spinnerIsLoading = false;
                });
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }

              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  } else if (
                    result.dismiss === this.$swal.DismissReason.cancel
                  ) {
                    this.$router.push("/orderManagement/orders");
                  }
                });

              this.spinnerIsLoading = false;
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.$router.push("/orderManagement/orders");
              }
            });

          this.spinnerIsLoading = false;
        });
    },
    formatNumber: function(value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    viewImage: function(imageUrl) {
      this.imageOverlayUrl = imageUrl;
      this.showImageOverlay = true;
    },
    editAndUpdateOrderDeliveryDate: function() {
      if (this.orderDeliveryDateEditMode) {
        if (this.roleId != 3 || (this.roleId == 3 && this.orderId.startsWith('2'))) {
          let deliveryDate = {
            entity: {
              deliveryDate: this.orderDeliveryDate,
              deliverySlot: this.orderDeliverySlot
            }
          };
          let deliveryDateData = {
            orderId: this.orderId,
            deliveryDate: deliveryDate
          };
          this.spinnerIsLoading = true;
          this.$store
            .dispatch("updateDeliveryDate", deliveryDateData)
            .then(() => {
              this.orderDeliveryDateEditMode = false;
              this.showDeliveryDateSuccessMessage = true;

              this.load();

              sleep(3000).then(() => {
                this.showDeliveryDateSuccessMessage = false;
              });
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }

              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.editAndUpdateOrderDeliveryDate();
                  }
                });
            })
            .finally(() => {
              this.spinnerIsLoading = false;
            });
        }
      } else {
        this.orderDeliveryDateEditMode = true;
      }
    },
    editAndUpdateBillingAddress() {
      this.cancelEditingShippingAddress();

      if (this.billingAddressEditMode) {
        // check changes
        if (
          this.billingAddressFirstNameOriginalValue ===
            this.billingAddressFirstName &&
          this.billingAddressFamilyNameOriginalValue ===
            this.billingAddressFamilyName &&
          this.billingAddressCompanyNameOriginalValue ===
            this.billingAddressCompanyName &&
          this.billingAddressCountryIdOriginalValue ===
            this.billingAddressCountryId &&
          this.billingAddressRegionIdOriginalValue ==
            this.billingAddressRegionId &&
          this.billingAddressRegionNameOriginalValue ===
            this.billingAddressRegionName &&
          this.billingAddressCityIdOriginalValue == this.billingAddressCityId &&
          this.billingAddressCityNameOriginalValue ===
            this.billingAddressCityName &&
          this.billingAddressLandmarkOriginalValue ===
            this.billingAddressLandmark &&
          this.billingAddressStreet1OriginalValue ===
            this.billingAddressStreet1 &&
          this.billingAddressStreet2OriginalValue ===
            this.billingAddressStreet2 &&
          this.billingAddressTelephoneOriginalValue ===
            this.billingAddressTelephone
        ) {
          this.cancelEditingBillingAddress();
          this.showBillingAddressNoChangesMessage = true;
          sleep(3000).then(() => {
            this.showBillingAddressNoChangesMessage = false;
          });
          return;
        }

        // update process
        this.billingAddressHeader = "Billing Address";
        this.billingAddressCardClass = "card-type-2";

        let updateOrderAddressData = {
          orderId: this.orderId,
          address: {
            firstName: this.billingAddressFirstName,
            lastName: this.billingAddressFamilyName,
            company: this.billingAddressCompanyName,
            street: [],
            countryId: this.billingAddressCountryId,
            regionId: this.billingAddressRegionId,
            region: this.billingAddressRegionName,
            cityId: this.billingAddressCityId,
            city: this.billingAddressCityName,
            township: this.billingAddressLandmark,
            telephone: this.billingAddressTelephone,
            addressType: 1
          }
        };

        if (this.billingAddressRegionId != 0)
          delete updateOrderAddressData.address.region;

        if (this.billingAddressCityId != 0)
          delete updateOrderAddressData.address.city;

        if (this.billingAddressStreet1.trim() !== "")
          updateOrderAddressData.address.street.push(
            this.billingAddressStreet1
          );

        if (this.billingAddressStreet2.trim() !== "")
          updateOrderAddressData.address.street.push(
            this.billingAddressStreet2
          );

        this.spinnerIsLoading = true;
        this.$store
          .dispatch("updateOrderAddress", updateOrderAddressData)
          .then(() => {
            this.billingAddressEditMode = false;
            this.billingAddressFirstNameOriginalValue = this.billingAddressFirstName;
            this.billingAddressFamilyNameOriginalValue = this.billingAddressFamilyName;
            this.billingAddressCompanyNameOriginalValue = this.billingAddressCompanyName;
            this.billingAddressCountryIdOriginalValue = this.billingAddressCountryId;
            this.billingAddressRegionIdOriginalValue = this.billingAddressRegionId;
            this.billingAddressCityIdOriginalValue = this.billingAddressCityId;
            this.billingAddressCityNameOriginalValue = this.billingAddressCityName;
            this.billingAddressLandmarkOriginalValue = this.billingAddressLandmark;
            this.billingAddressStreet1OriginalValue = this.billingAddressStreet1;
            this.billingAddressStreet2OriginalValue = this.billingAddressStreet2;
            this.billingAddressTelephoneOriginalValue = this.billingAddressTelephone;
            this.billingAddressRegionOptions = [];
            this.billingAddressCityOptions = [];

            this.load();

            this.showBillingAddressSuccessMessage = true;
            sleep(3000).then(() => {
              this.showBillingAddressSuccessMessage = false;
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.billingAddressEditMode = true;
                  this.editAndUpdateBillingAddress();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingBillingAddress();
                }
              });
            this.spinnerIsLoading = false;
          })
          .finally(() => {});
      } else {
        this.spinnerIsLoading = true;
        this.$store
          .dispatch("retrieveCountryList")
          .then(response => {
            this.billingAddressCountryOptions = response;

            this.$store
              .dispatch("retrieveRegionList", this.billingAddressCountryId)
              .then(responseRegionList => {
                if (this.billingAddressRegionId != 0)
                  this.$store
                    .dispatch("retrieveCityList", this.billingAddressRegionId)
                    .then(responseCityList => {
                      this.billingAddressRegionOptions = responseRegionList;
                      this.billingAddressCityOptions = responseCityList;
                      this.billingAddressHeader = "Billing Address (Edit Mode)";
                      this.billingAddressCardClass = "card-edit";
                      this.billingAddressEditMode = true;
                    })
                    .catch(error => {
                      let titleTxt = "Error";
                      let msgTxt = error.message;

                      if (
                        error.message &&
                        error.message === "Request failed with status code 403"
                      ) {
                        titleTxt = "Access Denied";
                        msgTxt = "Please call Exotica administration for help";
                      }

                      this.$swal
                        .fire({
                          type: "error",
                          title: titleTxt,
                          text: msgTxt,
                          showCancelButton: true,
                          allowOutsideClick: false,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Retry"
                        })
                        .then(result => {
                          if (result.value) {
                            this.editAndUpdateBillingAddress();
                          } else if (
                            result.dismiss === this.$swal.DismissReason.cancel
                          ) {
                            this.cancelEditingBillingAddress();
                          }
                        });
                    })
                    .finally(() => {
                      this.spinnerIsLoading = false;
                    });
                else {
                  this.billingAddressRegionOptions = responseRegionList;
                  this.billingAddressHeader = "Billing Address (Edit Mode)";
                  this.billingAddressCardClass = "card-edit";
                  this.billingAddressEditMode = true;
                  this.spinnerIsLoading = false;
                }
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }

                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.editAndUpdateBillingAddress();
                    } else if (
                      result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                      this.cancelEditingBillingAddress();
                    }
                  });
                this.spinnerIsLoading = false;
              });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.editAndUpdateBillingAddress();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingBillingAddress();
                }
              });
            this.spinnerIsLoading = false;
          });
      }
    },
    cancelEditingBillingAddress() {
      this.billingAddressHeader = "Billing Address";
      this.billingAddressCardClass = "card-type-2";
      this.billingAddressEditMode = false;
      this.billingAddressFirstName = this.billingAddressFirstNameOriginalValue;
      this.billingAddressFamilyName = this.billingAddressFamilyNameOriginalValue;
      this.billingAddressCompanyName = this.billingAddressCompanyNameOriginalValue;
      this.billingAddressCountryId = this.billingAddressCountryIdOriginalValue;
      this.billingAddressRegionId = this.billingAddressRegionIdOriginalValue;
      this.billingAddressRegionName = this.billingAddressRegionNameOriginalValue;
      this.billingAddressCityId = this.billingAddressCityIdOriginalValue;
      this.billingAddressCityName = this.billingAddressCityNameOriginalValue;
      this.billingAddressLandmark = this.billingAddressLandmarkOriginalValue;
      this.billingAddressStreet1 = this.billingAddressStreet1OriginalValue;
      this.billingAddressStreet2 = this.billingAddressStreet2OriginalValue;
      this.billingAddressTelephone = this.billingAddressTelephoneOriginalValue;
      this.billingAddressRegionOptions = [];
      this.billingAddressCityOptions = [];
    },
    editAndUpdateShippingAddress() {
      this.cancelEditingBillingAddress();
      if (this.shippingAddressEditMode) {
        // check changes
        if (
          this.shippingAddressFirstNameOriginalValue ===
            this.shippingAddressFirstName &&
          this.shippingAddressFamilyNameOriginalValue ===
            this.shippingAddressFamilyName &&
          this.shippingAddressCompanyNameOriginalValue ===
            this.shippingAddressCompanyName &&
          this.shippingAddressCountryIdOriginalValue ===
            this.shippingAddressCountryId &&
          this.shippingAddressRegionIdOriginalValue ==
            this.shippingAddressRegionId &&
          this.shippingAddressRegionNameOriginalValue ===
            this.shippingAddressRegionName &&
          this.shippingAddressCityIdOriginalValue ==
            this.shippingAddressCityId &&
          this.shippingAddressCityNameOriginalValue ===
            this.shippingAddressCityName &&
          this.shippingAddressLandmarkOriginalValue ===
            this.shippingAddressLandmark &&
          this.shippingAddressStreet1OriginalValue ===
            this.shippingAddressStreet1 &&
          this.shippingAddressStreet2OriginalValue ===
            this.shippingAddressStreet2 &&
          this.shippingAddressTelephoneOriginalValue ===
            this.shippingAddressTelephone
        ) {
          this.cancelEditingShippingAddress();
          this.showShippingAddressNoChangesMessage = true;
          sleep(3000).then(() => {
            this.showShippingAddressNoChangesMessage = false;
          });
          return;
        }

        this.shippingAddressHeader = "Delivery Address";
        this.shippingAddressCardClass = "card-type-2";

        let updateOrderAddressData = {
          orderId: this.orderId,
          address: {
            firstName: this.shippingAddressFirstName,
            lastName: this.shippingAddressFamilyName,
            company: this.shippingAddressCompanyName,
            street: [],
            countryId: this.shippingAddressCountryId,
            regionId: this.shippingAddressRegionId,
            region: this.shippingAddressRegionName,
            cityId: this.shippingAddressCityId,
            city: this.shippingAddressCityName,
            township: this.shippingAddressLandmark,
            telephone: this.shippingAddressTelephone,
            addressType: 2
          }
        };

        if (this.shippingAddressRegionId != 0)
          delete updateOrderAddressData.address.region;

        if (this.shippingAddressCityId != 0)
          delete updateOrderAddressData.address.city;

        if (this.shippingAddressStreet1.trim() !== "")
          updateOrderAddressData.address.street.push(
            this.shippingAddressStreet1
          );

        if (this.shippingAddressStreet2.trim() !== "")
          updateOrderAddressData.address.street.push(
            this.shippingAddressStreet2
          );

        this.spinnerIsLoading = true;
        this.$store
          .dispatch("updateOrderAddress", updateOrderAddressData)
          .then(() => {
            this.shippingAddressEditMode = false;
            this.shippingAddressFirstNameOriginalValue = this.shippingAddressFirstName;
            this.shippingAddressFamilyNameOriginalValue = this.shippingAddressFamilyName;
            this.shippingAddressCompanyNameOriginalValue = this.shippingAddressCompanyName;
            this.shippingAddressCountryIdOriginalValue = this.shippingAddressCountryId;
            this.shippingAddressRegionIdOriginalValue = this.shippingAddressRegionId;
            this.shippingAddressCityIdOriginalValue = this.shippingAddressCityId;
            this.shippingAddressCityNameOriginalValue = this.shippingAddressCityName;
            this.shippingAddressLandmarkOriginalValue = this.shippingAddressLandmark;
            this.shippingAddressStreet1OriginalValue = this.shippingAddressStreet1;
            this.shippingAddressStreet2OriginalValue = this.shippingAddressStreet2;
            this.shippingAddressTelephoneOriginalValue = this.shippingAddressTelephone;
            this.shippingAddressRegionOptions = [];
            this.shippingAddressCityOptions = [];

            this.showShippingAddressSuccessMessage = true;

            this.load();
            sleep(3000).then(() => {
              this.showShippingAddressSuccessMessage = false;
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.shippingAddressEditMode = true;
                  this.editAndUpdateShippingAddress();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingShippingAddress();
                }
              });
          })
          .finally(() => {});
      } else {
        this.spinnerIsLoading = true;
        this.$store
          .dispatch("retrieveCountryList")
          .then(response => {
            this.shippingAddressCountryOptions = response;
            this.$store
              .dispatch("retrieveRegionList", this.shippingAddressCountryId)
              .then(responseRegionList => {
                if (this.shippingAddressRegionId != 0)
                  this.$store
                    .dispatch("retrieveCityList", this.shippingAddressRegionId)
                    .then(responseCityList => {
                      this.shippingAddressRegionOptions = responseRegionList;
                      this.shippingAddressCityOptions = responseCityList;
                      this.shippingAddressHeader =
                        "Delivery Address (Edit Mode)";
                      this.shippingAddressCardClass = "card-edit";
                      this.shippingAddressEditMode = true;
                    })
                    .catch(error => {
                      let titleTxt = "Error";
                      let msgTxt = error.message;

                      if (
                        error.message &&
                        error.message === "Request failed with status code 403"
                      ) {
                        titleTxt = "Access Denied";
                        msgTxt = "Please call Exotica administration for help";
                      }

                      this.$swal
                        .fire({
                          type: "error",
                          title: titleTxt,
                          text: msgTxt,
                          showCancelButton: true,
                          allowOutsideClick: false,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Retry"
                        })
                        .then(result => {
                          if (result.value) {
                            this.editAndUpdateShippingAddress();
                          } else if (
                            result.dismiss === this.$swal.DismissReason.cancel
                          ) {
                            this.cancelEditingShippingAddress();
                          }
                        });
                    })
                    .finally(() => {
                      this.spinnerIsLoading = false;
                    });
                else {
                  this.shippingAddressRegionOptions = responseRegionList;
                  this.shippingAddressHeader = "Shipping Address (Edit Mode)";
                  this.shippingAddressCardClass = "card-edit";
                  this.shippingAddressEditMode = true;
                  this.spinnerIsLoading = false;
                }
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }

                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.editAndUpdateShippingAddress();
                    } else if (
                      result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                      this.cancelEditingShippingAddress();
                    }
                  });
                this.spinnerIsLoading = false;
              });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.editAndUpdateShippingAddress();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingShippingAddress();
                }
              });
            this.spinnerIsLoading = false;
          });
      }
    },
    cancelEditingDeliveryDate: function() {
      this.orderDeliveryDateEditMode = false;
      this.orderDeliveryDate = this.order.deliveryDate;
      this.orderDeliverySlot = this.order.deliverySlot;
    },
    cancelEditingShippingAddress() {
      this.shippingAddressHeader = "Shipping Address";
      this.shippingAddressCardClass = "card-type-2";
      this.shippingAddressEditMode = false;
      this.shippingAddressFirstName = this.shippingAddressFirstNameOriginalValue;
      this.shippingAddressFamilyName = this.shippingAddressFamilyNameOriginalValue;
      this.shippingAddressCompanyName = this.shippingAddressCompanyNameOriginalValue;
      this.shippingAddressCountryId = this.shippingAddressCountryIdOriginalValue;
      this.shippingAddressRegionId = this.shippingAddressRegionIdOriginalValue;
      this.shippingAddressRegionName = this.shippingAddressRegionNameOriginalValue;
      this.shippingAddressCityId = this.shippingAddressCityIdOriginalValue;
      this.shippingAddressCityName = this.shippingAddressCityNameOriginalValue;
      this.shippingAddressLandmark = this.shippingAddressLandmarkOriginalValue;
      this.shippingAddressStreet1 = this.shippingAddressStreet1OriginalValue;
      this.shippingAddressStreet2 = this.shippingAddressStreet2OriginalValue;
      this.shippingAddressTelephone = this.shippingAddressTelephoneOriginalValue;
      this.shippingAddressRegionOptions = [];
      this.shippingAddressCityOptions = [];
    },
    updateSpecialInstruction() {
      if (this.roleId != 3 || (this.roleId == 3 && this.orderId.startsWith('2'))) {
        let updatedSpecialInstruction = {
          entity: {
            extension_attributes: {
              specialInstruction: {
                sendAnonymously: this.isSendAnonymously,
                surpriseTheRecipient: this.isSurpriseTheRecipient,
                surpriseTheRecipientName: this.alternativeRecipientName,
                surpriseTheRecipientRelation: this.alternativeRecipientRelation,
                surpriseTheRecipientPhone: this.alternativeRecipientPhone,
                specialInstructionId: this.deliverySpecialInstructionCode,
                // internalSpecialInstruction: this.internalInstructionMessage,
                message: this.specialInstructionMessage
              }
            }
          }
        };
        let updateSpecialInstructionData = {
          orderId: this.orderId,
          specialInstruction: updatedSpecialInstruction
        };
        this.spinnerIsLoading = true;
        this.$store
          .dispatch("updateSpecialInstruction", updateSpecialInstructionData)
          .then(() => {
            this.load();
            this.specialInstructionOriginalValue = this.specialInstruction;
            this.showSpecialInstructionRevertBtn = false;
            this.showSpecialInstructionSuccessMessage = true;
            sleep(3000).then(() => {
              this.showSpecialInstructionSuccessMessage = false;
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.updateSpecialInstruction();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      }
    },
    revertSpecialInstruction() {
      this.deliverySpecialInstructionCode = this.deliverySpecialInstructionCodeOriginalValue;
      this.specialInstructionMessage = this.specialInstructionMessageOriginalValue;
      // this.internalInstructionMessage = this.internalInstructionMessageOriginalValue;
      this.isSendAnonymously = this.isSendAnonymouslyOriginalValue;
      this.isSurpriseTheRecipient = this.isSurpriseTheRecipientOriginalValue;
      this.alternativeRecipientName = this.alternativeRecipientNameOriginalValue;
      this.alternativeRecipientRelation = this.alternativeRecipientRelationOriginalValue;
      this.alternativeRecipientPhone = this.alternativeRecipientPhoneOriginalValue;

      this.showSpecialInstructionRevertBtn = false;
    },
    checkSpecialInstructionChanges() {
      this.showSpecialInstructionRevertBtn =
        this.deliverySpecialInstructionCode !==
          this.deliverySpecialInstructionCodeOriginalValue ||
        this.specialInstructionMessage !==
          this.specialInstructionMessageOriginalValue ||
        // this.internalInstructionMessage !==
        //   this.internalInstructionMessageOriginalValue ||
        this.isSendAnonymously !== this.isSendAnonymouslyOriginalValue ||
        this.isSurpriseTheRecipient !==
          this.isSurpriseTheRecipientOriginalValue ||
        this.alternativeRecipientName !==
          this.alternativeRecipientNameOriginalValue ||
        this.alternativeRecipientRelation !==
          this.alternativeRecipientRelationOriginalValue ||
        this.alternativeRecipientPhone !==
          this.alternativeRecipientPhoneOriginalValue;
    },
    checkOrderStatusChanges() {
      // With "with driver" status, the comment is not mandatory
      this.showUpdateStatusBtn =
        (this.orderStatusId != 11 && this.orderStatusComment !== "") || (this.orderStatusId == 11 && this.driverId && this.vehicleType);
    },
    checkOrderRestrictedStatusChanges() {
      // With "with driver" status, the comment is not mandatory
      this.showUpdateRestrictedStatusBtn =
        (this.orderRestrictedStatusId != 11 && this.orderRestrictedStatusComment !== "" &&
          this.orderRestrictedStatusId != 0) ||
        (this.orderRestrictedStatusId == 11 && this.driverId && this.vehicleType);
    },
    viewGiftMessage(message, productId, orderInternalId, editMode) {

      if (editMode)
        this.$swal
          .fire({
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: (message && Object.keys(message).length !== 0? "Update" : "Add Message"),
            cancelButtonText: "Close",
            html:
              "<div style='text-align: justify; font-size: 0.9em'>" +
              "<span style='display: inline-block; min-width: 50px;'>From</span><input type='text' id='messageFrom' placeholder='sender' style='width: calc(100% - 70px); border: none; outline: none' onfocus='this.style.outline=\"none\"' value='" +
              ((message && message.sender) ? message.sender : "") +
              "' />" +
              "<br /><span style='display: inline-block; min-width: 50px'>To</span>" +
              "<input type='text' id='messageTo' placeholder='recipient' style='width: calc(100% - 70px); border: none; outline: none' onfocus='this.style.outline=\"none\"' value='" +
              ((message && message.recipient) ? message.recipient : "") +
              "' /><br /><br /><textarea rows='5' cols='50' id='messageMsg' placeholder='Message' style='border-color:#dedede; outline: none; resize: none' onfocus='this.style.outline=\"none\"'>" +
              ((message && message.message) ? message.message : "") +
              "</textarea></div>"
          })
          .then(result => {
            if (result.value) {
              if (message)
                  this.updateGiftMessage(
                    message.giftMessageId,
                    productId,
                    orderInternalId,
                    document.getElementById("messageFrom").value,
                    document.getElementById("messageTo").value,
                    document.getElementById("messageMsg").value
                  );
                else 
                  this.updateGiftMessage(
                    0,
                    productId,
                    this.orderId,
                    document.getElementById("messageFrom").value,
                    document.getElementById("messageTo").value,
                    document.getElementById("messageMsg").value
                  ); 
                }
          });
      else
        this.$swal.fire({
          html:
            "<div style='text-align: justify; font-size: 0.9em'>" +
            (message.sender
              ? "From <strong>" + message.sender + "</strong><br />"
              : "") +
            (message.recipient
              ? "To <strong>" + message.recipient + "</strong><br />"
              : "") +
            "<br /><span style='white-space: pre-line'>" +
            message.message +
            "</span></div>"
        });
    },
    updateOrderStatus() {
      let updatedStatus = {};
      if (this.orderStatusId == 11 && this.driverId != 0)
        updatedStatus = {
          entity: {
            status: {
              statusId: this.orderStatusId,
              comment: this.orderStatusComment,
              driverId: this.driverId,
              vehicleTypeId: this.vehicleType.id,
              isCustomerNotified: this.isCustomerNotified,
              isVisibleOnFront: this.isVisibleOnFront
            }
          }
        };
      else
        updatedStatus = {
          entity: {
            status: {
              statusId: this.orderStatusId,
              comment: this.orderStatusComment,
              isCustomerNotified: this.isCustomerNotified,
              isVisibleOnFront: this.isVisibleOnFront
            }
          }
        };
      let orderStatus = {
        orderId: this.orderId,
        status: updatedStatus
      };

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("updateOrderStatus", orderStatus)
        .then(response => {
          if (response.messageType === "Success") {
            this.orderStatusErrorMessage = "";
            this.load();
            this.orderStatusComment = "";
            this.isCustomerNotified = false;
            this.isVisibleOnFront = false;
            this.showUpdateStatusBtn = false;
            this.showOrderStatusSuccessMessage = true;
            sleep(3000).then(() => {
              this.showOrderStatusSuccessMessage = false;
            });
          } else {
            this.orderStatusErrorMessage = response.message;
            this.showOrderStatusErrorMessage = true;
            sleep(3000).then(() => {
              this.showOrderStatusErrorMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.updateOrderStatus();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateOrderRestrictedStatus() {
      let updatedStatus = {};
      if (this.orderRestrictedStatusId == 11 && this.driverId != 0)
        updatedStatus = {
          entity: {
            status: {
              statusId: this.orderRestrictedStatusId,
              comment: this.orderRestrictedStatusComment,
              driverId: this.driverId,
              vehicleTypeId: this.vehicleType.id,
              isCustomerNotified: false,
              isVisibleOnFront: false
            }
          }
        };
      else
        updatedStatus = {
          entity: {
            status: {
              statusId: this.orderRestrictedStatusId,
              comment: this.orderRestrictedStatusComment,
              isCustomerNotified: false,
              isVisibleOnFront: false
            }
          }
        };

      let orderStatus = {
        orderId: this.orderId,
        status: updatedStatus
      };

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("updateOrderStatus", orderStatus)
        .then(response => {
          if (response.messageType === "Success") {
            this.showOrderRestrictedStatusErrorMessage = "";
            this.load();
            this.orderRestrictedStatusId = 0;
            this.orderRestrictedStatusComment = "";
            this.showUpdateRestrictedStatusBtn = false;
            this.showOrderRestrictedStatusSuccessMessage = true;
            sleep(3000).then(() => {
              this.showOrderRestrictedStatusSuccessMessage = false;
            });
          } else {
            this.orderRestrictedStatusErrorMessage = response.message;
            this.showOrderRestrictedStatusErrorMessage = true;
            sleep(3000).then(() => {
              this.showOrderRestrictedStatusErrorMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.updateOrderRestrictedStatus();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateGiftMessage(id, productId, orderInternalId, from, to, msg) {
      let giftMessage;
      if (id === 0)
       giftMessage = {
          entity: {
            giftMessage: {
              productId: productId,
              orderId: orderInternalId,
              sender: from,
              recipient: to,
              message: msg
            }
          } 
        };
      else
        giftMessage = {
          entity: {
            giftMessage: {
              giftMessageId: id,
              productId: productId,
              orderId: orderInternalId,
              sender: from,
              recipient: to,
              message: msg
            }
          }
      };

      let data = {
        orderId: this.orderId,
        giftMessage: giftMessage
      };

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("updateGiftMessage", data)
        .then(response => {
          if (response.messageType === "Success") {
            this.load();
            this.showUpdateGiftMessageSuccessMessage = true;
            sleep(3000).then(() => {
              this.showUpdateGiftMessageSuccessMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.updateGiftMessage(id, productId, orderInternalId, from, to, msg);
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    chechAssignToBranchValue: function() {
      this.enableAssignToBranchBtn =
        this.branchId != this.branchIdOriginalValue;
    },
    assignToBranch: function() {
      let assignOrderToBranchData = {};
      assignOrderToBranchData.orderData = { orderIds: [this.orderId] };
      assignOrderToBranchData.branchId = this.branchId;

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("assignOrderToBranch", assignOrderToBranchData)
        .then(() => {
          this.enableAssignToBranchBtn = false;
          this.showAssignToBranchSuccessMessage = true;
          this.load();
          sleep(3000).then(() => {
            this.showAssignToBranchSuccessMessage = false;
          });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showConfirmButton: false,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.assignToBranch();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    cancelUpdateOrderStatus() {
      this.orderStatusId = this.orderStatusIdOriginalValue;
      this.orderStatusComment = "";
      this.isCustomerNotified = false;
      this.isVisibleOnFront = false;
      this.showUpdateStatusBtn = false;
    },
    cancelUpdateOrderRestrictedStatus() {
      this.orderRestrictedStatusId = 0;
      this.orderRestrictedStatusComment = "";
      this.showUpdateRestrictedStatusBtn = false;
    },
    billingAddressCountryChanged() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveRegionList", this.billingAddressCountryId)
        .then(responseRegionList => {
          this.billingAddressCityOptions = [];
          this.billingAddressCityId = 0;
          this.billingAddressCityName = "";
          this.billingAddressRegionId = 0;
          this.billingAddressRegionName = "";
          this.billingAddressRegionOptions = responseRegionList;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.billingAddressCountryChanged();
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.cancelEditingBillingAddress();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    billingAddressRegionChanged() {
      if (this.billingAddressRegionId != 0)
        this.$store
          .dispatch("retrieveCityList", this.billingAddressRegionId)
          .then(responseCityList => {
            this.billingAddressCityId = 0;
            this.billingAddressCityName = "";
            this.billingAddressCityOptions = responseCityList;
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.billingAddressRegionChanged();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingBillingAddress();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
    },
    shippingAddressCountryChanged() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveRegionList", this.shippingAddressCountryId)
        .then(responseRegionList => {
          this.shippingAddressCityOptions = [];
          this.shippingAddressCityId = 0;
          this.shippingAddressCityName = "";
          this.shippingAddressRegionId = 0;
          this.shippingAddressRegionName = "";
          this.shippingAddressRegionOptions = responseRegionList;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.shippingAddressCountryChanged();
              } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                this.cancelEditingShippingAddress();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    shippingAddressRegionChanged() {
      if (this.shippingAddressRegionId != 0)
        this.$store
          .dispatch("retrieveCityList", this.shippingAddressRegionId)
          .then(responseCityList => {
            this.shippingAddressCityId = 0;
            this.shippingAddressCityName = "";
            this.shippingAddressCityOptions = responseCityList;
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.shippingAddressRegionChanged();
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  this.cancelEditingShippingAddress();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
    }
  },
  created() {
    this.load();
  }
};
</script>