<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Order Management</li>
      <li class="breadcrumb-item active">Orders</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Order Management</h1>
    <!-- end page-header -->
    <panel title="Orders">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div id="accordion" class="card-accordion">
        <!-- begin b-card -->
        <b-card no-body>
          <b-card-header
            header-tag="header"
            class="card-header bg-black text-white pointer-cursor"
            v-b-toggle.advancedSearch
          >
            <i class="fas fa-plus-circle m-r-10 when-closed"></i>
            <i class="fas fa-minus-circle m-r-10 when-opened"></i>
            Advanced Search
            <i class="fas fa-search m-r-10" style="float: right;"></i>
          </b-card-header>
          <b-collapse id="advancedSearch" accordion="advancedSearchAccordion">
            <b-card-body>
              <div class="form-group row col-xl-7 col-lg-10 col-sm-12">
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Delivery Date</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-5">
                      <date-picker
                        v-model="deliveryDateRangeFilter.startDate"
                        :config="deliveryDateRangeConfigs.start"
                        @dp-change="onDeliveryDateStartChange"
                        placeholder="Range Start Date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-5">
                      <date-picker
                        v-model="deliveryDateRangeFilter.endDate"
                        :config="deliveryDateRangeConfigs.end"
                        @dp-change="onOrderDateEndChange"
                        placeholder="Range End Date"
                      ></date-picker>
                    </div>
                    <a
                      href="#"
                      class="btn btn-default col-sm-2 active"
                      style="float: right;"
                      @click="clearOrderDateRangeFilter"
                      >Clear</a
                    >
                  </div>
                </div>
              </div>
              <!-- ------------------------------------------------------------------------------------ -->
              <div
                v-if="showSearchByCountry"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Delivery Country</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <select
                        ref="deliveryCountrySelect"
                        class="form-control"
                        v-model="deliveryCountryCode"
                        @change="deliveryCountryChanged"
                      >
                        <option
                          value
                          hidden
                          selected
                          v-if="deliveryCountryCode === ''"
                          >Filter by Country</option
                        >
                        <option
                          value
                          v-if="deliveryCountryCode !== ''"
                          style="color: #806d00; font-weight: bold;"
                          >Clear Country Filter</option
                        >
                        <option
                          v-for="country in deliveryCountryOptions"
                          :value="country.countryCode"
                          :key="country.countryCode"
                          >{{ country.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------------------------------------------------------------------------------------ -->
              <div class="form-group row col-xl-7 col-lg-10 col-sm-12">
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Delivery Region</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <select
                        ref="deliveryRegionSelect"
                        class="form-control"
                        v-model="deliveryRegionId"
                        @change="deliveryRegionChanged"
                      >
                        <option
                          value="0"
                          hidden
                          selected
                          v-if="deliveryRegionId == 0"
                          >Filter by Region</option
                        >
                        <option
                          value="0"
                          v-if="deliveryRegionId != 0"
                          style="color: #806d00; font-weight: bold;"
                          >Clear Region Filter</option
                        >
                        <option
                          v-for="region in deliveryRegionOptions"
                          :value="region.id"
                          :key="region.id"
                          >{{ region.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="deliveryRegionId != 0 && deliveryCityOptions.length > 0"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Delivery City</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <select
                        ref="deliveryCitySelect"
                        class="form-control"
                        v-model="deliveryCityId"
                        @change="deliveryCityChanged"
                      >
                        <option
                          value="0"
                          hidden
                          selected
                          v-if="deliveryCityId == 0"
                          >Filter by City</option
                        >
                        <option
                          value="0"
                          v-if="deliveryCityId != 0"
                          style="color: #806d00; font-weight: bold;"
                          >Clear Region Filter</option
                        >
                        <option
                          v-for="city in deliveryCityOptions"
                          :value="city.id"
                          :key="city.id"
                          >{{ city.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showFilterByBranch"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Branch</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <select
                        class="form-control"
                        ref="branchSelect"
                        v-model="branchId"
                        @change="branchChanged"
                      >
                        <option value="0" hidden selected v-if="branchId == 0"
                          >Filter by Branch</option
                        >
                        <option
                          value="0"
                          v-if="branchId != 0"
                          style="color: #806d00; font-weight: bold;"
                          >Clear Branch Filter</option
                        >
                        <option
                          v-for="branch in branchOptions"
                          :value="branch.id"
                          :key="branch.id"
                          >{{ branch.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showFilterByOrderStatus"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Order Status</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <select
                        class="form-control"
                        v-model="orderStatusName"
                        @change="orderStatusChanged"
                      >
                        <option
                          value
                          hidden
                          selected
                          v-if="orderStatusName === ''"
                          >Filter by Status</option
                        >
                        <option
                          value
                          v-if="orderStatusName !== ''"
                          style="color: #806d00; font-weight: bold;"
                          >Clear Status Filter</option
                        >
                        <option
                          v-for="status in orderStatusOptions"
                          :value="status.name"
                          :key="status.name"
                          >{{ status.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row col-xl-7 col-lg-10 col-sm-12">
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Product Name</label
                  >
                </div>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-sm-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="# Enter the product name"
                        style="margin-bottom: 5px;"
                        v-model="productName"
                        @keyup.enter="checkProductName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showFilterProductsByOrder"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Products By Order</label
                  >
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="any"
                    name="product-count"
                    v-model="productQty"
                    @change="productQtyChanged"
                    value="any"
                  />
                  <label for="any">Any Quantity</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="one"
                    name="product-count"
                    v-model="productQty"
                    @change="productQtyChanged"
                    value="one"
                  />
                  <label for="one">One Product</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="multi"
                    name="product-count"
                    v-model="productQty"
                    @change="productQtyChanged"
                    value="multi"
                  />
                  <label for="multi">Multi Products</label>
                </div>
              </div>
              <div
                v-if="showPrintActions"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Print Status</label
                  >
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="all"
                    name="print-status"
                    v-model="printStatus"
                    @change="printStatusChanged"
                    value="all"
                  />
                  <label for="all">All</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="any-unprinted"
                    name="print-status"
                    v-model="printStatus"
                    @change="printStatusChanged"
                    value="any-unprinted"
                  />
                  <label for="any-unprinted">Any Unprinted</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="all-unprinted"
                    name="print-status"
                    v-model="printStatus"
                    @change="printStatusChanged"
                    value="all-unprinted"
                  />
                  <label for="all-unprinted">All Unprinted</label>
                </div>
              </div>
              <div
                v-if="showFilterByOrderType"
                class="form-group row col-xl-7 col-lg-10 col-sm-12"
              >
                <div class="col-sm-3">
                  <label class="col-form-label f-s-14 text-grey-darker"
                    >Order Type</label
                  >
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="all-orders"
                    name="order-type"
                    v-model="orderType"
                    @change="orderTypeChanged"
                    value="all"
                  />
                  <label for="all-orders">All Orders</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="general-order"
                    name="order-type"
                    v-model="orderType"
                    @change="orderTypeChanged"
                    value="general"
                  />
                  <label for="general-order">General</label>
                </div>
                <div class="radio radio-css radio-inline">
                  <input
                    type="radio"
                    id="e-commerce-order"
                    name="order-type"
                    v-model="orderType"
                    @change="orderTypeChanged"
                    value="e-commerce"
                  />
                  <label for="e-commerce-order">E-commerce</label>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div
        class="alert alert-yellow fade show"
        v-if="
          searchKeywords !== '' ||
          deliveryDateRangeFilter.startDate !== null ||
          deliveryDateRangeFilter.endDate !== null ||
          orderStatusName !== '' ||
          deliveryRegionId != 0 ||
          deliveryCityId != 0 ||
          branchId != 0 ||
          deliveryCountryCode !== '' ||
          productQty !== 'any' ||
          productNameKeyword.trim() !== '' ||
          printStatus !== 'all' ||
          orderType !== 'all'
        "
      >
        Search for
        <!-- Keywords -->
        <span
          style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
          v-if="searchKeywords !== ''"
          >{{ searchKeywords }}</span
        >
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span
          style="padding-left: 15px;"
          v-if="
            deliveryDateRangeFilter.startDate !== null &&
            deliveryDateRangeFilter.endDate !== null
          "
        >
          Orders with delivery date between
          <span style="font-weight: bold; font-size: 1.3em; padding: 0 15px;">{{
            deliveryDateRangeFilter.startDate
          }}</span>
          and
          <span style="font-weight: bold; font-size: 1.3em; padding: 0 15px;">{{
            deliveryDateRangeFilter.endDate
          }}</span>
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span
          style="padding-left: 15px;"
          v-if="
            deliveryDateRangeFilter.startDate !== null &&
            deliveryDateRangeFilter.endDate === null
          "
        >
          Orders with delivery date since
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ deliveryDateRangeFilter.startDate }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span
          style="padding-left: 15px;"
          v-if="
            deliveryDateRangeFilter.startDate === null &&
            deliveryDateRangeFilter.endDate !== null
          "
        >
          Orders with delivery date till
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ deliveryDateRangeFilter.endDate }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="orderStatusName !== ''">
          status
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ orderStatusName }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="deliveryCountryCode != 0">
          country
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ deliveryCountryName }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="deliveryRegionId != 0">
          region
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ deliveryRegionName }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="deliveryCityName !== ''">
          city
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ deliveryCityName }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="branchId != 0">
          branch
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ branchName }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="productQty !== 'any'">
          <span
            v-if="productQty === 'one'"
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >One product by order</span
          >
          <span
            v-else
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >Multi products by order</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span
          style="padding-left: 15px;"
          v-if="productNameKeyword.trim() !== ''"
        >
          product name
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{ productNameKeyword }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="printStatus !== 'all'">
          <span
            v-if="printStatus === 'all-unprinted'"
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >All Unprinted</span
          >
          <span
            v-else
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >Any Unprinted</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span style="padding-left: 15px;" v-if="orderType !== 'all'">
          <span
            style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
            >{{
              orderType === "general" ? "General Orders" : "E-commerce Orders"
            }}</span
          >
        </span>
        <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
        <span
          v-if="!spinnerIsLoading"
          style="
            font-weight: bold;
            font-size: 1.3em;
            padding-left: 15px;
            color: #2a72b5;
          "
          >{{
            this.totalRecords === 0
              ? "No orders found"
              : "Total: " +
                this.totalRecords +
                " order" +
                (this.totalRecords > 1 ? "s" : "")
          }}</span
        >
      </div>
      <div id="orders-table-div">
        <div class="vgt-global-search vgt-clearfix">
          <div class="vgt-global-search__input vgt-pull-left">
            <span class="input__icon">
              <div class="magnifying-glass"></div>
            </span>
            <input
              type="text"
              ref="searchText"
              placeholder="Refine your search result by entering multiple keywords"
              class="vgt-input vgt-pull-left"
              :disabled="temporaryFreezeDataUpdate"
              v-model="searchTermValue"
              v-on:keyup.enter="searchTriggered"
            />
          </div>
          <div class="vgt-global-search__actions vgt-pull-right"></div>
        </div>
        <vue-good-table
          ref="ordersTable"
          :key="refreshKey"
          mode="remote"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          :rows="rows"
          :line-numbers="lineNumbers"
          :totalRows="totalRecords"
          :pagination-options="paginationOptions"
          :selectOptions="selectOptions"
          :search-options="searchOptions"
          :sort-options="sortOptions"
          :row-style-class="rowStyleClassFct"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectedRowsChange"
          @on-row-click="onRowClick"
        >
          <div slot="emptystate">No orders found</div>
          <div
            slot="selected-row-actions"
            class="selectionAction"
            v-if="showBulkAction"
          >
            <template v-if="showBulkPrint">
              <a href="#" @click="printOrderBulk">Print Production Orders</a>
              <span>|</span>
              <a href="#" @click="printDeliveryNoteBulk"
                >Print Delivery Notes</a
              >
              <span>|</span>
              <a href="#" @click="printGiftMessageBulk">Print Gift Messages</a>
              <span v-if="showPrintStickerView">|</span>
              <a v-if="showPrintStickerView" href="#" @click="printStickerBulk"
                >Print Stickers</a
              >
            </template>
            <span v-if="showAssignToBranch">|</span>
            <a v-if="showAssignToBranch" href="#" @click="assignToBranchBulk"
              >Assign to Branch</a
            >
            <span v-if="showAssignToDriver">|</span>
            <a v-if="showAssignToDriver" href="#" @click="assignToDriverBulk"
              >Assign to Driver</a
            >
            <span v-if="showUpdateDeliveryStatus">|</span>
            <a
              v-if="showUpdateDeliveryStatus"
              href="#"
              @click="updateDeliveryStatusBulk"
              >Update Delivery Status</a
            >
          </div>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="(props.column.field === 'deliveryDate' && !props.row.deliveryDate)"
              >-</span
            >
            <span
              v-else-if="props.column.field === 'creationTs' && hideOrderDate"
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="props.column.field === 'storeName' && hideStoreColumn"
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="
                props.column.field === 'branchName' && hideBranchColumn
              "
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="
                props.column.field === 'paymentMethod' &&
                hidePaymentMethodColumn
              "
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="
                props.column.field === 'printJobs' && hidePrintJobsColumn
              "
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="
                props.column.field === 'currencyCode' && hideCurrencyCodeColumn
              "
              >{{ (props.column.hidden = "true") }}</span
            >
            <span
              v-else-if="
                props.column.field === 'grandTotal' && hideGrandTotalColumn
              "
              >{{ (props.column.hidden = "true") }}</span
            >
            <!--span
              v-else-if="props.column.field === 'action' && hideActionColumn"
            >{{ props.column.hidden = "true" }}</span-->
            <span v-else-if="props.column.field === 'printJobs'">
              <span
                v-if="props.row.creationTs < '2019-08-01'"
                style="display: inline-block; width: 100%; text-align: center;"
                >-</span
              >
              <span v-else-if="!props.row.denyPrint">
                <span
                  v-for="printJob in props.row.printJobs"
                  v-bind:key="printJob.printJob"
                >
                  <span
                    v-if="
                      (printJob.printJob === 'Gift Message' ||
                        printJob.printJob === 'Sticker') &&
                      !props.row.giftMessageAvailable
                    "
                  ></span>
                  <span
                    v-else-if="printJob.printed"
                    class="label bg-green m-b-2"
                    style="display: block;"
                    >{{ printJob.printJob }}</span
                  >
                  <span
                    v-else
                    class="label bg-red m-b-2"
                    style="display: block;"
                    >{{ printJob.printJob }}</span
                  >
                </span>
              </span>
              <span v-else style="display: block; color: #ff5b57;"
                >Printing Not Allowed</span
              >
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-dropdown
                v-if="props.row.creationTs >= '2019-08-01'"
                text="Action "
                class="m-r-5 m-b-5"
                variant="default"
              >
                <b-dropdown-item href="#" @click="viewOrder"
                  >View Order</b-dropdown-item
                >
                <span v-if="!props.row.denyPrint && showPrintActions">
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="#" @click="printOrder"
                    >Print Production Order</b-dropdown-item
                  >
                  <b-dropdown-item href="#" @click="printDeliveryNote"
                    >Print Delivery Note</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="props.row.giftMessageAvailable"
                    href="#"
                    @click="printGiftMessage"
                    >Print Gift Message</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="
                      showPrintStickerView && props.row.giftMessageAvailable
                    "
                    href="#"
                    @click="printSticker"
                    >Print Sticker</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="
                      props.row.printJobs.some(
                        (obj) => obj.printJob === 'Receipt'
                      )
                    "
                    href="#"
                    @click="printReceipt"
                    >Print Receipt</b-dropdown-item
                  >
                </span>
                <span
                  v-if="!props.row.denyAssignToBranch && showAssignToBranch"
                >
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="#" @click="assignToBranch"
                    >Assign To Branch</b-dropdown-item
                  >
                </span>
              </b-dropdown>
              <b-dropdown
                v-else
                text="Action "
                class="m-r-5 m-b-5"
                variant="default"
              >
                <b-dropdown-item href="#" @click="viewOrder"
                  >View Order</b-dropdown-item
                >
              </b-dropdown>
            </span>
          </template>
        </vue-good-table>
      </div>
    </panel>
    <div id="orderPrintJobDiv" class="print">
      <order-print-job ref="orderPrintJobComponent"></order-print-job>
    </div>
  </div>
</template>
<script>
import Options from "@/conf/Options";
import OrderPrintJob from "@/components/order-print-job";
import printJS from "print-js";

export default {
  components: {
    OrderPrintJob
  },
  data() {
    return {
      refreshKey: 1,
      timer: null,
      destroyed: false,
      mountInProgress: true,
      loadingSearch: false,
      perPage: 15,
      page: 1,
      sortParams: null,
      productQty: "any",
      printStatus: "all",
      hideOrderDate: Options.user.roleId == 9 || Options.user.roleId == 10,
      hideStoreColumn: Options.user.roleId != 1,
      hideBranchColumn:
        Options.user.roleId == 3 ||
        Options.user.roleId == 7 ||
        Options.user.roleId == 9,
      hidePaymentMethodColumn: Options.user.roleId == 9,
      hidePrintJobsColumn:
        Options.user.roleId == 7, // || Options.user.roleId == 10
      hideCurrencyCodeColumn:
        Options.user.roleId == 7 || Options.user.roleId == 10,
      hideGrandTotalColumn:
        Options.user.roleId == 7 || Options.user.roleId == 10,
      //hideActionColumn: Options.user.roleId == 9,
      showAssignToBranch:
        Options.user.roleId == 1 ||
        Options.user.roleId == 2 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 12,
      showUpdateDeliveryStatus:
        Options.user.roleId == 1 ||
        Options.user.roleId == 3 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 10 ||
        Options.user.roleId == 12,
      showAssignToDriver:
        Options.user.roleId == 1 ||
        Options.user.roleId == 3 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 7 ||
        Options.user.roleId == 10 ||
        Options.user.roleId == 12,
      showPrintStickerView: Options.user.printStickerView,
      showPrintActions:
        Options.user.roleId != 5 &&
        Options.user.roleId != 6 &&
        Options.user.roleId != 7 &&
        Options.user.roleId != 9 &&
        Options.user.roleId != 11,
      showSearchByCountry: Options.user.roleId == 1,
      showFilterByBranch:
        Options.user.roleId == 1 ||
        Options.user.roleId == 2 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 10 ||
        Options.user.roleId == 11 ||
        Options.user.roleId == 12,
      showFilterByOrderType:
        Options.user.roleId == 1 ||
        Options.user.roleId == 3 ||
        Options.user.roleId == 4 ||
        Options.user.roleId == 9 ||
        Options.user.roleId == 10 ||
        Options.user.roleId == 12,
      showFilterByOrderStatus: true,
      showFilterProductsByOrder: Options.user.roleId != 10,
      // showBulkPrint: Options.user.roleId != 10,
      showBulkPrint: true,
      showBulkAction: Options.user.roleId != 9,
      temporaryFreezeDataUpdate: false,
      searchTermValue: "",
      searchKeywords: "",
      searchInput: "",
      printData: null,
      branchId: 0,
      branchName: "",
      branchOptions: [],
      deliveryCountryCode: "",
      deliveryStoreId: Options.user.storeId,
      deliveryCountryName: "",
      deliveryCountryOptions: [],
      deliveryRegionId: 0,
      deliveryRegionName: "",
      deliveryRegionOptions: [],
      deliveryCityId: 0,
      deliveryCityName: "",
      deliveryCityOptions: [],
      orderStatusOptions: [],
      orderStatusName: "",
      productName: "",
      productNameKeyword: "",
      orderType: Options.user.roleId == 1 ? "e-commerce" : "all",
      currentAction: null,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      lineNumbers: false,
      totalRecords: 0,
      deliveryDateRangeFilter: {
        startDate: null,
        endDate: null
      },
      deliveryDateRangeConfigs: {
        start: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        },
        end: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        }
      },
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Orders per page",
        perPage: 15,
        perPageDropdown: [10, 15, 30, 50, 100, 200],
        ofLabel: "of"
      },
      searchOptions: {
        enabled: false,
        trigger: "enter", // remove to enable on every keypressed
        placeholder: "Refine your search result by entering multiple keywords",
        externalQuery: this.searchKeywords
      },
      selectOptions: {
        enabled: Options.user.roleId != 9,
        selectOnCheckboxOnly: true,
        selectionInfoClass: "selectionAction",
        selectionText: "",
        clearSelectionText: "Clear Selection",
        disableSelectInfo: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          {
            field: "deliveryDate",
            type: "desc"
          },
          { field: "creationTs", type: "desc" }
        ]
      },
      columns: [
        {
          label: "Order ID",
          field: "orderId",
          type: "number",
          tdClass: "text-center"
        },
        {
          label: "Store",
          field: "storeName"
        },
        {
          label: "Product(s)",
          field: "productName",
          formatFn: this.formatProductName
        },
        {
          label: "Order Date",
          field: "creationTs",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd",
          tdClass: "text-center"
        },
        {
          label: "Sender",
          field: "clientName"
        },
        {
          label: "Recipient",
          field: "recipientName"
        },
        {
          label: "Delivery Date",
          field: "deliveryDate",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "yyyy-MM-dd",
          tdClass: "text-center"
        },
        {
          label: "Delivery Time",
          field: "deliverySlot",
          sortable: false
        },
        {
          label: "Delivery Region",
          field: "region"
        },
        {
          label: "Delivery City",
          field: "city"
        },
        {
          label: "Total",
          field: "grandTotal",
          type: "number",
          formatFn: this.formatNumber
        },
        {
          label: "Cur.",
          field: "currencyCode",
          tdClass: "text-center"
        },
        {
          label: "Payment Method",
          field: "paymentMethod",
          tdClass: "text-left"
        },
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Branch",
          field: "branchName"
        },
        {
          label: "Driver",
          field: "driverName"
        },
        {
          label: "Print Flag",
          field: "printJobs",
          sortable: false
        },
        {
          label: "Action",
          field: "action",
          sortable: false
        }
      ],
      rows: [],
      vueGoodTableServerParams: {
        columnFilters: {},
        sort: [
          {
            field: "creationTs",
            type: "desc"
          }
        ],
        page: 1,
        perPage: 15
      },
      filter: {
        offset: 0,
        limit: 15,
        filter: [],
        sort: [{ field: "deliveryDate", direction: "desc" }]
      }
    };
  },
  created() {
    if (localStorage.getItem("searchCriteria")) {
      let searchCriteria = JSON.parse(
        Options.crypter.decrypt(localStorage.getItem("searchCriteria"))
      );
      this.searchKeywords = searchCriteria.searchTerm;
      this.searchTermValue = searchCriteria.searchTerm;

      this.deliveryDateRangeFilter = searchCriteria.deliveryDateRangeFilter;
      this.deliveryStoreId = searchCriteria.deliveryStoreId;
      this.deliveryCountryCode = searchCriteria.deliveryCountryCode;
      this.deliveryCountryName = searchCriteria.deliveryCountryName;
      this.deliveryRegionId = searchCriteria.deliveryRegionId;
      this.deliveryRegionName = searchCriteria.deliveryRegionName;
      this.deliveryCityId = searchCriteria.deliveryCityId;
      this.deliveryCityName = searchCriteria.deliveryCityName;
      this.branchId = searchCriteria.branchId;
      this.branchName = searchCriteria.branchName;
      this.orderStatusName = searchCriteria.orderStatusName;
      if (searchCriteria.hasOwnProperty("productQty")) {
        this.productQty = searchCriteria.productQty;
      }
      if (searchCriteria.hasOwnProperty("productName")) {
        this.productName = searchCriteria.productName;
        this.productNameKeyword = searchCriteria.productName.trim();
      }
      if (searchCriteria.hasOwnProperty("printStatus")) {
        this.printStatus = searchCriteria.printStatus;
      }
      if (searchCriteria.hasOwnProperty("orderType")) {
        this.orderType = searchCriteria.orderType;
      }
      if (searchCriteria.hasOwnProperty("perPage")) {
        this.perPage = searchCriteria.perPage;
      }
      if (searchCriteria.hasOwnProperty("page")) {
        this.page = searchCriteria.page;
        this.filter.offset = (searchCriteria.page - 1) * this.perPage;
        this.vueGoodTableServerParams;
      }
      if (searchCriteria.hasOwnProperty("sortParams")) {
        this.sortParams = searchCriteria.sortParams;
        this.sortOptions.initialSortBy = searchCriteria.sortParams;
      }
    }

    this.vueGoodTableServerParams.perPage = this.perPage;
    this.vueGoodTableServerParams.page = this.page;

    this.paginationOptions = {
      enabled: true,
      dropdownAllowAll: false,
      rowsPerPageLabel: "Orders per page",
      perPage: this.perPage,
      setCurrentPage: this.page,
      perPageDropdown: [10, 15, 30, 50, 100, 200],
      ofLabel: "of"
    };
  },
  mounted() {
    this.mountInProgress = false;
    this.doSearch(this.searchKeywords);

    // Load country store list
    if (this.showSearchByCountry)
      this.$store.dispatch("retrieveStoreList").then((response) => {
        this.deliveryCountryOptions = response.data;
      });

    // Load region list
    this.$store
      .dispatch(
        "retrieveRegionList",
        this.deliveryCountryCode == 0
          ? Options.user.countryCode
          : this.deliveryCountryCode
      )
      .then((response) => {
        this.deliveryRegionOptions = response;
      });

    // load city list
    if (this.deliveryRegionId != 0) this.loadCities();

    if (this.showFilterByBranch) {
      // Load branch list
      this.$store
        .dispatch(
          "retrieveBranchList",
          this.deliveryStoreId == 0
            ? Options.user.storeId
            : this.deliveryStoreId
        )
        .then((response) => {
          this.branchOptions = response.data;
        });
    }

    let orderData = {
      storeId:
        this.deliveryStoreId == 0 ? Options.user.storeId : this.deliveryStoreId
    };

    // Load status list
    this.$store
      .dispatch("retrieveOrderStatusList", orderData)
      .then((responseRetrieveOrderStatusList) => {
        this.orderStatusOptions = responseRetrieveOrderStatusList.data;
      });
  },
  beforeDestroy() {
    this.storeSearchCriteria();
    if (this.timer) clearTimeout(this.timer);
    this.destroyed = true;
  },
  methods: {
    formatNumber: function (value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    searchTriggered: function () {
      this.searchKeywords = this.$refs.searchText.value;
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * (-1);
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    formatProductName: function (value) {
      let productArray = value.split(",");
      let productResult = "";
      for (let i = 0; i < productArray.length; i++) {
        if (productResult !== "") productResult += ",\n";
        productResult += productArray[i];
      }

      return productResult;
    },
    checkProductName: function () {
      this.productNameKeyword = this.productName;
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    onDeliveryDateStartChange(e) {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
      this.$set(this.deliveryDateRangeConfigs.end, "minDate", e.date);
    },
    onOrderDateEndChange(e) {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
      this.$set(this.deliveryDateRangeConfigs.start, "maxDate", e.date);
    },
    clearOrderDateRangeFilter() {
      this.deliveryDateRangeFilter.startDate = null;
      this.deliveryDateRangeFilter.endDate = null;
    },
    orderStatusChanged() {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    orderTypeChanged() {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    deliveryCountryChanged() {
      this.deliveryRegionId = 0;
      this.deliveryRegionName = "";
      this.branchId = 0;
      this.deliveryCityName = "";
      this.orderStatusName = "";
      this.loadRegions();
      this.deliveryCountryName = this.$refs.deliveryCountrySelect.options[
        this.$refs.deliveryCountrySelect.selectedIndex
      ].text;
      let storeIds = this.deliveryCountryOptions.map(function getId(item) {
        return item.id;
      });
      if (this.$refs.deliveryCountrySelect.selectedIndex != 0)
        this.deliveryStoreId =
          storeIds[this.$refs.deliveryCountrySelect.selectedIndex - 1];
      else this.deliveryStoreId = Options.user.storeId;
      this.loadBranches();
      this.loadStatuses();
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    deliveryRegionChanged() {
      this.deliveryRegionName = this.$refs.deliveryRegionSelect.options[
        this.$refs.deliveryRegionSelect.selectedIndex
      ].text;
      this.deliveryCityId = 0;
      this.deliveryCityName = "";
      this.loadCities();
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    deliveryCityChanged() {
      this.deliveryCityName = this.$refs.deliveryCitySelect.options[
        this.$refs.deliveryCitySelect.selectedIndex
      ].text;
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    branchChanged() {
      this.branchName = this.$refs.branchSelect.options[
        this.$refs.branchSelect.selectedIndex
      ].text;
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    productQtyChanged() {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    printStatusChanged() {
      this.page = 1;
      this.updateParams({ page: 1 }, true);
      this.paginationOptions.setCurrentPage = 1;
      this.refreshKey = this.refreshKey * -1;
      this.loadingSearch = true;
      this.doSearch(this.searchKeywords);
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.filter.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.filter.limit = this.vueGoodTableServerParams.perPage;
      this.filter.sort = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};

        if (this.vueGoodTableServerParams.sort[i].field === "clientName") {
          let sortByColumnFirstName = {};
          sortByColumnFirstName.field = "firstName";
          sortByColumnFirstName.direction = this.vueGoodTableServerParams.sort[
            i
          ].type;
          this.filter.sort.push(sortByColumnFirstName);

          let sortByColumnLastName = {};
          sortByColumnLastName.field = "lastName";
          sortByColumnLastName.direction = this.vueGoodTableServerParams.sort[
            i
          ].type;
          this.filter.sort.push(sortByColumnLastName);
        } else {
          sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
          sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
          this.filter.sort.push(sortByColumn);
        }
      }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage }, false);
      this.page = params.currentPage;
      if (!this.mountInProgress) this.loadItems(true);
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.perPage = params.currentPerPage;
      // reset page
      this.updateParams({ page: 1 }, false);
      this.page = 1;
      if (!this.mountInProgress) this.loadItems(true);
    },
    onSortChange(params) {
      if (!this.loadingSearch) {
        this.sortParams = params;
        this.updateParams(
          {
            sort: params
          },
          true
        );
        if (!this.mountInProgress) this.loadItems(true);
      }
    },
    onColumnFilter(params) {
      this.updateParams(params, false);
      if (!this.mountInProgress) this.loadItems(true);
    },
    loadBranches() {
      this.$store
        .dispatch("retrieveBranchList", this.deliveryStoreId)
        .then((response) => {
          this.branchOptions = response.data;
        });
    },
    loadStatuses() {
      let data = {
        storeId: this.deliveryStoreId
      };
      this.$store
        .dispatch("retrieveOrderStatusList", data)
        .then((responseRetrieveOrderStatusList) => {
          this.orderStatusOptions = responseRetrieveOrderStatusList.data;
        });
    },
    loadRegions() {
      let deliveryCountryCode =
        this.deliveryCountryCode === ""
          ? Options.user.countryCode
          : this.deliveryCountryCode;
      this.$store
        .dispatch("retrieveRegionList", deliveryCountryCode)
        .then((response) => {
          this.deliveryRegionOptions = response;
        });
    },
    loadCities() {
      this.$store
        .dispatch("retrieveCityList", this.deliveryRegionId)
        .then((response) => {
          this.deliveryCityOptions = response;
        });
    },
    storeSearchCriteria: function () {
      let searchCriteria = {
        searchTerm: this.searchKeywords,
        deliveryDateRangeFilter: this.deliveryDateRangeFilter,
        deliveryStoreId: this.deliveryStoreId,
        deliveryCountryCode: this.deliveryCountryCode,
        deliveryCountryName: this.deliveryCountryName,
        deliveryRegionId: this.deliveryRegionId,
        deliveryRegionName: this.deliveryRegionName,
        deliveryCityId: this.deliveryCityId,
        deliveryCityName: this.deliveryCityName,
        branchId: this.branchId,
        branchName: this.branchName,
        orderStatusName: this.orderStatusName,
        productQty: this.productQty,
        productName: this.productName,
        printStatus: this.printStatus,
        orderType: this.orderType,
        perPage: this.perPage,
        page: this.page,
        sortParams: this.sortParams
      };

      localStorage.setItem(
        "searchCriteria",
        Options.crypter.encrypt(JSON.stringify(searchCriteria))
      );
    },
    doSearch: function (searchTerm) {
      if (this.spinnerIsLoading) return; //Abort in case search was initiated
      this.storeSearchCriteria();

      this.filter.offset = (this.page - 1) * this.perPage;
      this.filter.filter = [];
      this.filter.limit = this.perPage;
      var keywords = searchTerm.trim().split(" ");

      for (var i = 0; i < keywords.length; i++) {
        let storeName = {
          field: "storeName",
          value: "",
          condition: "contains"
        };

        let firstName = {
          field: "firstName",
          value: "",
          condition: "contains"
        };

        let lastName = { field: "lastName", value: "", condition: "contains" };

        let recipientName = {
          field: "recipientName",
          value: "",
          condition: "contains"
        };

        let deliverySlot = {
          field: "deliverySlot",
          value: "",
          condition: "eq"
        };

        let deliveryRegion = {
          field: "region",
          value: "",
          condition: "contains"
        };
        let deliveryCity = {
          field: "city",
          value: "",
          condition: "contains"
        };
        let status = { field: "status", value: "", condition: "contains" };
        let creationTs = {
          field: "creationTs",
          value: "",
          condition: "contains"
        };

        let currencyCode = {
          field: "currencyCode",
          value: "",
          condition: "eq"
        };

        let products = {
          field: "products",
          value: "",
          condition: "contains"
        };

        let grandTotal = { field: "grandTotal", value: "", condition: "eq" };
        let orderId = { field: "orderId", value: "", condition: "eq" };
        let couponCode = {
          field: "couponCode",
          value: "",
          condition: "eq"
        };

        let searchGroup = [];

        let keyword = keywords[i].trim();

        if (keyword !== "") {
          storeName.value = keyword;
          searchGroup.push(storeName);

          firstName.value = keyword;
          searchGroup.push(firstName);

          lastName.value = keyword;
          searchGroup.push(lastName);

          recipientName.value = keyword;
          searchGroup.push(recipientName);

          deliverySlot.value = keyword;
          searchGroup.push(deliverySlot);

          deliveryRegion.value = keyword;
          searchGroup.push(deliveryRegion);

          deliveryCity.value = keyword;
          searchGroup.push(deliveryCity);

          status.value = keyword;
          searchGroup.push(status);

          creationTs.value = keyword;
          searchGroup.push(creationTs);

          currencyCode.value = keyword;
          searchGroup.push(currencyCode);

          products.value = keyword;
          searchGroup.push(products);

          couponCode.value = keyword;
          searchGroup.push(couponCode);

          if (!isNaN(keyword)) {
            grandTotal.value = keyword;
            searchGroup.push(grandTotal);

            orderId.value = keyword;
            searchGroup.push(orderId);
          }
          this.filter.filter.push(searchGroup);
        }
      }

      /*if (Options.user.roleId == 10) {
        let orderStatusFilter = [];
        let orderStatus = {
          field: "status",
          value: "Ready for pick-up",
          condition: "eq"
        };

        orderStatusFilter.push(orderStatus);
        this.filter.filter.push(orderStatusFilter);
      }*/
      // Delivery Date Range Filter
      let deliveryDateRangeStart = {
        field: "deliveryDate",
        value: "",
        condition: "gte"
      };
      let deliveryDateRangeEnd = {
        field: "deliveryDate",
        value: "",
        condition: "lte"
      };

      let deliveryDateRangeStartFilter = [];
      let deliveryDateRangeEndFilter = [];
      if (this.deliveryDateRangeFilter.startDate !== null) {
        deliveryDateRangeStart.value =
          this.deliveryDateRangeFilter.startDate + " 00:00:00";
        deliveryDateRangeStartFilter.push(deliveryDateRangeStart);
        this.filter.filter.push(deliveryDateRangeStartFilter);
      }

      if (this.deliveryDateRangeFilter.endDate !== null) {
        deliveryDateRangeEnd.value =
          this.deliveryDateRangeFilter.endDate + " 23:59:59";
        deliveryDateRangeEndFilter.push(deliveryDateRangeEnd);
        this.filter.filter.push(deliveryDateRangeEndFilter);
      }

      // Order Status Filter
      if (this.orderStatusName !== "") {
        let orderStatusFilter = [];
        let orderStatus = {
          field: "status",
          value: this.orderStatusName,
          condition: "eq"
        };

        orderStatusFilter.push(orderStatus);
        this.filter.filter.push(orderStatusFilter);
      }

      // Delivery Region Filter
      if (this.deliveryRegionId != 0) {
        let deliveryRegionFilter = [];
        let deliveryRegion = {
          field: "regionId",
          value: this.deliveryRegionId,
          condition: "eq"
        };

        deliveryRegionFilter.push(deliveryRegion);
        this.filter.filter.push(deliveryRegionFilter);
      }

      // Delivery City Filter
      if (this.deliveryCityId != 0) {
        let deliveryCityFilter = [];
        let deliveryCity = {
          field: "cityId",
          value: this.deliveryCityId,
          condition: "eq"
        };

        deliveryCityFilter.push(deliveryCity);
        this.filter.filter.push(deliveryCityFilter);
      }

      // Delivery Branch Filter
      if (this.branchId != 0) {
        let branchFilter = [];
        let branch = {
          field: "branchId",
          value: this.branchId,
          condition: "eq"
        };

        branchFilter.push(branch);
        this.filter.filter.push(branchFilter);
      }

      // Delivery Store Filter
      if (this.deliveryCountryCode !== "") {
        let deliveryStoreFilter = [];
        let store = {
          field: "countryCode",
          value: this.deliveryCountryCode,
          condition: "eq"
        };

        deliveryStoreFilter.push(store);
        this.filter.filter.push(deliveryStoreFilter);
      }

      // Product Quantity Filter
      if (this.productQty !== "any") {
        let productsQtyFilter = [];

        let productsQty = {
          field: "countProducts",
          value: "1",
          condition: this.productQty === "multi" ? "gt" : "eq"
        };

        productsQtyFilter.push(productsQty);
        this.filter.filter.push(productsQtyFilter);
      }

      // Products Filter
      if (this.productNameKeyword !== "") {
        let productNameFilter = [];

        let product = {
          field: "products",
          value: this.productNameKeyword,
          condition: "contains"
        };

        productNameFilter.push(product);
        this.filter.filter.push(productNameFilter);
      }

      // Product Quantity Filter
      if (this.printStatus !== "all") {
        let printStatusFilter = [];
        let printStatus = {
          field:
            this.printStatus === "all-unprinted"
              ? "allNotPrinted"
              : "anyUnprinted",
          value: "true",
          condition: "eq"
        };
        printStatusFilter.push(printStatus);
        this.filter.filter.push(printStatusFilter);
      }

      // Order Type Filter
      if (this.orderType !== "all") {
        let orderTypeFilter = [];
        let orderType = {
          field: "orderType",
          value: this.orderType === "general" ? "General" : "E-commerce",
          condition: "eq"
        };
        orderTypeFilter.push(orderType);
        this.filter.filter.push(orderTypeFilter);
      }

      this.loadItems(true);
    },
    loadItems(showSpinner) {
      if (this.timer) clearTimeout(this.timer);
      if (this.destroyed || this.mountInProgress) return;
      this.spinnerIsLoading = showSpinner;
      this.$store
        .dispatch("retrieveOrders", this.filter)
        .then((response) => {
          if (!this.temporaryFreezeDataUpdate) {
            this.rows = response.orders;
            this.totalRecords = response.totalCount;
          }
          this.timer = setTimeout(() => this.loadItems(false), 30000);
        })
        .catch((error) => {
          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            let titleTxt = "Access Denied";
            let msgTxt = "Please call Exotica administration for help";

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showConfirmButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then((result) => {
                if (result.value) {
                  this.loadItems(true);
                }
              });
          } else {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(
              () => this.loadItems(this.spinnerIsLoading),
              10000
            );
          }
        })
        .finally(() => {
          // close loading spinner
          this.spinnerIsLoading = false;
          this.$nextTick(() => {
            this.loadingSearch = false;
          });
        });
    },
    rowStyleClassFct(row) {
      let styleClass = "pending-row";

      if (Options.user.roleId == 3) styleClass = "pending-row-store-manager";

      if (Options.user.roleId == 7) styleClass = "pending-row-delivery-manager";

      if (Options.user.roleId == 9) styleClass = "pending-row-florist";

      if (Options.user.roleId == 10) styleClass = "pending-row-driver-manager";

      return row.status === "Payment Pending" ? styleClass : "";
    },
    onSelectedRowsChange(params) {
      this.temporaryFreezeDataUpdate = params.selectedRows.length > 0;

      if (params.selectedRows.length == 1)
        this.selectOptions.selectionText = "order selected";
      else this.selectOptions.selectionText = "orders selected";

      for (
        var i = 0;
        i < document.getElementsByClassName("vgt-table")[0].rows.length;
        i++
      ) {
        document
          .getElementsByClassName("vgt-table")[0]
          .rows[i].classList.remove("selected-row");
      }

      for (i = 0; i < params.selectedRows.length; i++) {
        var obj = params.selectedRows[i];
        for (var key in obj) {
          var attrName = key;
          var attrValue = obj[key];

          if (attrName === "originalIndex") {
            document
              .getElementsByClassName("vgt-table")[0]
              .rows[attrValue + 1].classList.add("selected-row");
          }
        }
      }
    },
    onRowClick: function (params) {
      if (this.currentAction)
        if (this.currentAction === "view order")
          this.doViewOrder(params.row.orderId);
      if (this.currentAction === "assign to branch")
        this.doAssignToBranch(
          params.row.orderId,
          params.row.clientName,
          params.row.storeId
        );
      if (this.currentAction === "print order")
        this.doPrintOrder(1, [params.row.orderId]);
      if (this.currentAction === "print delivery note")
        this.doPrintOrder(2, [params.row.orderId]);
      if (this.currentAction === "print gift message")
        this.doPrintOrder(3, [params.row.orderId]);
      if (this.currentAction === "print sticker")
        this.doPrintOrder(4, [params.row.orderId]);
      if (this.currentAction === "print receipt")
        this.doPrintOrder(5, [params.row.orderId]);
      this.currentAction = null;
    },
    viewOrder: function () {
      this.currentAction = "view order";
    },
    printOrder: function () {
      this.currentAction = "print order";
    },
    printDeliveryNote: function () {
      this.currentAction = "print delivery note";
    },
    printGiftMessage: function () {
      this.currentAction = "print gift message";
    },
    printSticker: function () {
      this.currentAction = "print sticker";
    },
    printReceipt: function () {
      this.currentAction = "print receipt";
    },
    assignToBranch: function () {
      this.currentAction = "assign to branch";
    },
    printOrderBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];

      // Check if print is allowed
      for (let i = 0; i < selectedOrders.length; i++) {
        if (selectedOrders[i].denyPrint) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "Print is not allowed for one or more of the selected orders",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      // this.$refs.ordersTable.unselectAllInternal(); // disabled since version "1.0.0.19" as requested by Exotica on 2020-02-20
      this.doPrintOrder(1, orderList);
    },
    printDeliveryNoteBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];

      // Check if print is allowed
      for (let i = 0; i < selectedOrders.length; i++) {
        if (selectedOrders[i].denyPrint) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "Print is not allowed for one or more of the selected orders",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      // this.$refs.ordersTable.unselectAllInternal(); // disabled since version "1.0.0.19" as requested by Exotica on 2020-02-20
      this.doPrintOrder(2, orderList);
    },
    printGiftMessageBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];

      // Check if print is allowed
      for (let i = 0; i < selectedOrders.length; i++) {
        if (selectedOrders[i].denyPrint) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "Print is not allowed for one or more of the selected orders",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      // this.$refs.ordersTable.unselectAllInternal(); // disabled since version "1.0.0.19" as requested by Exotica on 2020-02-20
      this.doPrintOrder(3, orderList);
    },
    printStickerBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];

      // Check if print is allowed
      for (let i = 0; i < selectedOrders.length; i++) {
        if (selectedOrders[i].denyPrint) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "Print is not allowed for one or more of the selected orders",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      // this.$refs.ordersTable.unselectAllInternal(); // disabled since version "1.0.0.19" as requested by Exotica on 2020-02-20
      this.doPrintOrder(4, orderList);
    },
    assignToBranchBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];
      let storeId = null;

      // Check if same store
      for (let i = 0; i < selectedOrders.length; i++) {
        if (storeId === null) {
          storeId = selectedOrders[i].storeId;
          continue;
        }
        if (storeId !== selectedOrders[i].storeId) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "You can only assign orders from the same store",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      // Check if assign to branch is allowed
      for (let i = 0; i < selectedOrders.length; i++) {
        if (selectedOrders[i].denyAssignToBranch) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text:
              "Assign is not allowed for one or more of the selected orders",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      this.$store.dispatch("retrieveBranchList", storeId).then((response) => {
        let branchOptionsHtml = "";

        for (let i = 0; i < response.data.length; i++) {
          branchOptionsHtml += "<option value='"+response.data[i].id+"'>"
              + response.data[i].name 
              +"</option>";
        }

        this.$swal
          .fire({
            position: "center",
            title: "Assign to Branch",
            text: orderList.length + " orders in total",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Assign",
            html:
            "<div>" + orderList.length + " orders in total</div>" +
            "<div style='text-align: center; font-size: 0.9em; margin-top: 20px'>" +
            "<select id='selectedBranches' style='display: block; margin: 5px auto; min-width: 70%; max-width: 100%; padding: 0.375em 0.625em; color: #545454; font-size: 1.125em;' name='selectedBranches'>" +
             branchOptionsHtml +
            "</select>" +
            "</textarea></div>"
          })
          .then((result) => {
            if (result.value) {
              let branchId = document.getElementById("selectedBranches").value;

              let assignOrderToBranchData = {};
              assignOrderToBranchData.orderData = { orderIds: orderList };
              assignOrderToBranchData.branchId = branchId;

              this.$store
                .dispatch("assignOrderToBranch", assignOrderToBranchData)
                .then(() => {
                  this.$swal.fire({
                    position: "center",
                    type: "success",
                    title: "Assigned to Branch",
                    text: null,
                    showConfirmButton: false,
                    timer: 3000
                  });
                })
                .catch((error) => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }

                  this.$swal.fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showConfirmButton: false,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "OK"
                  });
                });
            }
          });
      });
    },
    updateDeliveryStatusBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];
      let storeId = null;

      // Check if same store
      for (let i = 0; i < selectedOrders.length; i++) {
        if (storeId === null) {
          storeId = selectedOrders[i].storeId;
          continue;
        }
        if (storeId !== selectedOrders[i].storeId) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "You can only update status of orders from the same store",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      let deliveryStatuses = {};

      deliveryStatuses[12] = "Delivered";
      deliveryStatuses[14] = "Delivery Failed";

      this.$swal
        .fire({
          position: "center",
          title: "Update Status",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Update",
          cancelButtonText: "Cancel",
          html:
            "<div id='swal2-content' style='display: block; margin-bottom: 10px'>" +
            orderList.length +
            " orders in total</div>" +
            "<div style='text-align: center; font-size: 0.9em'>" +
            '<select id="deliveryStatus" style="display: block; margin: 5px auto; min-width: 70%; max-width: 100%; padding: 0.375em 0.625em; color: #545454; font-size: 1.125em;" name="deliveryStatus" onchange=\'if(this.value == 12) document.getElementById("deliveryFailedReason").style.display = "none"; else { document.getElementById("deliveryFailedReason").style.display = "block"; document.getElementById("deliveryFailedReason").focus() }\'>' +
            "<option value='12'>Delivered</option>" +
            "<option value='14'>Delivery Failed</option>" +
            "</select>" +
            "<textarea rows='5' cols='50' id='deliveryFailedReason' placeholder='Reason' style='margin: auto; border-color:#dedede; outline: none; resize: none; display: none' onfocus='this.style.outline=\"none\"'>" +
            "</textarea></div>"
        })
        .then((result) => {
          if (result.value) {
            let deliveryStatusId = document.getElementById("deliveryStatus")
              .value;
            let deliveryFailedReason = document.getElementById(
              "deliveryFailedReason"
            ).value;

            let data = {
              statusId: deliveryStatusId,
              orderIds: orderList
            };

            if (deliveryStatusId == 14)
              data.comment = deliveryFailedReason.trim();
            this.spinnerIsLoading = true;
            this.$store
              .dispatch("updateOrdersDeliveryStatus", data)
              .then((response) => {
                if (
                  response.data.messageType &&
                  response.data.messageType === "Error"
                ) {
                  let titleTxt = "Error";
                  let msgTxt = response.data.message;

                  if (
                    response.message &&
                    response.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }

                  this.$swal.fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showConfirmButton: false,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "OK"
                  });
                } else
                  this.$swal.fire({
                    position: "center",
                    type: "success",
                    title: "Status updated",
                    text: null,
                    showConfirmButton: false,
                    timer: 3000
                  });
              })
              .catch((error) => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }

                this.$swal.fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showConfirmButton: false,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "OK"
                });
              })
              .finally(() => {
                this.spinnerIsLoading = false;
              });
          }
        });
    },
    assignToDriverBulk: function () {
      let selectedOrders = this.$refs.ordersTable.selectedRows;
      let orderList = [];
      let storeId = null;

      // Check if same store
      for (let i = 0; i < selectedOrders.length; i++) {
        if (storeId === null) {
          storeId = selectedOrders[i].storeId;
          continue;
        }
        if (storeId !== selectedOrders[i].storeId) {
          this.$swal.fire({
            type: "error",
            title: "Not Allowed",
            text: "You can only assign orders from the same store",
            showConfirmButton: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "OK"
          });
          return;
        }
      }

      for (let i = 0; i < selectedOrders.length; i++) {
        orderList.push(selectedOrders[i].orderId);
      }

      let filter = {
        offset: -1,
        limit: -1,
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ]
      };

      this.$store.dispatch("retrieveDriverList", filter).then((responseDriverList) => {
        this.$store.dispatch("retrieveVehicleTypeList").then((responseVehicleType) => {
        let driverOptionsHtml = "";
        let vehicleTypeOptionsHtml = "";

        for (let i = 0; i < responseDriverList.data.drivers.length; i++) {
            driverOptionsHtml += "<option value='"+responseDriverList.data.drivers[i].driverId+"'>"
              +responseDriverList.data.drivers[i].firstName.trim() +
              " " +
              responseDriverList.data.drivers[i].familyName.trim()
              +"</option>";
        }

        for (let i = 0; i < responseVehicleType.length; i++) {
            vehicleTypeOptionsHtml += "<option value='"+responseVehicleType[i].id+"'>"
              +responseVehicleType[i].name 
              +"</option>";
        }


        this.$swal
          .fire({
            position: "center",
            title: "Assign to Driver",
            text: orderList.length + " orders in total",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Assign",
            html:
            "<div id='swal2-content' style='display: block; margin-bottom: 10px'>" +
            orderList.length +
            " orders in total</div>" +
            "<label>Select a driver and vehicle type</label>" +
            "<div style='text-align: center; font-size: 0.9em'>" +
            "<select id='deliveryDriver' style='display: block; margin: 5px auto; min-width: 70%; max-width: 100%; padding: 0.375em 0.625em; color: #545454; font-size: 1.125em;' name='deliveryDriver'>" +
             driverOptionsHtml +
            "</select>" +
            "<select id='deliveryVehicleType' style='display: block; margin: 5px auto; min-width: 70%; max-width: 100%; padding: 0.375em 0.625em; color: #545454; font-size: 1.125em;' name='deliveryVehicleType'>" +
             vehicleTypeOptionsHtml +
            "</select>" +
            "</textarea></div>"
          })
          .then((result) => {
            if (result.value) {
              let deliveryDriverId = document.getElementById("deliveryDriver").value;
              let deliveryVehicleTypeId  = document.getElementById("deliveryVehicleType").value;
              let data = {
                driverId: deliveryDriverId,
                vehicleTypeId: deliveryVehicleTypeId,
                orderIds: { orderIds: orderList }
              };

              this.$store
                .dispatch("assignOrdersToDriver", data)
                .then((response) => {
                  if (response.data.messageType === "Error") {
                    this.$swal.fire({
                      type: "error",
                      title: "Error",
                      text: response.data.message,
                      showCancelButton: false,
                      allowOutsideClick: false,
                      focusCancel: true,
                      confirmButtonColor: "#d33",
                      confirmButtonText: "OK"
                    });
                  } else
                    this.$swal.fire({
                      position: "center",
                      type: "success",
                      title: "Assigned to driver",
                      text: null,
                      showConfirmButton: false,
                      timer: 3000
                    });
                })
                .catch((error) => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }

                  this.$swal.fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showConfirmButton: false,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "OK"
                  });
                });
            }
          });
      });
      });
    },
    doViewOrder: function (orderId) {
      let routeData = this.$router.resolve({
        name: "order",
        query: { id: orderId }
      });
      window.open(routeData.href, "_self");
    },
    doAssignToBranch: function (orderId, clientName, storeId) {
      this.$store.dispatch("retrieveBranchList", storeId).then((response) => {
        let branchOptionsHtml = "";

        for (let i = 0; i < response.data.length; i++) {
          branchOptionsHtml += "<option value='"+response.data[i].id+"'>"
              + response.data[i].name 
              +"</option>";
        }

        this.$swal
          .fire({
            position: "center",
            title: "Assign To Branch",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Assign",
            cancelButtonText: "Cancel",
            html:
            "<div>"+  clientName + "\nOrder #" + orderId +"</div>" +
            "<div style='text-align: center; font-size: 0.9em; margin-top: 10px'>" +
            "<select id='selectedBranch' style='display: block; margin: 5px auto; min-width: 70%; max-width: 100%; padding: 0.375em 0.625em; color: #545454; font-size: 1.125em;' name='selectedBranch'>" +
             branchOptionsHtml +
            "</select>" +
            "</textarea></div>"
          })
          .then((result) => {
            if (result.value) {
              let branchId = document.getElementById("selectedBranch").value;

              let assignOrderToBranchData = {};
              assignOrderToBranchData.orderData = { orderIds: [orderId] };
              assignOrderToBranchData.branchId = branchId;

              this.$store
                .dispatch("assignOrderToBranch", assignOrderToBranchData)
                .then(() => {
                  this.$swal.fire({
                    position: "center",
                    type: "success",
                    title: "Branch Assigned",
                    text: "Order #" + orderId,
                    showConfirmButton: false,
                    timer: 3000
                  });
                })
                .catch((error) => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }

                  this.$swal.fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showConfirmButton: false,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33"
                  });
                });
            }
          });
      });
    },
    doPrintOrder: function (printType, orderIds) {
      this.spinnerIsLoading = true;
      var printData = {
        type: printType,
        orderIds: orderIds
      };

      this.$store
        .dispatch("printJob", printData)
        .then((response) => {
          this.$refs.orderPrintJobComponent
            .updateData(printType, response)
            .then(() => {
              printJS({
                printable: "orderPrintJobDiv",
                type: "html",
                scanStyles: false
              });
            });
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.doPrintOrder(printType, orderIds);
              }
            });
        })
        .finally(() => {
          // close loading spinner
          this.spinnerIsLoading = false;
        });
    }
  }
};
</script>
<style>
@media screen {
  .print {
    display: none;
  }
}

@media print {
  .print {
    display: none;
  }
}
</style>
