<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Seasons</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Seasons</h1>
    <!-- end page-header -->
    <panel title="Seasons">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <div>
          <vue-good-table
            ref="seasonsTable"
            mode="remote"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :totalRows="totalRecords"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No seasons found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'enabled'">
                <span v-if="props.row.enabled" class="label label-green">Active</span>
                <span v-else class="label label-danger">Inactive</span>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="navigateToEditHighSeasonPage(props.row.id)"
                >Edit</button>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="navigateToDefineNewSeasonPage"
          >Define New Season</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // spinner
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      columns: [
        {
          label: "Label",
          field: "label",
          tdClass: "text-left"
        },
        {
          label: "Start Date",
          field: "dateFrom",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd",
          tdClass: "text-left"
        },
        {
          label: "End Date",
          field: "dateTo",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd",
          tdClass: "text-left"
        },
        {
          label: "Store",
          field: "storeName",
          tdClass: "text-left"
        },
        {
          label: "Status",
          field: "enabled",
          tdClass: "text-left"
        },
        {
          label: "Created By",
          field: "createdBy",
          tdClass: "text-left"
        },
        {
          label: "Last Edited By",
          field: "editedBy",
          tdClass: "text-left"
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: false,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Seasons per page",
        perPage: 15,
        perPageDropdown: [10, 15, 30, 50],
        ofLabel: "of"
      },
      searchOptions: {
        enabled: false,
        placeholder: "Refine your search result by entering multiple keywords"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: false
        /* initialSortBy: [
          { field: "firstName", type: "asc" },
          { field: "lastName", type: "asc" }
        ]*/
      },
      filter: {
        filter: []
      }
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveHighSeasonsList", this.filter)
        .then(response => {
          this.rows = response;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    navigateToEditHighSeasonPage: function(seasonId) {
      let routeData = this.$router.resolve({
        name: "highSeasonSeason",
        query: { id: seasonId }
      });
      window.open(routeData.href, "_self");
    },
    navigateToDefineNewSeasonPage: function() {
      let routeData = this.$router.resolve({
        name: "highSeasonSeason"
      });
      window.open(routeData.href, "_self");
    }
  },
  created() {
    this.load();
  }
};
</script>