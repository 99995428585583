<style scoped>
  .total {
    display: inline-block;
    font-weight: 700;
    background-color: #74ad48;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    min-width: 50px;
  }

  .alert {
    background-color: #e93535;
  }
</style>
<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Dashboard</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">High Season</h1>
    <!-- end page-header -->
    <panel title="Dashboard">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div v-if="seasons.length == 0">
        <b-alert variant="danger" :show="!spinnerIsLoading">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">No active seasons found</p>
        </b-alert>
      </div>
      <div v-else>
        <div
          class="image-overlay"
          v-if="showImageOverlay"
          @click="showImageOverlay = false"
        >
          <img :src="imageOverlayUrl" />
        </div>
        <div>
          <div v-if="seasons.length > 1" style="max-width: 700px">
            <label class="f-w-700">Season</label>
            <i
              v-if="seasonId == 0"
              class="fas fa-asterisk m-l-10 text-red f-s-8"
            ></i>
            <select
              class="form-control"
              v-model="seasonId"
              @change="loadSeason(true)"
            >
              <option value="0" hidden selected v-if="seasonId === 0">
                Select a Season
              </option>
              <option
                v-for="season in seasons"
                :value="season.id"
                :key="season.id"
              >
                {{ season.label }}
              </option>
            </select>
            <br />
          </div>
          <div v-else-if="seasons.length == 1">
            <label class="f-w-700">High Season:</label>
            <label class="m-l-10">{{ seasons[0].label }}</label>
          </div>
          <div v-else>No Active Seasons</div>
          <div v-if="seasonId != 0">
            <v-select
                  ref="lineOfProduct"
                  :options="lineOfProductList"
                  :disabled="lineOfProductList.length == 0"
                  placeholder="Select a line of product"
                  :reduce="label => label.id"
                  v-model="lineOfProductId"
                  @input="loadSeason(true)"
                ></v-select>
                <div class="checkbox checkbox-css m-b-10">
                <input
                  type="checkbox"
                  id="zeroOrdersExcluded"
                  v-model="zeroOrdersExcluded"
                  @change="loadSeason(true)"
                />
                <label for="zeroOrdersExcluded" style="cursor: pointer;">Exclude non-ordered items</label>
              </div>
          </div>
          <div v-if="seasonId != 0 && rows.length > 0">
            <vue-good-table
              ref="productsTable"
              styleClass="vgt-table striped bordered"
              :columns="columns"
              :rows="rows"
              :line-numbers="false"
              :totalRows="totalRecords"
              :pagination-options="paginationOptions"
              :selectOptions="selectOptions"
              :search-options="searchOptions"
              :sort-options="sortOptions"
              @on-cell-click="onCellClick"
            >
              <div slot="emptystate">No products found</div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'image'">
                  <img :src="props.row.image" height="100" />
                </span>
                <span v-else-if="props.column.field === 'quantityInStock'">
                  <table style="text-align: left">
                    <tbody>
                      <tr>
                        <th>Quantity in stock</th>
                        <td>{{ props.row.quantityInStock }}</td>
                      </tr>
                      <tr>
                        <th>Remaining quantity in stock</th>
                        <td>{{ props.row.remainingQuantity }}</td>
                      </tr>
                      <tr>
                        <th>Quantity being wrapped</th>
                        <td>{{ props.row.quantityBeingWrapped }}</td>
                      </tr>
                      <tr>
                        <th>Quantity in dispatching fridges</th>
                        <td>{{ props.row.quantityInDispatchingFridge }}</td>
                      </tr>
                      <tr>
                        <th>Quantity in transit fridges</th>
                        <td>{{ props.row.quantityInTransitFridge }}</td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span v-else-if="props.column.field === 'quantityOrdered'">
                  <table style="text-align: left">
                    <thead>
                      <tr>
                        <th>Season days</th>
                        <th
                          v-for="qtyOrdered in props.row.quantityOrdered"
                          :key="qtyOrdered.day"
                        >
                          {{ qtyOrdered.day }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Quantity ordered</th>
                        <td
                          v-for="qtyOrdered in props.row.quantityOrdered"
                          :key="qtyOrdered.day"
                        >
                          <span v-if="qtyOrdered.day === 'Total'" class="total">{{ qtyOrdered.quantity }}</span>
                          <span v-else>{{ qtyOrdered.quantity }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th>Quantity to be produced</th>
                        <td
                          v-for="qtyToBeProduced in props.row.quantityToBeProduced"
                          :key="qtyToBeProduced.day"
                        >
                          <span v-if="qtyToBeProduced.day === 'Total' && qtyToBeProduced.quantity > 0" class="total alert">{{ qtyToBeProduced.quantity }}</span>
                          <span v-else-if="qtyToBeProduced.day === 'Total'" class="total">{{ qtyToBeProduced.quantity }}</span>
                          <span v-else>{{ qtyToBeProduced.quantity }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </span>
                <span v-else-if="props.column.field === 'quantityToBeProduced'">
                  <span
                    style="display: block"
                    v-for="qtyToBeProduced in props.row.quantityToBeProduced"
                    :key="qtyToBeProduced.day"
                    >{{
                      qtyToBeProduced.day + ": " + qtyToBeProduced.quantity
                    }}</span
                  >
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      destroyed: false,
      // spinner
      spinnerIsLoading: false,
      spinnerInitiatedByLoadSeason: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      zeroOrdersExcluded: false,
      // data grid
      columns: [
        {
          label: "SKU",
          field: "sku",
          tdClass: "text-left",
        },
        {
          label: "Product Name",
          field: "name",
          tdClass: "text-left",
        },
        {
          label: "Image",
          field: "image",
          tdClass: "text-center",
          sortable: false,
        },
        {
          label: "Quantity In Stock / Being Wrapped",
          field: "quantityInStock",
          tdClass: "text-center",
          sortable: false,
        },
        {
          label: "Quantity Ordered / To Be Produced",
          field: "quantityOrdered",
          tdClass: "text-left",
          sortable: false,
        },
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: false,
      },
      searchOptions: {
        enabled: true,
        placeholder: "Refine your search result by entering multiple keywords",
      },
      selectOptions: {
        enabled: false,
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "name", type: "asc" }],
      },
      filter: {
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq",
            },
          ],
        ],
      },
      // image overlay
      showImageOverlay: false,
      imageOverlayUrl: "",
      // data
      seasons: [],
      seasonId: 0,
      lineOfProductList: [],
      lineOfProductId: null
    };
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
    this.destroyed = true;
  },
  methods: {
    load: function () {
      this.spinnerIsLoading = true;

      this.$store.dispatch("retrieveLineOfProductList").then(response => {
        let _this = this;
        response.data.forEach(function(lineOfProduct) {
          _this.lineOfProductList.push({
            id: lineOfProduct.id,
            label: lineOfProduct.name
          });
        });
      });

      this.$store
        .dispatch("retrieveHighSeasonsList", this.filter)
        .then((response) => {
          this.seasons = response;
          if (this.seasons.length == 1) {
            this.seasonId = this.seasons[0].id;
            this.loadSeason();
          }
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry",
            })
            .then((result) => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = this.spinnerInitiatedByLoadSeason;
        });
    },
    loadSeason: function (showSpinner) {

      if (this.destroyed) return;

      if (typeof showSpinner === "undefined") this.spinnerIsLoading = true;
      else this.spinnerIsLoading = showSpinner;

      this.spinnerInitiatedByLoadSeason = true;
      if (this.timer) clearTimeout(this.timer);

      if (this.spinnerIsLoading) this.rows = [];

      let data = {
        "seasonId": this.seasonId,
        "lineOfProductId": this.lineOfProductId,
        "zeroOrdersExcluded": this.zeroOrdersExcluded
      }

      this.$store
        .dispatch("retrieveHighSeasonDashboard", data)
        .then((response) => {
          response.forEach(function (product) {
            let totalQty = 0;
            product.quantityOrdered.forEach(function (orderedProduct) {
              totalQty += orderedProduct.quantity;
            });

            product.quantityOrdered.push(JSON.parse('{"day": "Total","quantity": ' + totalQty + '}'));

            totalQty = 0;
            product.quantityToBeProduced.forEach(function (toBeProducedProduct) {
              totalQty += toBeProducedProduct.quantity;
            });

            product.quantityToBeProduced.push(JSON.parse('{"day": "Total","quantity": ' + totalQty + '}'));
            
          });

          this.rows = response;
          this.timer = setTimeout(() => this.loadSeason(false), 30000);
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry",
              })
              .then((result) => {
                if (result.value) {
                  this.loadSeason();
                }
              });
          } else {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(
              () => this.loadSeason(this.spinnerIsLoading),
              10000
            );
          }
        })
        .finally(() => {
          this.spinnerIsLoading = false;
          this.spinnerInitiatedByLoadSeason = false;
        });
    },
    onCellClick: function (record) {
      // Name cell clicked
      if (record.column.field === "image") {
        this.imageOverlayUrl = record.row.image;
        this.showImageOverlay = true;
      }
    },
  },
  created() {
    this.load();
  },
};
</script>
