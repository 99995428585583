<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Administration</li>
      <li class="breadcrumb-item">Product Checker</li>
      <li class="breadcrumb-item active">Products</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Product Checker</h1>
    <!-- end page-header -->
    <panel>
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <template slot="header">
        <h4 class="panel-title">Products</h4>
        <b-form-group
          class="m-0"
          style="float: right; margin: -20px 30px 0 0 !important;"
        >
          <button
            ref="removeAllProducts"
            type="button"
            class="btn btn-xs btn-danger"
            style="margin-right: 10px;"
            title="Remove all products"
            @click="removeAllProducts"
          >
            <i class="far fa-trash-alt" style=""></i>
          </button>
          <input
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
            style="display: none;"
          />
          <button
            ref="importBtn"
            type="button"
            class="btn btn-xs btn-success"
            style="margin-right: 10px;"
            @click="initiateImport"
          >
            <i class="fas fa-upload" style="padding-right: 8px;"></i>Import
          </button>
          <button
            ref="exportBtn"
            type="button"
            class="btn btn-xs btn-success"
            @click="doExport"
          >
            <i class="fas fa-download" style="padding-right: 8px;"></i>Export
          </button>
        </b-form-group>
      </template>
      <div>
        <b-alert v-if="successMessage" variant="success" show>
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ successMessage }}</p></b-alert
        >

        <b-alert v-if="errorMessage" variant="danger" show>
          <i
            class="fa fa-exclamation-triangle pull-left m-r-10"
            style="font-size: 1.5em;"
          ></i>
          <p class="m-b-0">
            {{ errorMessage }}
          </p>
        </b-alert>
      </div>
      <div v-if="showResults">
        <div class="alert alert-yellow fade show">
          <template v-if="!spinnerIsLoading">
            <template v-if="searchKeywords !== ''">
              Search for
              <!-- Keywords -->
              <span
                style="font-weight: bold; font-size: 1.3em; padding-left: 15px;"
                >{{ searchKeywords }}</span
              >
            </template>
            <span
              style="
                font-weight: bold;
                font-size: 1.3em;
                padding-left: 15px;
                color: #2a72b5;
              "
              >{{
                this.totalRecords === 0
                  ? "No products found"
                  : "Total: " +
                    this.formatNumber(this.totalRecords).replace(".00", "") +
                    " product" +
                    (this.totalRecords > 1 ? "s" : "")
              }}</span
            >
          </template>
          <template v-else>
            <span
              style="
                font-weight: bold;
                font-size: 1.3em;
                padding-left: 15px;
                color: #2a72b5;
              "
              ><i class="fas fa-spinner fa-pulse"></i>
              {{
                uploadProgress == null
                  ? exportInProgress
                    ? "Exporting data"
                    : deleteInProgress
                    ? "Removing product(s)"
                    : "Fetching data"
                  : Number(uploadProgress) != 100
                  ? "Uploading file " + uploadProgress + "%"
                  : "Processing data"
              }}
            </span>
          </template>
        </div>
        <div class="vgt-global-search vgt-clearfix">
          <div class="vgt-global-search__input vgt-pull-left">
            <span class="input__icon">
              <div class="magnifying-glass"></div>
            </span>
            <input
              type="text"
              ref="searchText"
              placeholder="Refine your search result by entering multiple keywords"
              class="vgt-input vgt-pull-left"
              v-model="searchTermValue"
              v-on:keyup.enter="searchForKeywords"
            />
          </div>
          <div class="vgt-global-search__actions vgt-pull-right"></div>
        </div>
        <vue-good-table
          ref="productsTable"
          :key="refreshKey"
          mode="remote"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :totalRows="totalRecords"
          :pagination-options="paginationOptions"
          :sort-options="sortOptions"
          :search-options="searchOptions"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-sort-change="onSortChange"
          @on-row-click="onRowClick"
        >
          <div slot="emptystate">No products found</div>
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'action'">
              <button
                type="button"
                class="btn btn-xs btn-danger"
                style="margin-right: 10px;"
                @click="removeProduct"
              >
                <i class="fas fa-minus" style="padding-right: 8px;"></i>Remove
              </button>
            </template>
          </template>
        </vue-good-table>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export default {
  data() {
    return {
      refreshKey: 1,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showResults: false,
      searchTermValue: "",
      searchKeywords: "",
      successMessage: null,
      errorMessage: null,
      removeClicked: false,
      exportInProgress: false,
      deleteInProgress: false,
      columns: [
        {
          label: "Description",
          field: "description",
          tdClass: "text-left",
          thClass: "text-center",
          html: true
        },
        {
          label: "Barcode",
          field: "barcode",
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          label: "Selling Price (LBP)",
          field: "price",
          tdClass: "text-right",
          thClass: "text-center",
          formatFn: this.formatNumber
        },
        {
          label: "Selling Price (USD)",
          field: "priceUSD",
          tdClass: "text-right",
          thClass: "text-center",
          formatFn: this.formatNumber
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      limit: 10,
      offset: 0,
      sortParams: [{ field: "description", direction: "asc" }],
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Products per page",
        perPage: 10,
        perPageDropdown: [10, 25, 50, 100, 200],
        ofLabel: "of"
      },
      searchOptions: {
        enabled: false,
        trigger: "enter", // remove to enable on every keypressed
        placeholder: "Refine your search result by entering multiple keywords",
        externalQuery: this.searchKeywords
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          {
            field: "description",
            type: "asc"
          }
        ]
      },
      vueGoodTableServerParams: {
        page: 1,
        perPage: 10,
        sort: [{ field: "description", type: "asc" }]
      },
      page: 1,
      perPage: 10,
      filter: {
        offset: 0,
        limit: 10,
        filter: [],
        sort: [{ field: "description", direction: "desc" }]
      }
    };
  },
  computed: {
    uploadProgress() {
      return this.$store.state.uploadProgress;
    }
  },
  methods: {
    formatNumber: function (value) {
      return value
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        //.replace(".00", "");
    },
    searchForKeywords: function () {
      this.searchKeywords = this.$refs.searchText.value;
      this.page = 1;
      this.paginationOptions.setCurrentPage = 1;
      this.updateParams({ page: 1, perPage: this.perPage }, true);
      this.refreshKey = this.refreshKey * -1;
      this.doSearch(this.searchKeywords);
    },
    load() {
      this.retrieveInfo();
    },
    doSearch: function (searchTerm) {
      if (this.spinnerIsLoading) return; //Abort in case search was initiated

      this.spinnerIsLoading = true;
      this.filter.offset = (this.page - 1) * this.perPage;
      this.filter.limit = this.perPage;
      this.filter.filter = [];
      this.filter.sort = this.sortParams;

      var keywords = searchTerm.trim().split(" ");

      for (var i = 0; i < keywords.length; i++) {
        let description = {
          field: "description",
          value: "",
          condition: "contains"
        };

        let barcode = {
          field: "barcode",
          value: "",
          condition: "eq"
        };

        let searchGroup = [];

        let keyword = keywords[i].trim();

        if (keyword !== "") {
          description.value = keyword;
          searchGroup.push(description);

          barcode.value = keyword;
          searchGroup.push(barcode);

          this.filter.filter.push(searchGroup);
        }
      }

      this.$store
        .dispatch("retrievePriceCheckerProducts", this.filter)
        .then((response) => {
          this.rows = response.data.products;
          this.totalRecords = response.data.totalCount;
          this.showResults = true;
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.doSearch(searchTerm);
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    retrieveInfo: function () {
      this.spinnerIsLoading = true;
      let data = {
        offset: this.offset,
        limit: this.limit,
        filter: [],
        sort: this.sortParams
      };

      this.$store
        .dispatch("retrievePriceCheckerProducts", data)
        .then((response) => {
          this.rows = response.data.products;
          this.totalRecords = response.data.totalCount;
          this.showResults = true;
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.retrieveInfo();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.filter.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.limit = this.vueGoodTableServerParams.perPage;
      this.sortParams = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.sortParams.push(sortByColumn);
      }
    },
    onPageChange: function (params) {
      this.updateParams({ page: params.currentPage }, false);
      this.page = params.currentPage;
      this.doSearch(this.searchKeywords);
    },
    onPerPageChange: function (params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.perPage = params.currentPerPage;
      // reset page
      this.updateParams({ page: 1 }, false);
      this.page = 1;

      this.doSearch(this.searchKeywords);
    },
    onSortChange(params) {
      if (!this.spinnerIsLoading) {
        this.sortParams = params;
        this.updateParams({ sort: params }, true);
        // reset page
        this.updateParams({ page: 1 }, false);
        this.page = 1;

        this.doSearch(this.searchKeywords);
      }
    },
    initiateImport: function () {
      this.$refs.file.click();
    },
    doImport: function () {
      let data = new FormData();
      data.append("file", this.file);
      this.$store.commit("updateUploadProgress", 0);
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("importPriceCheckerProducts", data)
        .then((response) => {
          this.$store.commit("updateUploadProgress", null);
          if (
            response.data.messageType &&
            response.data.messageType === "Error"
          ) {
            this.errorMessage =
              "Importing products failed. Reason: " + response.data.message;
            return;
          }

          if (
            response.data.messageType &&
            response.data.messageType === "Success"
          ) {
            this.successMessage = "Products imported successfully";
            return;
          }

          this.errorMessage = "Importing process failed. Reason: Unknown";
        })
        .catch((error) => {
          this.$store.commit("updateUploadProgress", null);
          this.errorMessage = "Importing process failed. Reason: " + error;
        })
        .finally(() => {
          this.file = null;
          this.spinnerIsLoading = false;
          sleep(8000).then(() => {
            this.errorMessage = null;
            this.successMessage = null;
          });
          this.doSearch(this.searchKeywords);
        });
    },
    handleFileUpload() {
      if (this.$refs.file.files[0]) this.file = this.$refs.file.files[0];
      else this.file = null;

      this.$refs.file.value = "";

      if (this.file) this.doImport();
      else {
        this.errorMessage = "Import of products cancelled";
        sleep(3000).then(() => {
          this.errorMessage = null;
        });
      }
    },
    doExport: function () {
      this.$refs.exportBtn.classList.remove("btn-success");
      this.$refs.exportBtn.classList.add("btn-warning");
      this.spinnerIsLoading = true;
      this.exportInProgress = true;

      this.$store
        .dispatch("retrievePriceCheckerProductsExcelFile")
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Products Prices (" +
              this.$moment(new Date()).format("YYYY-MM-DD HH_mm_ss") +
              ").xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.doExport();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
          this.exportInProgress = false;
          this.$refs.exportBtn.classList.remove("btn-warning");
          this.$refs.exportBtn.classList.add("btn-success");
        });
    },
    removeAllProducts: function () {
      this.$swal
        .fire({
          input: "text",
          position: "center",
          title: null,
          html:
            "To confirm, type <span style='font-weight: 700; color: #ff5b57'>remove all products</span>",
          confirmButtonText: "Remove All",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#aaa",
          allowEnterKey: false,
          onOpen: function (alertElement) {
            alertElement.querySelector(".swal2-confirm").style.display = "none";
            alertElement.addEventListener("keyup", function (e) {
              if (
                e.target.value.trim().toLowerCase() === "remove all products"
              ) {
                alertElement.querySelector(".swal2-confirm").style.display =
                  "inline-block";
              } else {
                alertElement.querySelector(".swal2-confirm").style.display =
                  "none";
              }
            });
          },
          preConfirm: function (textValue) {
            if (textValue.trim().toLowerCase() !== "remove all products") {
              return false;
            }
          }
        })
        .then((inputText) => {
          if (inputText.value) {
            if (
              inputText.value.trim().toLowerCase() === "remove all products"
            ) {
              this.deleteInProgress = true;
              this.spinnerIsLoading = true;
              this.$store
                .dispatch("removeAllProducts")
                .then((response) => {
                  if (
                    response.data.messageType &&
                    response.data.messageType === "Error"
                  ) {
                    this.errorMessage =
                      "Removing all products failed. Reason: " +
                      response.data.message;
                    return;
                  }

                  if (
                    response.data.messageType &&
                    response.data.messageType === "Success"
                  ) {
                    this.successMessage =
                      "All products were successfully removed";
                    return;
                  }

                  this.errorMessage =
                    "Removing all products failed. Reason: Unknown";
                })
                .catch((error) => {
                  this.errorMessage =
                    "Removing all products failed. Reason: " + error;
                })
                .finally(() => {
                  this.spinnerIsLoading = false;
                  this.deleteInProgress = false;
                  sleep(8000).then(() => {
                    this.errorMessage = null;
                    this.successMessage = null;
                  });
                  this.doSearch(this.searchKeywords);
                });
            }
          }
        });
    },
    onRowClick: function (params) {
      if (this.removeClicked) {
        this.removeClicked = false;

        this.$swal
          .fire({
            title:
              '<span style="color: #888; margin: 0 5px; border: 1px solid #888; padding: 5px 10px">' +
              params.row.barcode.trim() +
              "</span>",
            html:
              '<div>Are you sure you want to remove the product</div><div style="color: #ff5b57; font-weight: 700">' +
              params.row.description.trim(),
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove"
          })
          .then((result) => {
            if (result.value) {
              this.spinnerIsLoading = true;
              this.deleteInProgress = true;
              let data = { barcode: params.row.barcode.trim() };

              this.$store
                .dispatch("removePriceCheckerProduct", data)
                .then((response) => {
                  if (
                    response.data.messageType &&
                    response.data.messageType === "Error"
                  ) {
                    this.errorMessage =
                      'Removing product "' +
                      params.row.description.trim() +
                      '" failed. Reason: ' +
                      response.data.message;
                    return;
                  }

                  if (
                    response.data.messageType &&
                    response.data.messageType === "Success"
                  ) {
                    this.successMessage =
                      'Product "' + params.row.description.trim() + '" removed';
                    return;
                  }

                  this.errorMessage =
                    'Removing product "' +
                    params.row.description.trim() +
                    '" failed. Reason: Unknown';
                })
                .catch((error) => {
                  this.errorMessage =
                    'Removing product "' +
                    params.row.description.trim() +
                    '" failed. Reason: ' +
                    error;
                })
                .finally(() => {
                  this.spinnerIsLoading = false;
                  this.deleteInProgress = false;
                  sleep(8000).then(() => {
                    this.errorMessage = null;
                    this.successMessage = null;
                  });
                  this.doSearch(this.searchKeywords);
                });
            }
          });
      }
    },
    removeProduct: function () {
      this.removeClicked = true;
    }
  },
  created() {
    this.load();
  }
};
</script>
