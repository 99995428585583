<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Report</li>
      <li class="breadcrumb-item active">Orders</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Report</h1>
    <!-- end page-header -->
    <panel>
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <template slot="header">
        <h4 class="panel-title">Delivery Cost</h4>
        <b-form-group
          class="m-0"
          style="float: right; margin: -20px 30px 0 0 !important"
        >
          <button
            ref="exportBtn"
            type="button"
            class="btn btn-xs btn-success"
            @click="doExport"
          >
            <i class="fas fa-download" style="padding-right: 8px"></i>Export
          </button>
        </b-form-group>
      </template>
      <div class="form-group row col-xl-7 col-lg-10 col-sm-12 m-t-10">
        <div class="col-sm-3">
          <label class="col-form-label f-s-14 text-grey-darker"
            >Order Date</label
          >
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-5">
              <date-picker
                v-model="orderDateRangeFilter.startDate"
                :config="orderDateRangeConfigs.start"
                @dp-change="onDeliveryDateStartChange"
                placeholder="Range Start Date"
              ></date-picker>
            </div>
            <div class="col-sm-5">
              <date-picker
                v-model="orderDateRangeFilter.endDate"
                :config="orderDateRangeConfigs.end"
                @dp-change="onDeliveryDateEndChange"
                placeholder="Range End Date"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-10">
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="doSubmit"
                :disabled="submitDisabled"
              >
                {{ showResults ? "Refresh" : "Submit" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showResults">
        <div class="alert alert-yellow fade show">
          <span
            style="
              font-weight: bold;
              font-size: 1.3em;
              padding-left: 15px;
              color: #2a72b5;
            "
            >{{
              this.totalRecords === 0
                ? "No records found"
                : "Total: " +
                  this.totalRecords +
                  " record" +
                  (this.totalRecords > 1 ? "s" : "")
            }}</span
          >
        </div>
        <vue-good-table
          ref="resultTable"
          mode="remote"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :totalRows="totalRecords"
          :pagination-options="paginationOptions"
          :sort-options="sortOptions"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-sort-change="onSortChange"
        >
          <div slot="emptystate">No records found</div>
        </vue-good-table>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showResults: false,
      columns: [
        {
          label: "Branch",
          field: "branchName",
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Delivery Resource",
          field: "deliveryResource",
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Vehicle Type",
          field: "vehicleType",
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Number of Orders",
          field: "numberOfOrders",
          type: "number",
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Currency",
          field: "currencyCode",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Total Cost",
          field: "sumDeliveryCost",
          type: "number",
          formatFn: this.formatNumber,
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Average Cost",
          field: "avgDeliveryCost",
          type: "number",
          formatFn: this.formatNumber,
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Minimum Cost",
          field: "minDeliveryCost",
          type: "number",
          formatFn: this.formatNumber,
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Maximum Cost",
          field: "maxDeliveryCost",
          type: "number",
          formatFn: this.formatNumber,
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
      ],
      rows: [],
      totalRecords: 0,
      limit: 10,
      offset: 0,
      sortParams: [
        { field: "branchName", direction: "asc" },
        { field: "deliveryResource", direction: "asc" },
      ],
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Orders per page",
        perPage: 10,
        perPageDropdown: [10, 25, 50, 100, 200],
        ofLabel: "of",
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          {
            field: "branchName",
            type: "asc",
          },
          {
            field: "deliveryResource",
            type: "asc",
          },
        ],
      },
      vueGoodTableServerParams: {
        page: 1,
        perPage: 10,
        sort: [
          { field: "branchName", type: "asc" },
          { field: "deliveryResource", type: "asc" },
        ],
      },
      orderDateRangeFilter: {
        startDate: this.$moment(
          new Date(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          )
        ).format("YYYY-MM-DD"),
        endDate: this.$moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        ).format("YYYY-MM-DD"),
      },
      orderDateRangeConfigs: {
        start: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: false,
          maxDate: new Date(
            new Date(new Date().getFullYear(), new Date().getMonth(), 0)
          ),
          showTodayButton: true,
        },
        end: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: new Date(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          ),
          maxDate: false,
          showTodayButton: true,
        },
      },
    };
  },
  computed: {
    submitDisabled() {
      return (
        !this.orderDateRangeFilter.startDate ||
        !this.orderDateRangeFilter.endDate
      );
    },
  },
  methods: {
    formatNumber: function (value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    retrieveInfo: function () {
      this.spinnerIsLoading = true;
      let data = {
        orderCreationFromDate: this.orderDateRangeFilter.startDate,
        orderCreationToDate: this.orderDateRangeFilter.endDate,
        searchCriteria: {
          offset: this.offset,
          limit: this.limit,
          filter: [],
          sort: this.sortParams,
        },
      };

      this.$store
        .dispatch("retrieveDeliveryCostReport", data)
        .then((response) => {
          this.rows = response.data.report;
          this.totalRecords = response.data.totalCount;
          this.showResults = true;
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry",
            })
            .then((result) => {
              if (result.value) {
                this.retrieveInfo();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.limit = this.vueGoodTableServerParams.perPage;
      this.sortParams = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.sortParams.push(sortByColumn);
      }
    },
    onPageChange: function (params) {
      this.updateParams({ page: params.currentPage }, false);
      this.retrieveInfo();
    },
    onPerPageChange: function (params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.retrieveInfo();
    },
    onSortChange(params) {
      if (!this.spinnerIsLoading) {
        this.updateParams(
          {
            sort: params,
          },
          true
        );
        this.retrieveInfo();
      }
    },
    onDeliveryDateStartChange: function (e) {
      this.clearResults();
      this.$set(this.orderDateRangeConfigs.end, "minDate", e.date);
    },
    onDeliveryDateEndChange: function (e) {
      this.clearResults();
      this.$set(this.orderDateRangeConfigs.start, "maxDate", e.date);
    },
    onStoreChange: function () {
      this.clearResults();
    },
    doSubmit: function () {
      this.retrieveInfo();
    },
    clearResults: function () {
      this.showResults = false;
      this.offset = 0;
      this.limit = 10;
      this.rows = [];
      this.sortParams = [
        { field: "branchName", direction: "asc" },
        { field: "deliveryResource", direction: "asc" },
      ];
    },
    doExport: function () {
      this.spinnerIsLoading = true;
      this.$refs.exportBtn.classList.remove("btn-success");
      this.$refs.exportBtn.classList.add("btn-warning");
      let data = {
        orderCreationFromDate: this.orderDateRangeFilter.startDate,
        orderCreationToDate: this.orderDateRangeFilter.endDate,
      };

      this.$store
        .dispatch("retrieveDeliveryCostReportExcelFile", data)
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Delivery Cost (" +
              this.$moment(new Date()).format("YYYY-MM-DD HH_mm_ss") +
              ").xlsx"
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry",
            })
            .then((result) => {
              if (result.value) {
                this.doExport();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
          this.$refs.exportBtn.classList.remove("btn-warning");
          this.$refs.exportBtn.classList.add("btn-success");
        });
    },
  },
};
</script>
