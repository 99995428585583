<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Dashboard</li>
      <li class="breadcrumb-item active">Statuses Distribution Over Branches</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Dashboard</h1>
    <!-- end page-header -->
    <panel title="Statuses Distribution Over Branches" bodyClass="p-t-0">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div class="form-group row col-xl-7 col-lg-10 col-sm-12 m-t-10">
        <div class="col-sm-3">
          <label class="col-form-label f-s-14 text-grey-darker">Delivery Date</label>
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-5">
              <date-picker
                v-model="dateRangeFilter.startDate"
                :config="dateRangeConfigs.start"
                @dp-change="onDateStartChange"
                placeholder="Range Start Date"
              ></date-picker>
            </div>
            <div class="col-sm-5">
              <date-picker
                v-model="dateRangeFilter.endDate"
                :config="dateRangeConfigs.end"
                @dp-change="onDateEndChange"
                placeholder="Range End Date"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <apexchart ref="chart" type="bar" height="350" :options="chartOptions" :series="series" />
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";

export default {
  data() {
    return {
      // spinner options
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      // date picker control
      datePickerStartDateFirstLoad: true,
      datePickerEndtDateFirstLoad: true,
      dateRangeFilter: {
        startDate: new Date(
          new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
            0,
            0,
            0,
            0
          )
        ),
        endDate: new Date(new Date().setHours(0, 0, 0, 0))
      },
      dateRangeConfigs: {
        start: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        },
        end: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: false,
          maxDate: false,
          showTodayButton: true
        }
      },
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: "Statuses Distribution Over Branches",
          align: "left",
          margin: 10,
          offsetX: 10,
          offsetY: 5,
          floating: false,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function(val) {
              return val + " orders";
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " orders";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      },
      series: []
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      let data = {
        fromDate: this.dateRangeFilter.startDate,
        toDate: this.dateRangeFilter.endDate + " 23:59:59",
        storeId: Options.user.storeId
      };

      this.$store
        .dispatch("retrieveStatusBranchDistribution", data)
        .then(response => {
          this.chartOptions.xaxis.categories = response.data.statuses;
          this.series = response.data.series;
          this.$refs.chart.updateOptions(this.chartOptions, true, true, true);
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    onDateStartChange: function(e) {
      this.load();
      this.$set(this.dateRangeConfigs.end, "minDate", e.date);
    },
    onDateEndChange: function(e) {
      this.load();
      this.$set(this.dateRangeConfigs.start, "maxDate", e.date);
    }
  },
  created() {
    this.load();
  }
};
</script>