<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Fridge Management</li>
      <li class="breadcrumb-item active">Fridges</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Fridge Management</h1>
    <!-- end page-header -->
    <panel title="Fridges">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Fridge {{editModeMessage ? "updated":"added"}} successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>
        <div style="max-width: 700px">
          <div>
            <label class="f-w-700">Fridge Label</label>
            <i v-if="showLabelRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <input
              type="text"
              class="form-control"
              placeholder="# Enter the fridge label"
              style="margin-bottom: 5px"
              v-model="label"
              @keyup="checkForm"
            />
          </div>
          <div style="margin-top: 25px">
            <label class="f-w-700">Fridge Type</label>
            <i v-if="fridgeTypeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <select class="form-control" v-model="fridgeTypeId" @change="checkForm">
              <option value="0" hidden selected v-if="fridgeTypeId == 0">Select Fridge Type</option>
              <option
                v-for="fridgeType in fridgeTypeList"
                :value="fridgeType.id"
                :key="fridgeType.id"
              >{{ fridgeType.name }}</option>
            </select>
          </div>
          <div style="margin-top: 25px">
            <label class="f-w-700">Store</label>
            <select class="form-control" v-model="storeId">
              <option v-for="store in stores" :value="store.id" :key="store.id">{{ store.name }}</option>
            </select>
          </div>
          <div style="margin-top: 25px" v-if="editMode">
            <label class="f-w-700">Status</label>
            <div class="checkbox checkbox-css m-b-10">
              <input type="checkbox" id="status" v-model="enabled" />
              <label for="status">Enabled</label>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="createFridge"
              :disabled="!enableSubmitBtn"
            >{{ editMode ? "Update Info":"Add Fridge" }}</button>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="cancel"
              v-if="editMode"
            >Cancel</button>
          </div>
        </div>
        <div style="margin-top: 40px">
          <label class="f-w-700">Fridges List</label>
          <vue-good-table
            ref="fridgesTable"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :totalRows="totalRecords"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No fridges found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'enabled'">
                <span v-if="props.row.enabled" class="label label-green">Enabled</span>
                <span v-else class="label label-danger">Disabled</span>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="loadFridgeInfo(props.row.fridgeId)"
                >Edit</button>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div style="margin-top: 15px"></div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      showLabelRequired: true,
      editMode: false,
      editModeMessage: false,
      enabled: false,
      columns: [
        {
          label: "Label",
          field: "label",
          tdClass: "text-left"
        },
        {
          label: "Store",
          field: "storeName",
          tdClass: "text-left"
        },
        {
          label: "Fridge Type",
          field: "fridgeType",
          tdClass: "text-left"
        },
        {
          label: "Status",
          field: "enabled",
          tdClass: "text-left",
          sortable: false
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter by fridge label"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "label", type: "asc" }]
      },
      label: "",
      storeId: Options.user.storeId,
      fridgeTypeList: [],
      fridgeTypeId: 0,
      enableSubmitBtn: false,
      stores: []
    };
  },
  methods: {
    load() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveStoreList")
        .then(retrieveStoreListResponse => {
          this.stores = retrieveStoreListResponse.data;
          let retrieveFridgeListFilter = {
            filter: [],
            sort: [{ field: "label", direction: "asc" }]
          };
          this.$store
            .dispatch("retrieveFridgeList", retrieveFridgeListFilter)
            .then(retrieveFridgeListResponse => {
              this.rows = retrieveFridgeListResponse.data;
              this.$store
                .dispatch("retrieveFridgeTypeList")
                .then(retrieveFridgeTypeListResponse => {
                  this.fridgeTypeList = retrieveFridgeTypeListResponse.data;
                })
                .catch(error => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }
                  this.spinnerIsLoading = false;
                  this.$swal
                    .fire({
                      type: "error",
                      title: titleTxt,
                      text: msgTxt,
                      showCancelButton: true,
                      allowOutsideClick: false,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Retry"
                    })
                    .then(result => {
                      if (result.value) {
                        this.load();
                      }
                    });
                });
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    loadFridgeInfo: function(fridgeId) {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveFridgeInfo", fridgeId)
        .then(retrieveFridgeInfoResponse => {
          this.fridgeId = retrieveFridgeInfoResponse.fridgeId;
          this.label = retrieveFridgeInfoResponse.label;
          this.fridgeTypeId = retrieveFridgeInfoResponse.fridgeTypeId;
          this.storeId = retrieveFridgeInfoResponse.storeId;
          this.enabled = retrieveFridgeInfoResponse.enabled;
          this.editMode = true;
          this.checkForm();
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.loadFridgeInfo(fridgeId);
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    checkForm: function() {
      this.errorMessage = "";
      this.showLabelRequired = this.label.trim() === "";
      this.enableSubmitBtn = !this.showLabelRequired && this.fridgeTypeId != 0;
    },
    cancel: function() {
      this.errorMessage = "";
      this.label = "";
      this.fridgeId = 0;
      this.fridgeTypeId = 0;
      this.storeId = Options.user.storeId;
      this.enabled = false;
      this.editMode = false;
      this.checkForm();
    },
    createFridge: function() {
      if (this.editMode) {
        let data = {
          id: this.fridgeId,
          fridge: {
            label: this.label.trim(),
            storeId: this.storeId,
            typeId: this.fridgeTypeId,
            enabled: this.enabled
          }
        };

        this.$store
          .dispatch("updateFridgeInfo", data)
          .then(updateFridgeResponse => {
            if (updateFridgeResponse.data.messageType === "Error") {
              this.errorMessage = updateFridgeResponse.data.message;
            } else {
              this.errorMessage = "";
              this.showSuccessMessage = true;
              this.label = "";
              this.fridgeTypeId = 0;
              this.enabled = false;
              this.storeId = Options.user.storeId;
              this.editMode = false;
              this.editModeMessage = true;
              this.checkForm();
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                this.editModeMessage = false;
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createFridge();
                }
              });
          })
          .finally(() => {
            this.load();
          });
      } else {
        this.spinnerIsLoading = true;
        let fridge = {
          label: this.label.trim(),
          storeId: this.storeId,
          typeId: this.fridgeTypeId
        };
        this.$store
          .dispatch("createFridge", fridge)
          .then(createFridgeResponse => {
            if (createFridgeResponse.data.messageType === "Error") {
              this.errorMessage = createFridgeResponse.data.message;
            } else {
              this.errorMessage = "";
              this.label = "";
              this.showSuccessMessage = true;
              this.storeId = Options.user.storeId;
              this.fridgeTypeId = 0;
              this.checkForm();
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createFridge();
                }
              });
          })
          .finally(() => {
            this.load();
          });
      }
    }
  },
  created() {
    this.load();
  }
};
</script>