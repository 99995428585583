<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Customer Management</li>
      <li class="breadcrumb-item active">Customer</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Customer Management</h1>
    <!-- end page-header -->
    <panel>
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <template slot="header">
        <h4
          v-if="!spinnerIsLoading"
          class="panel-title"
        >{{ customerId && customerId != 0 ? "Update Customer Info": "Create New Customer" }}</h4>
        <h4 v-else class="panel-title">Customer Management</h4>
      </template>
      <b-alert variant="success" :show="showSuccessMessage">
        <i class="fa fa-check fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">
          Customer
          <strong>{{ firstName + " " + familyName }}</strong>
          {{ customerId && customerId != 0 ? "updated":"created" }} successfully
        </p>
      </b-alert>
      <b-alert variant="danger" :show="errorMessage !== ''">
        <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">{{ errorMessage }}</p>
      </b-alert>
      <div style="max-width: 700px" v-if="!spinnerIsLoading && !showSuccessMessage">
        <div v-if="customerCreationDateAndTime">
          <label class="f-w-700">Creation Date:</label>
          &nbsp;{{ customerCreationDateAndTime | moment("dddd Do of MMMM YYYY")}}
        </div>
        <div>
          <label class="f-w-700">First Name</label>
          <i v-if="firstName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <input
            type="text"
            ref="firstName"
            class="form-control"
            placeholder="First Name"
            style="margin-bottom: 5px"
            v-model="firstName"
            @blur="firstName=firstName.trim()"
            @keyup="checkForm"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Family Name</label>
          <i v-if="familyName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <input
            type="text"
            class="form-control"
            placeholder="Family Name"
            style="margin-bottom: 5px"
            v-model="familyName"
            @blur="familyName=familyName.trim()"
            @keyup="checkForm"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">E-mail Address</label>
          <i
            v-if="email.trim() !== '' && !validEmail"
            class="fas fa-exclamation-circle m-l-10 text-red f-s-14"
            style="cursor: help"
            title="Invalid e-mail address"
          ></i>
          <input
            type="text"
            ref="email"
            class="form-control"
            style="text-transform: lowercase"
            placeholder="E-mail Address"
            v-model="email"
            @blur="email=email.trim()"
            @input="checkForm"
          />
        </div>
        <div style="margin-top: 25px">
          <label
            class="f-w-700"
          >Phone Number{{ phoneNumbers.length > 2 ? "s (" + (phoneNumbers.length - 1) + ")":"" }}</label>
          <i v-if="phoneNumbers == null || phoneNumbers.length === 1" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <b-alert variant="danger" :show="showPhoneErrorMessage" style="padding: 10px">
            <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
            <p class="m-b-0">One or more of the phone numbers already exist</p>
          </b-alert>
          <div v-for="(phoneNumber, index) in phoneNumbers" v-bind:key="index" class="form-row">
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="Phone Number"
                :style="(phoneNumber.value.trim() !== '' && phoneNumber.style) ? phoneNumber.style:'margin-bottom: 5px'"
                v-model="phoneNumber.value"
                @input="onCheckPhoneNumbers"
              />
            </div>
            <div class="col">
              <select class="form-control" v-model="phoneNumber.type">
                <option
                  v-for="phoneType in phoneTypeList"
                  :value="phoneType.id"
                  :key="phoneType.id"
                >{{ phoneType.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Date of Birth</label>
          <div class="row">
            <div class="col">
              <date-picker v-model="dob" :config="dobConfiguration" placeholder="Date of birth"></date-picker>
            </div>
          </div>
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Gender</label>
          <select class="form-control" @change="checkForm" v-model="genderId">
            <option value="0" hidden selected v-if="genderId == 0">Select a Gender</option>
            <option v-for="gender in genders" :value="gender.id" :key="gender.id">{{ gender.name }}</option>
          </select>
        </div>
        <div style="margin-top: 25px" v-if="roleId == 1">
          <label class="f-w-700">Store</label>
          <select class="form-control" @change="checkForm" v-model="storeId">
            <option value="0" hidden selected v-if="storeId === 0">Select a Store</option>
            <option v-for="store in stores" :value="store.id" :key="store.id">{{ store.name }}</option>
          </select>
        </div>
        <div style="margin-top: 25px" v-if="customerId && customerId != 0">
          <label class="f-w-700">Status</label>
          <div class="checkbox checkbox-css m-b-10">
            <input type="checkbox" id="status" v-model="enabled" />
            <label for="status" style="cursor: pointer">Active</label>
          </div>
        </div>
        <hr />
        <div>
          <b-card-header
            header-tag="header"
            class="card-header bg-black text-white pointer-cursor m-t-25"
            v-b-toggle.billingAddresses
          >
            <i class="fas fa-plus-circle m-r-10 f-s-12 when-closed"></i>
            <i class="fas fa-minus-circle m-r-10 f-s-12 when-opened"></i>
            Billing Addresses
            <span
              v-if="this.billingAddresses.length > 1"
            >({{ this.billingAddresses.length - 1 }})</span>
          </b-card-header>
          <b-collapse id="billingAddresses">
            <div
              class="card border-dark bg-light mb-3 m-t-20"
              v-for="(billingAddress, index) in billingAddresses"
              v-bind:key="index"
            >
              <div
                class="card-header border-dark f-w-700 text-black"
                style="cursor: pointer"
                v-b-toggle="'billingAddress-' + index"
              >
                <i class="fas fa-plus-circle m-r-10 f-s-12 when-closed"></i>
                <i class="fas fa-minus-circle m-r-10 f-s-12 when-opened"></i>
                <span v-if="billingAddress.label.trim() === ''">Add New Billing Address</span>
                <span
                  v-else-if="billingAddress.invalidAddress"
                  style="color: #e83333"
                >{{ billingAddress.label }} (Billing Address)</span>
                <span v-else>{{ billingAddress.label }} (Billing Address)</span>
                <i
                  v-if="billingAddress.invalidAddress"
                  class="far fa-times-circle f-s-14 m-l-10"
                  style="color: #e83333"
                ></i>
              </div>
              <b-collapse :id="'billingAddress-'+index">
                <div class="col" style="max-width: 700px">
                  <div class="card-body">
                    <div style="margin-top: 5px">
                      <label>Label</label>
                      <i
                        v-if="billingAddress.label.trim() === ''"
                        class="fas fa-asterisk m-l-10 text-red f-s-8"
                      ></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Label"
                        style="margin-bottom: 5px"
                        v-model="billingAddress.label"
                        @blur="billingAddress.label = billingAddress.label.trim()"
                        @input="checkForm"
                      />
                    </div>
                    <div v-if="billingAddress.label.trim() !== ''">
                      <div style="margin-top: 25px">
                        <label>Billing Name</label>
                        <i
                          v-if="billingAddress.firstName.trim() === '' || billingAddress.familyName.trim() === '' "
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.firstName"
                          @blur="billingAddress.firstName = billingAddress.firstName.trim()"
                          @input="checkForm"
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Family Name"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.familyName"
                          @blur="billingAddress.familyName = billingAddress.familyName.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Company Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Company Name"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.companyName"
                          @blur="billingAddress.companyName = billingAddress.companyName.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Country</label>
                        <i
                          v-if="!billingAddress.country"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <v-select
                          ref="billingCountry"
                          :options="countries"
                          style="background-color: #ffffff"
                          placeholder="Select country"
                          @input="billingCountrySelected(index)"
                          v-model="billingAddress.country"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>Region</label>
                        <i
                          v-if="!billingAddress.region && billingAddress.regionName.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          v-if="billingAddress.regions.length == 0"
                          type="text"
                          class="form-control"
                          placeholder="Region"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.regionName"
                          @blur="billingAddress.regionName = billingAddress.regionName.trim()"
                          @input="checkForm"
                          :disabled="!billingAddress.country || !billingAddress.regionsLoaded"
                        />
                        <v-select
                          v-else
                          ref="billingRegion"
                          :options="billingAddress.regions"
                          style="background-color: #ffffff"
                          placeholder="Select region"
                          @input="billingRegionSelected(index)"
                          v-model="billingAddress.region"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>City</label>
                        <i
                          v-if="!billingAddress.city && billingAddress.cityName.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          v-if="billingAddress.cities.length == 0"
                          type="text"
                          class="form-control"
                          placeholder="City"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.cityName"
                          @blur="billingAddress.cityName = billingAddress.cityName.trim()"
                          @input="checkForm"
                          :disabled="(!billingAddress.region && billingAddress.regionName.trim() === '') || !billingAddress.citiesLoaded"
                        />
                        <v-select
                          v-else
                          ref="billingCity"
                          :options="billingAddress.cities"
                          style="background-color: #ffffff"
                          placeholder="Select city"
                          @input="checkForm"
                          v-model="billingAddress.city"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>Street</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Street"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.street"
                          @blur="billingAddress.street = billingAddress.street.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Landmark</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Landmark"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.landmark"
                          @blur="billingAddress.landmark = billingAddress.landmark.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Telephone</label>
                        <i
                          v-if="billingAddress.telephone.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Telephone"
                          style="margin-bottom: 5px"
                          v-model="billingAddress.telephone"
                          @input="checkForm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-collapse>
          <b-card-header
            header-tag="header"
            class="card-header bg-black text-white pointer-cursor m-t-25"
            v-b-toggle.shippingAddresses
          >
            <i class="fas fa-plus-circle m-r-10 f-s-12 when-closed"></i>
            <i class="fas fa-minus-circle m-r-10 f-s-12 when-opened"></i>
            Delivery Addresses
            <span
              v-if="this.shippingAddresses.length > 1"
            >({{ this.shippingAddresses.length - 1 }})</span>
          </b-card-header>
          <b-collapse id="shippingAddresses">
            <div
              class="card border-dark bg-light mb-3 m-t-20"
              v-for="(shippingAddress, index) in shippingAddresses"
              v-bind:key="index"
            >
              <div
                class="card-header border-dark f-w-700 text-black"
                style="cursor: pointer"
                v-b-toggle="'shippingAddress-' + index"
              >
                <i class="fas fa-plus-circle m-r-10 f-s-12 when-closed"></i>
                <i class="fas fa-minus-circle m-r-10 f-s-12 when-opened"></i>
                <span v-if="shippingAddress.label.trim() === ''">Add New Delivery Address</span>
                <span
                  v-else-if="shippingAddress.invalidAddress"
                  style="color: #e83333"
                >{{ shippingAddress.label }} (Delivery Address)</span>
                <span v-else>{{ shippingAddress.label }} (Delivery Address)</span>
                <i
                  v-if="shippingAddress.invalidAddress"
                  class="far fa-times-circle f-s-14 m-l-10"
                  style="color: #e83333"
                ></i>
              </div>
              <b-collapse :id="'shippingAddress-'+index">
                <div class="col" style="max-width: 700px">
                  <div class="card-body">
                    <div style="margin-top: 5px">
                      <label>Label</label>
                      <i
                        v-if="shippingAddress.label.trim() === ''"
                        class="fas fa-asterisk m-l-10 text-red f-s-8"
                      ></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Label"
                        style="margin-bottom: 5px"
                        v-model="shippingAddress.label"
                        @blur="shippingAddress.label = shippingAddress.label.trim()"
                        @input="checkForm"
                      />
                    </div>
                    <div v-if="shippingAddress.label.trim() !== ''">
                      <div style="margin-top: 25px">
                        <label>Recipient Name</label>
                        <i
                          v-if="shippingAddress.firstName.trim() === '' || shippingAddress.familyName.trim() === '' "
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="First Name"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.firstName"
                          @blur="shippingAddress.firstName = shippingAddress.firstName.trim()"
                          @input="checkForm"
                        />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Family Name"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.familyName"
                          @blur="shippingAddress.familyName = shippingAddress.familyName.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Company Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Company Name"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.companyName"
                          @blur="shippingAddress.companyName = shippingAddress.companyName.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Country</label>
                        <i
                          v-if="!shippingAddress.country"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <v-select
                          ref="shippingCountry"
                          :options="countries"
                          style="background-color: #ffffff"
                          placeholder="Select country"
                          @input="shippingCountrySelected(index)"
                          v-model="shippingAddress.country"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>Region</label>
                        <i
                          v-if="!shippingAddress.region && shippingAddress.regionName.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          v-if="shippingAddress.regions.length == 0"
                          type="text"
                          class="form-control"
                          placeholder="Region"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.regionName"
                          @blur="shippingAddress.regionName = shippingAddress.regionName.trim()"
                          @input="checkForm"
                          :disabled="!shippingAddress.country || !shippingAddress.regionsLoaded"
                        />
                        <v-select
                          v-else
                          ref="shippingRegion"
                          :options="shippingAddress.regions"
                          style="background-color: #ffffff"
                          placeholder="Select region"
                          @input="shippingRegionSelected(index)"
                          v-model="shippingAddress.region"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>City</label>
                        <i
                          v-if="!shippingAddress.city && shippingAddress.cityName.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          v-if="shippingAddress.cities.length == 0"
                          type="text"
                          class="form-control"
                          placeholder="City"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.cityName"
                          @blur="shippingAddress.cityName = shippingAddress.cityName.trim()"
                          @input="checkForm"
                          :disabled="(!shippingAddress.region && shippingAddress.regionName.trim() === '') || !shippingAddress.citiesLoaded"
                        />
                        <v-select
                          v-else
                          ref="shippingCity"
                          :options="shippingAddress.cities"
                          style="background-color: #ffffff"
                          placeholder="Select city"
                          @input="checkForm"
                          v-model="shippingAddress.city"
                        ></v-select>
                      </div>
                      <div style="margin-top: 25px">
                        <label>Street</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Street"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.street"
                          @blur="shippingAddress.street = shippingAddress.street.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Landmark</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Landmark"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.landmark"
                          @blur="shippingAddress.landmark = shippingAddress.landmark.trim()"
                          @input="checkForm"
                        />
                      </div>
                      <div style="margin-top: 25px">
                        <label>Telephone</label>
                        <i
                          v-if="shippingAddress.telephone.trim() === ''"
                          class="fas fa-asterisk m-l-10 text-red f-s-8"
                        ></i>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Telephone"
                          style="margin-bottom: 5px"
                          v-model="shippingAddress.telephone"
                          @input="checkForm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-collapse>
        </div>
        <div style="margin-top: 15px">
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="createOrUpdateCustomer"
            :disabled="!enableSubmitBtn"
          >{{ customerId && customerId != 0 ? "Update Customer Info" : "Create Customer" }}</button>
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="cancel"
          >Cancel</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      customerId: this.$route.query.id,
      roleId: Options.user.roleId,
      storeId: Options.user.storeId,
      customerCreationDateAndTime: null,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      firstName: "",
      familyName: "",
      email: "",
      phoneNumbers: [{ value: "", type: 2 }],
      phoneTypeList: [],
      dob: null,
      dobConfiguration: {
        format: "YYYY-MM-DD",
        useCurrent: true,
        showClear: true,
        showClose: true,
        minDate: false,
        maxDate: new Date(),
        showTodayButton: true
      },
      genderId: 0,
      enabled: true,
      genders: [],
      stores: [],
      billingAddresses: [
        {
          invalidAddress: false,
          label: "",
          firstName: "",
          familyName: "",
          companyName: "",
          regionName: "",
          cityName: "",
          street: "",
          landmark: "",
          telephone: "",
          country: null,
          regions: [],
          region: null,
          regionsLoaded: false,
          cities: [],
          city: null,
          citiesLoaded: false
        }
      ],
      shippingAddresses: [
        {
          invalidAddress: false,
          label: "",
          firstName: "",
          familyName: "",
          companyName: "",
          regionName: "",
          cityName: "",
          street: "",
          landmark: "",
          telephone: "",
          country: null,
          regions: [],
          region: null,
          regionsLoaded: false,
          cities: [],
          city: null,
          citiesLoaded: false
        }
      ],
      countries: [],
      debounce: null,
      showPhoneErrorMessage: false
    };
  },
  computed: {
    validEmail: function() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.trim()
      );
    },
    enableSubmitBtn: function() {
      let valid =
        this.firstName.trim() !== "" &&
        this.familyName.trim() !== "" &&
        this.phoneNumbers.length > 1 &&
        (this.email.trim() === "" || this.validEmail) &&
        this.storeId != 0;

      this.billingAddresses.forEach(function(address) {
        if (address.invalidAddress) valid = false;
      });

      this.shippingAddresses.forEach(function(address) {
        if (address.invalidAddress) valid = false;
      });

      for (let i = 0; i < this.phoneNumbers.length; i++) {
        if (
          this.phoneNumbers[i] &&
          (this.phoneNumbers[i].phoneExists ||
            (!this.phoneNumbers[i].phoneNumberChecked &&
              this.phoneNumbers[i].value !== ""))
        )
          valid = false;
      }

      return valid;
    }
  },
  methods: {
    load() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveStoreList")
        .then(retrieveStoreListResponse => {
          this.stores = retrieveStoreListResponse.data;

          this.$store
            .dispatch("retrieveGenderList")
            .then(retrieveGenderListResponse => {
              this.$store
                .dispatch("retrievePhoneTypeList")
                .then(retrievePhoneTypeListResponse => {
                  this.$store
                    .dispatch("retrieveCountryList")
                    .then(responseRetrieveCountryList => {
                      let _this = this;

                      responseRetrieveCountryList.forEach(function(country) {
                        _this.countries.push({
                          id: country.id,
                          label: country.name
                        });
                      });
                      this.phoneTypeList = retrievePhoneTypeListResponse.data;

                      this.genders = retrieveGenderListResponse.data;

                      if (this.customerId && this.customerId != 0) {
                        let customerData = {
                          customerId: this.customerId,
                          typeId: 1
                        };

                        this.$store
                          .dispatch("retrieveCustomer", customerData)
                          .then(retrieveCustomerResponse => {
                            this.customerCreationDateAndTime =
                              retrieveCustomerResponse.data.createdAt;
                            this.firstName =
                              retrieveCustomerResponse.data.firstName;
                            this.familyName =
                              retrieveCustomerResponse.data.lastName;
                            this.email = retrieveCustomerResponse.data.email;
                            this.storeId =
                              retrieveCustomerResponse.data.storeId;
                            this.dob =
                              retrieveCustomerResponse.data.dateOfBirth;
                            this.enabled =
                              retrieveCustomerResponse.data.enabled;
                            if (retrieveCustomerResponse.data.genderId)
                              this.genderId =
                                retrieveCustomerResponse.data.genderId;
                            if (retrieveCustomerResponse.data.phones)
                              retrieveCustomerResponse.data.phones.forEach(
                                function(phone) {
                                  _this.phoneNumbers.push({
                                    id: phone.customerContactId,
                                    value: phone.number,
                                    type: phone.phoneTypeId,
                                    phoneNumberChecked: true
                                  });
                                }
                              );

                            if (retrieveCustomerResponse.data.addresses)
                              retrieveCustomerResponse.data.addresses.forEach(
                                function(address) {
                                  if (address.addressTypeId == 1) {
                                    // Billing Address
                                    let addressVO = {
                                      id: address.id,
                                      invalidAddress: false,
                                      label: address.label,
                                      firstName: address.firstName,
                                      familyName: address.lastName,
                                      companyName: "",
                                      regionName: "",
                                      cityName: "",
                                      street: "",
                                      landmark: "",
                                      telephone: address.telephone,
                                      country: null,
                                      regions: [],
                                      region: null,
                                      regionsLoaded: false,
                                      cities: [],
                                      city: null,
                                      citiesLoaded: false
                                    };

                                    if (address.company)
                                      addressVO.companyName = address.company;

                                    if (address.township)
                                      addressVO.landmark = address.township;

                                    if (
                                      address.street &&
                                      address.street.length > 0
                                    )
                                      addressVO.street = address.street[0];

                                    if (address.countryId) {
                                      addressVO.country = {
                                        id: address.countryId,
                                        label: address.country
                                      };

                                      _this.$store
                                        .dispatch(
                                          "retrieveRegionList",
                                          address.countryId
                                        )
                                        .then(responseRegionList => {
                                          // populate region list
                                          responseRegionList.forEach(function(
                                            region
                                          ) {
                                            addressVO.regions.push({
                                              id: region.id,
                                              label: region.name
                                            });
                                          });

                                          // define region
                                          if (address.regionId)
                                            addressVO.region = {
                                              id: address.regionId,
                                              label: address.region
                                            };
                                          else if (address.region) {
                                            addressVO.regionsLoaded = true;
                                            addressVO.regionName =
                                              address.region;
                                          }

                                          if (address.regionId)
                                            _this.$store
                                              .dispatch(
                                                "retrieveCityList",
                                                address.regionId
                                              )
                                              .then(responseCityList => {
                                                // populate city list
                                                responseCityList.forEach(
                                                  function(city) {
                                                    addressVO.cities.push({
                                                      id: city.id,
                                                      label: city.name
                                                    });
                                                  }
                                                );

                                                // define city
                                                if (address.cityId)
                                                  addressVO.city = {
                                                    id: address.cityId,
                                                    label: address.city
                                                  };
                                                else if (address.city)
                                                  addressVO.cityName =
                                                    address.city;

                                                addressVO.citiesLoaded = true;
                                                // add to list
                                                _this.billingAddresses.push(
                                                  addressVO
                                                );
                                                _this.checkForm();
                                              })
                                              .catch(error => {
                                                let titleTxt = "Error";
                                                let msgTxt = error.message;

                                                if (
                                                  error.message &&
                                                  error.message ===
                                                    "Request failed with status code 403"
                                                ) {
                                                  titleTxt = "Access Denied";
                                                  msgTxt =
                                                    "Please call Exotica administration for help";
                                                }
                                                _this.spinnerIsLoading = false;
                                                _this.$swal
                                                  .fire({
                                                    type: "error",
                                                    title: titleTxt,
                                                    text: msgTxt,
                                                    showCancelButton: true,
                                                    allowOutsideClick: false,
                                                    confirmButtonColor:
                                                      "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Retry"
                                                  })
                                                  .then(result => {
                                                    if (result.value) {
                                                      _this.load();
                                                    }
                                                  });
                                              });
                                          else {
                                            if (address.city)
                                              addressVO.cityName = address.city;
                                            addressVO.citiesLoaded = true;
                                            _this.billingAddresses.push(
                                              addressVO
                                            );
                                            _this.checkForm();
                                          }
                                        })
                                        .catch(error => {
                                          let titleTxt = "Error";
                                          let msgTxt = error.message;

                                          if (
                                            error.message &&
                                            error.message ===
                                              "Request failed with status code 403"
                                          ) {
                                            titleTxt = "Access Denied";
                                            msgTxt =
                                              "Please call Exotica administration for help";
                                          }
                                          _this.spinnerIsLoading = false;
                                          _this.$swal
                                            .fire({
                                              type: "error",
                                              title: titleTxt,
                                              text: msgTxt,
                                              showCancelButton: true,
                                              allowOutsideClick: false,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText: "Retry"
                                            })
                                            .then(result => {
                                              if (result.value) {
                                                _this.load();
                                              }
                                            });
                                        })
                                        .finally(() => {
                                          address.regionsLoaded = true;
                                        });
                                    }
                                  }

                                  if (address.addressTypeId == 2) {
                                    // Shipping Address
                                    let addressVO = {
                                      id: address.id,
                                      invalidAddress: false,
                                      label: address.label,
                                      firstName: address.firstName,
                                      familyName: address.lastName,
                                      companyName: "",
                                      regionName: "",
                                      cityName: "",
                                      street: "",
                                      landmark: "",
                                      telephone: "",
                                      country: null,
                                      regions: [],
                                      region: null,
                                      regionsLoaded: false,
                                      cities: [],
                                      city: null,
                                      citiesLoaded: false
                                    };

                                    if (address.telephone)
                                      addressVO.telephone = address.telephone;

                                    if (address.company)
                                      addressVO.companyName = address.company;

                                    if (address.township)
                                      addressVO.landmark = address.township;

                                    if (
                                      address.street &&
                                      address.street.length > 0
                                    )
                                      addressVO.street = address.street[0];

                                    if (address.countryId) {
                                      addressVO.country = {
                                        id: address.countryId,
                                        label: address.country
                                      };

                                      _this.$store
                                        .dispatch(
                                          "retrieveRegionList",
                                          address.countryId
                                        )
                                        .then(responseRegionList => {
                                          // populate region list
                                          responseRegionList.forEach(function(
                                            region
                                          ) {
                                            addressVO.regions.push({
                                              id: region.id,
                                              label: region.name
                                            });
                                          });

                                          // define region
                                          if (address.regionId)
                                            addressVO.region = {
                                              id: address.regionId,
                                              label: address.region
                                            };
                                          else if (address.region) {
                                            addressVO.regionsLoaded = true;
                                            addressVO.regionName =
                                              address.region;
                                          }

                                          if (address.regionId)
                                            _this.$store
                                              .dispatch(
                                                "retrieveCityList",
                                                address.regionId
                                              )
                                              .then(responseCityList => {
                                                // populate city list
                                                responseCityList.forEach(
                                                  function(city) {
                                                    addressVO.cities.push({
                                                      id: city.id,
                                                      label: city.name
                                                    });
                                                  }
                                                );

                                                // define city
                                                if (address.cityId)
                                                  addressVO.city = {
                                                    id: address.cityId,
                                                    label: address.city
                                                  };
                                                else if (address.city)
                                                  addressVO.cityName =
                                                    address.city;
                                                // add to list
                                                addressVO.citiesLoaded = true;
                                                _this.shippingAddresses.push(
                                                  addressVO
                                                );

                                                _this.checkForm();
                                              })
                                              .catch(error => {
                                                let titleTxt = "Error";
                                                let msgTxt = error.message;

                                                if (
                                                  error.message &&
                                                  error.message ===
                                                    "Request failed with status code 403"
                                                ) {
                                                  titleTxt = "Access Denied";
                                                  msgTxt =
                                                    "Please call Exotica administration for help";
                                                }
                                                _this.spinnerIsLoading = false;
                                                _this.$swal
                                                  .fire({
                                                    type: "error",
                                                    title: titleTxt,
                                                    text: msgTxt,
                                                    showCancelButton: true,
                                                    allowOutsideClick: false,
                                                    confirmButtonColor:
                                                      "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Retry"
                                                  })
                                                  .then(result => {
                                                    if (result.value) {
                                                      _this.load();
                                                    }
                                                  });
                                              });
                                          else {
                                            if (address.city)
                                              addressVO.cityName = address.city;
                                            addressVO.citiesLoaded = true;
                                            _this.shippingAddresses.push(
                                              addressVO
                                            );
                                            _this.checkForm();
                                          }
                                        })
                                        .catch(error => {
                                          let titleTxt = "Error";
                                          let msgTxt = error.message;

                                          if (
                                            error.message &&
                                            error.message ===
                                              "Request failed with status code 403"
                                          ) {
                                            titleTxt = "Access Denied";
                                            msgTxt =
                                              "Please call Exotica administration for help";
                                          }
                                          _this.spinnerIsLoading = false;
                                          _this.$swal
                                            .fire({
                                              type: "error",
                                              title: titleTxt,
                                              text: msgTxt,
                                              showCancelButton: true,
                                              allowOutsideClick: false,
                                              confirmButtonColor: "#3085d6",
                                              cancelButtonColor: "#d33",
                                              confirmButtonText: "Retry"
                                            })
                                            .then(result => {
                                              if (result.value) {
                                                _this.load();
                                              }
                                            });
                                        })
                                        .finally(() => {
                                          address.regionsLoaded = true;
                                        });
                                    }
                                  }
                                }
                              );
                            this.checkForm();
                          })
                          .catch(error => {
                            let titleTxt = "Error";
                            let msgTxt = error.message;

                            if (
                              error.message &&
                              error.message ===
                                "Request failed with status code 403"
                            ) {
                              titleTxt = "Access Denied";
                              msgTxt =
                                "Please call Exotica administration for help";
                            }
                            this.spinnerIsLoading = false;
                            this.$swal
                              .fire({
                                type: "error",
                                title: titleTxt,
                                text: msgTxt,
                                showCancelButton: true,
                                allowOutsideClick: false,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Retry"
                              })
                              .then(result => {
                                if (result.value) {
                                  this.load();
                                }
                              });
                          })
                          .finally(() => {
                            this.spinnerIsLoading = false;
                            this.$nextTick(() => {
                              sleep(1).then(() => {
                                this.$refs.firstName.focus();
                                this.checkForm();
                              });
                            });
                          });
                      }
                    })
                    .catch(error => {
                      let titleTxt = "Error";
                      let msgTxt = error.message;

                      if (
                        error.message &&
                        error.message === "Request failed with status code 403"
                      ) {
                        titleTxt = "Access Denied";
                        msgTxt = "Please call Exotica administration for help";
                      }
                      this.spinnerIsLoading = false;
                      this.$swal
                        .fire({
                          type: "error",
                          title: titleTxt,
                          text: msgTxt,
                          showCancelButton: true,
                          allowOutsideClick: false,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Retry"
                        })
                        .then(result => {
                          if (result.value) {
                            this.load();
                          }
                        });
                    })
                    .finally(() => {
                      if (!this.customerId) {
                        this.spinnerIsLoading = false;
                        this.$nextTick(() => {
                          sleep(1).then(() => {
                            this.$refs.firstName.focus();
                            this.checkForm();
                          });
                        });
                      }
                    });
                })
                .catch(error => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }
                  this.spinnerIsLoading = false;
                  this.$swal
                    .fire({
                      type: "error",
                      title: titleTxt,
                      text: msgTxt,
                      showCancelButton: true,
                      allowOutsideClick: false,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Retry"
                    })
                    .then(result => {
                      if (result.value) {
                        this.load();
                      }
                    });
                })
                .finally(() => {
                  if (!this.customerId) {
                    this.spinnerIsLoading = false;
                    this.$nextTick(() => {
                      sleep(1).then(() => {
                        this.$refs.firstName.focus();
                        this.checkForm();
                      });
                    });
                  }
                });
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }

              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    billingCountrySelected: function(index) {
      for (let i = 0; i < this.billingAddresses.length; i++) {
        if (index == i) {
          this.billingAddresses[i].regions = [];
          this.billingAddresses[i].regionsLoaded = false;
          this.billingAddresses[i].region = null;
          this.billingAddresses[i].regionName = "";

          this.billingAddresses[i].cities = [];
          this.billingAddresses[i].citiesLoaded = false;
          this.billingAddresses[i].city = null;
          this.billingAddresses[i].cityName = "";

          if (this.billingAddresses[i].country) {
            let _this = this;
            this.$store
              .dispatch(
                "retrieveRegionList",
                this.billingAddresses[i].country.id
              )
              .then(responseRegionList => {
                responseRegionList.forEach(function(region) {
                  _this.billingAddresses[i].regions.push({
                    id: region.id,
                    label: region.name
                  });
                });
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.billingCountrySelected(index);
                    }
                  });
              })
              .finally(() => {
                this.billingAddresses[i].regionsLoaded = true;
              });
          }
        }
      }

      this.checkForm();
    },
    billingRegionSelected: function(index) {
      for (let i = 0; i < this.billingAddresses.length; i++) {
        if (index == i) {
          this.billingAddresses[i].cities = [];
          this.billingAddresses[i].citiesLoaded = false;
          this.billingAddresses[i].city = null;
          this.billingAddresses[i].cityName = "";

          if (this.billingAddresses[i].region) {
            let _this = this;
            this.$store
              .dispatch("retrieveCityList", this.billingAddresses[i].region.id)
              .then(responseCityList => {
                responseCityList.forEach(function(city) {
                  _this.billingAddresses[i].cities.push({
                    id: city.id,
                    label: city.name
                  });
                });
                this.checkForm();
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.billingRegionSelected(index);
                    }
                  });
              })
              .finally(() => {
                this.billingAddresses[i].citiesLoaded = true;
              });
          }
        }
      }
    },
    shippingCountrySelected: function(index) {
      for (let i = 0; i < this.shippingAddresses.length; i++) {
        if (index == i) {
          this.shippingAddresses[i].regions = [];
          this.shippingAddresses[i].regionsLoaded = false;
          this.shippingAddresses[i].region = null;
          this.shippingAddresses[i].regionName = "";

          this.shippingAddresses[i].cities = [];
          this.shippingAddresses[i].citiesLoaded = false;
          this.shippingAddresses[i].city = null;
          this.shippingAddresses[i].cityName = "";

          if (this.shippingAddresses[i].country) {
            let _this = this;
            this.$store
              .dispatch(
                "retrieveRegionList",
                this.shippingAddresses[i].country.id
              )
              .then(responseRegionList => {
                responseRegionList.forEach(function(region) {
                  _this.shippingAddresses[i].regions.push({
                    id: region.id,
                    label: region.name
                  });
                });
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.shippingCountrySelected(index);
                    }
                  });
              })
              .finally(() => {
                this.shippingAddresses[i].regionsLoaded = true;
              });
          }
        }
      }
      this.checkForm();
    },
    shippingRegionSelected: function(index) {
      for (let i = 0; i < this.shippingAddresses.length; i++) {
        if (index == i) {
          this.shippingAddresses[i].cities = [];
          this.shippingAddresses[i].citiesLoaded = false;
          this.shippingAddresses[i].city = null;
          this.shippingAddresses[i].cityName = "";

          if (this.shippingAddresses[i].region) {
            let _this = this;
            this.$store
              .dispatch("retrieveCityList", this.shippingAddresses[i].region.id)
              .then(responseCityList => {
                responseCityList.forEach(function(city) {
                  _this.shippingAddresses[i].cities.push({
                    id: city.id,
                    label: city.name
                  });
                });
                this.checkForm();
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.shippingRegionSelected(index);
                    }
                  });
              })
              .finally(() => {
                this.shippingAddresses[i].citiesLoaded = true;
              });
          }
        }
      }
    },
    onCheckPhoneNumbers: function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.checkPhoneNumbers();
      }, 1000);
    },
    checkPhoneNumbers: function() {
      for (let i = 0; i < this.phoneNumbers.length; i++) {
        if (this.phoneNumbers[i] && this.phoneNumbers[i].value) {
          this.phoneNumbers[i].phoneNumberChecked = false;
          let filter = [];
          let searchGroup = [];

          let phone = {
            field: "phoneNumber",
            value: this.phoneNumbers[i].value,
            condition: "eq"
          };

          searchGroup.push(phone);

          filter.push(searchGroup);
          filter.push([
            {
              field: "enabled",
              value: "true",
              condition: "equals"
            }
          ]);

          let data = {
            typeUrl: "/type/1",
            filter: {
              offset: 0,
              limit: 100,
              filter: filter,
              sort: [
                { field: "firstName", direction: "asc" },
                { field: "lastName", direction: "asc" }
              ]
            }
          };

          this.$store
            .dispatch("retrieveCustomerList", data)
            .then(response => {
              if (response.data.totalCount > 0) {
                this.phoneNumbers[i].phoneExists = true;

                if (this.customerId && this.customerId != 0)
                  for (let j = 0; j < response.data.customers.length; j++) {
                    if (response.data.customers[j].id == this.customerId) {
                      this.phoneNumbers[i].phoneExists = false;
                    }
                  }

                if (this.phoneNumbers[i].phoneExists)
                  this.phoneNumbers[i].style =
                    "margin-bottom: 5px; color: #9c0006; border-color: #ff0000; background-color: #ffc7ce";
                else this.phoneNumbers[i].style = "margin-bottom: 5px";
              } else {
                this.phoneNumbers[i].style = "margin-bottom: 5px";
                this.phoneNumbers[i].phoneExists = false;
              }
              this.checkForm();
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }

              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.checkPhoneNumbers();
                  }
                });
            })
            .finally(() => {
              this.phoneNumbers[i].phoneNumberChecked = true;
              this.checkForm();
            });
        } else this.checkForm();
      }
    },
    checkForm: function() {
      let _this = this;
      this.errorMessage = "";

      // email section
      if (!this.spinnerIsLoading) {
        this.email = this.email.toLowerCase();
        if (this.email.trim() === "" || this.validEmail)
          _this.$refs.email.style = "text-transform: lowercase";
        else {
          _this.$refs.email.style.color = "#ff0000";
          _this.$refs.email.style.borderColor = "#ff0000";
        }
      }

      // phone numbers section
      for (let i = 0; i < this.phoneNumbers.length; i++) {
        _this.phoneNumbers[i].value = _this.phoneNumbers[i].value.replace(
          /[^\d]/g,
          ""
        );
        if (_this.phoneNumbers[i].value === "") {
          _this.phoneNumbers.splice(
            _this.phoneNumbers.indexOf(_this.phoneNumbers[i--]),
            1
          );
        }
      }

      for (let i = 0; i < this.phoneNumbers.length; i++) {
        if (_this.phoneNumbers[i].phoneExists) {
          _this.showPhoneErrorMessage = true;
          break;
        } else _this.showPhoneErrorMessage = false;
      }

      this.phoneNumbers.push({ value: "", type: 2, phoneNumberChecked: false });

      // Billing addresses section
      for (let i = 0; i < this.billingAddresses.length; i++) {
        // telephone
        _this.billingAddresses[i].telephone = _this.billingAddresses[
          i
        ].telephone.replace(/[^\d]/g, "");

        // auto-fill feature
        if (
          i == 0 &&
          _this.billingAddresses[i].firstName.trim() === "" &&
          _this.billingAddresses[i].familyName.trim() === "" &&
          _this.billingAddresses[i].telephone.trim() === ""
        ) {
          _this.billingAddresses[i].firstName = _this.firstName;
          _this.billingAddresses[i].familyName = _this.familyName;
          if (_this.phoneNumbers.length >= 1)
            _this.billingAddresses[i].telephone = _this.phoneNumbers[0].value;
        }

        if (
          !_this.billingAddresses[i].region &&
          !_this.billingAddresses[i].citiesLoaded &&
          _this.billingAddresses[i].regionName.trim() !== ""
        )
          _this.billingAddresses[i].citiesLoaded = true;

        // check validity of billing address
        _this.billingAddresses[i].invalidAddress =
          _this.billingAddresses[i].label.trim() !== "" &&
          (_this.billingAddresses[i].firstName.trim() === "" ||
            _this.billingAddresses[i].familyName.trim() === "" ||
            !_this.billingAddresses[i].country ||
            (!_this.billingAddresses[i].region &&
              _this.billingAddresses[i].regionName.trim() === "") ||
            (!_this.billingAddresses[i].city &&
              _this.billingAddresses[i].cityName.trim() === "") ||
            _this.billingAddresses[i].telephone.trim() === "");

        if (_this.billingAddresses[i].label === "") {
          _this.billingAddresses.splice(
            _this.billingAddresses.indexOf(_this.billingAddresses[i--]),
            1
          );
        }
      }

      this.billingAddresses.push({
        invalidAddress: false,
        label: "",
        firstName: "",
        familyName: "",
        companyName: "",
        regionName: "",
        cityName: "",
        street: "",
        landmark: "",
        telephone: "",
        country: null,
        regions: [],
        region: null,
        regionsLoaded: false,
        cities: [],
        city: null,
        citiesLoaded: false
      });

      // shipping addresses section
      for (let i = 0; i < this.shippingAddresses.length; i++) {
        // telephone
        _this.shippingAddresses[i].telephone = _this.shippingAddresses[
          i
        ].telephone.replace(/[^\d]/g, "");

        if (
          !_this.shippingAddresses[i].region &&
          !_this.shippingAddresses[i].citiesLoaded &&
          _this.shippingAddresses[i].regionName.trim() !== ""
        )
          _this.shippingAddresses[i].citiesLoaded = true;

        // check validity of shipping address
        _this.shippingAddresses[i].invalidAddress =
          _this.shippingAddresses[i].label.trim() !== "" &&
          (_this.shippingAddresses[i].firstName.trim() === "" ||
            _this.shippingAddresses[i].familyName.trim() === "" ||
            !_this.shippingAddresses[i].country ||
            (!_this.shippingAddresses[i].region &&
              _this.shippingAddresses[i].regionName.trim() === "") ||
            (!_this.shippingAddresses[i].city &&
              _this.shippingAddresses[i].cityName.trim() === "") ||
            _this.shippingAddresses[i].telephone.trim() === "");

        if (_this.shippingAddresses[i].label === "") {
          _this.shippingAddresses.splice(
            _this.shippingAddresses.indexOf(_this.shippingAddresses[i--]),
            1
          );
        }
      }

      this.shippingAddresses.push({
        invalidAddress: false,
        label: "",
        firstName: "",
        familyName: "",
        companyName: "",
        regionName: "",
        cityName: "",
        street: "",
        landmark: "",
        telephone: "",
        country: null,
        regions: [],
        region: null,
        regionsLoaded: false,
        cities: [],
        city: null,
        citiesLoaded: false
      });
    },
    createOrUpdateCustomer: function() {
      this.spinnerIsLoading = true;
      let _phoneNumbers = [];
      let _addresses = [];

      // phone numbers section
      this.phoneNumbers.forEach(function(phone) {
        if (phone.value !== "") {
          if (phone.id == 0)
            _phoneNumbers.push({
              number: phone.value,
              phoneTypeId: phone.type
            });
          else
            _phoneNumbers.push({
              customerContactId: phone.id,
              number: phone.value,
              phoneTypeId: phone.type
            });
        }
      });

      let customer = {
        firstName: this.firstName,
        lastName: this.familyName,
        email: this.email
      };

      if (this.roleId == 1) customer.storeId = this.storeId;

      if (this.genderId != 0) customer.genderId = this.genderId;

      if (this.dob) customer.dateOfBirth = this.dob;

      if (_phoneNumbers.length > 0) customer.phones = _phoneNumbers;

      // billing addresses
      this.billingAddresses.forEach(function(address) {
        if (address.label !== "") {
          let _address = {
            label: address.label.trim(),
            addressTypeId: 1,
            firstName: address.firstName.trim(),
            lastName: address.familyName.trim(),
            telephone: address.telephone.trim(),
            countryId: address.country.id
          };

          if (address.id) _address.id = address.id;

          if (address.companyName.trim() !== "")
            _address.company = address.companyName.trim();

          if (address.region) _address.regionId = address.region.id;
          else if (address.regionName.trim() !== "")
            _address.region = address.regionName.trim();

          if (address.city) _address.cityId = address.city.id;
          else if (address.cityName.trim() !== "")
            _address.city = address.cityName.trim();

          if (address.street.trim() !== "")
            _address.street = [address.street.trim()];

          if (address.landmark.trim() !== "")
            _address.township = address.landmark.trim();

          _addresses.push(_address);
        }
      });

      // shipping addresses
      this.shippingAddresses.forEach(function(address) {
        if (address.label !== "") {
          let _address = {
            label: address.label.trim(),
            addressTypeId: 2,
            firstName: address.firstName.trim(),
            lastName: address.familyName.trim(),
            countryId: address.country.id
          };

          if (address.id) _address.id = address.id;

          if (address.telephone.trim() !== "")
            _address.telephone = address.telephone.trim();

          if (address.companyName.trim() !== "")
            _address.company = address.companyName.trim();

          if (address.region) _address.regionId = address.region.id;
          else if (address.regionName.trim() !== "")
            _address.region = address.regionName.trim();

          if (address.city) _address.cityId = address.city.id;
          else if (address.cityName.trim() !== "")
            _address.city = address.cityName.trim();

          if (address.street.trim() !== "")
            _address.street = [address.street.trim()];

          if (address.landmark.trim() !== "")
            _address.township = address.landmark.trim();

          _addresses.push(_address);
        }
      });

      customer.addresses = _addresses;

      if (this.customerId && this.customerId != 0) {
        customer.enabled = this.enabled;

        let data = {
          customerId: this.customerId,
          typeId: 1,
          customer: customer
        };

        this.spinnerIsLoading = true;

        this.$store
          .dispatch("updateCustomer", data)
          .then(updateCustomerResponse => {
            if (updateCustomerResponse.data.messageType === "Error") {
              this.errorMessage = updateCustomerResponse.data.message;
            } else {
              this.firstName = "";
              this.familyName = "";
              this.email = "";
              this.storeId = 0;
              this.genderId = 0;
              this.showSuccessMessage = true;
              this.enabled = false;

              sleep(1500).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "customerManagementCustomers"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createOrUpdateCustomer();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      } else {
        customer.customerTypeId = 1;
        customer.enabled = true;

        this.$store
          .dispatch("createCustomer", customer)
          .then(createCustomerResponse => {
            if (createCustomerResponse.data.messageType === "Error") {
              this.errorMessage = createCustomerResponse.data.message;
            } else {
              this.firstName = "";
              this.familyName = "";
              this.email = "";
              this.storeId = 0;
              this.genderId = 0;
              this.showSuccessMessage = true;
              this.enabled = false;

              sleep(1500).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "customerManagementCustomers"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createOrUpdateCustomer();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      }
    },
    cancel: function() {
      let routeData = this.$router.resolve({
        name: "customerManagementCustomers"
      });
      window.open(routeData.href, "_self");
    }
  },
  created() {
    this.load();
  }
};
</script>