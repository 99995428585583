<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Administration</li>
      <li class="breadcrumb-item">Product Checker</li>
      <li class="breadcrumb-item active">Activity Logs</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Product Checker</h1>
    <!-- end page-header -->
    <panel>
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <template slot="header">
        <h4 class="panel-title">Activity Logs</h4>
      </template>
      <div class="form-group row col-xl-7 col-lg-10 col-sm-12 m-t-10">
        <div class="col-sm-3">
          <label class="col-form-label f-s-14 text-grey-darker"
            >Date Range</label
          >
        </div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-5">
              <date-picker
                v-model="dateRangeFilter.startDate"
                :config="dateRangeConfigs.start"
                @dp-change="onDateStartChange"
                placeholder="Range Start Date"
              ></date-picker>
            </div>
            <div class="col-sm-5">
              <date-picker
                v-model="dateRangeFilter.endDate"
                :config="dateRangeConfigs.end"
                @dp-change="onDateEndChange"
                placeholder="Range End Date"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div class="row">
            <div class="col-sm-10">
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right;"
                :disabled="submitDisabled"
                @click="doSubmit"
              >
                {{ showResults ? "Refresh" : "Submit" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showResults">
        <div class="alert alert-yellow fade show">
          <span
            style="
              font-weight: bold;
              font-size: 1.3em;
              padding-left: 15px;
              color: #2a72b5;
            "
            >{{
              this.totalRecords === 0
                ? "No activities found"
                : "Total: " +
                  this.totalRecords +
                  " record" +
                  (this.totalRecords > 1 ? "s" : "")
            }}</span
          >
        </div>
        <vue-good-table
          ref="table"
          mode="remote"
          styleClass="vgt-table striped bordered"
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :totalRows="totalRecords"
          :pagination-options="paginationOptions"
          :sort-options="sortOptions"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-sort-change="onSortChange"
        >
          <div slot="emptystate">No activities found</div>
        </vue-good-table>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showResults: false,
      columns: [
        {
          label: "Date & Time",
          field: "ts",
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd HH:mm",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: "User",
          field: "userName",
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          label: "Event",
          field: "actionName",
          tdClass: "text-left",
          thClass: "text-center"
        },
        {
          label: "Description",
          field: "description",
          tdClass: "text-left",
          thClass: "text-center"
        }
      ],
      rows: [],
      totalRecords: 0,
      limit: 10,
      offset: 0,
      sortParams: [{ field: "ts", direction: "desc" }],
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Activities per page",
        perPage: 10,
        perPageDropdown: [10, 25, 50, 100, 200],
        ofLabel: "of"
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          {
            field: "ts",
            type: "desc"
          }
        ]
      },
      vueGoodTableServerParams: {
        page: 1,
        perPage: 10,
        sort: [{ field: "ts", type: "desc" }]
      },
      dateRangeFilter: {
        startDate: this.$moment(
          new Date(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          )
        ).format("YYYY-MM-DD"),
        endDate: this.$moment(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        ).format("YYYY-MM-DD")
      },
      dateRangeConfigs: {
        start: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: false,
          maxDate: new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          showTodayButton: true
        },
        end: {
          format: "YYYY-MM-DD",
          useCurrent: false,
          showClear: false,
          showClose: true,
          minDate: new Date(
            new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          ),
          maxDate: false,
          showTodayButton: true
        }
      }
    };
  },
  computed: {
    submitDisabled() {
      return !this.dateRangeFilter.startDate || !this.dateRangeFilter.endDate;
    }
  },
  methods: {
    formatNumber: function (value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    retrieveInfo: function () {
      this.spinnerIsLoading = true;
      let data = {
        fromDate: this.dateRangeFilter.startDate,
        toDate: this.dateRangeFilter.endDate,
        searchCriteria: {
          offset: this.offset,
          limit: this.limit,
          filter: [],
          sort: this.sortParams
        }
      };

      this.$store
        .dispatch("retrievePriceCheckerActivityLogs", data)
        .then((response) => {
          this.rows = response.data.activityLogs;
          this.totalRecords = response.data.totalCount;
          this.showResults = true;
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.retrieveInfo();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.limit = this.vueGoodTableServerParams.perPage;
      this.sortParams = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.sortParams.push(sortByColumn);
      }
    },
    onPageChange: function (params) {
      this.updateParams({ page: params.currentPage }, false);
      this.retrieveInfo();
    },
    onPerPageChange: function (params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.retrieveInfo();
    },
    onSortChange(params) {
      if (!this.spinnerIsLoading) {
        this.updateParams(
          {
            sort: params
          },
          true
        );
        this.retrieveInfo();
      }
    },
    onDateStartChange: function (e) {
      this.clearResults();
      this.$set(this.dateRangeConfigs.end, "minDate", e.date);
    },
    onDateEndChange: function (e) {
      this.clearResults();
      this.$set(this.dateRangeConfigs.start, "maxDate", e.date);
    },
    onStoreChange: function () {
      this.clearResults();
    },
    doSubmit: function () {
      this.retrieveInfo();
    },
    clearResults: function () {
      this.showResults = false;
      this.offset = 0;
      this.limit = 10;
      this.rows = [];
      this.sortParams = [{ field: "ts", direction: "desc" }];
    }
  }
};
</script>
