<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Wrapping Area Management</li>
      <li class="breadcrumb-item active">Wrapping Area</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Wrapping Area Management</h1>
    <!-- end page-header -->
    <panel title="Wrapping Areas">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Wrapping Area {{editModeMessage ? "updated":"created"}} successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>
        <div style="max-width: 700px">
          <div>
            <label class="f-w-700">Wrapping Area Label</label>
            <i v-if="showLabelRequired" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <input
              type="text"
              class="form-control"
              placeholder="# Enter the wrapping Area label"
              style="margin-bottom: 5px"
              v-model="label"
              @keyup="checkForm"
            />
          </div>
          <div v-if="!loadingPage">
            <div style="margin-top: 25px">
              <label class="f-w-700">Store</label>
              <select class="form-control" v-model="storeId">
                <option v-for="store in stores" :value="store.id" :key="store.id">{{ store.name }}</option>
              </select>
            </div>
            <div style="margin-top: 25px" v-if="editMode">
              <label class="f-w-700">Status</label>
              <div class="checkbox checkbox-css m-b-10">
                <input type="checkbox" id="status" v-model="enabled" />
                <label for="status">Enabled</label>
              </div>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="createWrappingArea"
                :disabled="this.showLabelRequired"
              >{{ editMode ? "Update Info":"Create Wrapping Area" }}</button>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="cancel"
                v-if="editMode"
              >Cancel</button>
            </div>
          </div>
        </div>
        <div style="margin-top: 40px" v-if="!loadingPage">
          <label class="f-w-700">Wrapping Area List</label>
          <vue-good-table
            ref="wrappingAreaTable"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :totalRows="totalRecords"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No wrapping areas found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'enabled'">
                <span v-if="props.row.enabled" class="label label-green">Enabled</span>
                <span v-else class="label label-danger">Disabled</span>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="loadWrappingAreaInfo(props.row.id)"
                >Edit</button>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div style="margin-top: 15px"></div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      loadingPage: true,
      showLabelRequired: true,
      columns: [
        {
          label: "Label",
          field: "name",
          tdClass: "text-left"
        },
        {
          label: "Store",
          field: "storeName",
          tdClass: "text-left"
        },
        {
          label: "Status",
          field: "enabled",
          tdClass: "text-left",
          sortable: false
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter wrapping areas"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "name", type: "asc" }]
      },
      label: "",
      wrappingAreaId: 0,
      storeId: Options.user.storeId,
      stores: [],
      enabled: false,
      editMode: false,
      editModeMessage: false
    };
  },
  methods: {
    load: function(loadingPage) {
      this.spinnerIsLoading = true;
      this.loadingPage = loadingPage;
      this.$store
        .dispatch("retrieveStoreList")
        .then(retrieveStoreListResponse => {
          this.stores = retrieveStoreListResponse.data;
          let retrieveWrappingAreaListFilter = {
            filter: [],
            sort: [{ field: "label", direction: "asc" }]
          };
          this.$store
            .dispatch(
              "retrieveWrappingAreaList",
              retrieveWrappingAreaListFilter
            )
            .then(retrieveWrappingAreaListResponse => {
              this.rows = retrieveWrappingAreaListResponse.data;
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load(loadingPage);
                  }
                });
            })
            .finally(() => {
              this.spinnerIsLoading = false;
              this.loadingPage = false;
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load(loadingPage);
              }
            });
        });
    },
    loadWrappingAreaInfo: function(wrappingAreaId) {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveWrappingAreaInfo", wrappingAreaId)
        .then(retrieveWrappingAreaInfoResponse => {
          this.wrappingAreaId = retrieveWrappingAreaInfoResponse.id;
          this.label = retrieveWrappingAreaInfoResponse.name;
          this.storeId = retrieveWrappingAreaInfoResponse.storeId;
          this.enabled = retrieveWrappingAreaInfoResponse.enabled;
          this.editMode = true;
          this.checkForm();
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.loadWrappingAreaInfo(wrappingAreaId);
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    checkForm: function() {
      this.errorMessage = "";
      this.showLabelRequired = this.label.trim() === "";
    },
    cancel: function() {
      this.errorMessage = "";
      this.label = "";
      this.wrappingAreaId = 0;
      this.storeId = Options.user.storeId;
      this.enabled = false;
      this.editMode = false;
      this.checkForm();
    },
    createWrappingArea: function() {
      if (this.editMode) {
        this.spinnerIsLoading = true;
        let data = {
          id: this.wrappingAreaId,
          wrappingArea: {
            name: this.label.trim(),
            storeId: this.storeId,
            enabled: this.enabled
          }
        };
        this.$store
          .dispatch("updateWrappingAreaInfo", data)
          .then(updateWrappingAreaResponse => {
            if (updateWrappingAreaResponse.data.messageType === "Error") {
              this.errorMessage = updateWrappingAreaResponse.data.message;
            } else {
              this.errorMessage = "";
              this.label = "";
              this.showSuccessMessage = true;
              this.storeId = Options.user.storeId;
              this.editMode = false;
              this.editModeMessage = true;
              this.checkForm();
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                this.editModeMessage = false;
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createWrappingArea();
                }
              });
          })
          .finally(() => {
            this.load(false);
          });
      } else {
        this.spinnerIsLoading = true;
        let wrappingArea = {
          name: this.label.trim(),
          storeId: this.storeId,
          enabled: true
        };
        this.$store
          .dispatch("createWrappingArea", wrappingArea)
          .then(createWrappingAreaResponse => {
            if (createWrappingAreaResponse.data.messageType === "Error") {
              this.errorMessage = createWrappingAreaResponse.data.message;
            } else {
              this.errorMessage = "";
              this.label = "";
              this.showSuccessMessage = true;
              this.storeId = Options.user.storeId;
              this.checkForm();
              sleep(3000).then(() => {
                this.showSuccessMessage = false;
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createWrappingArea();
                }
              });
          })
          .finally(() => {
            this.load(false);
          });
      }
    }
  },
  created() {
    this.load(true);
  }
};
</script>