<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Dashboard</li>
      <li class="breadcrumb-item active">High Season</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Dashboard</h1>
    <!-- end page-header -->
    <panel title="High Season" bodyClass="p-t-0">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div style="margin-top: 25px">
        <label class="f-w-700">High Season</label>
        <i v-if="!seasonId" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
        <v-select
          :disabled="seasons.length == 0"
          :clearable="false"
          :options="seasons"
          placeholder="Select a Season"
          :reduce="label => label.id"
          v-model="seasonId"
          @input="loadSeasonData"
        ></v-select>
      </div>
      <div style="margin-top: 25px">
        <apexchart
          v-if="seasonId && series.length > 0"
          ref="chart"
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        />
      </div>
      <div v-if="seasonId && series.length > 0 && name !== ''">
        <label class="f-w-700 p-r-10">Status:</label>
        {{ name }}
        <br />
        <label class="f-w-700 p-r-10">Date:</label>
        {{ date }}
        <br />
        <label class="f-w-700 p-r-10">Number Of Orders:</label>
        {{ orders.length }}
        <br />
        <label class="f-w-700 p-r-10">Orders:</label>
        <br />
        <ul>
          <li v-for="order in orders" :value="order" :key="order">{{ order }}</li>
        </ul>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";

export default {
  data() {
    return {
      timer: null,
      destroyed: false,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      seasons: [],
      seasonId: null,
      orders: [],
      date: "",
      name: "",
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          stacked: false,
          events: {
            click: (event, chartContext, config) => {
              if (this.series[config.seriesIndex]) {
                let selectedDate = this.chartOptions.xaxis.categories[
                  config.dataPointIndex
                ];
                this.orders = [];
                this.date = "";
                this.name = "";
                let _this = this;

                this.series[config.seriesIndex].orders.forEach(function(order) {
                  if (order.day === selectedDate) {
                    _this.date = selectedDate;
                    _this.name = _this.series[config.seriesIndex].name;
                    order.orderDetails.forEach(function(details) {
                      _this.orders.push(
                        details.orderId +
                          (details.hasOwnProperty("description")
                            ? ": " + details.description
                            : "")
                      );
                    });
                  }
                });
              }
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: "High Season Orders Distribution",
          align: "center",
          margin: 10,
          offsetX: 10,
          offsetY: 320,
          floating: true,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        xaxis: {
          categories: [],
          position: "top",
          labels: {
            offsetY: -18,
            formatter: function(val) {
              return val;
            }
          }
        },
        yaxis: {
          title: {
            text: "Orders"
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " orders";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      },
      series: []
    };
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
    this.destroyed = true;
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;

      let filter = {
        filter: []
      };

      this.$store
        .dispatch("retrieveHighSeasonsList", filter)
        .then(response => {
          this.seasons = response;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    loadSeasonData: function(showSpinner) {
      if (this.destroyed) return;

      if (this.timer) clearTimeout(this.timer);
      let dateFrom = "";
      let dateTo = "";
      let _this = this;

      this.seasons.forEach(function(season) {
        if (season.id == _this.seasonId) {
          dateFrom = season.dateFrom.replace(" 00:00:00", "");
          dateTo = season.dateTo.replace(" 00:00:00", "");
        }
      });

      let dates = [];
      for (
        var d = new Date(dateFrom);
        d <= new Date(dateTo);
        d.setDate(d.getDate() + 1)
      ) {
        dates.push(this.$moment(d).format("MMM DD YYYY"));
      }

      let data = {
        fromDate: dateFrom,
        toDate: dateTo,
        storeId: Options.user.storeId
      };

      if (typeof showSpinner === "boolean") this.spinnerIsLoading = showSpinner;
      else this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveDashboardHighSeasonStatuses", data)
        .then(response => {
          this.chartOptions.xaxis.categories = dates;

          this.series = [];
          response.forEach(function(status) {
            let _status = {
              name: status.statusName,
              data: [],
              orders: status.orders
            };
            dates.forEach(function(date) {
              let _qty = 0;
              status.orders.forEach(function(order) {
                if (date === order.day) _qty = order.quantity;
              });
              _status.data.push(_qty);
            });
            _this.series.push(_status);
          });

          this.$nextTick(() => {
            if (this.$refs.chart)
              this.$refs.chart.updateOptions(
                this.chartOptions,
                true,
                true,
                true
              );
          });

          this.timer = setTimeout(() => this.loadSeasonData(false), 30000);
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.loadSeasonData();
                }
              });
          } else {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(
              () => this.loadSeasonData(this.spinnerIsLoading),
              10000
            );
          }
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    }
  },
  created() {
    this.load();
  }
};
</script>