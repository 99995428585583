<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Order Management</li>
      <li class="breadcrumb-item active">Purchase Order</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Purchase Order</h1>
    <!-- end page-header -->
    <panel title="Purchase Order" style="min-width: 400px">
      <loading loader="spinner" background-color="#000" :opacity="spinnerOpacity" :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel" :is-full-page="spinnerFullPage"></loading>
      <b-alert variant="success" :show="showSuccessMessage">
        <i class="fa fa-check fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">Order submitted successfully</p>
      </b-alert>
      <b-alert variant="danger" :show="errorMessage !== ''">
        <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">{{ errorMessage }}</p>
      </b-alert>
      <div v-if="!spinnerIsLoading && !showSuccessMessage">
        <div class="alert alert-monitor show sticky" v-if="!enableSubmitBtn">
          <span v-if="!invalidAccount" class="helper">
            <span v-if="invalidOrderInformation" v-scroll-to="{ el: '#order-information', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Order Information
            </span>
            <span v-else-if="invalidBillingAddress" v-scroll-to="{ el: '#billing-address', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Billing Address
            </span>
            <span v-else-if="invalidShippingAddress" v-scroll-to="{ el: '#shipping-address', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Delivery Address
            </span>
            <span v-else-if="invalidSpecialInstruction" v-scroll-to="{ el: '#special-instruction', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Special Instruction
            </span>
            <span v-else-if="invalidBilling" v-scroll-to="{ el: '#billing', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Currency &amp; Delivery
            </span>
            <span v-else-if="invalidDeliveryCost" v-scroll-to="{ el: '#delivery-cost', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Delivery Cost
            </span>
            <span v-else-if="invalidOrderProducts" v-scroll-to="{ el: '#order-products', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Order Products
            </span>
            <span v-else-if="invalidPaymentMethod" v-scroll-to="{ el: '#payment-method', offset: -60 }">
              <i class="fas fa-exclamation-circle m-r-10" style="color: #e83333"></i>Review: Payment Method
            </span>
          </span>
        </div>
        <div v-else class="alert alert-monitor show sticky-success">
          <button type="button" class="btn btn-success" @click="submitOrder" :disabled="!enableSubmitBtn">Submit
            Order</button>
        </div>
        <div id="account-information" class="card border-dark bg-light mb-3">
          <div class="card-header border-dark f-w-700 text-black">Account Information</div>
          <div class="col" style="max-width: 700px">
            <div class="card-body">
              <div style="margin-top: 5px">
                <label>Customer Name</label>
                <i v-if="!customer" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                <div class="alert alert-info fade show" v-if="customersCount >= this.customersLimit">Too many records
                  returned. Only the fisrt {{ customersLimit }} records are displayed</div>
                <v-select ref="customers" class="async-dropdown" placeholder="Search for customer"
                  @search="onCustomerSearch" @input="onCustomerSelect" :filterable="false" :options="customers"
                  v-model="customer">
                  <template slot="no-options">Type to search for customers</template>
                  <template #option="{ label, email }">
                    <span style="font-weight: 700">{{ label }}</span>
                    <span style="float: right">{{ email }}</span>
                  </template>
                </v-select>
                <a href="#" @click="navigateToCreateCustomerPage"
                  style="float: right; text-decoration: inherit; color: #00008b; cursor: pointer">create new
                  customer</a>
              </div>
              <div v-if="customer" style="margin-top: 25px">
                <label style="min-width: 150px">E-mail Address</label>
                <span class="f-w-700 f-s-14 text-black">{{ customer ? customer.email : "-" }}</span>
                <div class="col-md-12"></div>
                <label style="min-width: 150px">Date of Birth</label>
                <span class="f-w-700 f-s-14 text-black">{{ customer ? customer.dob : "-" }}</span>
                <div class="col-md-12"></div>
                <span v-if="customer.phones && customer.phones.length > 0">
                  <label style="min-width: 150px; font-weight: 700; margin-top: 20px">Phone Numbers</label>
                  <div v-for="(phone, index) in customer.phones" v-bind:key="index">
                    <label style="min-width: 150px">{{ phone.phoneType }}</label>
                    <span class="f-w-700 f-s-14 text-black">{{ phone.number }}</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="customer">
          <div id="order-information" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Order Information
              <i v-if="invalidOrderInformation" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div style="margin-top: 5px">
                  <label class="f-w-700">Delivery Date</label>
                  <i v-if="!orderDeliveryDate" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <date-picker v-model="orderDeliveryDate" :config="orderDeliveryDatePickerConfig"
                    placeholder="Select a delivery date"></date-picker>
                  <div>
                    <div class="radio radio-css radio-inline">
                      <input type="radio" id="before-15" name="deliverySlot" v-model="orderDeliverySlot" value="1" />
                      <label for="before-15">Before 3:00PM</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input type="radio" id="after-15" name="deliverySlot" v-model="orderDeliverySlot" value="2" />
                      <label for="after-15">After 3:00PM</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input type="radio" id="all-day" name="deliverySlot" v-model="orderDeliverySlot" value="3" />
                      <label for="all-day">All Day</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="billing-address" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Billing Address
              <i v-if="invalidBillingAddress" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body" style="padding-top: 0">
                <div v-if="billingAddresses.length > 0" style="margin-top: 25px">
                  <label>Billing Address (Select to load info)</label>
                  <v-select ref="billingAddresses" style="background-color: #ffffff"
                    placeholder="Select a billing address" :options="billingAddresses" v-model="selectedBillingAddress"
                    @input="billingAddressSelected"></v-select>
                  <loading loader="dots" background-color="#000" :opacity="0.1"
                    :active.sync="billingAddressSpinnerIsLoading" :can-cancel="false" :is-full-page="false"
                    :z-index="9999"></loading>
                </div>
                <div style="margin-top: 25px">
                  <label>Load Info By Phone Number</label>
                  <v-select ref="billingAddressesSearchByPhoneNumber" class="async-dropdown"
                    style="background-color: #ffffff" placeholder="Type a phone number" :filterable="false"
                    :options="billingAddressesByPhoneNumberSearch" v-model="selectedBillingAddressByPhoneNumberSearch"
                    @input="billingAddressSelectedByPhoneNumberSearch" @search="loadBillingAddessByPhoneNumberSearch">
                    <template slot="no-options">Type a phone number to search for addresses</template>
                    <template #option="{ labelName, addressLabel, city }">
                      <span style="font-weight: 700">{{ labelName }} ({{ addressLabel }})</span>
                      <span style="float: right">{{ city.label }}</span>
                    </template>
                  </v-select>
                  <loading loader="dots" background-color="#000" :opacity="0.1"
                    :active.sync="billingAddressSpinnerIsLoading" :can-cancel="false" :is-full-page="false"
                    :z-index="9999"></loading>
                </div>
                <div style="margin-top: 25px">
                  <label>Billing Name</label>
                  <i v-if="billingFirstName.trim() === '' || billingFamilyName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" class="form-control" placeholder="First Name" style="margin-bottom: 5px"
                    v-model="billingFirstName" @blur="billingFirstName = billingFirstName.trim()" @input="checkForm" />
                  <input type="text" class="form-control" placeholder="Family Name" style="margin-bottom: 5px"
                    v-model="billingFamilyName" @blur="billingFamilyName = billingFamilyName.trim()"
                    @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Company Name</label>
                  <input type="text" class="form-control" placeholder="Company Name" style="margin-bottom: 5px"
                    v-model="billingCompanyName" @blur="billingCompanyName = billingCompanyName.trim()"
                    @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Country</label>
                  <i v-if="!billingCountry" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="billingCountry" :options="countries" style="background-color: #ffffff"
                    placeholder="Select country" @input="billingCountrySelected" v-model="billingCountry"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Region</label>
                  <i v-if="!billingRegion && billingRegionName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input v-if="billingRegions.length == 0" type="text" class="form-control" placeholder="Region"
                    style="margin-bottom: 5px" v-model="billingRegionName"
                    @blur="billingRegionName = billingRegionName.trim()" @input="checkForm"
                    :disabled="!billingCountry || !billingRegionsLoaded" />
                  <v-select v-else ref="billingRegion" :options="billingRegions" style="background-color: #ffffff"
                    placeholder="Select region" @input="billingRegionSelected" v-model="billingRegion"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>City</label>
                  <i v-if="!billingCity && billingCityName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input v-if="billingCities.length == 0" type="text" class="form-control" placeholder="City"
                    style="margin-bottom: 5px" v-model="billingCityName"
                    @blur="billingCityName = billingCityName.trim()" @input="checkForm"
                    :disabled="(!billingRegion && billingRegionName.trim() === '') || !billingCitiesLoaded" />
                  <v-select v-else ref="billingCity" :options="billingCities" style="background-color: #ffffff"
                    placeholder="Select city" v-model="billingCity"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Street</label>
                  <input type="text" class="form-control" placeholder="Street" style="margin-bottom: 5px"
                    v-model="billingStreet" @blur="billingStreet = billingStreet.trim()" @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Landmark</label>
                  <input type="text" class="form-control" placeholder="Landmark" style="margin-bottom: 5px"
                    v-model="billingLandmark" @blur="billingLandmark = billingLandmark.trim()" @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Telephone</label>
                  <i v-if="billingTelephone.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" class="form-control" placeholder="Telephone" style="margin-bottom: 5px"
                    v-model="billingTelephone" @input="checkForm" />
                </div>
              </div>
            </div>
          </div>
          <div id="shipping-address" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Delivery Address
              <i v-if="invalidShippingAddress" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body" style="padding-top: 0">
                <div v-if="shippingAddresses.length > 0" style="margin-top: 25px">
                  <label>Delivery Address (Select to load info)</label>
                  <v-select ref="deliveryAddresses" style="background-color: #ffffff"
                    placeholder="Select a delivery address" :options="shippingAddresses"
                    v-model="selectedShippingAddress" @input="shippingAddressSelected"></v-select>
                  <loading loader="dots" background-color="#000" :opacity="0.1"
                    :active.sync="shippingAddressSpinnerIsLoading" :can-cancel="false" :is-full-page="false"
                    :z-index="9999"></loading>
                </div>
                <div style="margin-top: 25px">
                  <label>Load Info By Phone Number</label>
                  <v-select ref="shippingAddressesSearchByPhoneNumber" class="async-dropdown"
                    style="background-color: #ffffff" placeholder="Type a phone number" :filterable="false"
                    :options="shippingAddressesByPhoneNumberSearch" v-model="selectedShippingAddressByPhoneNumberSearch"
                    @input="shippingAddressSelectedByPhoneNumberSearch" @search="loadShippingAddessByPhoneNumberSearch">
                    <template slot="no-options">Type a phone number to search for addresses</template>
                    <template #option="{ labelName, addressLabel, city }">
                      <span style="font-weight: 700">{{ labelName }} ({{ addressLabel }})</span>
                      <span style="float: right">{{ city.label }}</span>
                    </template>
                  </v-select>
                  <loading loader="dots" background-color="#000" :opacity="0.1"
                    :active.sync="shippingAddressSpinnerIsLoading" :can-cancel="false" :is-full-page="false"
                    :z-index="9999"></loading>
                </div>
                <div style="margin-top: 25px">
                  <label>Recipient Name</label>
                  <i v-if="recipientFirstName.trim() === '' || recipientFamilyName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" class="form-control" placeholder="First Name" style="margin-bottom: 5px"
                    v-model="recipientFirstName" @blur="recipientFirstName = recipientFirstName.trim()"
                    @input="checkForm" />
                  <input type="text" class="form-control" placeholder="Family Name" style="margin-bottom: 5px"
                    v-model="recipientFamilyName" @blur="recipientFamilyName = recipientFamilyName.trim()"
                    @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Company Name</label>
                  <input type="text" class="form-control" placeholder="Company Name" style="margin-bottom: 5px"
                    v-model="shippingCompanyName" @blur="shippingCompanyName = shippingCompanyName.trim()"
                    @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Country</label>
                  <i v-if="!shippingCountry" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="shippingCountry" :options="countries" style="background-color: #ffffff"
                    placeholder="Select country" @input="shippingCountrySelected" v-model="shippingCountry"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Region</label>
                  <i v-if="!shippingRegion && shippingRegionName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input v-if="shippingRegions.length == 0" type="text" class="form-control" placeholder="Region"
                    style="margin-bottom: 5px" v-model="shippingRegionName"
                    @blur="shippingRegionName = shippingRegionName.trim()" @input="checkForm"
                    :disabled="!shippingCountry || !shippingRegionsLoaded" />
                  <v-select v-else ref="shippingRegion" :options="shippingRegions" style="background-color: #ffffff"
                    placeholder="Select region" @input="shippingRegionSelected" v-model="shippingRegion"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>City</label>
                  <i v-if="!shippingCity && shippingCityName.trim() === ''"
                    class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input v-if="shippingCities.length == 0" type="text" class="form-control" placeholder="City"
                    style="margin-bottom: 5px" v-model="shippingCityName"
                    @blur="shippingCityName = shippingCityName.trim()" @input="checkForm"
                    :disabled="(!shippingRegion && shippingRegionName.trim() === '') || !shippingCitiesLoaded" />
                  <v-select v-else ref="shippingCity" :options="shippingCities" style="background-color: #ffffff"
                    placeholder="Select city" v-model="shippingCity"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Street</label>
                  <input type="text" class="form-control" placeholder="Street" style="margin-bottom: 5px"
                    v-model="shippingStreet" @blur="shippingStreet = shippingStreet.trim()" @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Landmark</label>
                  <input type="text" class="form-control" placeholder="Landmark" style="margin-bottom: 5px"
                    v-model="shippingLandmark" @blur="shippingLandmark = shippingLandmark.trim()" @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Telephone</label>
                  <input type="text" class="form-control" placeholder="Telephone" style="margin-bottom: 5px"
                    v-model="shippingTelephone" @blur="shippingTelephone = shippingTelephone.trim()"
                    @input="checkForm" />
                </div>
              </div>
            </div>
          </div>
          <div id="special-instruction" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Special Instruction
              <i v-if="invalidSpecialInstruction" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div class="form-group">
                  <label>Delivery Special Instruction</label>
                  <select class="form-control m-b-10" v-model="deliverySpecialInstructionCode" @change="checkForm">
                    <option value="0" hidden selected v-if="deliverySpecialInstructionCode == 0">Select a Special
                      Instruction
                      Option</option>
                    <option v-else value="0" style="color: #806d00; font-weight: bold;">Clear Special Instruction
                    </option>
                    <option v-for="option in deliverySpecialInstructionOptions" :value="option.option"
                      :key="option.option">{{
        option.value }}</option>
                  </select>
                  <div v-if="deliverySpecialInstructionCode == 102">
                    <label class="f-w-700">Special Instruction Details</label>
                    <i v-if="specialInstructionMessage.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                    <textarea type="text" class="form-control" style="resize: none" rows="8"
                      placeholder="Special Instruction" v-model="specialInstructionMessage"
                      @blur="specialInstructionMessage = specialInstructionMessage.trim()" @keyup="checkForm" />
                  </div>
                  <div class="checkbox checkbox-css m-b-10">
                    <input type="checkbox" id="send-anonymously" v-model="isSendAnonymously" @change="checkForm" />
                    <label for="send-anonymously" style="cursor: pointer">Send anonymously</label>
                  </div>
                  <div class="checkbox checkbox-css m-b-25">
                    <input type="checkbox" id="surprise-the-recipient" v-model="isSurpriseTheRecipient"
                      @change="checkForm" />
                    <label for="surprise-the-recipient" style="cursor: pointer">Surprise the recipient</label>
                  </div>
                  <div v-if="isSurpriseTheRecipient">
                    <label class="f-w-700">Alternative Recipient (The person to contact)</label>
                    <br />
                    <label class="col-form-label f-w-700">Name</label>
                    <i v-if="alternativeRecipientName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                    <input type="text" class="form-control" placeholder="Name" v-model="alternativeRecipientName"
                      @blur="alternativeRecipientName = alternativeRecipientName.trim()" @keyup="checkForm" />
                    <label class="col-form-label f-w-700">Relation</label>
                    <input type="text" class="form-control" placeholder="Relation"
                      v-model="alternativeRecipientRelation"
                      @blur="alternativeRecipientRelation = alternativeRecipientRelation.trim()" @keyup="checkForm" />
                    <label class="col-form-label f-w-700">Phone</label>
                    <i v-if="alternativeRecipientPhone.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                    <input type="text" class="form-control" placeholder="Phone" v-model="alternativeRecipientPhone"
                      @blur="alternativeRecipientPhone = alternativeRecipientPhone.trim()" @input="checkForm" />
                  </div>
                  <div style="margin: 25px 0">
                    <label class="f-w-700">Internal Instruction</label>
                    <textarea placeholder="Internal Instruction" class="form-control" style="resize: none" rows="8"
                      v-model="internalInstructionMessage"
                      @blur="internalInstructionMessage = internalInstructionMessage.trim()" @input="checkForm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="billing" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Currency &amp; Delivery
              <i v-if="invalidBilling" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div style="margin-top: 5px">
                  <label>Order Currency</label>
                  <i v-if="!currency" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="currencies" :options="currencies" style="background-color: #ffffff"
                    placeholder="Select Currency" @input="checkForm" v-model="currency"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Delivery Fee</label>
                  <i v-if="shippingAndHandelingFees === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" ref="shippingAndHandelingFees" class="form-control" placeholder="Delivery Fee"
                    style="margin-bottom: 25px" v-model="shippingAndHandelingFees" @blur="/^\d+(\.\d{1,2})?$/.test(shippingAndHandelingFees.trim()) ? shippingAndHandelingFees = parseFloat(
        shippingAndHandelingFees.trim()
      ).toFixed(2) : shippingAndHandelingFees = shippingAndHandelingFees.trim()" @input="checkForm" />
                </div>
              </div>
            </div>
          </div>
          <div id="delivery-cost" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Delivery Cost
              <i v-if="invalidDeliveryCost" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div style="margin-top: 5px">
                  <label>Delivery Cost Currency</label>
                  <i v-if="!deliveryCostCurrency" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="currencies" :options="currencies" style="background-color: #ffffff"
                    placeholder="Select Currency" @input="checkForm" v-model="deliveryCostCurrency"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Delivery Cost</label>
                  <i v-if="deliveryCost === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" ref="deliveryCost" class="form-control" placeholder="Delivery Cost"
                    style="margin-bottom: 25px" v-model="deliveryCost" @blur="/^\d+(\.\d{1,2})?$/.test(deliveryCost.trim()) ? deliveryCost = parseFloat(
        deliveryCost.trim()
      ).toFixed(2) : deliveryCost = deliveryCost.trim()" @input="checkForm" />
                </div>
                <div style="margin-top: 25px">
                  <label>Vehicle Type</label>
                  <i v-if="!vehicleType" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="vehicleTypes" :options="vehicleTypes" style="background-color: #ffffff"
                    placeholder="Select Vehicle Type" @input="checkForm" v-model="vehicleType"></v-select>
                </div>
              </div>
            </div>
          </div>
          <div id="order-products" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Order Products
              <i v-if="invalidOrderProducts" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div v-for="(product, index) in products" v-bind:key="index" class="form-row">
                  <div class="col">
                    <input type="text" class="form-control" placeholder="Product Name" style="margin-bottom: 5px"
                      v-model="product.name" @blur="product.name = product.name.trim()" @keyup="checkForm" />
                    <input type="text" class="form-control" placeholder="Quantity"
                      :style="(product.name.trim() !== '' && product.styleQuantity) ? product.styleQuantity : 'margin-bottom: 5px'"
                      :disabled="product.name.trim() === ''" v-model="product.quantity" @input="checkForm" />
                    <input type="text" class="form-control" placeholder="From" style="margin-bottom: 5px"
                      :disabled="product.name.trim() === ''" v-model="product.from"
                      @blur="product.from = product.from.trim()" @input="checkForm" />
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" placeholder="Product Description" style="margin-bottom: 5px"
                      :disabled="product.name.trim() === ''" v-model="product.description"
                      @blur="product.description = product.description.trim()" @input="checkForm" />
                    <input type="text" class="form-control" placeholder="Unit Price"
                      :style="(product.name.trim() !== '' && product.styleAmount) ? product.styleAmount : 'margin-bottom: 5px'"
                      :disabled="product.name.trim() === ''" v-model="product.amount" @blur="/^\d+(\.\d{1,2})?$/.test(product.amount.trim()) ? product.amount = parseFloat(
        product.amount.trim()
      ).toFixed(2) : product.amount = product.amount.trim()" @input="checkForm" />
                    <input type="text" class="form-control" placeholder="To" style="margin-bottom: 5px"
                      :disabled="product.name.trim() === ''" v-model="product.to" @blur="product.to = product.to.trim()"
                      @input="checkForm" />
                  </div>
                  <div class="col-md-12">
                    <textarea class="form-control" placeholder="Message" style="margin-bottom: 5px; resize: none"
                      :disabled="product.name.trim() === ''" rows="8" v-model="product.message"
                      @blur="product.message = product.message.trim()" @input="checkForm" />
                  </div>
                  <div class="col-md-12">&nbsp;</div>
                </div>
                <div style="margin-top: 25px" v-if="totalAmount > 0 && currency">
                  <label>Total:</label>
                  <span class="f-w-700 m-l-5 f-s-18" style="float: right">{{ currency.label + " " +
        formatNumber(totalAmount)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="payment-method" class="card border-dark bg-light mb-3">
            <div class="card-header border-dark f-w-700 text-black">
              Payment Method
              <i v-if="invalidPaymentMethod" class="far fa-times-circle f-s-14 m-l-10" style="color: #e83333"></i>
            </div>
            <div class="col" style="max-width: 700px">
              <div class="card-body">
                <div style="margin-top: 5px">
                  <label>Payment Method</label>
                  <i v-if="!paymentMethod" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <v-select ref="paymentMethod" :options="paymentMethods" style="background-color: #ffffff"
                    placeholder="Select Payment Method" @input="checkForm" v-model="paymentMethod"></v-select>
                </div>
                <div style="margin-top: 25px">
                  <label>Payment Details</label>
                  <i v-if="paymentDetails === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
                  <input type="text" ref="paymentDetails" class="form-control" placeholder="Payment Details"
                    style="margin-bottom: 25px" v-model="paymentDetails" @blur="paymentDetails = paymentDetails.trim()"
                    @input="checkForm" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<style>
::-webkit-input-placeholder {
  /* Edge */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

.async-dropdown .vs__open-indicator {
  display: none;
}

.async-dropdown {
  background-color: #ffffff;
}

div.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 13px;
  z-index: 1061;
  display: inline-block;
}

div.sticky-success {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 8px;
  z-index: 1061;
  display: inline-block;
}

.helper {
  margin-right: 15px;
}

.helper>span {
  font-weight: 700;
  display: inline-block !important;
  padding: 2px 5px;
  border-radius: 0.25rem;
  border: 1px solid #9c0006;
  color: #9c0006;
  background-color: #ffc7ce;
  cursor: pointer;
}

.panel-expand .helper>span {
  cursor: default;
}

.alert-monitor {
  margin: 0 0 15px 0 !important;
  padding: 0 !important;
}

.panel-expand div.sticky {
  position: fixed;
  top: 8px;
  right: 25px;
}

.panel-expand div.sticky-success {
  position: fixed;
  top: 2px;
  right: 40px;
}

.panel-heading-btn {
  z-index: 1062;
}

@media (max-width: 767px) {
  div.sticky {
    top: 63px;
    z-index: 1009;
    display: inline-block;
  }

  div.sticky-success {
    top: 60px;
    z-index: 1009;
    display: inline-block;
  }

  .panel-expand div.sticky,
  .panel-expand div.sticky-success {
    z-index: 1060;
  }

  .header {
    z-index: 1008 !important;
  }
}
</style>
<script>
import Options from "@/conf/Options";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      roleId: Options.user.roleId,
      storeId: Options.user.storeId,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      billingAddressSpinnerIsLoading: false,
      shippingAddressSpinnerIsLoading: false,
      showSuccessMessage: false,
      errorMessage: "",
      orderDeliveryDatePickerConfig: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: false,
        maxDate: false,
        showTodayButton: true
      },
      debounceCustomerList: null,
      searchCustomerInProgress: false,
      searchAddressesInProgress: false,
      orderDeliveryDate: null,
      orderDeliverySlot: "3",
      customers: [],
      customersCount: 0,
      customersLimit: 50,
      customer: null,
      customerTypeId: null,
      selectedBillingAddress: null,
      selectedBillingAddressByPhoneNumberSearch: null,
      selectedShippingAddress: null,
      selectedShippingAddressByPhoneNumberSearch: null,
      billingAddresses: [],
      billingAddressesByPhoneNumberSearch: [],
      shippingAddresses: [],
      shippingAddressesByPhoneNumberSearch: [],
      billingFirstName: "",
      billingFamilyName: "",
      billingCompanyName: "",
      billingRegionName: "",
      billingCityName: "",
      billingStreet: "",
      billingLandmark: "",
      billingTelephone: "",
      recipientFirstName: "",
      recipientFamilyName: "",
      shippingCompanyName: "",
      shippingCountryName: "",
      shippingRegionName: "",
      shippingCityName: "",
      shippingStreet: "",
      shippingLandmark: "",
      shippingTelephone: "",
      deliverySpecialInstruction: "",
      deliverySpecialInstructionCode: 0,
      deliverySpecialInstructionOptions: [],
      specialInstructionMessage: "",
      isSendAnonymously: false,
      isSurpriseTheRecipient: false,
      alternativeRecipientName: "",
      alternativeRecipientRelation: "",
      alternativeRecipientPhone: "",
      internalInstructionMessage: "",
      products: [
        {
          name: "",
          description: "",
          quantity: "",
          amount: "",
          from: "",
          to: "",
          message: ""
        }
      ],
      currencies: [],
      currency: null,
      deliveryCostCurrency: { id: 2, label: "LBP" },
      deliveryCost: '',
      vehicleTypes: [],
      vehicleType: null,
      countries: [],
      billingCountry: null,
      billingRegions: [],
      billingRegion: null,
      billingRegionsLoaded: false,
      billingCities: [],
      billingCity: null,
      billingCitiesLoaded: false,
      shippingCountry: null,
      shippingRegions: [],
      shippingRegion: null,
      shippingRegionsLoaded: false,
      shippingCities: [],
      shippingCity: null,
      shippingCitiesLoaded: false,
      shippingAndHandelingFees: "",
      totalAmount: 0,
      paymentMethod: null,
      paymentMethods: [],
      paymentDetails: ""
    };
  },
  computed: {
    validEmail: function () {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.trim()
      );
    },
    invalidAccount: function () {
      return !this.customer;
    },
    invalidOrderInformation: function () {
      return !this.orderDeliveryDate;
    },
    invalidBillingAddress: function () {
      return (
        this.billingFirstName.trim() === "" ||
        this.billingFamilyName.trim() === "" ||
        !this.billingCountry ||
        (!this.billingRegion && this.billingRegionName.trim() === "") ||
        (!this.billingCity && this.billingCityName.trim() === "") ||
        this.billingTelephone.trim() === ""
      );
    },
    invalidShippingAddress: function () {
      return (
        this.recipientFirstName.trim() === "" ||
        this.recipientFamilyName.trim() === "" ||
        !this.shippingCountry ||
        (!this.shippingRegion && this.shippingRegionName.trim() === "") ||
        (!this.shippingCity && this.shippingCityName.trim() === "")
      );
    },
    invalidSpecialInstruction: function () {
      return (
        (this.isSurpriseTheRecipient &&
          (this.alternativeRecipientName.trim() === "" ||
            this.alternativeRecipientPhone.trim() === "")) ||
        (this.deliverySpecialInstructionCode == 102 &&
          this.specialInstructionMessage.trim() === "")
      );
    },
    invalidOrderProducts: function () {
      let valid = true;

      if (this.products.length < 2) valid = false;

      if (valid)
        this.products.forEach(function (product) {
          if (product.name !== "") {
            if (product.quantity === "") valid = false;

            if (!/^\d+(\.\d{1,2})?$/.test(product.amount.trim())) valid = false;
          }
        });
      return !valid;
    },
    invalidBilling: function () {
      return (
        !this.currency ||
        this.shippingAndHandelingFees.trim() === "" ||
        !/^\d+(\.\d{1,2})?$/.test(this.shippingAndHandelingFees.trim())
      );
    },
    invalidDeliveryCost: function () {
      return (
        !this.deliveryCostCurrency ||
        !this.vehicleType ||
        this.deliveryCost.trim() === "" ||
        !/^\d+(\.\d{1,2})?$/.test(this.deliveryCost.trim())
      );
    },
    invalidPaymentMethod: function () {
      return (this.paymentMethod == null || this.paymentDetails.trim() === '');
    },
    enableSubmitBtn: function () {
      return (
        !this.invalidAccount &&
        !this.invalidOrderInformation &&
        !this.invalidBillingAddress &&
        !this.invalidShippingAddress &&
        !this.invalidSpecialInstruction &&
        !this.invalidOrderProducts &&
        !this.invalidBilling &&
        !this.invalidPaymentMethod &&
        !this.invalidDeliveryCost
      );
    }
  },
  watch: {
    selectedBillingAddressByPhoneNumberSearch(value) {
      if (value) {
        this.selectedBillingAddress = null;
      }
      else {
        this.billingAddressesByPhoneNumberSearch = [];
      }
    },
    selectedBillingAddress(value) {
      if (value) {
        this.billingAddressesByPhoneNumberSearch = [];
        this.selectedBillingAddressByPhoneNumberSearch = null;
      }
    },
    selectedShippingAddressByPhoneNumberSearch(value) {
      if (value) {
        this.selectedShippingAddress = null;
      }
      else {
        this.shippingAddressesByPhoneNumberSearch = [];
      }
    },
    selectedShippingAddress(value) {
      if (value) {
        this.shippingAddressesByPhoneNumberSearch = [];
        this.selectedShippingAddressByPhoneNumberSearch = null;
      }
    }
  },
  methods: {
    formatNumber: function (value) {
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    navigateToCreateCustomerPage: function () {
      let routeData = this.$router.resolve({
        name: "customerManagementCustomer"
      });
      window.open(routeData.href, "_self");
    },
    load() {
      let _this = this;

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveSpecialInstructionOptionList")
        .then(responseRetrieveSpecialInstructionOptionList => {
          this.deliverySpecialInstructionOptions = responseRetrieveSpecialInstructionOptionList;

          this.$store
            .dispatch("retrieveCountryList")
            .then(responseRetrieveCountryList => {
              responseRetrieveCountryList.forEach(function (country) {
                _this.countries.push({
                  id: country.id,
                  label: country.name
                });
              });


              this.$store
                .dispatch("retrieveCurrencyList")
                .then(responseRetrieveCurrencyList => {
                  responseRetrieveCurrencyList.forEach(function (currency) {
                    _this.currencies.push({
                      id: currency.id,
                      label: currency.name
                    });
                  });
                });

              this.$store
                .dispatch("retrieveVehicleTypeList")
                .then(responseRetrieveVehicleTypeList => {
                  responseRetrieveVehicleTypeList.forEach(function (vehicleType) {
                    _this.vehicleTypes.push({
                      id: vehicleType.id,
                      label: vehicleType.name
                    });
                  });
                });

              this.$store
                .dispatch("retrievePaymentMethodList")
                .then(responseRetrievePaymentMethodList => {
                  responseRetrievePaymentMethodList.forEach(function (paymentMethod) {
                    _this.paymentMethods.push({
                      id: paymentMethod.id,
                      label: paymentMethod.name
                    });
                  });
                })
                .catch(error => {
                  let titleTxt = "Error";
                  let msgTxt = error.message;

                  if (
                    error.message &&
                    error.message === "Request failed with status code 403"
                  ) {
                    titleTxt = "Access Denied";
                    msgTxt = "Please call Exotica administration for help";
                  }
                  this.spinnerIsLoading = false;
                  this.$swal
                    .fire({
                      type: "error",
                      title: titleTxt,
                      text: msgTxt,
                      showCancelButton: true,
                      allowOutsideClick: false,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Retry"
                    })
                    .then(result => {
                      if (result.value) {
                        this.load();
                      }
                    });
                })
                .finally(() => {
                  this.spinnerIsLoading = false;
                });
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    onCustomerSearch: function (search, loading) {
      clearTimeout(this.debounceCustomerList);
      if (search.trim() !== "") {
        this.debounceCustomerList = setTimeout(() => {
          this.searchCustomer(loading, search.trim());
        }, 600);
      } else this.customers = [];
    },
    onCustomerSelect: function () {
      this.customersCount = 0;
      this.selectedBillingAddress = null;
      this.selectedShippingAddress = null;
      this.billingAddresses = [];
      this.shippingAddresses = [];
      this.billingFirstName = "";
      this.billingFamilyName = "";
      this.billingCompanyName = "";
      this.billingRegionName = "";
      this.billingCityName = "";
      this.billingStreet = "";
      this.billingLandmark = "";
      this.billingTelephone = "";
      this.recipientFirstName = "";
      this.recipientFamilyName = "";
      this.shippingCompanyName = "";
      this.shippingCountryName = "";
      this.shippingRegionName = "";
      this.shippingCityName = "";
      this.shippingStreet = "";
      this.shippingLandmark = "";
      this.shippingTelephone = "";
      this.billingCountry = null;
      this.billingRegions = [];
      this.billingRegion = null;
      this.billingRegionsLoaded = false;
      this.billingCities = [];
      this.billingCity = null;
      this.billingCitiesLoaded = false;
      this.shippingCountry = null;
      this.shippingRegions = [];
      this.shippingRegion = null;
      this.shippingRegionsLoaded = false;
      this.shippingCities = [];
      this.shippingCity = null;
      this.shippingCitiesLoaded = false;
      this.selectedBillingAddressByPhoneNumberSearch = null;
      this.selectedShippingAddressByPhoneNumberSearch = null;
      this.billingAddressesByPhoneNumberSearch = [];
      this.shippingAddressesByPhoneNumberSearch = [];
      this.clearBillingAddress();
      this.clearShippingAddress();
      this.orderDeliveryDate = null;
      this.orderDeliverySlot = "3";

      let _this = this;

      if (this.customer) {
        let customerData = {
          customerId: this.customer.id,
          typeId: this.customer.typeId
        };

        this.$store
          .dispatch("retrieveCustomer", customerData)
          .then(retrieveCustomerResponse => {
            if (retrieveCustomerResponse.data.addresses) {
              retrieveCustomerResponse.data.addresses.forEach(function (
                address
              ) {
                if (address.addressTypeId == 1) {
                  // Billing Address
                  let addressVO = {
                    id: address.id,
                    invalidAddress: false,
                    label: address.label,
                    firstName: address.firstName,
                    familyName: address.lastName,
                    companyName: "",
                    regionName: "",
                    cityName: "",
                    street: "",
                    landmark: "",
                    telephone: address.telephone,
                    country: null,
                    regions: [],
                    region: null,
                    regionsLoaded: false,
                    cities: [],
                    city: null,
                    citiesLoaded: false
                  };

                  if (address.countryId)
                    addressVO.country = {
                      id: address.countryId,
                      label: address.country
                    };

                  if (address.regionId)
                    addressVO.region = {
                      id: address.regionId,
                      label: address.region
                    };
                  else if (address.region)
                    addressVO.regionName = address.region;

                  if (address.cityId)
                    addressVO.city = {
                      id: address.cityId,
                      label: address.city
                    };
                  else if (address.city) addressVO.cityName = address.city;

                  if (address.company) addressVO.companyName = address.company;

                  if (address.township) addressVO.landmark = address.township;

                  if (address.street && address.street.length > 0)
                    addressVO.street = address.street[0];

                  _this.billingAddresses.push(addressVO);
                }

                if (address.addressTypeId == 2) {
                  // Shipping Address
                  let addressVO = {
                    id: address.id,
                    invalidAddress: false,
                    label: address.label,
                    firstName: address.firstName,
                    familyName: address.lastName,
                    companyName: "",
                    regionName: "",
                    cityName: "",
                    street: "",
                    landmark: "",
                    telephone: "",
                    country: null,
                    regions: [],
                    region: null,
                    regionsLoaded: false,
                    cities: [],
                    city: null,
                    citiesLoaded: false
                  };

                  if (address.telephone)
                    addressVO.telephone = address.telephone;

                  if (address.countryId)
                    addressVO.country = {
                      id: address.countryId,
                      label: address.country
                    };

                  if (address.regionId)
                    addressVO.region = {
                      id: address.regionId,
                      label: address.region
                    };
                  else if (address.region)
                    addressVO.regionName = address.region;

                  if (address.cityId)
                    addressVO.city = {
                      id: address.cityId,
                      label: address.city
                    };
                  else if (address.city) addressVO.cityName = address.city;

                  if (address.company) addressVO.companyName = address.company;

                  if (address.township) addressVO.landmark = address.township;

                  if (address.street && address.street.length > 0)
                    addressVO.street = address.street[0];

                  _this.shippingAddresses.push(addressVO);
                }
              });
            }
          });
      }
    },
    searchCustomer: function (loading, search) {
      if (this.searchCustomerInProgress) return;

      // prepare filter
      let keywords = search.trim().split(" ");
      let filter = [];
      for (var i = 0; i < keywords.length; i++) {
        let searchGroup = [];

        let firstName = {
          field: "firstName",
          value: "",
          condition: "contains"
        };

        let familyName = {
          field: "lastName",
          value: "",
          condition: "contains"
        };

        let email = {
          field: "email",
          value: "",
          condition: "contains"
        };

        let phone = {
          field: "phoneNumber",
          value: "",
          condition: "eq"
        };

        let keyword = keywords[i].trim();

        if (keyword !== "") {
          firstName.value = keyword;
          searchGroup.push(firstName);

          familyName.value = keyword;
          searchGroup.push(familyName);

          email.value = keyword;
          searchGroup.push(email);

          phone.value = keyword;
          searchGroup.push(phone);

          filter.push(searchGroup);
          filter.push([
            {
              field: "enabled",
              value: "true",
              condition: "equals"
            }
          ]);
        }
      }

      let data = {
        typeUrl: "/type/1",
        filter: {
          offset: 0,
          limit: this.customersLimit,
          filter: filter,
          sort: [
            { field: "firstName", direction: "asc" },
            { field: "lastName", direction: "asc" }
          ]
        }
      };

      let _this = this;
      this.customersCount = 0;
      loading(true);
      this.searchCustomerInProgress = true;
      this.$store
        .dispatch("retrieveCustomerList", data)
        .then(response => {
          _this.customers = [];
          _this.customersCount = response.data.totalCount;
          response.data.customers.forEach(function (customer) {
            let _customer = {
              id: customer.id,
              label: customer.firstName.trim() + " " + customer.lastName.trim(),
              typeId: customer.typeId,
              email: customer.email ? customer.email : "-",
              dob: customer.dateOfBirth ? _this.$moment(customer.dateOfBirth).format("YYYY-MM-DD") : "-",
              phones: customer.phones
            };

            _this.customers.push(_customer);
          });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.searchCustomer(loading, search);
              }
            });
        })
        .finally(() => {
          loading(false);
          this.searchCustomerInProgress = false;
        });
    },
    searchAddresses: function (loading, search, addressType) {
      if (this.searchAddressesInProgress) return;

      let phoneNumber = search.replace(/[^\d]/g, "");

      if (phoneNumber.length < 8) return;

      let _this = this;
      loading(true);
      this.searchAddressesInProgress = true;
      this.$store
        .dispatch("retrieveAddressesByPhoneNumber", phoneNumber)
        .then(response => {
          if (addressType === 1) {
            _this.billingAddressesByPhoneNumberSearch = [];
          } else if (addressType === 2) {
            _this.shippingAddressesByPhoneNumberSearch = [];
          }


          response.data.forEach(function (address) {
            let _address = {
              firstName: address.firstName,
              familyName: address.lastName,
              label: address.telephone,
              labelName: address.firstName.trim() + " " + address.lastName.trim(),
              addressLabel: address.label.trim(),
              country: { id: address.countryId, label: address.country },
              region: { id: address.regionId, label: address.region },
              city: { id: address.cityId, label: address.city },
              companyName: address.companyName,
              telephone: address.telephone,
              street: address.street,
              customerId: address.customerId,
              landmark: address.landmark,
            };

            if (addressType === 1) {
              _this.billingAddressesByPhoneNumberSearch.push(_address);
            } else if (addressType === 2) {
              _this.shippingAddressesByPhoneNumberSearch.push(_address);
            }


          });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.searchAddresses(loading, search, addressType);
              }
            });
        })
        .finally(() => {
          loading(false);
          this.searchAddressesInProgress = false;
        });
    },
    billingCountrySelected: function () {
      this.billingRegions = [];
      this.billingRegionsLoaded = false;
      this.billingRegion = null;
      this.billingRegionName = "";

      this.billingCities = [];
      this.billingCitiesLoaded = false;
      this.billingCity = null;
      this.billingCityName = "";

      if (this.billingCountry) {
        let _this = this;
        this.$store
          .dispatch("retrieveRegionList", this.billingCountry.id)
          .then(responseRegionList => {
            responseRegionList.forEach(function (region) {
              _this.billingRegions.push({
                id: region.id,
                label: region.name
              });
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }
            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.billingCountrySelected();
                }
              });
          })
          .finally(() => {
            this.billingRegionsLoaded = true;
          });
      }
    },
    billingRegionSelected: function () {
      this.billingCities = [];
      this.billingCitiesLoaded = false;
      this.billingCity = null;
      this.billingCityName = "";

      if (this.billingRegion) {
        let _this = this;
        this.$store
          .dispatch("retrieveCityList", this.billingRegion.id)
          .then(responseCityList => {
            responseCityList.forEach(function (city) {
              _this.billingCities.push({
                id: city.id,
                label: city.name
              });
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }
            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.billingRegionSelected();
                }
              });
          })
          .finally(() => {
            this.billingCitiesLoaded = true;
          });
      }
    },
    shippingCountrySelected: function () {
      this.shippingRegions = [];
      this.shippingRegionsLoaded = false;
      this.shippingRegion = null;
      this.shippingRegionName = "";

      this.shippingCities = [];
      this.shippingCitiesLoaded = false;
      this.shippingCity = null;
      this.shippingCityName = "";

      if (this.shippingCountry) {
        let _this = this;
        this.$store
          .dispatch("retrieveRegionList", this.shippingCountry.id)
          .then(responseRegionList => {
            responseRegionList.forEach(function (region) {
              _this.shippingRegions.push({
                id: region.id,
                label: region.name
              });
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }
            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.shippingCountrySelected();
                }
              });
          })
          .finally(() => {
            this.shippingRegionsLoaded = true;
          });
      }
    },
    shippingRegionSelected: function () {
      this.shippingCities = [];
      this.shippingCitiesLoaded = false;
      this.shippingCity = null;
      this.shippingCityName = "";

      if (this.shippingRegion) {
        let _this = this;
        this.$store
          .dispatch("retrieveCityList", this.shippingRegion.id)
          .then(responseCityList => {
            responseCityList.forEach(function (city) {
              _this.shippingCities.push({
                id: city.id,
                label: city.name
              });
            });
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }
            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.shippingRegionSelected();
                }
              });
          })
          .finally(() => {
            this.shippingCitiesLoaded = true;
          });
      }
    },
    billingAddressSelected: function () {
      let _this = this;

      _this.clearBillingAddress();

      if (this.selectedBillingAddress) {
        this.billingAddressSpinnerIsLoading = true;
        if (this.selectedBillingAddress.firstName)
          this.billingFirstName = this.selectedBillingAddress.firstName;
        else this.billingFirstName = "";

        if (this.selectedBillingAddress.familyName)
          this.billingFamilyName = this.selectedBillingAddress.familyName;
        else this.billingFamilyName = "";

        if (this.selectedBillingAddress.companyName)
          this.billingCompanyName = this.selectedBillingAddress.companyName;
        else this.billingCompanyName = "";

        if (this.selectedBillingAddress.street)
          this.billingStreet = this.selectedBillingAddress.street;
        else this.billingStreet = "";

        if (this.selectedBillingAddress.landmark)
          this.billingLandmark = this.selectedBillingAddress.landmark;
        else this.billingLandmark = "";

        if (this.selectedBillingAddress.telephone)
          this.billingTelephone = this.selectedBillingAddress.telephone;
        else this.billingTelephone = "";

        // reset country
        this.billingCountry = null;

        // reset region
        this.billingRegions = [];
        this.billingRegionsLoaded = false;
        this.billingRegion = null;
        this.billingRegionName = "";

        // reset city
        this.billingCities = [];
        this.billingCitiesLoaded = false;
        this.billingCity = null;
        this.billingCityName = "";

        if (this.selectedBillingAddress.country) {
          this.billingCountry = this.selectedBillingAddress.country;

          this.$store
            .dispatch("retrieveRegionList", this.billingCountry.id)
            .then(responseRegionList => {
              responseRegionList.forEach(function (region) {
                _this.billingRegions.push({
                  id: region.id,
                  label: region.name
                });
              });

              if (this.selectedBillingAddress.region) {
                this.billingRegion = this.selectedBillingAddress.region;
                this.$store
                  .dispatch(
                    "retrieveCityList",
                    this.selectedBillingAddress.region.id
                  )
                  .then(responseCityList => {
                    // populate city list
                    responseCityList.forEach(function (city) {
                      _this.billingCities.push({
                        id: city.id,
                        label: city.name
                      });
                    });

                    if (this.selectedBillingAddress.city)
                      this.billingCity = this.selectedBillingAddress.city;
                    else if (this.selectedBillingAddress.cityName)
                      this.billingCityName = this.selectedBillingAddress.cityName;
                  })
                  .finally(() => {
                    this.billingCitiesLoaded = true;
                    this.billingAddressSpinnerIsLoading = false;
                  });
              } else if (this.selectedBillingAddress.regionName) {
                this.billingRegionName = this.selectedBillingAddress.regionName;
                this.billingAddressSpinnerIsLoading = false;
              }
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.billingAddressSelected();
                  }
                });
            })
            .finally(() => {
              this.billingRegionsLoaded = true;
            });
        } else this.billingAddressSpinnerIsLoading = false;
      }
    },
    billingAddressSelectedByPhoneNumberSearch: function () {
      let _this = this;

      _this.clearBillingAddress();

      if (this.selectedBillingAddressByPhoneNumberSearch) {
        this.billingAddressSpinnerIsLoading = true;
        if (this.selectedBillingAddressByPhoneNumberSearch.firstName)
          this.billingFirstName = this.selectedBillingAddressByPhoneNumberSearch.firstName;
        else this.billingFirstName = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.familyName)
          this.billingFamilyName = this.selectedBillingAddressByPhoneNumberSearch.familyName;
        else this.billingFamilyName = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.companyName)
          this.billingCompanyName = this.selectedBillingAddressByPhoneNumberSearch.companyName;
        else this.billingCompanyName = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.street)
          this.billingStreet = this.selectedBillingAddressByPhoneNumberSearch.street;
        else this.billingStreet = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.landmark)
          this.billingLandmark = this.selectedBillingAddressByPhoneNumberSearch.landmark;
        else this.billingLandmark = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.telephone)
          this.billingTelephone = this.selectedBillingAddressByPhoneNumberSearch.telephone;
        else this.billingTelephone = "";

        // reset country
        this.billingCountry = null;

        // reset region
        this.billingRegions = [];
        this.billingRegionsLoaded = false;
        this.billingRegion = null;
        this.billingRegionName = "";

        // reset city
        this.billingCities = [];
        this.billingCitiesLoaded = false;
        this.billingCity = null;
        this.billingCityName = "";

        if (this.selectedBillingAddressByPhoneNumberSearch.country) {
          this.billingCountry = this.selectedBillingAddressByPhoneNumberSearch.country;

          this.$store
            .dispatch("retrieveRegionList", this.billingCountry.id)
            .then(responseRegionList => {
              responseRegionList.forEach(function (region) {
                _this.billingRegions.push({
                  id: region.id,
                  label: region.name
                });
              });

              if (this.selectedBillingAddressByPhoneNumberSearch.region.id !== 0) {
                this.billingRegion = this.selectedBillingAddressByPhoneNumberSearch.region;
                this.$store
                  .dispatch(
                    "retrieveCityList",
                    this.selectedBillingAddressByPhoneNumberSearch.region.id
                  )
                  .then(responseCityList => {
                    // populate city list
                    responseCityList.forEach(function (city) {
                      _this.billingCities.push({
                        id: city.id,
                        label: city.name
                      });
                    });

                    if (this.selectedBillingAddressByPhoneNumberSearch.city.id !== 0)
                      this.billingCity = this.selectedBillingAddressByPhoneNumberSearch.city;
                    else if (this.selectedBillingAddressByPhoneNumberSearch.city.label)
                      this.billingCityName = this.selectedBillingAddressByPhoneNumberSearch.city.label;

                  })
                  .finally(() => {
                    this.billingCitiesLoaded = true;
                    this.billingAddressSpinnerIsLoading = false;
                  });
              } else if (this.selectedBillingAddressByPhoneNumberSearch.region.label) {
                this.billingRegionName = this.selectedBillingAddressByPhoneNumberSearch.region.label;
                if (this.selectedBillingAddressByPhoneNumberSearch.city.label) {
                  this.billingCityName = this.selectedBillingAddressByPhoneNumberSearch.city.label;
                  this.billingCitiesLoaded = true;
                }
                this.billingAddressSpinnerIsLoading = false;
              }
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.billingAddressSelectedByPhoneNumberSearch();
                  }
                });
            })
            .finally(() => {
              this.billingRegionsLoaded = true;
            });
        } else this.billingAddressSpinnerIsLoading = false;
      }
    },
    loadBillingAddessByPhoneNumberSearch: function (search, loading) {
      clearTimeout(this.debounceLoadBillingAddessByPhoneNumberSearch);
      if (search.trim() !== "") {
        this.debounceLoadBillingAddessByPhoneNumberSearch = setTimeout(() => {
          this.searchAddresses(loading, search.trim(), 1);
        }, 600);
      } else if (!this.selectedBillingAddressByPhoneNumberSearch) this.billingAddressesByPhoneNumberSearch = [];
    },
    shippingAddressSelected: function () {
      let _this = this;

      if (this.selectedShippingAddress) {
        this.shippingAddressSpinnerIsLoading = true;
        if (this.selectedShippingAddress.firstName)
          this.recipientFirstName = this.selectedShippingAddress.firstName;
        else this.recipientFirstName = "";

        if (this.selectedShippingAddress.familyName)
          this.recipientFamilyName = this.selectedShippingAddress.familyName;
        else this.recipientFamilyName = "";

        if (this.selectedShippingAddress.companyName)
          this.shippingCompanyName = this.selectedShippingAddress.companyName;
        else this.shippingCompanyName = "";

        if (this.selectedShippingAddress.street)
          this.shippingStreet = this.selectedShippingAddress.street;
        else this.shippingStreet = "";

        if (this.selectedShippingAddress.landmark)
          this.shippingLandmark = this.selectedShippingAddress.landmark;
        else this.shippingLandmark = "";

        if (this.selectedShippingAddress.telephone)
          this.shippingTelephone = this.selectedShippingAddress.telephone;
        else this.shippingTelephone = "";

        // reset country
        this.shippingCountry = null;

        // reset region
        this.shippingRegions = [];
        this.shippingRegionsLoaded = false;
        this.shippingRegion = null;
        this.shippingRegionName = "";

        // reset city
        this.shippingCities = [];
        this.shippingCitiesLoaded = false;
        this.shippingCity = null;
        this.shippingCityName = "";

        if (this.selectedShippingAddress.country) {
          this.shippingCountry = this.selectedShippingAddress.country;

          this.$store
            .dispatch("retrieveRegionList", this.shippingCountry.id)
            .then(responseRegionList => {
              responseRegionList.forEach(function (region) {
                _this.shippingRegions.push({
                  id: region.id,
                  label: region.name
                });
              });

              if (this.selectedShippingAddress.region) {
                this.shippingRegion = this.selectedShippingAddress.region;
                this.$store
                  .dispatch(
                    "retrieveCityList",
                    this.selectedShippingAddress.region.id
                  )
                  .then(responseCityList => {
                    // populate city list
                    responseCityList.forEach(function (city) {
                      _this.shippingCities.push({
                        id: city.id,
                        label: city.name
                      });
                    });

                    if (this.selectedShippingAddress.city)
                      this.shippingCity = this.selectedShippingAddress.city;
                    else if (this.selectedShippingAddress.cityName)
                      this.shippingCityName = this.selectedShippingAddress.cityName;
                  })
                  .finally(() => {
                    this.shippingCitiesLoaded = true;
                    this.shippingAddressSpinnerIsLoading = false;
                  });
              } else if (this.selectedShippingAddress.regionName) {
                this.shippingRegionName = this.selectedShippingAddress.regionName;
                this.shippingAddressSpinnerIsLoading = false;
              }
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.shippingAddressSelected();
                  }
                });
            })
            .finally(() => {
              this.shippingRegionsLoaded = true;
            });
        } else this.shippingAddressSpinnerIsLoading = false;
      }
    },
    shippingAddressSelectedByPhoneNumberSearch: function () {
      let _this = this;

      _this.clearShippingAddress();

      if (this.selectedShippingAddressByPhoneNumberSearch) {
        this.shippingAddressSpinnerIsLoading = true;
        if (this.selectedShippingAddressByPhoneNumberSearch.firstName)
          this.recipientFirstName = this.selectedShippingAddressByPhoneNumberSearch.firstName;
        else this.recipientFirstName = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.familyName)
          this.recipientFamilyName = this.selectedShippingAddressByPhoneNumberSearch.familyName;
        else this.recipientFamilyName = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.companyName)
          this.shippingCompanyName = this.selectedShippingAddressByPhoneNumberSearch.companyName;
        else this.shippingCompanyName = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.street)
          this.shippingStreet = this.selectedShippingAddressByPhoneNumberSearch.street;
        else this.shippingStreet = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.landmark)
          this.shippingLandmark = this.selectedShippingAddressByPhoneNumberSearch.landmark;
        else this.shippingLandmark = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.telephone)
          this.shippingTelephone = this.selectedShippingAddressByPhoneNumberSearch.telephone;
        else this.shippingTelephone = "";

        // reset country
        this.shippingCountry = null;

        // reset region
        this.shippingRegions = [];
        this.shippingRegionsLoaded = false;
        this.shippingRegion = null;
        this.shippingRegionName = "";

        // reset city
        this.shippingCities = [];
        this.shippingCitiesLoaded = false;
        this.shippingCity = null;
        this.shippingCityName = "";

        if (this.selectedShippingAddressByPhoneNumberSearch.country) {
          this.shippingCountry = this.selectedShippingAddressByPhoneNumberSearch.country;

          this.$store
            .dispatch("retrieveRegionList", this.shippingCountry.id)
            .then(responseRegionList => {
              responseRegionList.forEach(function (region) {
                _this.shippingRegions.push({
                  id: region.id,
                  label: region.name
                });
              });

              if (this.selectedShippingAddressByPhoneNumberSearch.region.id !== 0) {
                this.shippingRegion = this.selectedShippingAddressByPhoneNumberSearch.region;
                this.$store
                  .dispatch(
                    "retrieveCityList",
                    this.selectedShippingAddressByPhoneNumberSearch.region.id
                  )
                  .then(responseCityList => {
                    // populate city list
                    responseCityList.forEach(function (city) {
                      _this.shippingCities.push({
                        id: city.id,
                        label: city.name
                      });
                    });

                    if (this.selectedShippingAddressByPhoneNumberSearch.city.id !== 0)
                      this.shippingCity = this.selectedShippingAddressByPhoneNumberSearch.city;
                    else if (this.selectedShippingAddressByPhoneNumberSearch.city.label)
                      this.shippingCityName = this.selectedShippingAddressByPhoneNumberSearch.city.label;

                  })
                  .finally(() => {
                    this.shippingCitiesLoaded = true;
                    this.shippingAddressSpinnerIsLoading = false;
                  });
              } else if (this.selectedShippingAddressByPhoneNumberSearch.region.label) {
                this.shippingRegionName = this.selectedShippingAddressByPhoneNumberSearch.region.label;
                if (this.selectedShippingAddressByPhoneNumberSearch.city.label) {
                  this.shippingCityName = this.selectedShippingAddressByPhoneNumberSearch.city.label;
                  this.shippingCitiesLoaded = true;
                }
                this.shippingAddressSpinnerIsLoading = false;
              }
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.shippingAddressSelectedByPhoneNumberSearch();
                  }
                });
            })
            .finally(() => {
              this.shippingRegionsLoaded = true;
            });
        } else this.shippingAddressSpinnerIsLoading = false;
      }
    },
    loadShippingAddessByPhoneNumberSearch: function (search, loading) {
      clearTimeout(this.debounceLoadShippingAddessByPhoneNumberSearch);
      if (search.trim() !== "") {
        this.debounceLoadShippingAddessByPhoneNumberSearch = setTimeout(() => {
          this.searchAddresses(loading, search.trim(), 2);
        }, 600);
      } else if (!this.selectedShippingAddressByPhoneNumberSearch) this.selectedShippingAddressByPhoneNumberSearch = [];
    },
    checkForm: function () {
      let _this = this;
      let _total = 0;
      this.errorMessage = "";

      this.customersCount = 0;

      if (
        !this.billingRegion &&
        !this.billingCitiesLoaded &&
        this.billingRegionName.trim() !== ""
      )
        this.billingCitiesLoaded = true;

      if (
        !this.shippingRegion &&
        !this.shippingCitiesLoaded &&
        this.shippingRegionName.trim() !== ""
      )
        this.shippingCitiesLoaded = true;

      this.billingTelephone = this.billingTelephone.replace(/[^\d]/g, "");
      this.shippingTelephone = this.shippingTelephone.replace(/[^\d]/g, "");
      this.alternativeRecipientPhone = this.alternativeRecipientPhone.replace(
        /[^\d]/g,
        ""
      );

      // Products section
      for (let i = 0; i < this.products.length; i++) {
        _this.products[i].quantity = _this.products[i].quantity.replace(
          /[^\d]/g,
          ""
        );

        if (_this.products[i].quantity === "0") _this.products[i].quantity = "";

        if (this.products[i].quantity === "")
          _this.products[i].styleQuantity =
            "margin-bottom: 5px; color: #9c0006; border-color: #ff0000; background-color: #ffc7ce";
        else _this.products[i].styleQuantity = "margin-bottom: 5px";

        if (
          /*_this.products[i].amount.trim() === "" ||*/
          /^\d+(\.\d{1,2})?$/.test(_this.products[i].amount.trim())
        ) {
          _this.products[i].styleAmount = "margin-bottom: 5px";
          if (
            _this.products[i].amount.trim() !== "" &&
            _this.products[i].quantity.trim()
          )
            _total =
              _total +
              parseFloat(_this.products[i].amount.trim()) *
              parseInt(_this.products[i].quantity.trim());
        } else {
          _this.products[i].styleAmount =
            "margin-bottom: 5px; color: #9c0006; border-color: #ff0000; background-color: #ffc7ce";
        }
        if (_this.products[i].name.trim() === "") {
          _this.products.splice(_this.products.indexOf(_this.products[i--]), 1);
        }
      }

      this.products.push({
        name: "",
        description: "",
        quantity: "",
        amount: "",
        from: "",
        to: "",
        message: ""
      });

      // clear alternative recipient info on uncheck
      if (!this.isSurpriseTheRecipient) {
        this.alternativeRecipientName = "";
        this.alternativeRecipientRelation = "";
        this.alternativeRecipientPhone = "";
      }

      // clear special instruction other if unselected
      if (this.deliverySpecialInstructionCode != 102)
        this.specialInstructionMessage = "";

      // shippingAndHandelingFees section
      if (
        this.shippingAndHandelingFees.trim() === "" ||
        /^\d+(\.\d{1,2})?$/.test(this.shippingAndHandelingFees.trim())
      ) {
        this.$refs.shippingAndHandelingFees.style = "margin-bottom: 5px";
        if (this.shippingAndHandelingFees.trim() !== "")
          _total = _total + parseFloat(this.shippingAndHandelingFees.trim());
      } else {
        this.$refs.shippingAndHandelingFees.style.color = "#9c0006";
        this.$refs.shippingAndHandelingFees.style.borderColor = "#ff0000";
        this.$refs.shippingAndHandelingFees.style.backgroundColor = "#ffc7ce";
      }

      // delivery cost section
      if (
        this.deliveryCost.trim() === "" ||
        /^\d+(\.\d{1,2})?$/.test(this.deliveryCost.trim())
      )
        this.$refs.deliveryCost.style = "margin-bottom: 5px";
      else {
        this.$refs.deliveryCost.style.color = "#9c0006";
        this.$refs.deliveryCost.style.borderColor = "#ff0000";
        this.$refs.deliveryCost.style.backgroundColor = "#ffc7ce";
      }

      this.totalAmount = _total;
    },
    submitOrder: function () {
      this.spinnerIsLoading = true;

      let order = {
        customerId: this.customer.id,
        deliveryDate: this.orderDeliveryDate,
        deliveryTimeId: this.orderDeliverySlot,
        billingAddress: {
          countryId: this.billingCountry.id,
          firstName: this.billingFirstName.trim(),
          lastName: this.billingFamilyName.trim(),
          telephone: this.billingTelephone.trim()
        },
        shippingAddress: {
          countryId: this.shippingCountry.id,
          firstName: this.recipientFirstName.trim(),
          lastName: this.recipientFamilyName.trim()
        },
        totalPrice: this.totalAmount,
        shippingAmount: parseFloat(this.shippingAndHandelingFees),
        currencyId: this.currency.id,
        deliveryCost: parseFloat(this.deliveryCost),
        deliveryCostCurrencyId: this.deliveryCostCurrency.id,
        vehicleTypeId: this.vehicleType.id
      };

      // complete billing address
      if (this.billingCompanyName.trim() !== "")
        order.billingAddress.company = this.billingCompanyName.trim();

      if (this.billingRegion)
        order.billingAddress.regionId = this.billingRegion.id;
      else order.billingAddress.region = this.billingRegionName.trim();

      if (this.billingCity) order.billingAddress.cityId = this.billingCity.id;
      else order.billingAddress.city = this.billingCityName.trim();

      if (this.billingStreet.trim() !== "")
        order.billingAddress.street = [this.billingStreet.trim()];

      if (this.billingLandmark.trim() !== "")
        order.billingAddress.township = this.billingLandmark.trim();

      // complete shipping address
      if (this.shippingTelephone.trim() !== "")
        order.shippingAddress.telephone = this.shippingTelephone.trim();

      if (this.shippingCompanyName.trim() !== "")
        order.shippingAddress.company = this.shippingCompanyName.trim();

      if (this.shippingRegion)
        order.shippingAddress.regionId = this.shippingRegion.id;
      else order.shippingAddress.region = this.shippingRegionName.trim();

      if (this.shippingCity)
        order.shippingAddress.cityId = this.shippingCity.id;
      else order.shippingAddress.city = this.shippingCityName.trim();

      if (this.shippingStreet.trim() !== "")
        order.shippingAddress.street = [this.shippingStreet.trim()];

      if (this.shippingLandmark.trim() !== "")
        order.shippingAddress.township = this.shippingLandmark.trim();

      // special instruction

      if (this.deliverySpecialInstructionCode != 0)
        order.specialInstruction = {
          specialInstructionId: parseInt(this.deliverySpecialInstructionCode)
        };

      if (this.deliverySpecialInstructionCode == 102)
        order.specialInstruction.comment = this.specialInstructionMessage.trim();

      order.surpriseOption = { sendAnonymously: this.isSendAnonymously };

      order.surpriseOption.surpriseTheRecipient = this.isSurpriseTheRecipient;

      if (this.isSurpriseTheRecipient) {
        order.surpriseOption.recipientName = this.alternativeRecipientName.trim();
        order.surpriseOption.recipientPhone = this.alternativeRecipientPhone.trim();

        if (this.alternativeRecipientRelation.trim() !== "")
          order.surpriseOption.recipientRelation = this.alternativeRecipientRelation.trim();
      }

      if (this.internalInstructionMessage.trim() !== "")
        order.instruction = this.internalInstructionMessage.trim();

      // order products
      let items = [];
      let item = null;
      this.products.forEach(function (product) {
        item = {};
        if (product.name !== "") {
          item.productName = product.name;
          item.quantity = parseInt(product.quantity);
          item.price = parseFloat(product.amount);

          if (product.description.trim() !== "")
            item.productDescription = product.description.trim();

          if (
            product.from.trim() !== "" ||
            product.to.trim() !== "" ||
            product.message.trim() !== ""
          ) {
            item.giftMessage = {};

            if (product.from.trim() !== "")
              item.giftMessage.sender = product.from.trim();

            if (product.to.trim() !== "")
              item.giftMessage.recipient = product.to.trim();

            if (product.message.trim() !== "")
              item.giftMessage.message = product.message.trim();
          }
          items.push(item);
        }
      });
      order.items = items;

      order.paymentMethodId = this.paymentMethod.id;
      order.paymentDetail = this.paymentDetails;

      this.$store
        .dispatch("createOrder", order)
        .then(createOrderResponse => {
          if (createOrderResponse.data.messageType === "Error") {
            this.errorMessage = createOrderResponse.data.message;
          } else {
            this.showSuccessMessage = true;
            // reset values
            this.orderDeliveryDate = null;
            this.orderDeliverySlot = "3";
            this.customers = [];
            this.customersCount = 0;
            this.customersLimit = 50;
            this.customer = null;
            this.customerTypeId = null;
            this.billingFirstName = "";
            this.billingFamilyName = "";
            this.billingCompanyName = "";
            this.billingRegionName = "";
            this.billingCityName = "";
            this.billingStreet = "";
            this.billingLandmark = "";
            this.billingTelephone = "";
            this.recipientFirstName = "";
            this.recipientFamilyName = "";
            this.shippingCompanyName = "";
            this.shippingCountryName = "";
            this.shippingRegionName = "";
            this.shippingCityName = "";
            this.shippingStreet = "";
            this.shippingLandmark = "";
            this.shippingTelephone = "";
            this.deliveryDate;
            this.deliverySpecialInstruction = "";
            this.deliverySpecialInstructionCode = 0;
            this.specialInstructionMessage = "";
            this.isSendAnonymously = false;
            this.isSurpriseTheRecipient = false;
            this.alternativeRecipientName = "";
            this.alternativeRecipientRelation = "";
            this.alternativeRecipientPhone = "";
            this.internalInstructionMessage = "";
            this.products = [
              {
                name: "",
                description: "",
                quantity: "",
                amount: "",
                from: "",
                to: "",
                message: ""
              }
            ];
            this.currency = null;
            this.deliveryCostCurrency = { id: 2, label: "LBP" };
            this.billingCountry = null;
            this.billingRegions = [];
            this.billingRegion = null;
            this.billingRegionsLoaded = false;
            this.billingCities = [];
            this.billingCity = null;
            this.billingCitiesLoaded = false;
            this.shippingCountry = null;
            this.shippingRegions = [];
            this.shippingRegion = null;
            this.shippingRegionsLoaded = false;
            this.shippingCities = [];
            this.shippingCity = null;
            this.shippingCitiesLoaded = false;
            this.shippingAndHandelingFees = "";
            this.deliveryCost = "";
            this.totalAmount = 0;
            this.paymentMethod = null;
            this.paymentDetails = "";

            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.submitOrder();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    clearBillingAddress: function () {
      this.billingFirstName = "";
      this.billingFamilyName = "";
      this.billingCompanyName = "";
      this.billingRegionName = "";
      this.billingCityName = "";
      this.billingStreet = "";
      this.billingLandmark = "";
      this.billingTelephone = "";
      this.billingCountry = null;
      this.billingRegions = [];
      this.billingRegion = null;
      this.billingRegionsLoaded = false;
      this.billingCities = [];
      this.billingCity = null;
      this.billingCitiesLoaded = false;
    },
    clearShippingAddress: function () {
      this.recipientFirstName = "";
      this.recipientFamilyName = "";
      this.shippingCompanyName = "";
      this.shippingRegionName = "";
      this.shippingCityName = "";
      this.shippingStreet = "";
      this.shippingLandmark = "";
      this.shippingTelephone = "";
      this.shippingCountry = null;
      this.shippingRegions = [];
      this.shippingRegion = null;
      this.shippingRegionsLoaded = false;
      this.shippingCities = [];
      this.shippingCity = null;
      this.shippingCitiesLoaded = false;
    }
  },
  created() {
    this.load();
  }
};
</script>
