<template>
  <ul class="nav">
    <li class="nav-profile">
      <a href="javascript:;" v-on:click="expand()">
        <div class="cover with-shadow"></div>
        <div class="image image-icon bg-black text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <div class="info">
          <b class="caret pull-right"></b>
          {{ firstName + ' ' + lastName }}
          <br />
          {{ store }}
          <small>{{ email }}</small>
        </div>
      </a>
    </li>
    <li>
      <ul
        class="nav nav-profile"
        v-bind:class="{ 'd-block': this.stat == 'expand', 'd-none': this.stat == 'collapse' }"
      >
        <li>
          <a href="javascript:;" @click="changePassword">
            <i class="fa fa-key" aria-hidden="true"></i> Change Password
          </a>
        </li>
        <li>
          <a href="javascript:;" @click="logout">
            <i class="fa fa-power-off" aria-hidden="true"></i> Log Out
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Options from "@/conf/Options";

export default {
  name: "SidebarNavProfile",
  data() {
    return {
      stat: "",
      firstName: Options.user.firstName,
      lastName: Options.user.lastName,
      email: Options.user.email,
      store: Options.user.store
    };
  },
  methods: {
    expand: function() {
      this.stat = this.stat == "expand" ? "collapse" : "expand";
    },
    logout: function() {
      this.$store.dispatch("doLogout").then(() => {
        this.$router.push("/?logout=" + new Date().getTime());
        setTimeout(() => location.reload(true), 250);
      });
    },
    changePassword: function() {
      this.$router.push("/profile/changePassword");
    }
  }
};
</script>
