<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Driver Management</li>
      <li class="breadcrumb-item active">Drivers</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Driver Management</h1>
    <!-- end page-header -->
    <panel title="Drivers">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <div>
          <vue-good-table
            ref="driversTable"
            mode="remote"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :totalRows="totalRecords"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
          >
            <div slot="emptystate">No drivers found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'enabled'">
                <span v-if="props.row.enabled" class="label label-green">Active</span>
                <span v-else class="label label-danger">Inactive</span>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="navigateToEditDriverPage(props.row.driverId)"
                >Edit</button>
              </span>
            </template>
          </vue-good-table>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="navigateToDriverRegistrationPage"
          >Register New Driver</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // spinner
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      columns: [
        {
          label: "First Name",
          field: "firstName",
          tdClass: "text-left"
        },
        {
          label: "Family Name",
          field: "familyName",
          tdClass: "text-left"
        },
        {
          label: "Mobile Number",
          field: "mobileNumber",
          tdClass: "text-left"
        },
        {
          label: "Company",
          field: "companyName",
          tdClass: "text-left"
        },
        {
          label: "Store",
          field: "storeName",
          tdClass: "text-left"
        },
        {
          label: "Status",
          field: "enabled",
          tdClass: "text-left",
          sortable: false
        },
        {
          label: "Registered By",
          field: "createdBy",
          tdClass: "text-left",
          sortable: false
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      paginationOptions: {
        enabled: true,
        dropdownAllowAll: false,
        rowsPerPageLabel: "Drivers per page",
        perPage: 15,
        perPageDropdown: [10, 15, 30, 50],
        ofLabel: "of"
      },
      searchOptions: {
        enabled: true,
        placeholder: "Refine your search result by entering multiple keywords",
        trigger: "enter"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [
          { field: "firstName", type: "asc" },
          { field: "familyName", type: "asc" }
        ]
      },
      filter: {
        offset: 0,
        limit: 15,
        filter: [],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ]
      },
      vueGoodTableServerParams: {
        columnFilters: {},
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ],
        page: 1,
        perPage: 15
      }
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveDriverList", this.filter)
        .then(response => {
          this.totalRecords = response.data.totalCount;
          this.rows = response.data.drivers;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;
          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    updateParams(newProps, reset) {
      this.vueGoodTableServerParams = Object.assign(
        {},
        this.vueGoodTableServerParams,
        newProps
      );
      this.filter.offset = reset
        ? 0
        : (this.vueGoodTableServerParams.page - 1) *
          this.vueGoodTableServerParams.perPage;
      this.filter.limit = this.vueGoodTableServerParams.perPage;
      this.filter.sort = [];

      for (let i = 0; i < this.vueGoodTableServerParams.sort.length; i++) {
        let sortByColumn = {};
        sortByColumn.field = this.vueGoodTableServerParams.sort[i].field;
        sortByColumn.direction = this.vueGoodTableServerParams.sort[i].type;
        this.filter.sort.push(sortByColumn);
      }
    },
    navigateToDriverRegistrationPage: function() {
      let routeData = this.$router.resolve({
        name: "driverManagementDriver"
      });
      window.open(routeData.href, "_self");
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage }, false);
      this.load();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage }, true);
      this.load();
    },
    onSortChange(params) {
      this.updateParams(
        {
          sort: params
        },
        true
      );
      this.load();
    },
    onSearch(search) {
      if (this.spinnerIsLoading) return; //Abort in case another search was initiated

      this.filter.offset = 0;
      this.filter.filter = [];
      var keywords = search.searchTerm.trim().split(" ");

      for (var i = 0; i < keywords.length; i++) {
        let searchGroup = [];

        let firstName = {
          field: "firstName",
          value: "",
          condition: "contains"
        };

        let familyName = {
          field: "familyName",
          value: "",
          condition: "contains"
        };

        let mobileNumber = {
          field: "mobileNumber",
          value: "",
          condition: "contains"
        };

        let companyName = {
          field: "companyName",
          value: "",
          condition: "contains"
        };

        let storeName = {
          field: "storeName",
          value: "",
          condition: "contains"
        };

        let keyword = keywords[i].trim();
        if (keyword !== "") {
          firstName.value = keyword;
          searchGroup.push(firstName);

          familyName.value = keyword;
          searchGroup.push(familyName);

          mobileNumber.value = keyword;
          searchGroup.push(mobileNumber);

          companyName.value = keyword;
          searchGroup.push(companyName);

          storeName.value = keyword;
          searchGroup.push(storeName);

          this.filter.filter.push(searchGroup);
        }
      }
      this.load();
    },
    navigateToEditDriverPage: function(driverId) {
      let routeData = this.$router.resolve({
        name: "driverManagementDriver",
        query: { id: driverId }
      });
      window.open(routeData.href, "_self");
    }
  },
  created() {
    this.load();
  }
};
</script>