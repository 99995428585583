<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">User Management</li>
      <li class="breadcrumb-item active">User</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">User Management</h1>
    <!-- end page-header -->
    <panel title="Create User">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <b-alert variant="success" :show="showSuccessMessage">
        <i class="fa fa-check fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">
          User
          <strong>{{ username }}</strong>
          {{ userId && userId != 0 ? "updated":"created" }} successfully
        </p>
      </b-alert>
      <b-alert variant="danger" :show="errorMessage !== ''">
        <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
        <p class="m-b-0">{{ errorMessage }}</p>
      </b-alert>
      <div style="max-width: 700px" v-if="!spinnerIsLoading && !showSuccessMessage">
        <div>
          <label class="f-w-700">First Name</label>
          <i v-if="firstName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <input
            type="text"
            class="form-control"
            placeholder="First Name"
            style="margin-bottom: 5px"
            v-model="firstName"
            @input="checkForm"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Family Name</label>
          <i v-if="familyName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <input
            type="text"
            class="form-control"
            placeholder="Family Name"
            style="margin-bottom: 5px"
            v-model="familyName"
            @input="checkForm"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Username</label>
          <i v-if="username.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            style="margin-bottom: 5px"
            v-model="username"
            @input="checkForm"
            :disabled="userId && userId != 0"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">E-mail Address</label>
          <i v-if="email.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <i
            v-if="email.trim() !== '' && !validEmail"
            class="fas fa-exclamation-circle m-l-10 text-red f-s-14"
            style="cursor: help"
            title="Invalid e-mail address"
          ></i>
          <input
            type="text"
            ref="email"
            class="form-control"
            style="text-transform: lowercase"
            placeholder="Email"
            v-model="email"
            @blur="email=email.trim()"
            @input="checkForm"
            :disabled="userId && userId != 0"
          />
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Role</label>
          <i v-if="roleId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <select class="form-control" @change="checkForm" v-model="roleId">
            <option value="0" hidden selected v-if="roleId == 0">Select a Role</option>
            <option v-for="role in roles" :value="role.id" :key="role.id">{{ role.name }}</option>
          </select>
        </div>
        <div style="margin-top: 25px">
          <label class="f-w-700">Store</label>
          <i v-if="storeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <select class="form-control" @change="checkForm" v-model="storeId">
            <option value="0" hidden selected v-if="storeId == 0">Select a Store</option>
            <option v-for="store in stores" :value="store.id" :key="store.id">{{ store.name }}</option>
          </select>
        </div>
        <div v-if="storeId != 0 && (this.roleId == 3 || this.roleId == 7 || this.roleId == 9)" style="margin-top: 25px">
          <label class="f-w-700">Branch</label>
          <i v-if="branchId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
          <select
            class="form-control"
            @change="checkForm"
            v-model="branchId"
            :disabled="branches.length == 0"
          >
            <option value="0" hidden selected v-if="branchId == 0">Select a Branch</option>
            <option
              v-for="branch in branches "
              :value="branch.id"
              :key="branch.id"
            >{{ branch.name }}</option>
          </select>
        </div>
        <div style="margin-top: 25px" v-if="userId && userId != 0">
          <label class="f-w-700">Status</label>
          <div class="checkbox checkbox-css m-b-10">
            <input type="checkbox" id="status" v-model="enabled" />
            <label for="status">Active</label>
          </div>
        </div>
        <div style="margin-top: 15px">
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="createUser"
            :disabled="!enableSubmitBtn"
          >{{ userId && userId != 0 ? "Update User Info" : "Create User" }}</button>
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="cancel"
          >Cancel</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      userId: this.$route.query.id,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      firstName: "",
      familyName: "",
      username: "",
      email: "",
      roleId: 0,
      storeId: 0,
      originalStoreId: -1,
      branchId: 0,
      enabled: false,
      roles: [],
      stores: [],
      branches: []
    };
  },
  computed: {
    validEmail: function() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email.trim()
      );
    },
    enableSubmitBtn: function() {
      return (
        this.firstName.trim() !== "" &&
        this.familyName.trim() !== "" &&
        this.username.trim() !== "" &&
        this.email.trim() !== "" &&
        this.validEmail &&
        this.storeId != 0 &&
        this.roleId != 0 &&
        ((this.roleId != 3 && this.roleId != 7 && this.roleId != 9) || ((this.roleId == 3 || this.roleId == 7 || this.roleId == 9) && this.branchId != 0))
      );
    }
  },
  methods: {
    load() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveStoreList")
        .then(retrieveStoreListResponse => {
          this.stores = retrieveStoreListResponse.data;

          this.$store
            .dispatch("retrieveRoleList")
            .then(retrieveRoleListResponse => {
              this.roles = retrieveRoleListResponse.data;

              if (this.userId && this.userId != 0) {
                this.$store
                  .dispatch("retrieveUser", this.userId)
                  .then(retrieveUserResponse => {
                    this.firstName = retrieveUserResponse.data.firstName;
                    this.familyName = retrieveUserResponse.data.lastName;
                    this.username = retrieveUserResponse.data.username;
                    this.email = retrieveUserResponse.data.email;
                    this.storeId = retrieveUserResponse.data.storeId;
                    this.roleId = retrieveUserResponse.data.roleId;
                    this.branchId = retrieveUserResponse.data.hasOwnProperty(
                      "branchId"
                    )
                      ? retrieveUserResponse.data.branchId
                      : 0;
                    this.enabled = retrieveUserResponse.data.enabled;
                    this.checkForm();
                  })
                  .catch(error => {
                    let titleTxt = "Error";
                    let msgTxt = error.message;

                    if (
                      error.message &&
                      error.message === "Request failed with status code 403"
                    ) {
                      titleTxt = "Access Denied";
                      msgTxt = "Please call Exotica administration for help";
                    }
                    this.spinnerIsLoading = false;
                    this.$swal
                      .fire({
                        type: "error",
                        title: titleTxt,
                        text: msgTxt,
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Retry"
                      })
                      .then(result => {
                        if (result.value) {
                          this.load();
                        }
                      });
                  })
                  .finally(() => {
                    this.spinnerIsLoading = false;
                  });
              } else this.spinnerIsLoading = false;
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }

              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            })
            .finally(() => {
              if (!this.userId || this.userId == 0)
                this.spinnerIsLoading = false;
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    checkForm: function() {
      this.errorMessage = "";

      let _this = this;

      // email section
      if (!this.spinnerIsLoading) {
        this.email = this.email.toLowerCase();
        if (this.email.trim() === "" || this.validEmail)
          _this.$refs.email.style = "text-transform: lowercase";
        else {
          _this.$refs.email.style.color = "#ff0000";
          _this.$refs.email.style.borderColor = "#ff0000";
        }
      }

      if (this.roleId != 3 && this.roleId != 7 && this.roleId != 9) this.branchId = 0;
      else if (this.storeId != 0 && this.storeId != this.originalStoreId) {
        this.branches = [];
        this.$store
          .dispatch("retrieveBranchList", this.storeId)
          .then(retrieveBranchListResponse => {
            this.branches = retrieveBranchListResponse.data;
            this.originalStoreId = this.storeId;
            let branchFoundInList = false;
            if (this.branchId != 0) {
              let _this = this;
              this.branches.forEach(function(branch) {
                if (branch.id == _this.branchId) branchFoundInList = true;
              });
              if (!branchFoundInList) _this.branchId = 0;
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.spinnerIsLoading = false;
            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.load();
                }
              });
          });
      }
    },
    createUser: function() {
      if (this.userId && this.userId != 0) {
        this.spinnerIsLoading = true;

        let user = {
          firstName: this.firstName,
          lastName: this.familyName,
          storeId: this.storeId,
          roleId: this.roleId,
          branchId: this.branchId,
          enabled: this.enabled
        };

        let data = {
          id: this.userId,
          user: user
        };
        this.$store
          .dispatch("updateUserInfo", data)
          .then(updateUserInfoResponse => {
            if (updateUserInfoResponse.data.messageType === "Error") {
              this.errorMessage = updateUserInfoResponse.data.message;
            } else {
              this.username = "";
              this.firstName = "";
              this.familyName = "";
              this.email = "";
              this.storeId = 0;
              this.branchId = 0;
              this.roleId = 0;
              this.showSuccessMessage = true;
              this.enabled = false;

              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "userManagementUsers"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createUser();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      } else {
        this.spinnerIsLoading = true;
        let user = {
          username: this.username,
          email: this.email,
          firstName: this.firstName,
          lastName: this.familyName,
          storeId: this.storeId,
          roleId: this.roleId,
          branchId: this.branchId
        };
        this.$store
          .dispatch("createUser", user)
          .then(createUserResponse => {
            if (createUserResponse.data.messageType === "Error") {
              this.errorMessage = createUserResponse.data.message;
            } else {
              this.username = "";
              this.firstName = "";
              this.familyName = "";
              this.email = "";
              this.storeId = 0;
              this.branchId = 0;
              this.roleId = 0;
              this.showSuccessMessage = true;

              sleep(3000).then(() => {
                this.showSuccessMessage = false;
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.createUser();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      }
    },
    cancel: function() {
      let routeData = this.$router.resolve({
        name: "userManagementUsers"
      });
      window.open(routeData.href, "_self");
    }
  },
  created() {
    this.load();
  }
};
</script>