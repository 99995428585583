<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">High Season</li>
      <li class="breadcrumb-item active">Orders Delivery Status</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">High Season</h1>
    <!-- end page-header -->
    <panel title="Orders Delivery Status">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Order(s) status updated successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>

        <div style="max-width: 700px;">
          <div class="form-group row col-xl-7 col-lg-10 col-sm-12">
            <div class="col-sm-3">
              <label class="col-form-label f-s-14 text-grey-darker"
                >Status</label
              >
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                id="delivered"
                name="status"
                v-model="statusId"
                @change="statusChanged"
                value="12"
              />
              <label for="delivered">Delivered</label>
            </div>
            <div class="radio radio-css radio-inline">
              <input
                type="radio"
                id="deliveryFailed"
                name="status"
                v-model="statusId"
                @change="statusChanged"
                value="14"
              />
              <label for="deliveryFailed">Delivery Failed</label>
            </div>
          </div>

          <div>
            <div style="margin-top: 25px;">
              <label class="f-w-700">Order ID</label>
              <i
                v-if="orderId.trim() === ''"
                class="fas fa-asterisk m-l-10 text-red f-s-8"
              ></i>
              <input
                type="text"
                class="form-control"
                ref="orderId"
                placeholder="# Enter the Order ID"
                style="margin-bottom: 5px;"
                v-model="orderId"
                @keyup="checkForm"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right;"
                @click="add"
                :disabled="orderId.trim() === ''"
              >
                {{ btnAddText }}
              </button>
              <span
                v-if="rows.length > 0"
                style="margin-top: 10px; float: left;"
              >
                <span class="label label-green m-2 f-s-14">{{
                  rows.length
                }}</span>
                <span class="f-w-700"
                  >order{{ rows.length > 1 ? "s" : "" }} captured</span
                >
              </span>
            </div>
          </div>
        </div>
        <div style="margin-top: 75px;">
          <vue-good-table
            ref="ordersTable"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No orders entered</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit;"
                  @click="remove(props.row.orderId)"
                >
                  Remove
                </button>
              </span>
            </template>
          </vue-good-table>
          <div>
            <div v-if="statusId == 14" style="margin-top: 25px;">
              <label class="f-w-700">Comment</label>
              <i
                v-if="comment.trim() === ''"
                class="fas fa-asterisk m-l-10 text-red f-s-8"
              ></i>
              <textarea
                type="text"
                class="form-control"
                rows="5"
                placeholder="# Delivery Failed Reason"
                v-model="comment"
              />
            </div>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right;"
              @click="submit"
              :disabled="
                rows.length == 0 || (statusId == 14 && comment.trim() === '')
              "
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right;"
              @click="doReset"
              :disabled="rows.length == 0"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
import Options from "@/conf/Options";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      roleId: Options.user.roleId,
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      columns: [
        {
          label: "Order ID",
          field: "orderId",
          tdClass: "text-left"
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-left",
          sortable: false
        }
      ],
      rows: [],
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter Orders"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "orderId", type: "asc" }]
      },
      btnAddDisabled: true,
      btnAddText: "Add",
      orders: [],
      orderId: "",
      statusId: 12,
      comment: "",
      filter: {
        offset: 0,
        limit: 15,
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ],
        sort: [
          { field: "firstName", direction: "asc" },
          { field: "familyName", direction: "asc" }
        ]
      }
    };
  },
  methods: {
    load: function () {
      this.$nextTick(() => {
        sleep(1).then(() => {
          this.$refs.orderId.focus();
        });
      });
    },
    statusChanged: function () {
      if (this.statusId !== 14) this.comment = "";
    },
    checkForm: function (event) {
      this.errorMessage = "";
      this.orderId = this.orderId.trim();
      if (this.orderId !== "" && event.keyCode === 13) this.add();
      else
        this.$nextTick(() => {
          sleep(1).then(() => {
            this.$refs.orderId.focus();
          });
        });
    },
    remove: function (orderId) {
      this.$swal
        .fire({
          type: null,
          title: "Remove order " + orderId + "?",
          text: null,
          showCancelButton: true,
          allowOutsideClick: false,
          focusCancel: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Remove"
        })
        .then((result) => {
          if (result.value) {
            let _this = this;
            this.rows.forEach(function (order) {
              if (order.orderId === orderId) {
                _this.rows.splice(_this.rows.indexOf(order), 1);
              }
            });
          }
        });
    },
    submit: function () {
      this.spinnerIsLoading = true;

      let orders = [];

      this.rows.forEach(function (row) {
        orders.push(Number(row.orderId));
      });

      let data = {
        statusId: this.statusId,
        orderIds: orders
      };

      if (this.statusId == 14) data.comment = this.comment.trim();

      this.$store
        .dispatch("updateOrdersDeliveryStatus", data)
        .then((response) => {
          if (response.data.messageType === "Error") {
            this.errorMessage = response.data.message;
          } else {
            this.showSuccessMessage = true;
            this.reset();
            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.submit();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    doReset: function () {
      this.$swal
        .fire({
          type: null,
          title: "Proceed with the reset?",
          text: null,
          showCancelButton: true,
          allowOutsideClick: false,
          focusCancel: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reset"
        })
        .then((result) => {
          if (result.value) {
            this.reset();
          }
        });
    },
    reset: function () {
      this.errorMessage = "";
      this.rows = [];
      this.orderId = "";
      (this.statusId = 12), (this.comment = "");
      this.checkForm();
    },
    add: function () {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("checkOrder", this.orderId)
        .then((response) => {
          if (response.data.orderExists) {
            if (response.data.orderStatus === "delivered") {
              if (this.roleId == 1 || this.roleId == 4 || this.roleId == 12) {
                // role "Super Admin" or "Administrator" or "Advanced Admin"
                this.$swal
                  .fire({
                    type: null,
                    title: null,
                    text: "Order already delivered, proceed?",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    focusCancel: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Proceed"
                  })
                  .then((result) => {
                    if (result.value) {
                      let order = { orderId: this.orderId };
                      let alreadyCaptured = false;
                      let _this = this;

                      this.rows.forEach(function (order) {
                        if (order.orderId === _this.orderId)
                          alreadyCaptured = true;
                      });
                      if (!alreadyCaptured) this.rows.push(order);
                      this.orderId = "";
                      this.$nextTick(() => {
                        sleep(1).then(() => {
                          this.$refs.orderId.focus();
                        });
                      });
                    }
                  });
              } else {
                this.$swal.fire({
                  type: "error",
                  title: null,
                  text: "Order already delivered",
                  showCancelButton: false,
                  allowOutsideClick: false,
                  focusCancel: true,
                  confirmButtonColor: "#d33",
                  confirmButtonText: "OK"
                });
              }
            } else {
              let order = { orderId: this.orderId };
              let alreadyCaptured = false;
              let _this = this;

              this.rows.forEach(function (order) {
                if (order.orderId === _this.orderId) alreadyCaptured = true;
              });
              if (!alreadyCaptured) this.rows.push(order);
              this.orderId = "";
              this.$nextTick(() => {
                sleep(1).then(() => {
                  this.$refs.orderId.focus();
                });
              });
            }
          } else
            this.errorMessage =
              'Order with ID "' + this.orderId + '" was not found';
        })
        .catch((error) => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then((result) => {
              if (result.value) {
                this.add();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    }
  },
  created() {
    this.load();
  }
};
</script>
