import Vue from 'vue'
import VueX from 'vuex'
import routes from '@/conf/router'
import store from '@/conf/store'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueSweetalert2 from 'vue-sweetalert2'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueGoodTable from 'vue-good-table'
import Loading from 'vue-loading-overlay'
import VueBarcode from 'vue-barcode'
import Moment from 'vue-moment'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import vueScrollto from 'vue-scrollto'

// plugins css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'bootstrap-social/bootstrap-social.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-select/dist/vue-select.css';

// Font
import '@/assets/fonts/openSans/openSans.css'
import '@/assets/fonts/fontAwesome/fontAwesome.css'

// Style
import '@/assets/css/default/style.css'
import '@/assets/css/default/style-responsive.css'
import '@/assets/css/default/theme/default.css'
import '@/assets/css/style.css'


import App from '@/App.vue'

Vue.config.productionTip = false

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueSweetalert2)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(Moment)
Vue.use(vueScrollto)
Vue.component('loading', Loading)
Vue.component('barcode', VueBarcode)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', vSelect);

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth');
  if (store.state.accessToken === null) {
    if (to.path === '/')
      next();
    else
      next({
        path: '/'
      });
  } else {
    if (to.path === '/')
      next({
        path: '/orderManagement/orders'
      });
    else
      next();
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')