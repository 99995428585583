<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Driver Management</li>
      <li class="breadcrumb-item active">Driver Registration</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Driver Management</h1>
    <!-- end page-header -->
    <panel title="Driver Registration">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div v-if="companies.length == 0">
        <b-alert variant="danger" :show="!spinnerIsLoading">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">No delivery companies found</p>
        </b-alert>
      </div>
      <div v-else>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p
            class="m-b-0"
          >Driver {{ driverId && driverId != 0 ? "updated":"registered" }} successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>
        <div style="max-width: 700px" v-if="!spinnerIsLoading && !showSuccessMessage">
          <div>
            <label class="f-w-700">First Name</label>
            <i v-if="firstName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <input
              type="text"
              class="form-control"
              placeholder="# First Name"
              style="margin-bottom: 5px"
              v-model="firstName"
              @keyup="checkForm"
            />
          </div>
          <div style="margin-top: 25px">
            <label class="f-w-700">Family Name</label>
            <i v-if="familyName.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <input
              type="text"
              class="form-control"
              placeholder="# Family Name"
              style="margin-bottom: 5px"
              v-model="familyName"
              @keyup="checkForm"
            />
          </div>
          <div style="margin-top: 25px">
            <label class="f-w-700">Mobile Number</label>
            <i v-if="phone.trim() === ''" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <input
              type="text"
              class="form-control"
              placeholder="# Mobile Number"
              v-model="phone"
              @keyup="checkForm"
            />
          </div>
          <div style="margin-top: 25px">
            <label class="f-w-700">Company</label>
            <i v-if="companyId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <select class="form-control" @change="checkForm" v-model="companyId">
              <option value="0" hidden selected v-if="companyId == 0">Select a Company</option>
              <option
                v-for="company in companies"
                :value="company.companyId"
                :key="company.companyId"
              >{{ company.name }}</option>
            </select>
          </div>
          <div style="margin-top: 25px" v-if="driverId && driverId != 0">
            <label class="f-w-700">Status</label>
            <div class="checkbox checkbox-css m-b-10">
              <input type="checkbox" id="status" v-model="enabled" />
              <label for="status">Active</label>
            </div>
          </div>
          <div style="margin-top: 15px">
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="registerDriver"
              :disabled="!enableSubmitBtn"
            >{{ driverId ? "Update Driver Info" : "Register Driver" }}</button>
            <button
              type="button"
              class="btn btn-success m-5"
              style="float: right"
              @click="cancel"
            >Cancel</button>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  data() {
    return {
      driverId: this.$route.query.id,
      enabled: false,
      companies: [],
      companyId: 0,
      enableSubmitBtn: false,
      errorMessage: "",
      familyName: "",
      filter: {
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ]
      },
      firstName: "",
      phone: "",
      roleId: 0,
      showSuccessMessage: false,
      spinnerFullPage: true,
      spinnerIsLoading: false,
      spinnerOpacity: 0.2,
      spinnerUserCanCancel: false
    };
  },
  methods: {
    load() {
      this.spinnerIsLoading = true;

      // if edit mode retrieve all companies
      if (this.driverId && this.driverId != 0) {
        this.filter = {
          filter: []
        };
      }

      this.$store
        .dispatch("retrieveDriverCompanyList", this.filter)
        .then(retrieveDriverCompanyListResponse => {
          this.companies = retrieveDriverCompanyListResponse.data;

          if (this.driverId && this.driverId != 0) {
            this.$store
              .dispatch("retrieveDriver", this.driverId)
              .then(retrieveDriverResponse => {
                this.firstName = retrieveDriverResponse.data.firstName;
                this.familyName = retrieveDriverResponse.data.familyName;
                this.phone = retrieveDriverResponse.data.mobileNumber;
                this.companyId = retrieveDriverResponse.data.companyId;
                this.enabled = retrieveDriverResponse.data.enabled;
                this.checkForm();
              })
              .catch(error => {
                let titleTxt = "Error";
                let msgTxt = error.message;

                if (
                  error.message &&
                  error.message === "Request failed with status code 403"
                ) {
                  titleTxt = "Access Denied";
                  msgTxt = "Please call Exotica administration for help";
                }
                this.spinnerIsLoading = false;
                this.$swal
                  .fire({
                    type: "error",
                    title: titleTxt,
                    text: msgTxt,
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Retry"
                  })
                  .then(result => {
                    if (result.value) {
                      this.load();
                    }
                  });
              })
              .finally(() => {
                this.spinnerIsLoading = false;
              });
          } else this.spinnerIsLoading = false;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    checkForm: function() {
      this.errorMessage = "";

      this.enableSubmitBtn =
        this.firstName.trim() !== "" &&
        this.familyName.trim() !== "" &&
        this.phone.trim() !== "" &&
        this.companyId != 0;
    },
    registerDriver: function() {
      let driver = {
        firstName: this.firstName.trim(),
        familyName: this.familyName.trim(),
        mobileNumber: this.phone.trim(),
        companyId: this.companyId
      };

      if (this.driverId && this.driverId != 0) {
        this.spinnerIsLoading = true;

        driver.enabled = this.enabled;

        let data = {
          id: this.driverId,
          driver: driver
        };
        this.$store
          .dispatch("updateDriverInfo", data)
          .then(updateDriverInfoResponse => {
            if (updateDriverInfoResponse.data.messageType === "Error") {
              this.errorMessage = updateDriverInfoResponse.data.message;
            } else {
              this.firstName = "";
              this.familyName = "";
              this.phone = "";
              this.companyId = 0;
              this.enableSubmitBtn = false;
              this.showSuccessMessage = true;

              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "driverManagementDrivers"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.registerDriver();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      } else {
        this.spinnerIsLoading = true;
        this.$store
          .dispatch("registerDriver", driver)
          .then(registerDriverResponse => {
            if (registerDriverResponse.data.messageType === "Error") {
              this.errorMessage = registerDriverResponse.data.message;
            } else {
              this.firstName = "";
              this.familyName = "";
              this.phone = "";
              this.companyId = 0;
              this.enableSubmitBtn = false;
              this.showSuccessMessage = true;

              sleep(3000).then(() => {
                this.showSuccessMessage = false;
                let routeData = this.$router.resolve({
                  name: "driverManagementDrivers"
                });
                window.open(routeData.href, "_self");
              });
            }
          })
          .catch(error => {
            let titleTxt = "Error";
            let msgTxt = error.message;

            if (
              error.message &&
              error.message === "Request failed with status code 403"
            ) {
              titleTxt = "Access Denied";
              msgTxt = "Please call Exotica administration for help";
            }

            this.$swal
              .fire({
                type: "error",
                title: titleTxt,
                text: msgTxt,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Retry"
              })
              .then(result => {
                if (result.value) {
                  this.registerDriver();
                }
              });
          })
          .finally(() => {
            this.spinnerIsLoading = false;
          });
      }
    },
    cancel: function() {
      let routeData = this.$router.resolve({
        name: "driverManagementDrivers"
      });
      window.open(routeData.href, "_self");
    }
  },
  created() {
    this.load();
  }
};
</script>