<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Profile</li>
      <li class="breadcrumb-item active">Change Password</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Change Password</h1>
    <!-- end page-header -->
    <panel title="Change Password">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div style="max-width: 700px">
        <b-alert variant="danger" :show="showGlobalErrorMessage">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fas fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Password Updated</p>
        </b-alert>
        <div>
          <label class="f-w-700">Current Password</label>
          <b-alert variant="danger" :show="showCurrentPasswordErrorMessage">
            <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
            <p class="m-b-0">Wrong Current Password</p>
          </b-alert>
          <input
            type="password"
            class="form-control"
            placeholder="Current Password"
            style="margin-bottom: 5px"
            v-model="currentPassword"
            @keyup="checkForm"
          />
        </div>
        <div style="padding-top: 25px">
          <label class="f-w-700">New Password</label>
          <input
            type="password"
            class="form-control"
            placeholder="New Password"
            v-model="newPassword"
            @keyup="checkForm"
            @change="checkForm"
          />
          <input
            type="password"
            class="form-control"
            style="margin-top: 5px;"
            placeholder="Confirm Password"
            v-model="confirmNewPassword"
            @keyup="checkForm"
          />
          <b-alert variant="danger" :show="showNewPasswordErrorMessage" style="margin-top: 15px">
            <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
            <p class="m-b-0">Passwords do not match</p>
          </b-alert>
          <b-alert
            variant="success"
            :show="!showNewPasswordErrorMessage && newPassword !== '' && confirmNewPassword !== ''"
            style="margin-top: 15px"
          >
            <i class="fas fa-check fa-2x pull-left m-r-10"></i>
            <p class="m-b-0">Passwords match</p>
          </b-alert>
        </div>
        <div style="margin-top: 15px">
          <button
            type="button"
            class="btn btn-success m-5"
            style="float: right"
            @click="changePassword"
            :disabled="!enableSubmitBtn"
          >Change Password</button>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // spinner
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,

      showSuccessMessage: false,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      enableSubmitBtn: false,
      showCurrentPasswordErrorMessage: false,
      showNewPasswordErrorMessage: false,
      showGlobalErrorMessage: false,
      errorMessage: null
    };
  },
  methods: {
    changePassword: function() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.showNewPasswordErrorMessage = true;
        return;
      }

      let user = {
        currentPassword: this.currentPassword,
        password: this.newPassword
      };

      this.spinnerIsLoading = true;
      this.$store
        .dispatch("updateUser", user)
        .then(response => {
          if (response.data.messageType === "Error") {
            this.showGlobalErrorMessage = true;
            this.errorMessage = response.data.message;
          } else if (response.data.messageType === "Success") {
            this.showSuccessMessage = true;
            this.currentPassword = "";
            this.newPassword = "";
            this.confirmNewPassword = "";
            setTimeout(() => (this.showSuccessMessage = false), 3000);
          } else {
            this.showGlobalErrorMessage = true;
            this.errorMessage = "Unknown Error";
          }
        })
        .catch(error => {
          this.showGlobalErrorMessage = true;
          this.errorMessage = error.message;
        })
        .finally(() => {
          // close loading spinner
          this.spinnerIsLoading = false;
        });
    },
    checkForm: function() {
      this.showGlobalErrorMessage = false;

      this.showNewPasswordErrorMessage =
        this.newPassword !== this.confirmNewPassword;
      this.enableSubmitBtn =
        this.currentPassword !== "" &&
        this.newPassword !== "" &&
        this.newPassword === this.confirmNewPassword;
    }
  }
};
</script>