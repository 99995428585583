<template>
  <div>
    <!-- begin breadcrumb -->
    <ol class="breadcrumb pull-right">
      <li class="breadcrumb-item">Exotica</li>
      <li class="breadcrumb-item">Fridge Management</li>
      <li class="breadcrumb-item active">Production Fridges</li>
    </ol>
    <!-- end breadcrumb -->
    <!-- begin page-header -->
    <h1 class="page-header">Fridge Management</h1>
    <!-- end page-header -->
    <panel title="Production Fridges">
      <loading
        loader="spinner"
        background-color="#000"
        :opacity="spinnerOpacity"
        :active.sync="spinnerIsLoading"
        :can-cancel="spinnerUserCanCancel"
        :is-full-page="spinnerFullPage"
      ></loading>
      <div v-if="seasons.length == 0">
        <b-alert variant="danger" :show="!spinnerIsLoading">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">No active seasons found</p>
        </b-alert>
      </div>
      <div v-else>
        <b-alert variant="success" :show="showSuccessMessage">
          <i class="fa fa-check fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">Fridge updated successfully</p>
        </b-alert>
        <b-alert variant="danger" :show="errorMessage !== ''">
          <i class="fas fa-exclamation-circle fa-2x pull-left m-r-10"></i>
          <p class="m-b-0">{{ errorMessage }}</p>
        </b-alert>

        <div style="max-width: 700px">
          <div v-if="seasons.length > 1">
            <label class="f-w-700">High Season</label>
            <i v-if="seasonId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
            <select class="form-control" v-model="seasonId" :disabled="seasonId != 0">
              <option value="0" hidden selected v-if="seasonId === 0">Select a Season</option>
              <option
                v-for="season in seasons"
                :value="season.id"
                :key="season.id"
              >{{ season.label }}</option>
            </select>
          </div>
          <div v-else-if="seasons.length == 1">
            <label class="f-w-700">High Season:</label>
            <label class="m-l-10">{{ seasons[0].label }}</label>
          </div>
          <div v-else>No Active Seasons</div>
          <div v-if="seasonId != 0">
            <div style="margin-top: 25px">
              <label class="f-w-700">Production Fridge</label>
              <i v-if="fridgeId == 0" class="fas fa-asterisk m-l-10 text-red f-s-8"></i>
              <select class="form-control" v-model="fridgeId" @change="loadProducts">
                <option value="0" hidden selected v-if="fridgeId === 0">Select a production fridge</option>
                <option
                  v-for="fridge in fridges"
                  :value="fridge.fridgeId"
                  :key="fridge.fridgeId"
                >{{ fridge.label }}</option>
              </select>
            </div>
          </div>
          <div v-if="sku !== ''">
            <div style="margin-top: 25px">
              <label class="f-w-700">Product</label>
              <div>{{ sku + " (" + productName + ")" }}</div>
            </div>
            <div style="margin-top: 25px">
              <label class="f-w-700">Quantity</label>
              <input
                type="number"
                min="0"
                class="form-control"
                style="margin-bottom: 5px"
                v-model="quantity"
                @keyup="checkQty"
              />
            </div>
            <div>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="submit"
                :disabled="rows.length == 0"
              >Update Quantity</button>
              <button
                type="button"
                class="btn btn-success m-5"
                style="float: right"
                @click="cancel"
              >Cancel</button>
            </div>
          </div>
        </div>
        <div v-if="seasonId != 0 && fridgeId != 0 && !spinnerIsLoading" style="margin-top: 75px">
          <vue-good-table
            ref="productsTable"
            styleClass="vgt-table striped bordered"
            :columns="columns"
            :rows="rows"
            :line-numbers="false"
            :pagination-options="paginationOptions"
            :selectOptions="selectOptions"
            :search-options="searchOptions"
            :sort-options="sortOptions"
          >
            <div slot="emptystate">No products found</div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'action'">
                <button
                  type="button"
                  class="btn btn-link"
                  style="text-decoration: none; color: inherit"
                  @click="edit(props.row)"
                >Edit Quantity</button>
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </panel>
  </div>
</template>
<script>
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export default {
  data() {
    return {
      spinnerIsLoading: false,
      spinnerUserCanCancel: false,
      spinnerFullPage: true,
      spinnerOpacity: 0.2,
      showSuccessMessage: false,
      errorMessage: "",
      columns: [
        {
          label: "SKU",
          field: "sku",
          tdClass: "text-left"
        },
        {
          label: "Product",
          field: "productName",
          tdClass: "text-left"
        },
        {
          label: "Quantity",
          field: "quantity",
          type: "number",
          tdClass: "text-left"
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center",
          sortable: false
        }
      ],
      rows: [],
      paginationOptions: {
        enabled: false
      },
      searchOptions: {
        enabled: true,
        placeholder: "Filter Products"
      },
      selectOptions: {
        enabled: false
      },
      sortOptions: {
        enabled: true,
        initialSortBy: [{ field: "productName", type: "asc" }]
      },
      btnAddDisabled: true,
      btnAddText: "Add",
      seasons: [],
      seasonId: 0,
      seasonIdOriginalValue: 0,
      fridges: [],
      fridgeId: 0,
      filter: {
        filter: [
          [
            {
              field: "enabled",
              value: true,
              condition: "eq"
            }
          ]
        ]
      },
      sku: "",
      productName: "",
      quantity: 1
    };
  },
  methods: {
    load: function() {
      this.spinnerIsLoading = true;
      this.$store
        .dispatch("retrieveHighSeasonsList", this.filter)
        .then(response => {
          this.seasons = response;

          if (this.seasons.length == 1) {
            this.seasonId = this.seasons[0].id;
            this.seasonIdOriginalValue = this.seasons[0].id;
          }

          let retrieveFridgeListFilter = {
            filter: [
              [
                {
                  field: "enabled",
                  value: true,
                  condition: "eq"
                }
              ],
              [
                {
                  field: "typeId",
                  value: 1,
                  condition: "eq"
                }
              ]
            ],
            sort: [{ field: "label", direction: "asc" }]
          };
          this.$store
            .dispatch("retrieveFridgeList", retrieveFridgeListFilter)
            .then(retrieveFridgeListResponse => {
              this.fridges = retrieveFridgeListResponse.data;
            })
            .catch(error => {
              let titleTxt = "Error";
              let msgTxt = error.message;

              if (
                error.message &&
                error.message === "Request failed with status code 403"
              ) {
                titleTxt = "Access Denied";
                msgTxt = "Please call Exotica administration for help";
              }
              this.spinnerIsLoading = false;
              this.$swal
                .fire({
                  type: "error",
                  title: titleTxt,
                  text: msgTxt,
                  showCancelButton: true,
                  allowOutsideClick: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Retry"
                })
                .then(result => {
                  if (result.value) {
                    this.load();
                  }
                });
            })
            .finally(() => {
              this.spinnerIsLoading = false;
            });
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.load();
              }
            });
        });
    },
    checkQty() {
      if (this.quantity < 0) this.quantity = 0;
    },
    edit(row) {
      this.sku = row.sku;
      this.productName = row.productName;
      this.quantity = row.quantity;
    },
    submit: function() {
      this.spinnerIsLoading = false;
      let data = {
        id: this.fridgeId,
        product: {
          highSeasonId: this.seasonId,
          sku: this.sku,
          quantity: this.quantity
        }
      };

      this.$store
        .dispatch("updateFridgeProductQuantity", data)
        .then(response => {
          if (response.data.messageType === "Error") {
            this.errorMessage = response.data.message;
          } else {
            this.showSuccessMessage = true;
            this.cancel();
            this.loadProducts();
            sleep(3000).then(() => {
              this.showSuccessMessage = false;
            });
          }
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }

          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.submit();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    },
    reset: function() {
      this.rows = [];
      this.sku = "";
      this.productName = "";
      this.quantity = 0;
      this.fridgeId = 0;
      this.seasonId = this.seasonIdOriginalValue;
    },
    cancel: function() {
      this.sku = "";
      this.productName = "";
      this.quantity = 0;
    },
    loadProducts: function() {
      this.errorMessage = "";

      this.spinnerIsLoading = true;
      let data = {
        seasonId: this.seasonId,
        fridgeId: this.fridgeId
      };

      this.$store
        .dispatch("retrieveFridgeProducts", data)
        .then(response => {
          this.rows = response;
        })
        .catch(error => {
          let titleTxt = "Error";
          let msgTxt = error.message;

          if (
            error.message &&
            error.message === "Request failed with status code 403"
          ) {
            titleTxt = "Access Denied";
            msgTxt = "Please call Exotica administration for help";
          }
          this.spinnerIsLoading = false;
          this.$swal
            .fire({
              type: "error",
              title: titleTxt,
              text: msgTxt,
              showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Retry"
            })
            .then(result => {
              if (result.value) {
                this.loadProducts();
              }
            });
        })
        .finally(() => {
          this.spinnerIsLoading = false;
        });
    }
  },
  created() {
    this.load();
  }
};
</script>